import {
  Button,
  Container,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { CompanyReportLayout, FileResponse, ReportCoverImage, SloganResult, UserType } from '../../Swagger/api';
import React, { useContext, useEffect, useState } from 'react';

import { LoginContext } from '../../Contexts/loginContext';
import { generateCompanyReport } from '../../words/companyReport';
import { localize } from '../../Localization/localize';
import { getApis } from '../../Services/webservice';

interface ICompanyReport {
  onClose: () => void;
  starId: number;
  companyId?: number;
}

export const CompanyReport = (props: ICompanyReport) => {
  const [selectedLayout, setSelectedLayout] = useState<CompanyReportLayout>(-1);
  const [selectedImageId, setSelectedImageId] = useState<number>(1);
  const [slogan, setSlogan] = useState('');
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>();
  const [coverImageRef, setCoverImageRef] = useState<HTMLInputElement | null>();
  const [coverImageUrl, setCoverImageUrl] = useState<string>();
  const [textColor, setTextColor] = useState<string>('');
  const [uploadingCoverImage, setUploadigCoverImage] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const loginContext = useContext(LoginContext);

  const { reportClient } = { ...getApis() };

  useEffect(() => {
    loadLayout();
    loadCoverImage();
    loadSlogan();
    loadLogo();
    loadTextColor();
  }, []);

  useEffect(() => {
    saveLayoutAndCoverImage();
  }, [selectedLayout, selectedImageId]);

  const loadLayout = async () => {
    let result: CompanyReportLayout;
    if (props.companyId) {
      result = await reportClient.getLayoutAdmin(props.companyId);
    } else {
      result = await reportClient.getLayout();
    }
    setSelectedLayout(result);
  };

  const saveLayoutAndCoverImage = async () => {
    if ((selectedLayout as any) !== -1) {
      if (props.companyId) {
        await reportClient.setLayoutAdmin(props.companyId, selectedLayout);
      } else {
        await reportClient.setLayout(selectedLayout);
      }
    }

    if (props.companyId) {
      await reportClient.setPredefinedCoverImageAdmin(props.companyId, selectedImageId);
    } else {
      await reportClient.setPredefinedCoverImage(selectedImageId);
    }

    if (props.companyId) {
      await reportClient.setCustomCoverImageAdmin(props.companyId, selectedImageId === -1);
    } else {
      await reportClient.setCustomCoverImage(selectedImageId === -1);
    }
  };

  const loadCoverImage = async () => {
    let reportCoverImage: ReportCoverImage;
    if (props.companyId) {
      reportCoverImage = await reportClient.getCoverImageAdmin(props.companyId);
    } else {
      reportCoverImage = await reportClient.getCoverImage();
    }
    if (reportCoverImage.customImage) {
      let customImageResult: FileResponse | null;
      if (props.companyId) {
        customImageResult = await reportClient.getCustomCoverImageAdmin(props.companyId);
      } else {
        customImageResult = await reportClient.getCustomCoverImage();
      }
      if (customImageResult?.data) {
        const imageUrl = window.URL.createObjectURL(customImageResult?.data);
        setCoverImageUrl(imageUrl);
      }
    }

    setSelectedImageId(reportCoverImage.predefinedImageId || 1);
  };

  const loadSlogan = async () => {
    let result: SloganResult;
    if (props.companyId) {
      result = await reportClient.getCompanySloganAdmin(props.companyId);
    } else {
      result = await reportClient.getCompanySlogan();
    }
    setSlogan(result.value || '');
  };

  const saveSlogan = async () => {
    if (props.companyId) {
      await reportClient.setCompanySloganAdmin(props.companyId, slogan);
    } else {
      await reportClient.setCompanySlogan(slogan);
    }
  };

  const loadTextColor = async () => {
    let result: SloganResult;
    if (props.companyId) {
      result = await reportClient.getTextColorAdmin(props.companyId);
    } else {
      result = await reportClient.getTextColor();
    }
    setTextColor(result.value || '');
  };

  const saveTextColor = async () => {
    if (props.companyId) {
      await reportClient.setTextColorAdmin(props.companyId, textColor);
    } else {
      await reportClient.setTextColor(textColor);
    }
  };

  const loadLogo = async () => {
    let result: FileResponse | null;
    if (props.companyId) {
      result = await reportClient.getLogoAdmin(props.companyId);
    } else {
      result = await reportClient.getLogo();
    }
    if (result) {
      const imageUrl = window.URL.createObjectURL(result?.data);
      setLogoUrl(imageUrl);
    }
  };

  const uploadLogo = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event?.target?.files?.length || 0) < 1) {
      return;
    }

    const file = event!.target!.files![0];

    if (file.size / 1024 > 2048) {
      alert(localize.fileTooBig);
      return;
    }

    setUploadingLogo(true);

    const fileParameter = { fileName: file.name, data: file };

    if (loginContext.login?.userType == 1) {
      await reportClient.uploadLogo(fileParameter);
    } else {
      await reportClient.uploadLogoAdmin(props.companyId, fileParameter);
    }

    loadLogo();
    setUploadingLogo(false);
  };

  const uploadCoverImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event?.target?.files?.length || 0) < 1) {
      return;
    }

    const file = event!.target!.files![0];

    if (file.size / 1024 > 2048) {
      alert(localize.fileTooBig);
      return;
    }

    setCoverImageUrl('');
    setUploadigCoverImage(true);
    const fileParameter = { fileName: file.name, data: file };

    if (loginContext.login?.userType == 1) {
      await reportClient.uploadCoverImage(fileParameter);
    } else {
      await reportClient.uploadCoverImageAdmin(props.companyId, fileParameter);
    }

    if (props.companyId) {
      await reportClient.setCustomCoverImageAdmin(props.companyId, true);
    } else {
      await reportClient.setCustomCoverImage(true);
    }
    await loadCoverImage();
    setUploadigCoverImage(false);
  };

  const checkForCoverImageUpload = async () => {
    if (!coverImageRef) {
      return;
    }

    if (!coverImageUrl) {
      coverImageRef.click();
    } else if (coverImageUrl && selectedImageId !== -1) {
      setSelectedImageId(-1);
    } else if (coverImageUrl && selectedImageId === -1) {
      coverImageRef.click();
    }
  };

  const downloadReport = async () => {
    setDownloading(true);
    setHasError(false);
    try {
      await generateCompanyReport(props.starId, loginContext.login?.userType === UserType.Admin);
    } catch (ex) {
      console.error(ex);
      setHasError(true);
    }

    setDownloading(false);
  };

  return (
    <Modal dimmer="blurring" open={true} closeIcon onClose={props.onClose}>
      <Modal.Header>{localize.generateCompanyReport}</Modal.Header>
      <Modal.Content>
        <Header>{localize.generateCompanyReportStep1}</Header>
        <Container textAlign="center" className="dd-layout-container">
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <img
                  src="/assets/images/companyReport/companyreport1.jpg"
                  className={`dd-layout-image ${selectedLayout === CompanyReportLayout.Down ? 'selected' : ''}`}
                  onClick={() => setSelectedLayout(CompanyReportLayout.Down)}
                />
                <br />
                {selectedLayout === CompanyReportLayout.Down ? <Label pointing>{localize.selectedLowerCase}</Label> : null}
              </Grid.Column>
              <Grid.Column>
                <img
                  src="/assets/images/companyReport/companyreport2.jpg"
                  className={`dd-layout-image ${selectedLayout === CompanyReportLayout.Up ? 'selected' : ''}`}
                  onClick={() => setSelectedLayout(CompanyReportLayout.Up)}
                />
                <br />
                {selectedLayout === CompanyReportLayout.Up ? <Label pointing>{localize.selectedLowerCase}</Label> : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Header>{localize.generateCompanyReportStep2}</Header>
        <Container textAlign="center" className="dd-layout-container">
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <img
                  className={`dd-cover-image ${selectedImageId === 1 ? 'selected' : ''}`}
                  src="/assets/images/companyReport/pic1.jpg"
                  onClick={() => setSelectedImageId(1)}
                />
                {selectedImageId === 1 ? <Label pointing>{localize.selectedLowerCase}</Label> : null}
              </Grid.Column>
              <Grid.Column>
                <img
                  className={`dd-cover-image ${selectedImageId === 2 ? 'selected' : ''}`}
                  src="/assets/images/companyReport/pic2.jpg"
                  onClick={() => setSelectedImageId(2)}
                />
                {selectedImageId === 2 ? <Label pointing>{localize.selectedLowerCase}</Label> : null}
              </Grid.Column>
              <Grid.Column>
                <img
                  className={`dd-cover-image ${selectedImageId === 3 ? 'selected' : ''}`}
                  src="/assets/images/companyReport/pic3.jpg"
                  onClick={() => setSelectedImageId(3)}
                />
                {selectedImageId === 3 ? <Label pointing>{localize.selectedLowerCase}</Label> : null}
              </Grid.Column>
              <Grid.Column>
                {coverImageUrl && !uploadingCoverImage ? (
                  <img
                    className={`dd-cover-image ${selectedImageId === -1 ? 'selected' : ''}`}
                    src={coverImageUrl}
                    onClick={checkForCoverImageUpload}
                  />
                ) : (
                  <Segment
                    className={`dd-upload ${uploadingCoverImage ? 'dd-nohover' : ''}`}
                    onClick={checkForCoverImageUpload}
                  >
                    {uploadingCoverImage ? (
                      <Dimmer active inverted>
                        <Loader active inverted></Loader>
                      </Dimmer>
                    ) : (
                      <Icon name="upload" size="huge" />
                    )}
                  </Segment>
                )}
                {selectedImageId === -1 ? <Label pointing>{localize.selectedLowerCase}</Label> : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <input
            type="file"
            ref={(ref) => setCoverImageRef(ref)}
            onChange={uploadCoverImage}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </Container>
        <Message
          icon="info"
          header={localize.fileUploadMessage}
          content={<div dangerouslySetInnerHTML={{ __html: localize.fileUploadContent }}></div>}
        />
        <Header>{localize.generateCompanyReportStep3}</Header>
        <Container textAlign="center" className="dd-layout-container">
          <Input type="file" onChange={uploadLogo} fluid accept="image/*" />
          <Segment className="dd-logo">
            {uploadingLogo ? (
              <Dimmer active inverted>
                <Loader active inverted>
                  {localize.fileUploading}
                </Loader>
              </Dimmer>
            ) : logoUrl ? (
              <img src={logoUrl} />
            ) : (
              <Icon name="file image outline" size="huge" />
            )}
          </Segment>
        </Container>
        <Header>{localize.generateCompanyReportStep4}</Header>
        <Form>
          <Form.TextArea value={slogan} onChange={(_e, { value }) => setSlogan(value as string)} onBlur={saveSlogan} />
        </Form>
        <Header>{localize.generateCompanyReportStep5}</Header>
        <section className="dd-color-picker">
          <Input type="text" value={textColor} onChange={(_e, { value }) => setTextColor(value)} onBlur={saveTextColor} />
          <div className="dd-color" style={{ backgroundColor: `#${textColor?.replace('#', '')}` }}></div>
        </section>
        <Header>{localize.generateCompanyReportStep6}</Header>
        {uploadingCoverImage || uploadingLogo || !logoUrl || !slogan ? (
          <Message
            warning
            header={localize.companyReportFinalMessageHeader}
            content={localize.companyReportFinalMessageContent}
          />
        ) : (
          <Button loading={downloading} primary fluid onClick={downloadReport}>
            {localize.downloadNowLowerCase}
          </Button>
        )}
        <Message error header={localize.generalErrorHeader} content={localize.generalErrorMessage} hidden={!hasError} />
      </Modal.Content>
    </Modal>
  );
};
