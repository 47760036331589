import React from "react";
import { Header, Icon, Divider } from "semantic-ui-react";
import { localize } from "../../Localization/localize";

export const Offline = () => (
  <section>
    <Divider horizontal hidden></Divider>
    <Divider horizontal hidden></Divider>
    <Header icon textAlign="center">
      <Icon name="cloud download"></Icon>
      {localize.notOnlineMessage}
    </Header>
  </section>
);
