import React, { useEffect, useState } from 'react';
import { Button, Container, Divider, Form, Header, Icon, Label, Message, Segment } from 'semantic-ui-react';
import { localize } from '../../../Localization/localize';
import Textarea from 'react-textarea-autosize';
import { getApis } from '../../../Services/webservice';
import localForage from 'localforage';
import { AuditNotes as IAuditNotes } from '../../../Swagger/api';

let typingTimeOut: any;
export const updatesKey = `audit_notes_updated_companyIds`;
export const getLocalKey = (companyId: number) => `audit_notes_${companyId}`;

export const AuditNotes = ({ companyId }: { companyId?: number }) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [autoSaving, setAutoSaving] = useState(false);

  useEffect(() => {
    loadNotes();
  }, [companyId]);

  useEffect(() => {
    clearTimeout(typingTimeOut);
    typingTimeOut = setTimeout(async () => {
      setAutoSaving(true);
      await saveToServer();
      setAutoSaving(false);
    }, 3000);

    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [content]);

  const { auditNotesClient } = getApis();

  const loadNotes = async () => {
    if (!companyId) {
      return;
    }

    try {
      const data = await localForage.getItem<IAuditNotes>(getLocalKey(companyId));
      setContent(data?.notes || '');
    } catch (ex) {
      console.error(ex);
      setHasError(true);
    }
    setLoading(false);
  };

  const saveToServer = async () => {
    clearTimeout(typingTimeOut);
    if (!companyId) {
      return;
    }

    setLoading(true);
    await saveToLocalDb();

    try {
      if (navigator.onLine) {
        await auditNotesClient.setAuditNotes({ companyId, notes: content });
      }
    } catch (ex) {
      console.error(ex);
      setHasError(true);
    }
    setLoading(false);
  };

  const saveToLocalDb = async () => {
    if (!companyId) {
      return;
    }

    try {
      const existingData = await localForage.getItem<IAuditNotes>(getLocalKey(companyId));
      if (existingData?.notes === content) {
        return;
      }
      await localForage.setItem(getLocalKey(companyId), {
        companyId,
        notes: content,
      });
      const existingUpdates = await localForage.getItem<number[]>(updatesKey);
      if (!existingUpdates) {
        await localForage.setItem(updatesKey, [companyId]);
      } else {
        if (existingUpdates.indexOf(companyId) === -1) {
          await localForage.setItem(updatesKey, [...existingUpdates, companyId]);
        }
      }
    } catch (ex) {
      console.error(ex);
      setHasError(true);
    }
  };

  return (
    <Segment>
      <Header>{localize.auditNotesHeader}</Header>
      <Form>
        <Textarea
          readOnly={loading || hasError}
          value={content}
          onChange={(event) => setContent(event.currentTarget?.value || '')}
        />
      </Form>
      <Divider horizontal />
      {hasError && <Message error content={localize.generalErrorMessage} />}
      <Container fluid textAlign="right">
        {autoSaving && (
          <Label basic pointing="right">
            {localize.savingAuditNotes}
          </Label>
        )}
        <Button icon labelPosition="left" disabled={hasError || loading} loading={loading} onClick={saveToServer}>
          <Icon name="save" />
          {localize.saveLowerCase}
        </Button>
      </Container>
    </Segment>
  );
};
