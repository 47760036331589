import {
  Button,
  Divider,
  Icon,
  Input,
  InputOnChangeData,
  Message,
  Modal,
} from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { CertificationCenter } from "../../../Swagger/api";
import { localize } from "../../../Localization/localize";
import { getApis } from "../../../Services/webservice";

export interface ICertificationCenters {
  onCancel: () => void;
  onDone: () => void;
}

export const CertificationCenters = (props: ICertificationCenters) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [items, setItems] = useState<CertificationCenter[]>([]);
  const { userAccountsClient } = { ...getApis() };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setHasError(false);
      try {
        const items = await userAccountsClient.getAllCertificationCenters();
        setItems(items);
      } catch {
        setHasError(true);
      }
      setLoading(false);
    })();
  }, []);

  const { onCancel, onDone } = { ...props };

  const onInputChange = (
    data: InputOnChangeData,
    currentItem: CertificationCenter
  ) => {
    const copy = items.slice(0, items.length);
    for (const item of copy) {
      if (item.id == currentItem.id) {
        item.title = data.value;
      }
    }

    setItems(copy);
  };

  const onActivateChange = (currentItem: CertificationCenter) => {
    const copy = items.slice(0, items.length);
    for (const item of copy) {
      if (item.id == currentItem.id) {
        item.deactivated = !currentItem.deactivated;
      }
    }

    setItems(copy);
  };

  const addNewItem = () => {
    const copy = items.slice(0, items.length);
    copy.push({
      deactivated: false,
      id: (items.length + 1) * -1,
      title: "",
    });
    setItems(copy);
  };

  const onSave = async () => {
    setLoading(true);
    setHasError(false);
    try {
      await userAccountsClient.setCertificationCenters(items);
      onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>{localize.certificationCenters}</Modal.Header>
      <Modal.Content>
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <Input
              action={{
                content: item.deactivated
                  ? localize.activateLowerCase
                  : localize.deactivateLowerCase,
                onClick: () => {
                  onActivateChange(item);
                },
              }}
              value={item.title}
              fluid
              spellCheck="false"
              onChange={(_event, data) => {
                onInputChange(data, item);
              }}
            />
            <Divider horizontal hidden />
          </React.Fragment>
        ))}
        <Divider horizontal hidden />
        {hasError ? (
          <Message
            error
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        ) : null}
        <div className="dd-certificationCenters-footerMessage">
          <Divider horizontal hidden />
          {localize.certificationCentersFooterMessage}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} floated="left">
          {localize.cancelLowerCase}
        </Button>
        <Button icon labelPosition="left" onClick={addNewItem}>
          <Icon name="plus" />
          {localize.insertLowerCase}
        </Button>

        <Button
          primary
          icon
          labelPosition="right"
          onClick={onSave}
          loading={loading}
        >
          <Icon name="save" />
          {localize.saveLowerCase}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
