import {
  CompaniesStarAuditorOverview,
  SubCompany,
  UserAccountCompany,
} from "../../Swagger/api";
import { Divider, Form, FormGroup, Header } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { companiesDbKey } from "./auditorOverview";
import localForage from "localforage";
import { localize } from "../../Localization/localize";
import { useParams } from "react-router-dom";

export const CompanyProfileReadOnly = () => {
  const [company, setCompany] = useState<UserAccountCompany>();
  const [subCompanies, setSubCompanies] = useState<SubCompany[]>([]);
  const { companyId } = useParams<{ companyId: string }>();

  useEffect(() => {
    loadCompaniesDb();
  }, []);

  const loadCompaniesDb = async () => {
    const data = await localForage.getItem<CompaniesStarAuditorOverview>(
      companiesDbKey
    );
    if (!data) {
      return;
    }
    const currentCompany = data.companies?.find(
      (x) => x.userLoginId == Number(companyId)
    );
    setCompany(currentCompany);

    const currentSubCompanies = data.subCompanies?.filter(
      (x) => x.parentCompanyId == Number(companyId)
    );
    setSubCompanies(currentSubCompanies || []);
  };

  if (!company) {
    return <div></div>;
  }

  return (
    <section>
      <Header size="huge">{company.companyTitle}</Header>
      <Form>
        <Form.Input
          label={localize.emailAddress}
          type="email"
          value={company.userLogin ? company.userLogin.emailAddress : ""}
          readOnly
        />

        <Header>{localize.contactPerson}</Header>
        <Form.Group widths="equal">
          <Form.Input
            label={localize.firstName}
            type="text"
            value={company.firstName}
            readOnly
          />
          <Form.Input
            label={localize.lastName}
            type="text"
            value={company.lastName}
            readOnly
          />
        </Form.Group>
        <FormGroup>
          <Form.Input
            width="11"
            label={localize.myCompanyWithLegalForm}
            type="text"
            value={company.companyTitle}
            readOnly
          />
          <Form.Input
            width="5"
            label={localize.companyType}
            type="text"
            value={(company.companyTypes || []).map((x) => x.title).join(", ")}
            readOnly
          />
        </FormGroup>

        <Header>{localize.contactInfo}</Header>
        <Form.Input
          label={localize.streetAndHouseNumber}
          type="text"
          value={company.street}
          readOnly
        />
        <Form.Group>
          <Form.Input
            width="4"
            label={localize.postCode}
            type="text"
            value={company.postcode}
            readOnly
          />
          <Form.Input
            width="6"
            label={localize.city}
            type="text"
            value={company.city}
            readOnly
          />
          <Form.Input
            width="6"
            label={localize.state}
            type="text"
            value={company.state}
            readOnly
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label={localize.telephoneNumber}
            type="text"
            value={company.telephoneNumber}
            readOnly
          />
          <Form.Input
            label={localize.website}
            type="text"
            value={company.webSite}
            readOnly
          />
        </Form.Group>

        <Header>{localize.fulltimePositions}</Header>
        <Form.Input value={company.fullTimePositions} type="number" readOnly />
      </Form>
      {subCompanies.map((subCompany) => (
        <article key={subCompany.id}>
          <Divider horizontal hidden></Divider>
          <Header size="huge">
            {subCompany.companyTitle || localize.noSubCompanyName} (
            {localize.subCompanyUpperCase})
          </Header>
          <Form>
            <Form.Input
              label={localize.emailAddress}
              type="email"
              value={subCompany.emailAddress}
              readOnly
            />
            <Header>{localize.contactPerson}</Header>
            <Form.Group widths="equal">
              <Form.Input
                label={localize.firstName}
                type="text"
                value={subCompany.firstName}
                readOnly
              />
              <Form.Input
                label={localize.lastName}
                type="text"
                value={subCompany.lastName}
                readOnly
              />
            </Form.Group>
            <FormGroup>
              <Form.Input
                width="11"
                label={localize.myCompanyWithLegalForm}
                type="text"
                value={subCompany.companyTitle}
                readOnly
              />
              <Form.Input
                width="5"
                label={localize.companyType}
                type="text"
                value={(subCompany.companyTypes || [])
                  .map((c) => c.title)
                  .join(", ")}
                readOnly
              />
            </FormGroup>

            <Header>{localize.contactInfo}</Header>
            <Form.Input
              label={localize.streetAndHouseNumber}
              type="text"
              value={subCompany.street}
              readOnly
            />
            <Form.Group>
              <Form.Input
                width="4"
                label={localize.postCode}
                type="text"
                value={subCompany.postcode}
                readOnly
              />
              <Form.Input
                width="6"
                label={localize.city}
                type="text"
                value={subCompany.city}
                readOnly
              />
              <Form.Input
                width="6"
                label={localize.state}
                type="text"
                value={subCompany.state}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label={localize.telephoneNumber}
                type="text"
                value={subCompany.telephoneNumber}
                readOnly
              />
              <Form.Input
                label={localize.website}
                type="text"
                value={subCompany.webSite}
                readOnly
              />
            </Form.Group>
          </Form>
        </article>
      ))}
    </section>
  );
};
