import { ArchivedStarItem, UserType } from '../../Swagger/api';
import { Dropdown, Grid, Header, Icon, Menu, Message, Search, Segment } from 'semantic-ui-react';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { ArchiveConfirm } from '../auditor/archiveConfirm';
import { LoginContext } from '../../Contexts/loginContext';
import { Offline } from '../layout/offline';
import { OverviewItemPlaceholder } from '../company/overview/overviewItemPlaceholder';
import { formatDate } from '../company/overview/companyOverview';
import { localize } from '../../Localization/localize';
import { getApis } from '../../Services/webservice';

const initialFilters: {
  [key: string]: {
    name: string | undefined;
    selectedValue: string | number;
  };
} = {
  starYear: { name: localize.yearStartedFilter, selectedValue: -1 },
  starContentVersion: { name: localize.starContentVersion, selectedValue: -1 },
  searchQuery: { name: undefined, selectedValue: '' },
};

export const Archive = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [items, setItems] = useState<ArchivedStarItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<ArchivedStarItem[]>([]);
  const [selectedRetrieveArchive, setSelectedRetrieveArchive] = useState<number | undefined>(undefined);
  const [filters, setFilters] = useState(initialFilters);

  const loginContext = useContext(LoginContext);

  const { starClient } = { ...getApis() };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    let data = [...items];

    if (filters.starYear.selectedValue !== -1) {
      data = data.filter((x) => new Date(x.started).getFullYear() === Number(filters.starYear.selectedValue));
    }

    if (filters.starContentVersion.selectedValue !== -1) {
      data = data.filter((x) => x.starVersion === filters.starContentVersion.selectedValue);
    }

    if (filters.searchQuery.selectedValue !== -1) {
      const filterValue = (filters.searchQuery.selectedValue as string).toLowerCase();
      data = data.filter((x) => x.company?.toLowerCase().indexOf(filterValue) !== -1);
    }

    setFilteredItems(data);
  }, [filters, items]);

  const loadData = async () => {
    if (!navigator.onLine) {
      return;
    }

    const isAdmin = loginContext.login?.userType === UserType.Admin;
    let data: ArchivedStarItem[];
    setHasError(false);
    setLoading(true);
    try {
      if (isAdmin) {
        data = await starClient.getArchivedStarItemsAdmin();
      } else {
        data = await starClient.getArchivedStarItems();
      }

      setItems(data);
    } catch {
      setHasError(true);
    }
    setLoading(false);
  };

  const getFilterOptions = (filterKey: string) => {
    if (filterKey === 'starYear') {
      const years: number[] = [];
      const uniqueYears = items
        .filter((x) => x.started)
        .map((x) => new Date(x.started).getFullYear())
        .reduce((a, c) => {
          if (a.indexOf(c) === -1) {
            return [...a, c];
          } else {
            return a;
          }
        }, years);

      uniqueYears.sort((a, b) => (a > b ? 1 : -1));
      return [
        { text: localize.all, value: -1, key: 0 },
        ...uniqueYears.map((y) => ({
          text: y,
          value: y,
          key: y,
        })),
      ];
    }

    if (filterKey === 'starContentVersion') {
      const versions: string[] = [];
      const uniqueVersions = items
        .filter((x) => x.starVersion)
        .map((x) => x.starVersion)
        .reduce((a, c) => {
          if (!c) {
            return a;
          }
          if (a.indexOf(c) === -1) {
            return [...a, c];
          } else {
            return a;
          }
        }, versions);

      uniqueVersions.sort((a, b) => (a > b ? 1 : -1));
      return [
        { text: localize.all, value: -1, key: 0 },
        ...uniqueVersions.map((y) => ({
          text: y,
          value: y,
          key: y,
        })),
      ];
    }
  };

  if (!navigator.onLine) {
    return <Offline></Offline>;
  }

  return (
    <>
      <Header size="huge">{localize.archive}</Header>
      <Search
        onSearchChange={(_event, { value }) =>
          setFilters((p) => ({ ...p, searchQuery: { ...p.searchQuery, selectedValue: value as string } }))
        }
        fluid
        input={{ fluid: true, placeholder: localize.searchStars }}
        open={false}
      />
      <Segment>
        {Object.keys(filters)
          .filter((key) => filters[key].name)
          .map((key) => (
            <Fragment key={key}>
              <strong>{filters[key].name}: </strong>
              <Dropdown
                inline
                className="dd-filters"
                options={getFilterOptions(key)}
                value={filters[key].selectedValue}
                onChange={(_, { value }) =>
                  setFilters((p) => ({ ...p, [key]: { name: p[key].name, selectedValue: value as any } }))
                }
              />
            </Fragment>
          ))}
      </Segment>
      {loading && <OverviewItemPlaceholder />}
      {hasError && <Message error header={localize.signinErrorHeader} content={localize.signinErrorMessage}></Message>}
      {filteredItems?.map((item) => (
        <Segment key={item.id} className="dd-star-list-item">
          <Grid divided>
            <Grid.Row>
              <Grid.Column width={12}>
                <p title={item.company || '-'}>
                  <Icon name="building" />
                  <span className="dd-item-title">{item.company || '-'}</span>
                </p>
                <p title="Auditor*in">
                  <Icon name="user" />
                  {item.auditorFullName || '-'}
                </p>
                <p title={item.starVersion || '-'}>
                  <Icon name="star" />
                  {item.starVersion || '-'}
                </p>
                <div className="dd-compact-grid">
                  <div className="dd-first-column">
                    <strong>{localize.startedAtDate}</strong>
                  </div>
                  <div className="dd-arrow-column"></div>
                  <div className="dd-second-column">
                    <strong>{localize.availableForAuditDate}</strong>
                  </div>
                </div>
                <div className="dd-compact-grid">
                  <div className="dd-first-column">{item.started ? formatDate(new Date(item.started)) : '-'}</div>
                  <div className="dd-arrow-column">
                    <Icon name="arrow right" />
                  </div>
                  <div className="dd-second-column">
                    {item.auditAvailable ? formatDate(new Date(item.auditAvailable)) : '-'}
                  </div>
                </div>
                <div className="dd-compact-grid">
                  <div>
                    <div className="dd-grid-separator" />
                  </div>
                </div>
                <div className="dd-compact-grid">
                  <div className="dd-first-column">
                    <strong>{localize.auditStartedDate}</strong>
                  </div>
                  <div className="dd-arrow-column"></div>
                  <div className="dd-second-column">
                    <strong>{localize.auditDoneDate}</strong>
                  </div>
                </div>
                <div className="dd-compact-grid">
                  <div className="dd-first-column">{item.auditStarted ? formatDate(new Date(item.auditStarted)) : '-'}</div>
                  <div className="dd-arrow-column">
                    <Icon name="arrow right" />
                  </div>
                  <div className="dd-second-column">{item.auditFinished ? formatDate(new Date(item.auditFinished)) : '-'}</div>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <Menu vertical secondary fluid>
                  <Menu.Item onClick={() => setSelectedRetrieveArchive(item.id)}>{localize.retrieveFromArchive}</Menu.Item>
                </Menu>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ))}
      {selectedRetrieveArchive && (
        <ArchiveConfirm
          starId={selectedRetrieveArchive}
          onCancel={() => setSelectedRetrieveArchive(undefined)}
          onDone={() => {
            setSelectedRetrieveArchive(undefined);
            loadData();
          }}
          archive={false}
        />
      )}
    </>
  );
};
