import React, { useEffect, useState } from 'react';
import { DropdownItemProps, DropdownProps, Form, Grid, Image, Message } from 'semantic-ui-react';
import { localize } from '../../Localization/localize';
import { getApis } from '../../Services/webservice';
import { GraphicalResultCategory, GraphicAssignment, QuestionCategory } from '../../Swagger/api';
import { GraphicalDisplay } from '../graphicalDisplay/graphicalDisplay';

export const GraphicAssigment = () => {
  const [assignments, setAssignments] = useState<GraphicAssignment[]>([]);
  const [categories, setCategories] = useState<QuestionCategory[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { questionClient, graphicClient } = { ...getApis() };

  useEffect(() => {
    loadAssignments();
    loadCategories();
  }, []);

  const loadAssignments = async () => {
    const data = await graphicClient.getGraphicAssignments();
    setAssignments(data);
  };

  const loadCategories = async () => {
    const data = await questionClient.getQuestionCategories();
    setCategories(data);
  };

  const getValue = (partId: number) => {
    const existing = assignments.find((x) => x.partId == partId);
    return existing ? existing.categoryId : -1;
  };

  const setAssignment = (partId: number, option: DropdownProps) => {
    const copy = [...assignments];
    var existing = copy.find((x) => x.partId == partId);

    if (existing) {
      existing.categoryId = option.value as number;
    } else {
      copy.push({ partId, categoryId: option.value as number, id: 0 });
    }

    setAssignments(copy);
  };

  const getOptions = (): DropdownItemProps[] => {
    return categories.map((x) => ({ value: x.id, text: x.title }));
  };

  const save = async () => {
    setLoading(true);

    try {
      await graphicClient.setGraphicAssignment(assignments);
    } catch {
      setHasError(true);
    }

    setLoading(false);
  };

  const results: GraphicalResultCategory[] = assignments.map((x) => ({
    categoryId: x.categoryId,
    procent: 100,
    categoryColor: categories.find((y) => y.id == x.categoryId)?.color || 'white',
  }));

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6} verticalAlign="middle">
          <GraphicalDisplay assignments={assignments} results={results} />
          <Image src="/assets/images/admin_star_legend.png" />
        </Grid.Column>
        <Grid.Column width={10}>
          <Form>
            <Form.Select
              label={localize.graphicCenter}
              value={getValue(1)}
              onChange={(_event, option) => setAssignment(1, option)}
              options={getOptions()}
            />
            <Form.Select
              label={localize.formatString(localize.graphicPoint, 1)}
              value={getValue(2)}
              onChange={(_event, option) => setAssignment(2, option)}
              options={getOptions()}
            />
            <Form.Select
              label={localize.formatString(localize.graphicPoint, 2)}
              value={getValue(3)}
              onChange={(_event, option) => setAssignment(3, option)}
              options={getOptions()}
            />
            <Form.Select
              label={localize.formatString(localize.graphicPoint, 3)}
              value={getValue(4)}
              onChange={(_event, option) => setAssignment(4, option)}
              options={getOptions()}
            />
            <Form.Select
              label={localize.formatString(localize.graphicPoint, 4)}
              value={getValue(5)}
              onChange={(_event, option) => setAssignment(5, option)}
              options={getOptions()}
            />
            <Form.Select
              label={localize.formatString(localize.graphicPoint, 5)}
              value={getValue(6)}
              onChange={(_event, option) => setAssignment(6, option)}
              options={getOptions()}
            />
            <Form.Button loading={loading} primary onClick={save} floated="right">
              {localize.saveLowerCase}
            </Form.Button>
            <Message
              hidden={!hasError}
              error
              header={localize.generalErrorHeader}
              content={localize.generalErrorMessage}
            ></Message>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
