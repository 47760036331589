import React from "react";
import { Tab } from "semantic-ui-react";
import { localize } from "../../Localization/localize";
import { history, paths } from "../layout/layout";

const panes = [
  {
    menuItem: {
      content: localize.administrators,
      onClick: () => history.push(paths.admin.users.admins),
      key: 1,
    },
  },
  {
    menuItem: {
      content: localize.companies,
      onClick: () => history.push(paths.admin.users.companies),
      key: 2,
    },
  },
  {
    menuItem: {
      content: localize.auditors,
      onClick: () => history.push(paths.admin.users.auditors),
      key: 3,
    },
  },
  {
    menuItem: {
      content: localize.superAuditors,
      onClick: () => history.push(paths.admin.users.superAuditors),
      key: 4,
    },
  },
];

export const UserTabs = ({ activeIndex }: { activeIndex: number }) => (
  <Tab
    menu={{ secondary: true, pointing: true }}
    panes={panes}
    activeIndex={activeIndex}
  />
);
