import { Button, Divider, Icon, Message, Modal } from "semantic-ui-react";
import React, { useState } from "react";

import { UserLogin } from "../../Swagger/api";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export interface IDeleteUserAccount {
  userLogin: UserLogin;
  onDone: () => void;
  onCancel: () => void;
}

export const DeleteUserAccount = (props: IDeleteUserAccount) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { userAccountsClient } = { ...getApis() };

  const { userLogin, onDone, onCancel } = { ...props };

  const deleteUserAccount = async () => {
    setLoading(true);

    try {
      await userAccountsClient.delete(userLogin.id);

      setLoading(false);
      onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>{localize.userDeleteModalTitle}</Modal.Header>
      <Modal.Content>
        {localize.formatString(
          localize.userDeleteModalContent,
          userLogin.emailAddress || ""
        )}
        <Divider horizontal hidden />
        {hasError ? (
          <Message
            error
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} floated="left">
          {localize.cancelLowerCase}
        </Button>
        <Button
          primary
          icon
          labelPosition="right"
          onClick={deleteUserAccount}
          loading={loading}
        >
          <Icon name="user delete" />
          {localize.deleteLowerCase}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
