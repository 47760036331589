import { Button, Divider, Icon, Message, Modal } from "semantic-ui-react";
import React, { useState } from "react";

import { UserLogin } from "../../Swagger/api";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export interface IDeactivateUserLogin {
  userLogin: UserLogin;
  onDone: () => void;
  onCancel: () => void;
}

export const DeactivateUserLogin = (props: IDeactivateUserLogin) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { userAccountsClient } = { ...getApis() };

  const { userLogin, onDone, onCancel } = { ...props };

  const toggleUserLoginActivation = async () => {
    setLoading(true);

    try {
      await userAccountsClient.deactivate({
        userLoginId: userLogin.id,
        deactivate: !userLogin.deactivated,
      });

      setLoading(false);
      onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>
        {userLogin.deactivated
          ? localize.userActivateModalTitle
          : localize.userDeactivateModalTitle}
      </Modal.Header>
      <Modal.Content>
        {localize.formatString(
          localize.userLoginDeactivateConfirmMessage,
          userLogin.emailAddress || "",
          userLogin.deactivated
            ? localize.activateLowerCase
            : localize.deactivateLowerCase
        )}
        <Divider horizontal hidden />
        {hasError ? (
          <Message
            error
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} floated="left">
          {localize.cancelLowerCase}
        </Button>
        <Button
          primary
          icon
          labelPosition="right"
          onClick={toggleUserLoginActivation}
          loading={loading}
        >
          {userLogin.deactivated ? (
            <Icon name="user plus" />
          ) : (
            <Icon name="user delete" />
          )}
          {userLogin.deactivated
            ? localize.activateLowerCase
            : localize.deactivateLowerCase}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
