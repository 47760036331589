import { Divider, Icon, Menu } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";

import { AuditorAddEdit } from "./auditorAddEdit";
import { CertificationCenters } from "./certificationCenters/certificationCenters";
import { DeactivateUserLogin } from "./deactivateUserLogin";
import { DeleteUserAccount } from "./deleteUserAccount";
import { ItemsList } from "../common/itemsList/itemsList";
import { LoginContext } from "../../Contexts/loginContext";
import { ResetUserPassword } from "./resetPasswort";
import { UserAccount } from "./userAccount";
import { UserAccountAuditor } from "../../Swagger/api";
import { UserTabs } from "./UserTabs";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export const AdminUserAuditors = () => {
  const [userAccounts, setUserAccounts] = useState<UserAccountAuditor[]>([]);
  const [selectedForEdit, setSelectedForEdit] =
    useState<UserAccountAuditor | null>();
  const [userAccountsUpdated, setUserAccountsUpdated] = useState(false);
  const [selectedForDeactivate, setSelectedForDeactivate] =
    useState<UserAccountAuditor | null>();
  const [selectedForDelete, setSelectedForDelete] =
    useState<UserAccountAuditor | null>();
  const [selectedForReset, setSelectedForReset] =
    useState<UserAccountAuditor | null>();
  const [showCertificationCentersModal, setShowCertificationCentersModal] =
    useState(false);
  const loginContext = useContext(LoginContext);
  const { userAccountsClient } = { ...getApis() };

  useEffect(() => {
    reloadUsers();
  }, []);

  useEffect(() => {
    if (userAccountsUpdated) {
      reloadUsers();
      setUserAccountsUpdated(false);
    }
  }, [userAccountsUpdated]);

  const reloadUsers = async () => {
    const userAccounts = await userAccountsClient.getAllAuditors();
    setUserAccounts(userAccounts);
  };

  return (
    <div>
      {loginContext?.login?.userType !== 3 ? (
        <React.Fragment>
          <UserTabs activeIndex={2} />
          <Divider horizontal hidden />
        </React.Fragment>
      ) : null}
      <Menu icon="labeled">
        <Menu.Item onClick={() => setSelectedForEdit(null)}>
          <Icon name="black tie" />
          {localize.createAuditor}
        </Menu.Item>
        <Menu.Item onClick={() => setShowCertificationCentersModal(true)}>
          <Icon name="clipboard" />
          {localize.certificationCenters}
        </Menu.Item>
      </Menu>
      {userAccounts.map((userAccount) => (
        <UserAccount
          key={userAccount.userLoginId}
          deactivated={userAccount.userLogin?.deactivated || false}
          onDeactivate={() => setSelectedForDeactivate(userAccount)}
          onDelete={() => setSelectedForDelete(userAccount)}
          onEdit={() => setSelectedForEdit(userAccount)}
          onPasswordReset={() => setSelectedForReset(userAccount)}
          title={`${userAccount.firstName} ${userAccount.lastName}`}
          titleIconName="user"
        >
          <p title={userAccount.userLogin!.emailAddress}>
            <Icon name="mail" />
            {userAccount.userLogin!.emailAddress}
          </p>
          <p
            title={[
              userAccount.street,
              userAccount.postcode,
              userAccount.city,
              userAccount.state,
            ]
              .filter((x) => x)
              .join(", ")}
          >
            <Icon name="map" />
            {[
              userAccount.street,
              userAccount.postcode,
              userAccount.city,
              userAccount.state,
            ]
              .filter((x) => x)
              .join(", ")}
          </p>
          <p title={userAccount.certificationCenter?.title || ""}>
            <Icon name="clipboard" />
            {userAccount.certificationCenter?.title || ""}
          </p>
          <p className="dd-centered-meta">
            <Icon name="building" />
            <ItemsList
              items={
                userAccount?.companies
                  ?.filter((x) => x.companyTitle)
                  .map((x) => {
                    return { title: x.companyTitle! };
                  }) || []
              }
            />
          </p>
        </UserAccount>
      ))}
      {selectedForEdit !== undefined ? (
        <AuditorAddEdit
          userAccount={selectedForEdit}
          onCancel={() => setSelectedForEdit(undefined)}
          onDone={() => {
            setSelectedForEdit(undefined);
            setUserAccountsUpdated(true);
          }}
        />
      ) : null}
      {selectedForDeactivate?.userLogin ? (
        <DeactivateUserLogin
          onCancel={() => setSelectedForDeactivate(undefined)}
          onDone={() => {
            setSelectedForDeactivate(null);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForDeactivate.userLogin}
        />
      ) : null}
      {selectedForDelete?.userLogin ? (
        <DeleteUserAccount
          onCancel={() => setSelectedForDelete(undefined)}
          onDone={() => {
            setSelectedForDelete(undefined);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForDelete.userLogin}
        />
      ) : null}
      {selectedForReset?.userLogin ? (
        <ResetUserPassword
          onCancel={() => setSelectedForReset(undefined)}
          onDone={() => {
            setSelectedForReset(undefined);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForReset.userLogin}
        />
      ) : null}
      {showCertificationCentersModal ? (
        <CertificationCenters
          onCancel={() => setShowCertificationCentersModal(false)}
          onDone={() => {
            setUserAccountsUpdated(true);
            setShowCertificationCentersModal(false);
          }}
        />
      ) : null}
    </div>
  );
};
