import { Button, Form, Header, Message, Modal } from "semantic-ui-react";
import {
  CompanyType,
  ProductAccess,
  StarVersion,
  UserAccountCompany,
} from "../../Swagger/api";
import React, { useEffect, useState } from "react";

import { countriesListDe } from "../../constants";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

interface ICompanyAddEdit {
  userAccount: UserAccountCompany | null;
  onDone: () => void;
  onCancel: () => void;
}

export const CompanyAddEdit = (props: ICompanyAddEdit) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [companyTitle, setCompanyTitle] = useState("");
  const [companyTypes, setCompanyTypes] = useState<number[]>([]);
  const [fullTimePositions, setFullTimePositions] = useState(0);
  const [productAccess, setProductAccess] = useState<ProductAccess>(
    ProductAccess.CseStar
  );
  const [webSite, setWebSite] = useState("");
  const [hasError, setHasError] = useState(false);
  const [emailAddressEmpty, setEmailAddressEmpty] = useState(false);
  const [availableCompanyTypes, setAvailableCompanyTypes] =
    useState<CompanyType[]>();
  const [emailAddressDuplicate, setEmailAddressDuplicate] = useState(false);
  const [starVersionId, setStarVersionId] = useState<number>();
  const [availableStarVersions, setAvailableStarVersions] = useState<
    StarVersion[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userAccount && userAccount.userLogin) {
      setEmailAddress(userAccount.userLogin.emailAddress || "");
      setFirstName(userAccount.firstName || "");
      setLastName(userAccount.lastName || "");
      setStreet(userAccount.street || "");
      setPostcode(userAccount.postcode || "");
      setCity(userAccount.city || "");
      setState(userAccount.state || "");
      setTelephoneNumber(userAccount.telephoneNumber || "");
      setCompanyTitle(userAccount.companyTitle || "");
      setFullTimePositions(userAccount.fullTimePositions || 0);
      setWebSite(userAccount.webSite || "");
      setStarVersionId(userAccount.starVersionId);
      setProductAccess(userAccount.productAccess);

      setCompanyTypes(
        userAccount.companyTypes
          ? userAccount.companyTypes.map((c) => c.id!)
          : []
      );
    }

    (async () => {
      const [availableCompanyTypesData, availableStarVersionsData] =
        await Promise.all([
          userAccountsClient.getAllCompanyTypes(),
          starVersionClient.getAllStarVersions(),
        ]);
      setAvailableCompanyTypes(availableCompanyTypesData);
      setAvailableStarVersions(availableStarVersionsData);
    })();
  }, []);

  const { userAccount, onDone, onCancel } = { ...props };
  const { userAccountsClient, starVersionClient } = { ...getApis() };

  const onSave = async () => {
    if (!emailAddress) {
      setEmailAddressEmpty(true);
      return;
    } else {
      setEmailAddressEmpty(false);
    }

    setHasError(false);
    setLoading(true);

    const update = userAccount && userAccount.userLogin ? true : false;

    let data: UserAccountCompany = {
      city,
      firstName,
      lastName,
      postcode,
      state,
      street,
      telephoneNumber,
      companyTitle,
      fullTimePositions,
      webSite,
      userLoginId: 0,
      userLogin: {
        emailAddress,
        userType: 1,
        deactivated: false,
        id: 0,
      },
      starVersionId,
      productAccess,
    };

    try {
      if (update) {
        data.userLoginId = userAccount!.userLoginId;
        await userAccountsClient.updateCompany(data);
      } else {
        data = await userAccountsClient.addCompany(data);
      }

      await userAccountsClient.assignCompanyTypes({
        userAccountCompanyId: data.userLoginId,
        companyTypes: companyTypes,
      });

      onDone();
    } catch {
      setHasError(true);
    }
    setLoading(false);
  };

  const checkIfDuplicate = async () => {
    const duplicate = await userAccountsClient.isDuplicate(emailAddress);
    setEmailAddressDuplicate(duplicate);
  };

  return (
    <Modal open={true} dimmer="blurring">
      <Modal.Header>
        {userAccount && userAccount.userLoginId
          ? localize.editCompany
          : localize.createCompany}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label={localize.emailAddress}
            type="email"
            required
            value={emailAddress}
            onChange={(_e, { value }) => setEmailAddress(value)}
            error={emailAddressEmpty}
            onBlur={checkIfDuplicate}
          />
          <Message
            warning
            content={localize.emailDuplicate}
            visible={emailAddressDuplicate}
          />
          <Header>{localize.contactPerson}</Header>
          <Form.Group widths="equal">
            <Form.Input
              label={localize.firstName}
              type="text"
              value={firstName}
              onChange={(_e, { value }) => setFirstName(value)}
            />
            <Form.Input
              label={localize.lastName}
              type="text"
              value={lastName}
              onChange={(_e, { value }) => setLastName(value)}
            />
          </Form.Group>
          <Form.Input
            label={localize.myCompanyWithLegalForm}
            type="text"
            value={companyTitle}
            onChange={(_e, { value }) => setCompanyTitle(value)}
          />
          <Form.Select
            label={localize.companyType}
            value={companyTypes}
            options={
              availableCompanyTypes
                ? availableCompanyTypes.map((c) => {
                    return {
                      text: c.title,
                      value: c.id,
                      disabled: c.deactivated,
                    };
                  })
                : []
            }
            multiple
            loading={!availableCompanyTypes}
            onChange={(_e, { value }) => setCompanyTypes(value as number[])}
          />
          <Form.Input
            label={localize.fulltimePositions}
            type="number"
            value={fullTimePositions}
            onChange={(_e, { value }) => setFullTimePositions(Number(value))}
          />
          <Header>{localize.contactInfo}</Header>
          <Form.Input
            label={localize.streetAndHouseNumber}
            type="text"
            value={street}
            onChange={(_e, { value }) => setStreet(value)}
          />
          <Form.Group>
            <Form.Input
              width="4"
              label={localize.postCode}
              type="text"
              value={postcode}
              onChange={(_e, { value }) => setPostcode(value)}
            />
            <Form.Input
              width="6"
              label={localize.city}
              type="text"
              value={city}
              onChange={(_e, { value }) => setCity(value)}
            />
            <Form.Select
              width="6"
              label={localize.state}
              type="text"
              value={state}
              search
              options={countriesListDe.map((x) => ({
                value: x.name,
                text: x.name,
                flag: x.alpha2,
              }))}
              onChange={(_e, { value }) => setState(value as string)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label={localize.telephoneNumber}
              type="text"
              value={telephoneNumber}
              onChange={(_e, { value }) => setTelephoneNumber(value)}
            />
            <Form.Input
              label={localize.website}
              type="text"
              value={webSite}
              onChange={(_e, { value }) => setWebSite(value)}
            />
          </Form.Group>
          <Header>{localize.starContentVersion}</Header>
          <Form.Select
            type="text"
            value={starVersionId}
            search
            options={availableStarVersions.map((x) => ({
              value: x.id,
              text: x.title,
            }))}
            onChange={(_e, { value }) => setStarVersionId(value as number)}
          />
          <Header>{localize.productAccess}</Header>
          <Form.Radio
            label={localize.productAccessCseStar}
            checked={productAccess === ProductAccess.CseStar}
            value={ProductAccess.CseStar}
            onChange={(_, { value }) =>
              setProductAccess(value as ProductAccess)
            }
          />
          <Form.Radio
            label={localize.productAccessCompanyReport}
            value={ProductAccess.CompanyReportOnly}
            checked={productAccess === ProductAccess.CompanyReportOnly}
            onChange={(_, { value }) =>
              setProductAccess(value as ProductAccess)
            }
          />
          <Message
            error
            visible={hasError}
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={localize.cancelLowerCase}
          floated="left"
          onClick={onCancel}
        />
        <Button
          disabled={emailAddressDuplicate}
          primary
          icon="save"
          content={localize.saveLowerCase}
          labelPosition="right"
          onClick={() => onSave()}
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};
