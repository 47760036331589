import { Button, Divider, Form, Header, Icon, Message, Tab } from 'semantic-ui-react';
import {
  CompanyType,
  Question,
  QuestionAspect,
  QuestionCategory,
  QuestionCompanyTypeMap,
  QuestionConnectionMap,
  QuestionCrossReference,
  QuestionCrossReferenceMap,
  QuestionDocument,
} from '../../../Swagger/api';
import React, { useEffect, useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { history, paths } from '../../layout/layout';

import { localize } from '../../../Localization/localize';
import { richTextEditorConfig } from '../../../constants';
import { getApis } from '../../../Services/webservice';
import { useParams } from 'react-router-dom';

export const QuestionAddEdit = () => {
  const [question, setQuestion] = useState<Question>({
    id: 0,
    justForIso: false,
    questionAspectId: -1,
    questionCategoryId: -1,
    sort: 1000,
  });
  const [avaialbleDocuments, setAvailableDocuments] = useState<QuestionDocument[]>([]);
  const [availableCompanyTypes, setAvailableCompanyTypes] = useState<CompanyType[]>([]);
  const [availableQuestions, setAvailableQuestions] = useState<Question[]>([]);
  const [avaiableCategories, setAvailableCategories] = useState<QuestionCategory[]>([]);
  const [availableAspects, setAvailableAspects] = useState<QuestionAspect[]>([]);
  const [availableCrossReferences, setAvailableCrossReferences] = useState<QuestionCrossReference[]>([]);

  const [questionCrossReferenceMaps, setCrossReferenceMaps] = useState<QuestionCrossReferenceMap[]>([]);
  const [companyTypeMaps, setCompanyTypeMaps] = useState<QuestionCompanyTypeMap[]>([]);
  const [connectedQuestionMaps, setConnectedQuestionMaps] = useState<QuestionConnectionMap[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [cseStandardHtml, setCseStandardHtml] = useState(RichTextEditor.createEmptyValue());
  const [hintsForCompanyHtml, setHintsForCompanyHtml] = useState(RichTextEditor.createEmptyValue());
  const [hintsForAuditorHtml, setHintsForAuditorHtml] = useState(RichTextEditor.createEmptyValue());
  const [isoNormHtml, setIsoNormHtml] = useState(RichTextEditor.createEmptyValue());
  const [crossReferencesHtml, setCrossReferencesHtml] = useState<{ crossReferenceId: number; editorValue: EditorValue }[]>([]);

  const [activeTab, setActiveTab] = useState(0);

  const { id } = useParams<{ id: string }>();

  const { userAccountsClient, questionClient } = { ...getApis() };

  useEffect(() => {
    loadQuestion();
    loadOptions();
  }, []);

  useEffect(() => {
    setCrossReferencesHtmlFromMaps();
  }, [question, questionCrossReferenceMaps]);

  const loadOptions = async () => {
    setAvailableDocuments(await questionClient.getAvailabeDocuments());
    setAvailableCompanyTypes(await userAccountsClient.getAllCompanyTypes());
    setAvailableQuestions(await questionClient.getAllQuestionsSlim());
    setAvailableAspects(await questionClient.getQuestionAspects());
    setAvailableCategories(await questionClient.getQuestionCategories());
    setAvailableCrossReferences(await questionClient.getQuestionCrossReferences());
  };

  const loadQuestion = async () => {
    if (!id) {
      return;
    }

    const questionData = await questionClient.getQuestionById(Number(id));

    if (!questionData) {
      return;
    }

    setQuestion(questionData);

    setCrossReferenceMaps(
      (questionData.questionCrossReferenceMaps || []).map((x) => {
        return {
          hint: x.hint || '',
          questionCrossReferenceId: x.questionCrossReferenceId,
          questionId: x.questionId,
          questionCrossReference: x.questionCrossReference,
        };
      })
    );
    setCompanyTypeMaps(
      (questionData.companyTypeMaps || []).map((x) => {
        return {
          companyTypeId: x.companyTypeId,
          questionId: x.questionId,
        };
      })
    );
    setConnectedQuestionMaps(
      (questionData.connectedQuestionMaps || []).map((x) => {
        return {
          childQuestionId: x.childQuestionId,
          parentQuestionId: x.parentQuestionId,
        };
      })
    );

    setCseStandardHtml(RichTextEditor.createValueFromString(questionData.cseStandard || '', 'html'));
    setHintsForCompanyHtml(RichTextEditor.createValueFromString(questionData.hintsForCompany || '', 'html'));
    setHintsForAuditorHtml(RichTextEditor.createValueFromString(questionData.hintsForAuditor || '', 'html'));
    setIsoNormHtml(RichTextEditor.createValueFromString(questionData.isoNorm || '', 'html'));
  };

  const setCrossReferencesHtmlFromMaps = () => {
    const crossReferencesIntialValues: {
      crossReferenceId: number;
      editorValue: EditorValue;
    }[] = [];
    for (const crossReference of question.questionCrossReferenceMaps || []) {
      crossReferencesIntialValues.push({
        crossReferenceId: crossReference.questionCrossReferenceId,
        editorValue: RichTextEditor.createValueFromString(crossReference.hint || '', 'html'),
      });
    }
    setCrossReferencesHtml(crossReferencesIntialValues);
  };

  const setCrossReferences = (ids: number[]) => {
    let existing = questionCrossReferenceMaps.slice(0, questionCrossReferenceMaps.length);

    existing = existing.filter((x) => ids.find((y) => y === x.questionCrossReferenceId));

    const newReferences = availableCrossReferences.filter(
      (x) => !existing.find((y) => y.questionCrossReferenceId == x.id) && ids.find((y) => y === x.id)
    );

    for (const newReference of newReferences) {
      existing.push({
        questionCrossReferenceId: newReference.id,
        questionCrossReference: newReference,
        questionId: question?.id || 0,
        hint: '',
      });
    }

    setCrossReferenceMaps(existing);
  };

  const setCrossReferencesHints = (value: EditorValue, crossReferenceId: number) => {
    const copy = [...crossReferencesHtml];
    const existing = copy.find((x) => x.crossReferenceId === crossReferenceId);
    if (existing) {
      existing.editorValue = value;
    } else {
      copy.push({ crossReferenceId: crossReferenceId, editorValue: value });
    }
    setCrossReferencesHtml(copy);
  };

  const setDocs = (fileNames: string[]) => {
    const newDocs: QuestionDocument[] = [];

    const stillExisting = fileNames.filter(
      (f) => !!avaialbleDocuments.find((b) => !!b.documentFileName && b.documentFileName.toLowerCase() === f.toLowerCase())
    );

    for (const fileName of stillExisting) {
      newDocs.push({
        documentFileName: fileName,
        questionId: question?.id || 0,
        id: 0,
      });
    }

    setQuestion({ ...question, questionDocuments: newDocs });
  };

  const setCompanyTypes = (companyTypeIds: number[]) => {
    const newMaps: QuestionCompanyTypeMap[] = companyTypeIds.map((x) => {
      return {
        questionId: question?.id || 0,
        companyTypeId: x,
      };
    });

    setCompanyTypeMaps(newMaps);
  };

  const setQuestionConnections = (questionIds: number[]) => {
    const newMaps: QuestionConnectionMap[] = questionIds.map((x) => {
      return {
        parentQuestionId: question?.id || 0,
        childQuestionId: x,
      };
    });

    setConnectedQuestionMaps(newMaps);
  };

  const saveQuestion = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const data: Question = {
        ...question,
        companyTypeMaps,
        connectedQuestionMaps,
      };

      data.cseStandard = cseStandardHtml.toString('html');
      data.hintsForCompany = hintsForCompanyHtml.toString('html');
      data.hintsForAuditor = hintsForAuditorHtml.toString('html');
      data.isoNorm = isoNormHtml.toString('html');

      data.questionCrossReferenceMaps = [];
      for (const crossReferenceHtml of crossReferencesHtml) {
        const map = questionCrossReferenceMaps.find((x) => x.questionCrossReferenceId === crossReferenceHtml.crossReferenceId);
        if (!map) {
          continue;
        }
        data.questionCrossReferenceMaps.push({
          questionId: map.questionId,
          questionCrossReferenceId: map.questionCrossReferenceId,
          hint: crossReferenceHtml.editorValue.toString('html'),
        });
      }

      if (question?.id && question.id > 0) {
        await questionClient.updateQuestion(data);
      } else {
        await questionClient.addQuestion(data);
      }

      setLoading(false);
      history.push(paths.admin.questions);
    } catch {
      setLoading(false);
      setHasError(true);
    }
  };

  const panes = [
    {
      menuItem: localize.generalSettings,
      render: () => (
        <Tab.Pane attached={false}>
          <Form>
            <Form.Input
              label={localize.questionContent}
              type="text"
              value={question.content || ''}
              onChange={(_event, { value }) => setQuestion({ ...question, content: value })}
            />
            <Form.Input
              label={localize.questionContentForCompanyReport}
              type="text"
              value={question.contentForCompanyReport || ''}
              onChange={(_event, { value }) => setQuestion({ ...question, contentForCompanyReport: value })}
            />
            <Header>{localize.helpTexts}</Header>
            <Form.Field>
              <label>{localize.questionCseStandard}</label>
              <RichTextEditor
                className="dd-rich-text-editor"
                toolbarConfig={richTextEditorConfig}
                value={cseStandardHtml}
                onChange={setCseStandardHtml}
              />
            </Form.Field>
            <Form.Input
              label={localize.cseStandardChapter}
              value={question.cseStandardChapter || ''}
              onChange={(_event, { value }) => setQuestion({ ...question, cseStandardChapter: value })}
            ></Form.Input>
            <Form.Field>
              <label>{localize.questionIsoNorm}</label>
              <RichTextEditor
                className="dd-rich-text-editor"
                toolbarConfig={richTextEditorConfig}
                value={isoNormHtml}
                onChange={setIsoNormHtml}
              />
            </Form.Field>
            <Form.Input
              label={localize.isoNormReference}
              value={question.isoNormReference || ''}
              onChange={(_event, { value }) => setQuestion({ ...question, isoNormReference: value })}
            ></Form.Input>
            <Form.Field>
              <label>{localize.questionHintsForCompany}</label>
              <RichTextEditor
                className="dd-rich-text-editor"
                toolbarConfig={richTextEditorConfig}
                value={hintsForCompanyHtml}
                onChange={setHintsForCompanyHtml}
              />
            </Form.Field>
            <Form.Field>
              <label>{localize.questionHintsForAuditor}</label>
              <RichTextEditor
                className="dd-rich-text-editor"
                toolbarConfig={richTextEditorConfig}
                value={hintsForAuditorHtml}
                onChange={setHintsForAuditorHtml}
              />
            </Form.Field>
            <Header>{localize.documents}</Header>
            <Form.Select
              key="forms"
              label={localize.forms}
              value={(question.questionDocuments || []).map((qd) => qd.documentFileName || '')}
              options={avaialbleDocuments.map((qd) => {
                return {
                  value: qd.documentFileName,
                  text: qd.documentFileName,
                };
              })}
              onChange={(_event, { value }) => {
                setDocs(value as string[]);
              }}
              multiple
            />
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: localize.crossreferences,
      render: () => (
        <Tab.Pane attached={false}>
          <Form>
            <Form.Select
              label={localize.crossreferences}
              value={questionCrossReferenceMaps.map((x) => x.questionCrossReferenceId || '')}
              options={availableCrossReferences.map((x) => {
                return { value: x.id, text: x.title };
              })}
              onChange={(_event, { value }) => setCrossReferences(value as number[])}
              multiple
            />
            {questionCrossReferenceMaps.map((cr) => (
              <Form.Field key={cr.questionCrossReferenceId}>
                <label>
                  {localize.formatString(
                    localize.hintsForCrossReference,
                    cr.questionCrossReference ? cr.questionCrossReference.title || '' : ''
                  )}
                </label>
                <RichTextEditor
                  className="dd-rich-text-editor"
                  toolbarConfig={richTextEditorConfig}
                  value={
                    crossReferencesHtml.find((x) => x.crossReferenceId === cr.questionCrossReferenceId)?.editorValue ||
                    RichTextEditor.createValueFromString(cr.hint || '', 'html')
                  }
                  onChange={(value: EditorValue) => setCrossReferencesHints(value, cr.questionCrossReferenceId)}
                />
              </Form.Field>
            ))}
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: localize.settings,
      render: () => (
        <Tab.Pane attached={false}>
          <Form>
            <Form.Select
              label={localize.relevantCompanyTypes}
              value={companyTypeMaps.map((x) => x.companyTypeId!)}
              options={availableCompanyTypes.map((x) => {
                return { value: x.id, text: x.title, disabled: x.deactivated };
              })}
              onChange={(_event, { value }) => setCompanyTypes(value as number[])}
              multiple
            />
            <Form.Select
              label={localize.connectedQuestions}
              value={connectedQuestionMaps.map((x) => x.childQuestionId!)}
              options={availableQuestions.map((x) => {
                return { value: x.id, text: x.content };
              })}
              onChange={(_event, { value }) => setQuestionConnections(value as number[])}
              multiple
            />
            <Form.Select
              label={localize.questionCategory}
              value={question.questionCategoryId}
              options={avaiableCategories.map((x) => {
                return { value: x.id, text: x.title, disabled: x.deactivated };
              })}
              required
              onChange={(_event, { value }) => setQuestion({ ...question, questionCategoryId: value as number })}
            />
            <Form.Select
              label={localize.questionAspect}
              value={question.questionAspectId}
              options={availableAspects
                .filter((x) => x.questionCategoryId == question.questionCategoryId)
                .map((x) => {
                  return { value: x.id, text: x.title, disabled: x.deactivated };
                })}
              required
              onChange={(_event, { value }) => setQuestion({ ...question, questionAspectId: value as number })}
            />
            <Form.Field label={localize.questionRelevance} />
            <Form.Checkbox
              label={localize.questionJustForIso}
              checked={question.justForIso || false}
              onChange={(_event, { checked }) => setQuestion({ ...question, justForIso: checked as boolean })}
            />
            <Form.Checkbox
              label={localize.questionGoal}
              checked={question.goalQuestion || false}
              onChange={(_event, { checked }) => setQuestion({ ...question, goalQuestion: checked as boolean })}
            />
          </Form>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Header size="huge">{question !== null ? localize.questionEdit : localize.questionCreate}</Header>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={activeTab}
        onTabChange={(_, { activeIndex }) => setActiveTab(Number(activeIndex))}
      />
      {hasError && <Message error content={localize.generalErrorMessage} header={localize.generalErrorHeader} />}
      <Divider horizontal />
      <Button icon labelPosition="left" onClick={() => history.push(paths.admin.questions)}>
        <Icon name="arrow left" /> {localize.backToOverview}
      </Button>
      <Button primary icon labelPosition="right" floated="right" onClick={saveQuestion} loading={loading}>
        <Icon name="save" />
        {localize.saveLowerCase}
      </Button>
    </>
  );
};
