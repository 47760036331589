import React, { useContext, useState } from 'react';
import { Prompt } from 'react-router-dom';
import Textarea from 'react-textarea-autosize';
import { Button, Divider, Form, Grid, Header, Icon, Label, Loader, Message, Popup } from 'semantic-ui-react';
import { LoginContext } from '../../../Contexts/loginContext';
import { localize } from '../../../Localization/localize';
import { AuditResult, ProductAccess, Question, StarAuditorAnswer, StarCompanyAnswer } from '../../../Swagger/api';
import { CollapseText } from '../../common/collapseText';
import { AnswerState } from './answerState';
import { QuestionMeta } from './questionMeta';

interface IQuestionContent {
  question: Question;
  allAnswers: StarCompanyAnswer[];
  onBack: () => void;
  onSearch: () => void;
  onClose: () => void;
  onNext: () => void;
  onContentChange: (value: string) => void;
  onReportTextChange: (value: string) => void;
  content: string;
  reportText: string;
  answerState: number;
  readOnlyMode: boolean;
  firstQuestion: boolean;
  lastQuestion: boolean;
  onStateNotAnswered: () => void;
  onStateInProgress: () => void;
  onStateAnswered: () => void;
  starId: number;
  saving: boolean;
  relevantQuestionIds: number[];
  previousAnswer?: StarCompanyAnswer;
  important: boolean;
}

export const getAuditMethod = (answer?: StarAuditorAnswer) => {
  if (!answer) {
    return '-';
  }

  const parts: string[] = [];
  if (answer.observation) {
    parts.push(localize.auditMethodObservation);
  }
  if (answer.comunication) {
    parts.push(localize.auditMethodCommunication);
  }
  if (answer.documentation) {
    parts.push(localize.auditMethodDocument);
  }

  if (parts.length) {
    return parts.join(', ');
  } else {
    return '-';
  }
};

export const getAuditResult = (result?: AuditResult, notRelevant?: boolean) => {
  if (notRelevant) {
    return localize.notRelevant;
  }
  switch (result) {
    case AuditResult.Completed:
      return localize.completed;
    case AuditResult.PartiallyCompleted:
      return localize.partiallyCompleted;
    case AuditResult.NotCompleted:
      return localize.notCompleted;
    case AuditResult.NotAudited:
      return localize.notAudited;
    default:
      return '-';
  }
};

export const QuestionContent = (props: IQuestionContent) => {
  const [block, setBlock] = useState(!props.readOnlyMode);
  const loginContext = useContext(LoginContext);

  const {
    question,
    allAnswers,
    onClose,
    onBack,
    onNext,
    onSearch,
    content,
    onContentChange,
    onReportTextChange,
    reportText,
    readOnlyMode,
    firstQuestion,
    lastQuestion,
    answerState,
    saving,
    previousAnswer,
  } = { ...props };

  const getAnswerContent = (questionId?: number) => {
    var item = allAnswers.find((x) => x.questionId == questionId);

    return item ? item.content || localize.noData : localize.noData;
  };

  const getAnswerReportText = (questionId: number) => {
    var item = allAnswers.find((x) => x.questionId == questionId);

    return item ? item.reportText || localize.noData : localize.noData;
  };

  const connectedQuestions =
    question.connectedQuestionMaps?.length &&
    question.connectedQuestionMaps.filter((x) => props.relevantQuestionIds.indexOf(x.childQuestionId) !== -1 && x.childQuestion)
      .length
      ? question.connectedQuestionMaps.filter(
          (x) => props.relevantQuestionIds.indexOf(x.childQuestionId) !== -1 && x.childQuestion
        )
      : [];

  return (
    <Grid className="dd-question-content">
      <Grid.Row>
        <Grid.Column width="16">
          <Header size="huge">
            {question.sort}.{' '}
            {loginContext.login?.productAccess === ProductAccess.CompanyReportOnly
              ? question.contentForCompanyReport
              : question.content}
          </Header>
          <Popup
            content={localize.questionIdHint}
            trigger={
              <Label basic>
                {localize.questionId}: {props.question.id}
              </Label>
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="10">
          {loginContext.login?.productAccess !== ProductAccess.CompanyReportOnly && (
            <Form>
              {connectedQuestions.length ? (
                <>
                  <Header>{localize.connectedQuestionsInfo}</Header>
                  {connectedQuestions.map((x, i) => (
                    <Form.Field key={i}>
                      <label>
                        {x.childQuestion?.sort}. {x.childQuestion?.content}
                      </label>
                      <CollapseText>{getAnswerContent(x.childQuestionId)}</CollapseText>
                    </Form.Field>
                  ))}
                </>
              ) : null}
              {previousAnswer && (
                <>
                  <Header>{localize.previousAnswerAuditor}</Header>
                  <CollapseText>
                    <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>{localize.auditMethod}</label>
                            {getAuditMethod(previousAnswer.starAuditorAnswer)}
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field>
                            <label>{localize.result}</label>
                            {getAuditResult(previousAnswer.starAuditorAnswer?.auditResult)}
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <Form.Field className="dd-previous-answer-content-container">
                      <label>{localize.auditorComment}</label>
                      {previousAnswer.starAuditorAnswer?.auditReportText || '-'}

                      {previousAnswer.starAuditorAnswer?.improvements && (
                        <>
                          <label>{localize.improvements}</label>
                          {previousAnswer.starAuditorAnswer?.improvements}
                        </>
                      )}
                      {previousAnswer.starAuditorAnswer?.problems && (
                        <>
                          <label>{localize.problems}</label>
                          {previousAnswer.starAuditorAnswer?.problems}
                        </>
                      )}
                    </Form.Field>
                  </CollapseText>
                  <Divider horizontal />
                </>
              )}

              <Form.Field>
                <label>{localize.yourAnswer}</label>
                {readOnlyMode ? (
                  <div className="dd-with-preline">{content || localize.noData}</div>
                ) : (
                  <Textarea maxRows={10} value={content} onChange={(event) => onContentChange(event.currentTarget.value)} />
                )}
              </Form.Field>
              <AnswerState
                currentState={answerState}
                onAnswered={props.onStateAnswered}
                onInProgress={props.onStateInProgress}
                onNotAnswered={props.onStateNotAnswered}
              />

              <Header>{localize.reportText}</Header>
              {connectedQuestions.length ? (
                <>
                  {connectedQuestions.map((x, i) => (
                    <Form.Field key={i}>
                      <label>
                        {x.childQuestion?.sort}. {x.childQuestion?.content}
                      </label>
                      <CollapseText>{getAnswerReportText(x.childQuestionId)}</CollapseText>
                    </Form.Field>
                  ))}
                </>
              ) : null}

              <Divider horizontal hidden />
              <Form.Field>
                <label>{question.contentForCompanyReport || localize.yourReportEntry}</label>
                <Textarea maxRows={10} value={reportText} onChange={(event) => onReportTextChange(event.currentTarget.value)} />
                <Message visible={props.readOnlyMode} content={localize.reportTextHint} success />
              </Form.Field>
            </Form>
          )}

          {loginContext.login?.productAccess == ProductAccess.CompanyReportOnly && (
            <Form>
              <Form.Field>
                <Textarea
                  maxRows={20}
                  minRows={10}
                  value={reportText}
                  onChange={(event) => onReportTextChange(event.currentTarget.value)}
                />
              </Form.Field>
              <AnswerState
                currentState={answerState}
                onAnswered={props.onStateAnswered}
                onInProgress={props.onStateInProgress}
                onNotAnswered={props.onStateNotAnswered}
              />
            </Form>
          )}

          <Divider horizontal hidden />
          {firstQuestion ? (
            <Button basic>{localize.firstQuestion}</Button>
          ) : (
            <Button icon labelPosition="left" onClick={onBack} disabled={saving}>
              <Icon name="arrow left" /> {localize.backLowerCase}
            </Button>
          )}

          <Button icon labelPosition="left" onClick={onSearch} disabled={saving}>
            <Icon name="search" /> {localize.toOtherQuestionLowerCase}
          </Button>

          {lastQuestion ? (
            <Button basic floated="right">
              {localize.lastQuestion}
            </Button>
          ) : (
            <Button icon labelPosition="right" primary floated="right" onClick={onNext} disabled={saving}>
              <Icon name="arrow right" /> {localize.nextQuestion}
            </Button>
          )}
          <Divider horizontal hidden />

          <Button
            icon
            labelPosition="left"
            onClick={() => {
              setBlock(false);
              onClose();
            }}
            disabled={saving}
          >
            <Icon name="save" />
            {localize.saveAndCloseLowerCase}
          </Button>

          {saving && (
            <div className="dd-question-saving">
              {localize.savingAnswer}
              <Loader active size="small" inline />
            </div>
          )}
        </Grid.Column>
        <Grid.Column width="6">
          <QuestionMeta question={question} starId={props.starId} importantQuestion={props.important} />
        </Grid.Column>
      </Grid.Row>
      <Prompt when={block} message={localize.saveDataPrompt} />
    </Grid>
  );
};
