import { Accordion, Container, Divider, Header, Icon, Label, List, Popup, Segment, SegmentGroup } from 'semantic-ui-react';
import { FileResponse, Question, UserType } from '../../../Swagger/api';
import React, { useContext, useEffect, useState } from 'react';

import { FileMissing } from '../../common/fileMissing';
import Linkify from 'react-linkify';
import { LoginContext } from '../../../Contexts/loginContext';
import { localize } from '../../../Localization/localize';
import { getApis } from '../../../Services/webservice';

interface IQuestionMeta {
  question: Question;
  starId: number;
  importantQuestion: boolean;
}

export const QuestionMeta = (props: IQuestionMeta) => {
  const [metaIndex, setMetaIndex] = useState(-1);
  const [crossIndex, setCrossIndex] = useState(-1);
  const [starVersion, setStarVersion] = useState('');
  const [missingFile, setShowMissingFile] = useState(false);

  const loginContext = useContext(LoginContext);

  const { starClient } = { ...getApis() };

  const { question, starId } = { ...props };

  useEffect(() => {
    loadStarVersion();
  }, []);

  const loadStarVersion = async () => {
    const version = await starClient.getStarVersion(starId);
    setStarVersion(version?.title || '');
  };

  const downloadForm = async (fileName?: string) => {
    if (!fileName) {
      return;
    }

    let result: FileResponse | null = null;
    try {
      result = await starClient.getFormDocument(fileName);
    } catch (ex) {
      console.error(ex);
    }

    if (!result) {
      setShowMissingFile(true);
      return;
    }

    const tempUrl = window.URL.createObjectURL(result?.data);

    var a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = tempUrl;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(tempUrl);
  };

  return (
    <section className="dd-question-meta">
      <article title={`${question.questionAspect?.questionCategory?.title || ''} > ${question?.questionAspect?.title || ''}`}>
        <Label
          size="large"
          className={`dd-category-label dd-dotdotdot ${question.questionAspect?.questionCategory?.color || 'dd-no-color'}`}
          style={{
            backgroundColor: question.questionAspect?.questionCategory?.color,
          }}
        >
          {question.questionAspect?.questionCategory?.title || ''} <Icon name="chevron right" />{' '}
          {question.questionAspect?.title || ''}
        </Label>
      </article>
      {props.importantQuestion && (
        <>
          <Divider horizontal hidden />

          <Popup
            header={localize.importantQuestion}
            content={localize.importantQuestionInfo}
            position="left center"
            trigger={
              <Container textAlign="center" fluid>
                <Icon size="big" name="exclamation" color="orange" />
                <Header as="span">{localize.importantQuestion}</Header>
              </Container>
            }
          />
        </>
      )}
      <Divider horizontal hidden />
      <article>
        <SegmentGroup>
          <Segment className="dd-preserve-pre-line">
            <Header>{localize.questionHintsForCompany}</Header>
            <div
              dangerouslySetInnerHTML={{
                __html: question.hintsForCompany || localize.noData,
              }}
            ></div>
          </Segment>

          {loginContext.login?.userType !== UserType.Company && (
            <Segment className="dd-preserve-pre-line">
              <Header>{localize.questionHintsForAuditor}</Header>
              <div
                dangerouslySetInnerHTML={{
                  __html: question.hintsForAuditor || localize.noData,
                }}
              ></div>
            </Segment>
          )}
          {loginContext.login?.userType !== UserType.Auditor && (
            <Segment>
              <Header>{localize.forms}</Header>
              <List>
                {question.questionDocuments && question.questionDocuments.length
                  ? question.questionDocuments.map((x, i) => (
                      <List.Item key={i}>
                        <List.Icon name="file alternate" />
                        <List.Content>
                          <a onClick={() => downloadForm(x.documentFileName)}>{x.documentFileName}</a>
                        </List.Content>
                      </List.Item>
                    ))
                  : localize.noData}
              </List>
            </Segment>
          )}
        </SegmentGroup>

        <Divider horizontal hidden />
        <article>
          <Accordion fluid styled>
            <Accordion.Title index={0} active={metaIndex === 0} onClick={() => setMetaIndex(0)}>
              <Header>{localize.questionCseStandard}</Header>
            </Accordion.Title>
            <Accordion.Content active={metaIndex === 0}>
              <div
                dangerouslySetInnerHTML={{
                  __html: question.cseStandard || localize.noData,
                }}
              ></div>
            </Accordion.Content>

            <Accordion.Title index={1} active={metaIndex === 1} onClick={() => setMetaIndex(1)}>
              <Header>{localize.questionIsoNorm}</Header>
            </Accordion.Title>
            <Accordion.Content active={metaIndex === 1}>
              <div
                dangerouslySetInnerHTML={{
                  __html: question.isoNorm || localize.noData,
                }}
              ></div>
            </Accordion.Content>
          </Accordion>
        </article>

        <Divider horizontal hidden />
        {question.questionCrossReferenceMaps && question.questionCrossReferenceMaps.length ? (
          <Accordion fluid styled>
            <Accordion.Title active>
              <Header>{localize.corssReferencesDisplayName}</Header>
            </Accordion.Title>
            {question.questionCrossReferenceMaps.map((c, i) => (
              <React.Fragment key={i}>
                <Accordion.Title index={i} active={crossIndex === i} onClick={() => setCrossIndex(i)}>
                  <img src={`/assets/images/crossreferences/${encodeURIComponent(c.questionCrossReference?.iconUrl || '')}`} />
                  <Header size="small">{c.questionCrossReference?.title}</Header>
                  {c.questionCrossReference?.description && (
                    <Popup hoverable position="left center" trigger={<Icon name="info" size="small" />} wide="very">
                      <Linkify>{c.questionCrossReference!.description}</Linkify>
                    </Popup>
                  )}
                </Accordion.Title>
                <Accordion.Content active={crossIndex === i}>
                  <div dangerouslySetInnerHTML={{ __html: c.hint || '' }}></div>
                </Accordion.Content>
              </React.Fragment>
            ))}
          </Accordion>
        ) : null}
      </article>

      <Divider horizontal hidden />
      {starVersion && (
        <div className="dd-version">
          {localize.starContentVersion}: {starVersion}
        </div>
      )}

      {missingFile && <FileMissing onDone={() => setShowMissingFile(false)} />}
    </section>
  );
};
