import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { localize } from "../../../Localization/localize";

export interface ISetAvailablePromptProps {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
}

export const SetAvailablePrompt = ({
  onCancel,
  onConfirm,
  loading,
}: ISetAvailablePromptProps) => (
  <Modal open dimmer="blurring">
    <Modal.Header>{localize.approveModalHeader}</Modal.Header>
    <Modal.Content>{localize.approveModalContent}</Modal.Content>
    <Modal.Actions>
      <Button floated="left" onClick={onCancel}>
        {localize.cancelLowerCase}
      </Button>
      <Button
        primary
        icon
        labelPosition="right"
        onClick={onConfirm}
        loading={loading}
      >
        <Icon name="save"></Icon>
        {localize.saveLowerCase}
      </Button>
    </Modal.Actions>
  </Modal>
);
