import { Button, Icon, Modal } from "semantic-ui-react";

import React from "react";
import { localize } from "../../../Localization/localize";
import { ISetAvailablePromptProps } from "./setAvailablePrompt";

export const SetCompanyReportDonePrompt = ({
  onCancel,
  onConfirm,
  loading,
}: ISetAvailablePromptProps) => (
  <Modal open dimmer="blurring">
    <Modal.Header>{localize.setCompanyReportDonePromptHeader}</Modal.Header>
    <Modal.Content>
      <div
        dangerouslySetInnerHTML={{
          __html: localize.setCompanyReportDonePromptContent,
        }}
      ></div>
    </Modal.Content>
    <Modal.Actions>
      <Button floated="left" onClick={onCancel}>
        {localize.cancelLowerCase}
      </Button>
      <Button
        primary
        icon
        labelPosition="right"
        onClick={onConfirm}
        loading={loading}
      >
        <Icon name="save"></Icon>
        {localize.saveLowerCase}
      </Button>
    </Modal.Actions>
  </Modal>
);
