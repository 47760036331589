import {
  Button,
  Divider,
  Icon,
  Input,
  InputOnChangeData,
  Message,
  Modal,
  Select,
} from "semantic-ui-react";
import { QuestionAspect, QuestionCategory } from "../../Swagger/api";
import React, { useEffect, useState } from "react";

import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export interface IQuetionAspects {
  onCancel: () => void;
  onDone: () => void;
}

export const QuestionAspects = (props: IQuetionAspects) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [items, setItems] = useState<QuestionAspect[]>([]);
  const [availableCategories, setAvailableCategories] = useState<
    QuestionCategory[]
  >([]);
  const [selectedForDelete, setSelectedForDelete] = useState<number>();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [hasDeleteError, setHasDeleteError] = useState(false);
  const [usedItemsIds, setUsedItemsIds] = useState<number[]>([]);

  const { questionClient } = getApis();

  useEffect(() => {
    (async () => {
      setLoading(true);
      setHasError(false);
      try {
        const [data, usedItemsData] = await Promise.all([
          questionClient.getQuestionAspects(),
          questionClient.getUsedQuestionAspectIds(),
        ]);
        setItems(data);
        setUsedItemsIds(usedItemsData);

        const categories = await questionClient.getQuestionCategories();
        setAvailableCategories(categories);
      } catch {
        setHasError(true);
      }
      setLoading(false);
    })();
  }, []);

  const { onCancel, onDone } = { ...props };

  const onInputChange = (
    data: InputOnChangeData,
    currentItem: QuestionCategory
  ) => {
    const copy = [...items];
    for (const item of copy) {
      if (item.id == currentItem.id) {
        item.title = data.value;
      }
    }

    setItems(copy);
  };

  const onCategoryChange = (data: number, currentItem: QuestionCategory) => {
    const copy = [...items];
    for (const item of copy) {
      if (item.id == currentItem.id) {
        item.questionCategoryId = data;
      }
    }

    setItems(copy);
  };

  const onActivateChange = (currentItem: QuestionCategory) => {
    const copy = [...items];
    for (const item of copy) {
      if (item.id == currentItem.id) {
        item.deactivated = !currentItem.deactivated;
      }
    }

    setItems(copy);
  };

  const addNewItem = () => {
    const copy = [...items];
    copy.push({
      deactivated: false,
      id: (items.length + 1) * -1,
      title: "",
      questionCategoryId: 1,
    });
    setItems(copy);
  };

  const onSave = async () => {
    setLoading(true);
    setHasError(false);
    try {
      await questionClient.setQuestionAspects(items);
      onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  const deleteItem = async () => {
    setHasDeleteError(false);
    setLoadingDelete(true);
    try {
      // if the item was already saved on the server it has a correct id
      if (selectedForDelete && selectedForDelete > 0) {
        await questionClient.deleteQuestionAspect(selectedForDelete);
        const data = await questionClient.getQuestionAspects();
        setItems(data);
      } else {
        // otherwise just clear it from the state
        const data = [...items].filter((x) => x.id !== selectedForDelete);
        setItems(data);
      }

      setSelectedForDelete(undefined);
    } catch {
      setHasDeleteError(true);
    }
    setLoadingDelete(false);
  };

  const isItemUsed = (itemId: number) =>
    !!usedItemsIds.find((x) => x === itemId);

  return (
    <>
      <Modal dimmer="blurring" open>
        <Modal.Header>{localize.aspects}</Modal.Header>
        <Modal.Content>
          {items.map((item) => (
            <React.Fragment key={item.id}>
              <Input
                value={item.title}
                fluid
                spellCheck="false"
                onChange={(_event, data) => {
                  onInputChange(data, item);
                }}
                action
              >
                <input />
                <Select
                  className="dd-sub-select"
                  search
                  compact
                  value={item.questionCategoryId}
                  options={availableCategories.map((x) => {
                    return {
                      text: x.title,
                      value: x.id,
                      disabled: x.deactivated,
                    };
                  })}
                  onChange={(_event, { value }) =>
                    onCategoryChange(value as number, item)
                  }
                />
                <Button
                  content={
                    item.deactivated
                      ? localize.activateLowerCase
                      : localize.deactivateLowerCase
                  }
                  onClick={() => {
                    onActivateChange(item);
                  }}
                />
                <Button
                  disabled={isItemUsed(item.id)}
                  onClick={() => setSelectedForDelete(item.id)}
                >
                  {localize.deleteLowerCase}
                </Button>
              </Input>
              <Divider horizontal hidden />
            </React.Fragment>
          ))}
          <Divider horizontal hidden />
          {hasError ? (
            <Message
              error
              content={localize.generalErrorMessage}
              header={localize.generalErrorHeader}
            />
          ) : null}
          <div className="dd-certificationCenters-footerMessage">
            <Divider horizontal hidden />
            {localize.questionAspectsFooterMessage}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel} floated="left">
            {localize.cancelLowerCase}
          </Button>
          <Button icon labelPosition="left" onClick={addNewItem}>
            <Icon name="plus" />
            {localize.insertLowerCase}
          </Button>

          <Button
            primary
            icon
            labelPosition="right"
            onClick={onSave}
            loading={loading}
          >
            <Icon name="save" />
            {localize.saveLowerCase}
          </Button>
        </Modal.Actions>
      </Modal>
      {selectedForDelete && (
        <Modal open dimmer="blurring">
          <Modal.Header>{localize.deleteUpperCase}</Modal.Header>
          <Modal.Content>
            <p>{localize.confirmAspectDelete}</p>
            {hasDeleteError && (
              <Message
                error
                content={localize.generalErrorMessage}
                header={localize.generalErrorHeader}
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              floated="left"
              onClick={() => setSelectedForDelete(undefined)}
            >
              {localize.cancelLowerCase}
            </Button>

            <Button
              primary
              icon
              labelPosition="right"
              onClick={deleteItem}
              loading={loadingDelete}
            >
              <Icon name="delete" />
              {localize.deleteLowerCase}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};
