import { Button, Container, Header, Icon, Modal } from 'semantic-ui-react';

import React from 'react';
import { localize } from '../../Localization/localize';

export const NewVersionNotice = ({ registration }: { registration: ServiceWorkerRegistration }) => (
  <Modal open basic dimmer="blurring">
    <Header icon>
      <Icon name="thumbs up outline" />
      {localize.newVersionNoticeHeader}
    </Header>
    <Modal.Content>
      <Container fluid textAlign="center">
        {localize.newVersionNoticeContent}
      </Container>
    </Modal.Content>
    <Modal.Actions>
      <Button
        basic
        inverted
        color="green"
        onClick={() => {
          registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();
        }}
      >
        {localize.newVersionNoticeButton}
      </Button>
    </Modal.Actions>
  </Modal>
);
