import { Container, Divider, Header, Image, Label, Table } from 'semantic-ui-react';

import React from 'react';

export const AuditorHome = () => (
  <Container>
    <Header size="large">CSE-Audit Schritt für Schritt</Header>
    <p>
      Liebe*r Auditor*in,
      <br />
      diese Anleitung soll Dich im CSE-Audit unterstützen. Es ist sinnvoll, sie sich im Vorfeld durchzulesen und gegebenenfalls
      auch dabei zu haben.
    </p>
    <p>
      CSE ist eines der strengsten Nachhaltigkeitssiegel. Wir alle – die Unternehmen, die Standardgeberin und Du – wirken an der
      Gestaltung einer nachhaltigen Wirtschaft mit. Die GfaW dankt Dir dafür, dass Du auch dabei bist. <br /> Die Unternehmen
      begeben sich mit CSE auf ein neues Terrain und sind entsprechend unsicher, begeistert, euphorisch, motiviert und demnach
      leicht zu frustrieren. Bitte beachte, dass wir nicht eine Verordnung prüfen, uns also keine Behörde im Nacken sitzt. Die
      Belegschaft des Unternehmens hat schon Wochen vorher an der Ausarbeitung der Inhalte gearbeitet. Das Audit ist für sie ein
      wichtiger Termin. Ab und zu ein lobendes Wort oder eine Anerkennung für die Leistung, die das zu auditierende Unternehmen
      für die Zertifizierung erbringt, trägt deutlich zur weiteren Motivation nach dem Audit bei. Du als Auditor*in bist
      schließlich jemand, der die Leistung sehen und beurteilen kann. Gleichzeitig bist Du es, der bewertet ob ein Kriterium
      erfüllt ist oder nicht. Es ist somit eine Gradwanderung, auf die Du dich begibst. Zu den meisten Kriterien gibt dir das
      STAR Hinweise als Unterstützung für Deine Bewertung.
    </p>
    <Header>Tipps zum Ablauf</Header>
    <p>
      Es macht absolut Sinn, sich im <u>Vorfeld die Antworten der Unternehmen anzusehen</u>. Im besten Falle hat das Unternehmen
      Dir schon textlastige Dokumente zugeschickt und Du hattest Gelegenheit Dich in das QMH oder ähnliches einzulesen.
    </p>
    <p>
      Hier eine Tabelle, die Dir eine grobe Einschätzung der reinen Auditzeit gibt, wenn Du dich gut vorbereitet hast und nicht
      erst im Audit anfängst die Texte durchzulesen.
    </p>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Auditart</Table.HeaderCell>
          <Table.HeaderCell>Jahr</Table.HeaderCell>
          <Table.HeaderCell>Inhalt</Table.HeaderCell>
          <Table.HeaderCell>Reine Auditzeit Produktions- unternehmen ca. 80 MA</Table.HeaderCell>
          <Table.HeaderCell>Reine Auditzeit Dienstleister</Table.HeaderCell>
          <Table.HeaderCell>Ergebnis</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Vor-Audit (freiwillig)</Table.Cell>
          <Table.Cell>0</Table.Cell>
          <Table.Cell>Überprüfen auf Zertifizierbarkeit</Table.Cell>
          <Table.Cell>Ca. 4 Stunden</Table.Cell>
          <Table.Cell>2 Stunden</Table.Cell>
          <Table.Cell>„Hausaufgabenliste“ für Zertifizierbarkeit</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Erst-Audit</Table.Cell>
          <Table.Cell>1</Table.Cell>
          <Table.Cell>Überprüfen des Managementsystems und der CSE- Kriterien</Table.Cell>
          <Table.Cell>8 bis 10 stunden</Table.Cell>
          <Table.Cell>4 bis 6 Stunden</Table.Cell>
          <Table.Cell>CSE-Zertifikat mit einer Gültigkeit von 3 Jahren bis zum Rezertifizierungsaudit</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Überwachungs- audit</Table.Cell>
          <Table.Cell>2, 3</Table.Cell>
          <Table.Cell>Überprüfen der CSE-Kriterien und stichprobenartig das Managementsystem</Table.Cell>
          <Table.Cell>6 Stunden</Table.Cell>
          <Table.Cell>2 bis 3 Stunden</Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Rezertifizierungs -audit</Table.Cell>
          <Table.Cell>4</Table.Cell>
          <Table.Cell>Wie Erst-Audit</Table.Cell>
          <Table.Cell>8 bis 10 Stunden</Table.Cell>
          <Table.Cell>4 bis 6 Stunden</Table.Cell>
          <Table.Cell>Neues CSE-Zertifikat</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    <p>
      Während des Audits hat es sich bewährt, sozusagen <u>in Blöcken vorzugehen</u>. Solange sich die Fragen auf die ISO-Norm
      beziehen, kann eine Frage als Block verstanden werden. Danach können die Rubriken (Umweltschutz, Arbeitswelt,
      Lieferantenbeziehungen, etc.) als Blöcke verstanden werden. Lass Dir z.B. zum Thema „Lieferantenbeziehungen“ darstellen,
      was und wie das Unternehmen zu dem Thema unternimmt. Dann hast Du schon viele Informationen beisammen und kannst meist
      recht schnell die detaillierten Fragen im STAR beantworten.
    </p>
    <Header>Vorbereitung durch das Unternehmen</Header>
    <p>
      Das Unternehmen sollte 2 Wochen vor dem Audittermin seine Daten im STAR freigegeben haben. Du kannst es an dem Status des
      STAR erkennen:
    </p>
    <Image fluid src="/assets/images/auditorHome/image 1.png" />
    <p>Falls der Status „wird noch ausgefüllt“ ist, bitte das Unternehmen anschrieben, es möge seinen STAR freigeben.</p>
    <Header>Vorbereitung online</Header>
    <p>
      Bitte im Browser anmelden und Roboterschutz bestätigen. Falls dieser nicht angezeigt wird, bitte anderen Browser
      verwenden. Angemeldet bleiben und im Client einloggen, auf „Unternehmen“ gehen und Daten synchronisieren lassen. Das zu
      auditierende Unternehmen kann geprüft werden, wenn ein leerer Stern angezeigt wird und der Text „Prüfung starten“. Falls
      dort noch steht „wird noch ausgefüllt“ bitte dem Unternehmen schreiben, dass es seine Daten freigeben soll.
    </p>
    <Label color="red">NICHT ausloggen, sondern einfach schließen</Label>
    <Header>Im Unternehmen</Header>
    <p>Offline (oder online) den Client aufrufen, auf „Unternehmen“ gehen und die Prüfung starten.</p>
    <p>Du führst das Audit durch, in dem Du </p>
    <ol>
      <li>
        eine bewertende Antwort in das Antwortfeld eingibst oder den entsprechenden Text aus den Unterlagen des Unternehmens
        reinkopierst: <br />
        <Image size="big" centered src="/assets/images/auditorHome/image 2.png" />
      </li>
      <li>
        die Prüfmethode auswählst, mit der Du zu Deiner Bewertung kommst:
        <Image size="big" centered src="/assets/images/auditorHome/image 3.png" />
      </li>
      <li>
        deine Bewertung konform/nicht konform/teilweise erfüllt abgibst:
        <Image size="big" centered src="/assets/images/auditorHome/image 4.png" />
      </li>
      <li>
        Falls ein Kriterium nur teilweise erfüllt ist, trage bitte im Feld „Verbesserungsmaßnahmen“ die notwendigen
        Verbesserungsmaßnahmen ein:
        <Image size="big" centered src="/assets/images/auditorHome/image 5.png" />
      </li>
      <li>
        Falls Du zu dem Ergebnis kommst, dass das Kriterium nicht erfüllt ist, handelt es sich um eine Abweichung. Dann klicke
        bitte „Abweichung“ an und fülle im Textfeld „Abweichung“ aus, weshalb das Kriterium nicht erfüllt ist und was notwendig
        ist, damit das Kriterium erfüllt wird:
        <Image size="big" centered src="/assets/images/auditorHome/image 6.png" />
      </li>
    </ol>
    <p>
      Wenn Du auf „zu anderer Frage“ klickst:
      <Image size="big" centered src="/assets/images/auditorHome/image 7.png" />
      kommst Du in die Übersicht und siehst an dem grünen oder roten Button ob eine Frage noch unbeantwortet ist. Rot =
      unbewertet, grün = bewertet:
      <Image size="big" centered src="/assets/images/auditorHome/image 8.png" />
    </p>
    <p>
      Nachdem alle Fragen bewertet wurden (alle müssen in der Übersicht grün sein), Prüfung abschließen durch klicken auf
      „speichern und schließen“.
    </p>
    <Divider horizontal />
    <p>
      Du kommst wieder in die Übersicht mit all Deinen Unternehmen.{' '}
      <strong>
        <u>Dann bitte die Daten fertig synchronisieren lassen - es dauert etwas - Du kannst Dir kurz was zu trinken holen.</u>
      </strong>
    </p>
    <Image fluid src="/assets/images/auditorHome/image 9.png" />
    <p>Hat das Programm fertig synchronisiert, auf „freigeben“ klicken:</p>
    <Image fluid src="/assets/images/auditorHome/image 10.png" />
    Es erscheint dann ein Warnfenster, dass wenn Du auf „speichern“ klickst, Deine Antworten fix sind.{' '}
    <strong>
      <u>Es kann dann an den Antworten nichts mehr geändert werden!</u>
    </strong>
    <Image centered size="big" src="/assets/images/auditorHome/image 11.png" />
    <p>Wenn Du alle Antworten bewertet hast, klickst Du auf „speichern“. </p>
    <p>
      Danach erscheinen nochmal die Kontrolldaten und Du kannst die letzten notwendigen Angaben machen.{' '}
      <u>Die mit rotem Stern markierten Felder müssen ausgefüllt werden.</u>
    </p>
    <p>
      Dann das Audit abschließen, in dem Du wieder auf „speichern“ klickst. Du kommst dann wieder in die Übersicht und wenn die
      Daten fertig synchronisiert haben, kannst Du „Auditbericht“ anklicken. Damit exportierst Du den Auditbericht. Falls eine
      word-Datei erstellt wurde, bitte pdfen!
    </p>
    <p>
      Letzte Seite und die Kontrolldaten ausdrucken lassen, unterschrieben mitnehmen. Falls das Ausdrucken auf dem Betrieb nicht
      klappt, dann das pdf an den Betrieb mailen und um Unterschrift bitten.
    </p>
    <Divider horizontal />
    <Label color="red">Achtung: Während des ganzen Auditprozesses bitte nicht aus dem Client ausloggen! </Label> Dann werden
    nämlich all Deine Eingaben gelöscht und Du kannst von vorne beginnen. Solange Du den Client nutzt, sind Deine Angaben auf
    Deinem Laptop gespeichert. Sobald Du online gehst, bzw. Dich über Firefox oder Chrome (bitte immer denselben Browser
    nutzen!) wieder in das STAR eingeloggt hast, synchronisiert der client, d.h. er holt alle Daten von Deinem Laptop herunter.
    (Am besten gar nicht mehr aus dem Client ausloggen… 😊)
    <Header>Nachbereitung</Header>
    <p>
      Client aufrufen und online gehen mit <strong>demselben Browser</strong>, den Du zu Beginn genutzt hast. (Wenn Du zum
      Synchronisieren der Daten vor dem Audit z.B. mit Chrome online warst, dann bitte wieder mit Chrome online gehen.) Dann
      wieder Daten synchronisieren lassen.
    </p>
    <p>
      Wenn es Abweichungen oder Verbesserungsmaßnahmen gab, das Unternehmen also Dokumente nachreichen soll, dann werden die im
      Auditbericht auf den letzten Seiten angegeben. Sobald das Unternehmen die Dokumente nachgereicht hat, loggst Du Dich
      wieder in das STAR ein und rufst die „Kontrolldaten“ auf. Hier kannst Du ganz unten bei „Umsetzung der Korrekturmaßnahmen“
      eingeben, was nachgereicht wurde und ob die Abweichung nun behoben wurde. Wenn Du dann auf „Auditbericht“ gehst, wird
      wieder ein Bericht heruntergeladen, der nun auf der letzten Seite die Umsetzungen aufführt. Diesen Bericht unterschreibst
      Du erneut mit aktuellem Datum. Dann ist das Audit vollkommen abgeschlossen.
    </p>
    <p>
      Falls es noch Hinweise, Bemerkungen, etc. im Nachgang des Audits gab, diese in den Kontrolldaten eintragen. Die GfaW ist
      sehr dankbar über einen persönlichen Eindruck. Dieser kann im Textfeld „Hinweise“ bei den Kontrolldaten eingefügt werden.
      Deine Einschätzung ist sehr wertvoll als Rückmeldung, welche Themen wir für die Arbeitsgruppentreffen aufgreifen sollten.
      Auditbericht an EcoControl schicken - Fertig. 😊
    </p>
  </Container>
);
