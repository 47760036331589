import React from 'react';
import { Segment, Grid, Icon, Menu, Popup, Label } from 'semantic-ui-react';
import { localize } from '../../../Localization/localize';
import { ItemsList } from '../../common/itemsList/itemsList';

interface IQuestionItemProps {
  title: string;
  id: number;
  onEdit: () => void;
  onDelete: () => void;
  crossReferences: string[];
  companyTypes: string[];
}

export const QuestionItem = (props: IQuestionItemProps) => (
  <Segment>
    <Grid divided>
      <Grid.Row>
        <Grid.Column width={12}>
          <h4 title={props.title} className="dd-dotdotdot">
            <Popup
              content={localize.questionIdHint}
              trigger={
                <Label basic>
                  {localize.questionId}: {props.id}
                </Label>
              }
            />{' '}
            {props.title}
          </h4>
          <p className="dd-centered-meta">
            <Icon name="shuffle" />
            <ItemsList
              items={props.crossReferences.map((x) => {
                return { title: x };
              })}
            />
          </p>
          <p className="dd-centered-meta">
            <Icon name="building" />
            <ItemsList
              items={props.companyTypes.map((x) => {
                return { title: x };
              })}
            />
          </p>
        </Grid.Column>
        <Grid.Column width={4}>
          <Menu vertical secondary fluid>
            <Menu.Item onClick={props.onEdit}>{localize.editUppercase}</Menu.Item>
            <Menu.Item onClick={props.onDelete}>{localize.deleteUpperCase}</Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);
