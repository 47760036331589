import { Button, Form, FormGroup, Header, Modal } from "semantic-ui-react";

import React from "react";
import { SubCompany } from "../../Swagger/api";
import { localize } from "../../Localization/localize";

interface ISubCompanyModal {
  subCompany: SubCompany;
  onClose: () => void;
}

export const SubCompanyModal = ({ subCompany, onClose }: ISubCompanyModal) => (
  <Modal open={true} dimmer="blurring">
    <Modal.Header>{subCompany.companyTitle || localize.noSubCompanyName}</Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Input label={localize.emailAddress} type="email" value={subCompany.emailAddress} transparent />
        <Header>{localize.contactPerson}</Header>
        <Form.Group widths="equal">
          <Form.Input label={localize.firstName} type="text" value={subCompany.firstName} transparent />
          <Form.Input label={localize.lastName} type="text" value={subCompany.lastName} transparent />
        </Form.Group>
        <FormGroup>
          <Form.Input
            width="11"
            label={localize.myCompanyWithLegalForm}
            type="text"
            value={subCompany.companyTitle}
            transparent
          />
          <Form.Field width="5">
            <label>{localize.companyType}</label>
            {(subCompany.companyTypes || []).map((c) => c.title).join(", ")}
          </Form.Field>
        </FormGroup>

        <Header>{localize.contactInfo}</Header>
        <Form.Input label={localize.streetAndHouseNumber} type="text" value={subCompany.street} transparent />
        <Form.Group>
          <Form.Input width="4" label={localize.postCode} type="text" value={subCompany.postcode} transparent />
          <Form.Input width="6" label={localize.city} type="text" value={subCompany.city} transparent />
          <Form.Field>
            <label>{localize.state}</label>
            {subCompany.state}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input label={localize.telephoneNumber} type="text" value={subCompany.telephoneNumber} transparent />
          <Form.Input label={localize.website} type="text" value={subCompany.webSite} transparent />
        </Form.Group>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose}>{localize.closeLowerCase}</Button>
    </Modal.Actions>
  </Modal>
);
