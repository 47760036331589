import * as localForage from "localforage";

import {
  AuditType,
  CompaniesStarAuditorOverview,
  Star,
  StarAuditorOverviewItem,
  StarControlData,
  UserAccountCompany,
} from "../../../Swagger/api";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import {
  StarsDb,
  companiesDbKey,
  getCurrentStarFromDb,
  starDbKey,
} from "../../auditor/auditorOverview";
import { history, paths } from "../../layout/layout";

import { LoginContext } from "../../../Contexts/loginContext";
import Textarea from "react-textarea-autosize";
import { localize } from "../../../Localization/localize";
import { getApis } from "../../../Services/webservice";

interface IStartAudit {
  location: {
    state: {
      starId: number;
    };
  };
}

export const getTimeForInput = (date: Date) => {
  const hoursValue = date.getHours();
  const minutesValue = date.getMinutes();

  const hours = hoursValue < 10 ? `0${hoursValue}` : hoursValue.toString();
  const minutes =
    minutesValue < 10 ? `0${minutesValue}` : minutesValue.toString();

  return `${hours}:${minutes}`;
};

export const StartAudit = (props: IStartAudit) => {
  const [showContents, setShowContents] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [starControlData, setStarControlData] = useState<StarControlData>();
  const [auditStartedDate, setAuditStartDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [auditStartedTime, setAuditStartTime] = useState<string>(
    getTimeForInput(new Date())
  );
  const [company, setCompany] = useState<UserAccountCompany>();

  const loginContext = useContext(LoginContext);
  const { starClient } = { ...getApis() };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const currentStar = await getCurrentStarFromDb(props.location.state.starId);
    const companiesDb = await localForage.getItem<CompaniesStarAuditorOverview>(
      companiesDbKey
    );
    if (!currentStar) {
      alert("STAR not synced!");
    }

    const currentCompany = companiesDb?.companies?.find(
      (x) => x.userLoginId == currentStar?.companyId
    );
    setCompany(currentCompany);
    if (currentCompany) {
      const name = `${currentCompany.firstName || ""} ${
        currentCompany.lastName || ""
      }`;
      setStarControlData({
        ...(starControlData! ?? {}),
        contactPersonName: name,
      });
    }
  };

  const startStarAudit = async (starId: number) => {
    try {
      setLoading(true);
      const data: Star = {
        auditStarted:
          `${auditStartedDate}T${auditStartedTime}` as unknown as Date,
        starControlData,
        id: props.location.state.starId,
        justForIso: false,
        started: new Date(),
        edited: new Date(),
        finished: false,
        justCompanyReport: false,
      };

      if (starControlData) {
        if (starControlData.auditType !== 3) {
          starControlData.otherAuditTypeName = "";
        }

        if (
          starControlData.previousCorrectionsDone ||
          starControlData.previousCorrectionsNotRequested
        ) {
          starControlData.previousCorrectionsNotDone = "";
        }

        if (
          starControlData.previousImprovementsDone ||
          starControlData.previousImprovementsNotRequested
        ) {
          starControlData.previousImprovementsNotDone = "";
        }
      }

      if (starControlData?.previousCorrectionsNotRequested) {
        starControlData.previousCorrectionsDone = undefined;
      }

      if (starControlData?.previousImprovementsNotRequested) {
        starControlData.previousImprovementsDone = undefined;
      }

      if (navigator.onLine) {
        await starClient.startStarAudit(data);
      }

      const starsInDb = await localForage.getItem<StarsDb>(starDbKey);
      if (
        starsInDb?.updatedStarIds.indexOf(props.location.state.starId) === -1
      ) {
        starsInDb.updatedStarIds.push(props.location.state.starId);
      }
      const currentStar = starsInDb?.stars.find(
        (x) => x.id === props.location.state.starId
      ) as StarAuditorOverviewItem;
      currentStar.auditStarted = data.auditStarted;
      currentStar.auditorName = loginContext.login?.displayName;
      currentStar.starControlData = {
        ...(currentStar.starControlData! ?? {}),
        ...starControlData,
      };
      await localForage.setItem(starDbKey, starsInDb);

      history.push(paths.star.auditStar, { starId });
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  if (showContents) {
    return (
      <React.Fragment>
        <Header size="large">{localize.startAuditHeader}</Header>
        <div
          dangerouslySetInnerHTML={{ __html: localize.startAuditContent }}
        ></div>
        <Divider hidden horizontal />

        <Button
          primary
          floated="right"
          onClick={() => setShowContents(false)}
          icon
          labelPosition="right"
        >
          <Icon name="arrow right"></Icon>
          {localize.toControlData}
        </Button>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Header size="large">{localize.auditControlDataHeader}</Header>
        <Form>
          <Header>{localize.companyData}</Header>
          <Form.Field>
            <Form.Field>
              <label>{localize.companyWithLegalForm}</label>
              {company
                ? company.companyTitle || localize.noData
                : localize.noData}
            </Form.Field>
            <Form.Input
              label={localize.ceoName}
              value={starControlData ? starControlData.ceoName || "" : ""}
              onChange={(_event, { value }) =>
                setStarControlData({ ...starControlData!, ceoName: value })
              }
            ></Form.Input>
            <Form.Group widths="equal">
              <Form.Input
                label={localize.contactPersonName}
                value={
                  starControlData ? starControlData.contactPersonName || "" : ""
                }
                onChange={(_event, { value }) =>
                  setStarControlData({
                    ...starControlData!,
                    contactPersonName: value,
                  })
                }
              ></Form.Input>
              <Form.Input
                label={localize.enviromentResponsibleName}
                value={
                  starControlData
                    ? starControlData.enviromentResponsibleName || ""
                    : ""
                }
                onChange={(_event, { value }) =>
                  setStarControlData({
                    ...starControlData!,
                    enviromentResponsibleName: value,
                  })
                }
              ></Form.Input>
            </Form.Group>

            <Form.Field>
              <label>{localize.address}</label>
              {company
                ? [
                    company.street,
                    company.postcode,
                    company.city,
                    company.state,
                  ]
                    .filter((x) => x)
                    .join(", ")
                : localize.noData}
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{localize.telephoneNumber}</label>
                {company
                  ? company.telephoneNumber || localize.noData
                  : localize.noData}
              </Form.Field>
              <Form.Field>
                <label htmlFor="">{localize.emailAddress}</label>
                {company && company.userLogin
                  ? company.userLogin.emailAddress || localize.noData
                  : localize.noData}
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label htmlFor="">{localize.fulltimePositions}</label>
              {company && company.fullTimePositions !== undefined
                ? company.fullTimePositions
                : localize.noData}
            </Form.Field>
            <Header>{localize.auditData}</Header>
            <Form.Field>
              <label>{localize.auditorName}</label>
              {loginContext?.login?.displayName || localize.noData}
            </Form.Field>
            <Form.Input
              label={localize.accompanyingPersonName}
              value={
                starControlData
                  ? starControlData.accompanyingPersonName || ""
                  : ""
              }
              onChange={(_event, { value }) =>
                setStarControlData({
                  ...starControlData!,
                  accompanyingPersonName: value,
                })
              }
            ></Form.Input>
            <Form.Group>
              <Form.Input
                width="5"
                type="date"
                label={localize.auditStartedDate}
                value={auditStartedDate}
                onChange={(_event, { value }) => setAuditStartDate(value)}
              ></Form.Input>
              <Form.Input
                width="3"
                type="time"
                label={localize.fromLowerCase}
                value={auditStartedTime}
                onChange={(_event, { value }) => setAuditStartTime(value)}
              ></Form.Input>
              <Form.Input
                width="8"
                type="date"
                label={localize.lastAuditDate}
                value={
                  starControlData && starControlData.lastAuditDate
                    ? starControlData.lastAuditDate.toISOString().split("T")[0]
                    : ""
                }
                onChange={(_event, { value }) =>
                  setStarControlData({
                    ...starControlData!,
                    lastAuditDate: new Date(value),
                  })
                }
              ></Form.Input>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Select
                options={[
                  { value: 0, text: localize.auditTypeFirstTime },
                  { value: 1, text: localize.auditTypeInspection },
                  { value: 2, text: localize.auditTypeRecertification },
                  { value: 3, text: localize.auditTypeOther },
                ]}
                label={localize.auditType}
                value={starControlData ? starControlData.auditType : undefined}
                onChange={(_event, { value }) =>
                  setStarControlData({
                    ...starControlData!,
                    auditType: value as AuditType,
                  })
                }
              ></Form.Select>
              <Form.Input
                label={localize.otherAuditTypeName}
                disabled={starControlData && starControlData.auditType !== 3}
                value={
                  starControlData
                    ? starControlData.otherAuditTypeName || ""
                    : ""
                }
                onChange={(_event, { value }) =>
                  setStarControlData({
                    ...starControlData!,
                    otherAuditTypeName: value,
                  })
                }
              ></Form.Input>
            </Form.Group>
            <Form.Field>
              <label>{localize.previousCorrectionsDone}</label>
            </Form.Field>
            <Form.Checkbox
              label={localize.previousCorrectionsNotRequested}
              checked={starControlData?.previousCorrectionsNotRequested}
              onChange={(_, { checked }) =>
                setStarControlData({
                  ...starControlData!,
                  previousCorrectionsNotRequested: checked ?? false,
                  previousCorrectionsDone: undefined,
                })
              }
            ></Form.Checkbox>
            <Form.Checkbox
              label={localize.yes}
              checked={starControlData?.previousCorrectionsDone === true}
              onChange={(_, { checked }) =>
                setStarControlData({
                  ...starControlData!,
                  previousCorrectionsDone: checked ?? true,
                  previousCorrectionsNotRequested: false,
                })
              }
            ></Form.Checkbox>
            <Form.Checkbox
              label={localize.no}
              checked={starControlData?.previousCorrectionsDone === false}
              onChange={(_, { checked }) =>
                setStarControlData({
                  ...starControlData!,
                  previousCorrectionsDone: !checked ?? false,
                  previousCorrectionsNotRequested: false,
                })
              }
            ></Form.Checkbox>
            <Form.Field
              disabled={starControlData?.previousCorrectionsDone !== false}
            >
              <label>{localize.previousCorrectionsNotDone}</label>
              <Textarea
                maxRows={10}
                disabled={starControlData?.previousCorrectionsDone !== false}
                value={
                  starControlData
                    ? starControlData.previousCorrectionsNotDone || ""
                    : ""
                }
                onChange={(event) =>
                  setStarControlData({
                    ...starControlData!,
                    previousCorrectionsNotDone: event.currentTarget!.value,
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="">{localize.previousImprovementsDone}</label>
            </Form.Field>
            <Form.Checkbox
              label={localize.previousImprovementsNotRequested}
              checked={starControlData?.previousImprovementsNotRequested}
              onChange={(_, { checked }) =>
                setStarControlData({
                  ...starControlData!,
                  previousImprovementsNotRequested: checked ?? false,
                  previousImprovementsDone: undefined,
                })
              }
            ></Form.Checkbox>
            <Form.Checkbox
              label={localize.yes}
              checked={starControlData?.previousImprovementsDone === true}
              onChange={(_, { checked }) =>
                setStarControlData({
                  ...starControlData!,
                  previousImprovementsDone: checked ?? true,
                  previousImprovementsNotRequested: false,
                })
              }
            ></Form.Checkbox>
            <Form.Checkbox
              label={localize.no}
              checked={starControlData?.previousImprovementsDone === false}
              onChange={(_, { checked }) =>
                setStarControlData({
                  ...starControlData!,
                  previousImprovementsDone: !checked ?? false,
                  previousImprovementsNotRequested: false,
                })
              }
            ></Form.Checkbox>
            <Form.Field
              disabled={starControlData?.previousImprovementsDone !== false}
            >
              <label>{localize.previousImprovementsNotDone}</label>
              <Textarea
                maxRows={10}
                disabled={starControlData?.previousImprovementsDone !== false}
                value={
                  starControlData
                    ? starControlData.previousImprovementsNotDone || ""
                    : ""
                }
                onChange={(event) =>
                  setStarControlData({
                    ...starControlData!,
                    previousImprovementsNotDone: event.currentTarget!.value,
                  })
                }
              />
            </Form.Field>
            <Button onClick={() => setShowContents(true)}>
              {localize.backLowerCase}
            </Button>
            <Button
              animated
              primary
              floated="right"
              loading={loading}
              onClick={() => startStarAudit(props.location.state.starId)}
            >
              <Button.Content visible>
                {localize.startStarButton}
              </Button.Content>
              <Button.Content hidden>
                {localize.goCrazyLowerCase}
              </Button.Content>
            </Button>
          </Form.Field>

          <Message
            error
            visible={hasError}
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        </Form>
      </React.Fragment>
    );
  }
};
