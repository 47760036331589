import { Divider, Icon, Menu } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { DeactivateUserLogin } from "./deactivateUserLogin";
import { DeleteUserAccount } from "./deleteUserAccount";
import { ResetUserPassword } from "./resetPasswort";
import { SuperAuditorAddEdit } from "./superAuditorAddEdit";
import { UserAccount } from "./userAccount";
import { UserAccountSuperAuditor } from "../../Swagger/api";
import { UserTabs } from "./UserTabs";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export const AdminUserSuperAuditors = () => {
  const [userAccounts, setUserAccounts] = useState<UserAccountSuperAuditor[]>(
    []
  );
  const [selectedForEdit, setSelectedForEdit] =
    useState<UserAccountSuperAuditor | null>();
  const [userAccountsUpdated, setUserAccountsUpdated] = useState(false);
  const [selectedForDeactivate, setSelectedForDeactivate] =
    useState<UserAccountSuperAuditor>();
  const [selectedForDelete, setSelectedForDelete] =
    useState<UserAccountSuperAuditor>();
  const [selectedForReset, setSelectedForReset] =
    useState<UserAccountSuperAuditor>();
  const { userAccountsClient } = { ...getApis() };

  useEffect(() => {
    reloadUsers();
  }, []);

  useEffect(() => {
    if (userAccountsUpdated) {
      reloadUsers();
      setUserAccountsUpdated(false);
    }
  }, [userAccountsUpdated]);

  const reloadUsers = async () => {
    const userAccounts = await userAccountsClient.getAllSuperAuditors();
    setUserAccounts(userAccounts);
  };

  return (
    <div>
      <UserTabs activeIndex={3} />
      <Divider horizontal hidden />
      <Menu icon="labeled">
        <Menu.Item onClick={() => setSelectedForEdit(null)}>
          <Icon name="id badge outline" />
          {localize.createSuperAuditor}
        </Menu.Item>
      </Menu>
      {userAccounts.map((userAccount) => (
        <UserAccount
          key={userAccount.userLoginId}
          deactivated={userAccount.userLogin?.deactivated || false}
          onDeactivate={() => setSelectedForDeactivate(userAccount)}
          onDelete={() => setSelectedForDelete(userAccount)}
          onEdit={() => setSelectedForEdit(userAccount)}
          onPasswordReset={() => setSelectedForReset(userAccount)}
          title={`${userAccount.firstName} ${userAccount.lastName}`}
          titleIconName="black tie"
        >
          <p title={userAccount.userLogin?.emailAddress}>
            <Icon name="mail" />
            {userAccount.userLogin!.emailAddress}
          </p>
        </UserAccount>
      ))}
      {selectedForEdit !== undefined ? (
        <SuperAuditorAddEdit
          userAccount={selectedForEdit}
          onCancel={() => setSelectedForEdit(undefined)}
          onDone={() => {
            setSelectedForEdit(undefined);
            setUserAccountsUpdated(true);
          }}
        />
      ) : null}
      {selectedForDeactivate?.userLogin ? (
        <DeactivateUserLogin
          onCancel={() => setSelectedForDeactivate(undefined)}
          onDone={() => {
            setSelectedForDeactivate(undefined);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForDeactivate.userLogin}
        />
      ) : null}
      {selectedForDelete?.userLogin ? (
        <DeleteUserAccount
          onCancel={() => setSelectedForDelete(undefined)}
          onDone={() => {
            setSelectedForDelete(undefined);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForDelete.userLogin}
        />
      ) : null}
      {selectedForReset?.userLogin ? (
        <ResetUserPassword
          onCancel={() => setSelectedForReset(undefined)}
          onDone={() => {
            setSelectedForReset(undefined);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForReset.userLogin}
        />
      ) : null}
    </div>
  );
};
