import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Checkbox, Divider, Grid, Icon, Label, Modal, Popup, Search, Segment } from 'semantic-ui-react';
import { localize } from '../../../Localization/localize';
import { Question, QuestionCategory, StarCompanyAnswer } from '../../../Swagger/api';
import { scrollUp } from '../starAdmin/starAdmin';
import { AuditorAnswerState } from '../starAudit/audiorAnswerState';
import { AnswerState } from './answerState';

interface IQuestionNavigator {
  questions: Question[];
  allAnswers?: StarCompanyAnswer[];
  allAnswersForAuditor?: StarCompanyAnswer[];
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onNavigate: (position: number) => void | Promise<void>;
  justCompanyReport: boolean;
}

export const QuestionNavigator = (props: IQuestionNavigator) => {
  const [query, setQuery] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
  const [activeCategories, setActiveCategories] = useState<number[]>([]);
  const [showAll, setShowAll] = useState(false);

  const { questions, onClose, onNavigate } = { ...props };

  useEffect(() => {
    let baseQuestions = questions;
    if (!showAll) {
      const notRelevantIds =
        props.allAnswersForAuditor?.filter((x) => x.starAuditorAnswer?.notRelevant).map((x) => x.questionId) || [];
      baseQuestions = baseQuestions.filter((x) => !notRelevantIds.includes(x.id));
    }
    setFilteredQuestions(
      query
        ? baseQuestions.filter(
            (x) =>
              x.content!.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
              (x.questionCategory && x.questionCategory.title!.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
              x.sort.toString().indexOf(query) !== -1
          )
        : baseQuestions
    );
  }, [query, showAll, questions]);

  const categories = useMemo(() => {
    const all = filteredQuestions.map((x) => x.questionAspect?.questionCategory).filter((x) => x) as QuestionCategory[];
    const distinct = all.filter((x, i) => i === all.findIndex((y) => y.id === x.id));
    return distinct;
  }, [filteredQuestions]);

  const markedQuestions = useMemo(() => {
    const result: Array<{ id: number; important: boolean; notRelevant: boolean }> = [];
    for (const answer of props.allAnswersForAuditor || []) {
      if (answer.starAuditorAnswer?.important || answer.starAuditorAnswer?.notRelevant) {
        result.push({
          id: answer.questionId,
          important: answer.starAuditorAnswer?.important,
          notRelevant: answer.starAuditorAnswer?.notRelevant,
        });
      }
    }
    return result;
  }, [props.allAnswersForAuditor]);

  const toggleCategory = (id: number) => {
    if (activeCategories.indexOf(id) === -1) {
      setActiveCategories([...activeCategories, id]);
    } else {
      setActiveCategories([...activeCategories.filter((x) => x !== id)]);
    }
  };

  const getAnswerState = (questionId: number) => {
    const answer = props.allAnswers?.find((x) => x.questionId === questionId);

    return answer?.answerState || 0;
  };

  const getAuditorAnswerState = (questionId: number) => {
    const answer = props.allAnswersForAuditor?.find((x) => x.questionId === questionId);

    return answer?.starAuditorAnswer?.auditResult || 0;
  };

  const auditorMode = props.allAnswersForAuditor && !props.allAnswers?.length;

  return (
    <Modal open closeIcon closeOnDimmerClick onClose={onClose} size="large">
      <Modal.Header>{localize.toOtherQuestionUpperCase}</Modal.Header>
      <Modal.Content className="dd-question-navigator">
        <Search
          open={false}
          fluid
          input={{ fluid: true }}
          onSearchChange={(_event, data) => setQuery(data.value || '')}
          placeholder={localize.searchQuestionPlaceholder}
        />
        <Divider horizontal hidden />
        <Accordion>
          {categories.map((category) => [
            <Accordion.Title
              index={category.id}
              active={activeCategories.indexOf(category.id!) !== -1}
              onClick={() => toggleCategory(category.id!)}
              key={`t_${category.id}`}
            >
              <Label
                size="large"
                className={`dd-category-label dd-dotdotdot ${category.color ? '' : 'dd-no-color'} dd-navigator`}
                style={{
                  backgroundColor: category.color,
                }}
              >
                {category.title || ''}
              </Label>
            </Accordion.Title>,
            <Accordion.Content
              className="dd-navigator-group"
              active={activeCategories.indexOf(category.id!) !== -1}
              key={`c_${category.id}`}
            >
              <Grid>
                {filteredQuestions
                  .filter((q) => q.questionAspect?.questionCategoryId === category.id)
                  .map((q) => (
                    <Grid.Row key={q.id}>
                      <Grid.Column width={2} textAlign="center" verticalAlign="middle">
                        {markedQuestions.some((x) => x.id === q.id && x.important) && (
                          <Popup
                            header={localize.importantQuestion}
                            content={localize.importantQuestionInfo}
                            position="left center"
                            trigger={<Icon fitted size="big" name="exclamation" color="orange" />}
                          />
                        )}
                        <Popup
                          content={localize.questionIdHint}
                          trigger={
                            <Label basic>
                              {localize.questionId}: {q.id}
                            </Label>
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={14}>
                        <Popup
                          content={localize.questionNavigationNotRelevant}
                          disabled={!markedQuestions.some((x) => x.id === q.id && x.notRelevant)}
                          trigger={
                            <Segment
                              disabled={markedQuestions.some((x) => x.id === q.id && x.notRelevant)}
                              className="dd-question"
                              key={q.id}
                              onClick={async () => {
                                await onNavigate(q.sort || 1);
                                scrollUp();
                              }}
                            >
                              <div>
                                {q.sort}. {props.justCompanyReport ? q.contentForCompanyReport : q.content}
                              </div>
                              {!auditorMode ? <AnswerState currentState={getAnswerState(q.id)} /> : null}
                              {auditorMode ? <AuditorAnswerState currentState={getAuditorAnswerState(q.id)} /> : null}
                            </Segment>
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  ))}
              </Grid>
            </Accordion.Content>,
          ])}
        </Accordion>
        <Divider horizontal />
        <Checkbox
          toggle
          label={localize.questionNavigatorShowAll}
          checked={showAll}
          onChange={(_, { checked }) => setShowAll(!!checked)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{localize.closeLowerCase}</Button>
      </Modal.Actions>
    </Modal>
  );
};
