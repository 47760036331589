import { Button, Form, Header, Icon, Message } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { CertificationCenter } from "../../Swagger/api";
import { Offline } from "../layout/offline";
import { countriesListDe } from "../../constants";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export const AuditorProfile = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [hasError, setHasError] = useState(false);
  const [availableCertificationCenters, setAvailableCertificationCenters] =
    useState<CertificationCenter[]>();
  const [certificationCenterId, setCertificationCenterId] = useState<number>();
  const [shortForm, setShortForm] = useState("");
  const [loading, setLoading] = useState(true);
  const [userLoginId, setUserLoginId] = useState(-1);
  const { auditorProfileClient } = { ...getApis() };

  useEffect(() => {
    setHasError(false);

    (async () => {
      const myProfile = await auditorProfileClient.getMyProfile();

      setEmailAddress(myProfile.userLogin!.emailAddress || "");
      setFirstName(myProfile.firstName || "");
      setLastName(myProfile.lastName || "");
      setStreet(myProfile.street || "");
      setPostcode(myProfile.postcode || "");
      setCity(myProfile.city || "");
      setState(myProfile.state || "");
      setTelephoneNumber(myProfile.telephoneNumber || "");
      setShortForm(myProfile.shortForm || "");
      setCertificationCenterId(myProfile.certificationCenterId);
      setUserLoginId(myProfile.userLoginId || -1);
      setLoading(false);
    })();

    (async () => {
      const availableCertificationCenters =
        await auditorProfileClient.getAllCertificationCenters();
      setAvailableCertificationCenters(availableCertificationCenters);
    })();
  }, []);

  const onSave = async () => {
    setLoading(true);

    try {
      await auditorProfileClient.setUserAccount({
        certificationCenterId: certificationCenterId!,
        city,
        firstName,
        lastName,
        postcode,
        shortForm,
        state,
        street,
        telephoneNumber,
        userLoginId,
      });
    } catch {
      setHasError(true);
    }

    setLoading(false);
  };

  if (!navigator.onLine) {
    return <Offline></Offline>;
  }

  return (
    <section>
      <Button
        primary
        icon
        labelPosition="right"
        floated="right"
        loading={loading}
        onClick={onSave}
      >
        <Icon name="save" />
        {localize.saveLowerCase}
      </Button>
      <Header size="huge">{localize.myProfile}</Header>
      <Form>
        <Form.Input
          label={localize.emailAddress}
          type="email"
          value={emailAddress}
          disabled
        />
        <Form.Select
          label={localize.certificationCenter}
          value={certificationCenterId}
          onChange={(_e, { value }) =>
            setCertificationCenterId(value as number)
          }
          options={(availableCertificationCenters || []).map((cc) => {
            return { text: cc.title, value: cc.id, disabled: cc.deactivated };
          })}
          loading={!availableCertificationCenters}
        />
        <Header>{localize.contactPerson}</Header>
        <Form.Group widths="equal">
          <Form.Input
            label={localize.firstName}
            type="text"
            value={firstName}
            onChange={(_e, { value }) => setFirstName(value)}
          />
          <Form.Input
            label={localize.lastName}
            type="text"
            value={lastName}
            onChange={(_e, { value }) => setLastName(value)}
          />
        </Form.Group>
        <Form.Input
          label={localize.shortForm}
          type="text"
          value={shortForm}
          onChange={(_e, { value }) => setShortForm(value)}
        />
        <Header>{localize.contactInfo}</Header>
        <Form.Input
          label={localize.streetAndHouseNumber}
          type="text"
          value={street}
          onChange={(_e, { value }) => setStreet(value)}
        />
        <Form.Group>
          <Form.Input
            width="4"
            label={localize.postCode}
            type="text"
            value={postcode}
            onChange={(_e, { value }) => setPostcode(value)}
          />
          <Form.Input
            width="6"
            label={localize.city}
            type="text"
            value={city}
            onChange={(_e, { value }) => setCity(value)}
          />
          <Form.Select
            width="6"
            label={localize.state}
            type="text"
            value={state}
            search
            options={countriesListDe.map((x) => ({
              value: x.name,
              text: x.name,
              flag: x.alpha2,
            }))}
            onChange={(_e, { value }) => setState(value as string)}
          />
        </Form.Group>
        <Form.Input
          label={localize.telephoneNumber}
          type="text"
          value={telephoneNumber}
          onChange={(_e, { value }) => setTelephoneNumber(value)}
        />
        <Message
          error
          visible={hasError}
          content={localize.generalErrorMessage}
          header={localize.generalErrorHeader}
        />
      </Form>
    </section>
  );
};
