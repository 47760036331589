import React from 'react';
import { Button, Divider, Form, Grid, Header, Icon, Label, Popup, Radio, Segment } from 'semantic-ui-react';
import { localize } from '../../../Localization/localize';
import { AuditResult, Question, StarCompanyAnswer } from '../../../Swagger/api';
import { paths, history } from '../../layout/layout';
import { QuestionMeta } from '../starRun/questionMeta';

interface IQuestionAdminContent {
  question: Question;
  answer?: StarCompanyAnswer;
  onBack: () => void;
  onSearch: () => void;
  onNext: () => void;
  firstQuestion: boolean;
  lastQuestion: boolean;
  starId: number;
  justCompanyReport: boolean;
}

export const QuestionAdminContent = (props: IQuestionAdminContent) => {
  return (
    <Grid className="dd-question-content">
      <Grid.Row>
        <Grid.Column width="16">
          <Header size="huge">
            {props.question.sort}. {props.justCompanyReport ? props.question.contentForCompanyReport : props.question.content}
          </Header>
          <Popup
            content={localize.questionIdHint}
            trigger={
              <Label basic>
                {localize.questionId}: {props.question.id}
              </Label>
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="10">
          {!props.justCompanyReport && (
            <Form>
              <Form.Field>
                <label>{localize.answerForAuditor}</label>
                <Segment>{props.answer ? props.answer.content || localize.noData : localize.noData}</Segment>
              </Form.Field>
              <Form.Field>
                <label>{localize.reportText}</label>
                <Segment>{props.answer ? props.answer.reportText || localize.noData : localize.noData}</Segment>
              </Form.Field>
              <Form.Field>
                <label>{localize.auditorComment}</label>
                <Segment>
                  {props.answer && props.answer.starAuditorAnswer
                    ? props.answer.starAuditorAnswer.auditReportText || localize.noData
                    : localize.noData}
                </Segment>
              </Form.Field>
              <Form.Field>
                <label>{localize.auditMethod}</label>
              </Form.Field>
              <Form.Group widths="4">
                <Form.Checkbox
                  label={localize.auditMethodObservation}
                  readOnly
                  checked={props.answer?.starAuditorAnswer?.observation}
                />
                <Form.Checkbox
                  label={localize.auditMethodCommunication}
                  readOnly
                  checked={props.answer?.starAuditorAnswer?.comunication}
                />
                <Form.Checkbox
                  label={localize.auditMethodDocument}
                  readOnly
                  checked={props.answer?.starAuditorAnswer?.documentation}
                />
              </Form.Group>
              <Form.Field>
                <label>{localize.result}</label>
              </Form.Field>
              <Form.Group widths="4">
                <Form.Field>
                  <Radio label={localize.completed} checked={props.answer?.starAuditorAnswer?.auditResult === 1} />
                </Form.Field>
                <Form.Field>
                  <Radio label={localize.partiallyCompleted} checked={props.answer?.starAuditorAnswer?.auditResult === 2} />
                </Form.Field>
                <Form.Field>
                  <Radio label={localize.notCompleted} checked={props.answer?.starAuditorAnswer?.auditResult === 4} />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={localize.notAudited}
                    checked={props.answer?.starAuditorAnswer?.auditResult === AuditResult.NotAudited}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio label={localize.notRelevant} checked={props.answer?.starAuditorAnswer?.notRelevant} />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <label>{localize.improvements}</label>
                <Segment>
                  {props.answer && props.answer.starAuditorAnswer
                    ? props.answer.starAuditorAnswer.improvements || localize.noData
                    : localize.noData}
                </Segment>
              </Form.Field>
              <Form.Field>
                <label>{localize.problems}</label>
                <Segment>
                  {props.answer && props.answer.starAuditorAnswer
                    ? props.answer.starAuditorAnswer.problems || localize.noData
                    : localize.noData}
                </Segment>
              </Form.Field>
            </Form>
          )}
          {props.justCompanyReport && <Segment>{props.answer?.reportText || localize.noData}</Segment>}

          <Divider horizontal hidden />
          {props.firstQuestion ? (
            <Button basic>{localize.firstQuestion}</Button>
          ) : (
            <Button icon labelPosition="left" onClick={props.onBack}>
              <Icon name="arrow left" /> {localize.previousQuestion}
            </Button>
          )}

          <Button icon labelPosition="left" onClick={props.onSearch}>
            <Icon name="search" /> {localize.toOtherQuestionLowerCase}
          </Button>

          {props.lastQuestion ? (
            <Button basic floated="right">
              {localize.lastQuestion}
            </Button>
          ) : (
            <Button icon labelPosition="right" primary floated="right" onClick={props.onNext}>
              <Icon name="arrow right" /> {localize.nextQuestion}
            </Button>
          )}

          <Divider horizontal hidden />
          <Button icon labelPosition="left" onClick={() => history.push(paths.admin.overview)}>
            <Icon name="arrow left" /> {localize.backToOverview}
          </Button>
        </Grid.Column>
        <Grid.Column width="6">
          <QuestionMeta
            question={props.question}
            starId={props.starId}
            importantQuestion={!!props.answer?.starAuditorAnswer?.important}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
