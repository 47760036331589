import { Divider, Icon, Menu, Message, Segment } from "semantic-ui-react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";

import { ItemsList } from "../../../common/itemsList/itemsList";
import { Question } from "../../../../Swagger/api";
import { localize } from "../../../../Localization/localize";
import { getApis } from "../../../../Services/webservice";

export interface IQuestionSort {
  questionsData: Question[];
  onDone: () => void;
  onCancel: () => void;
}

export const QuestionSort = (props: IQuestionSort) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const { questionClient } = { ...getApis() };

  const { questionsData, onDone, onCancel } = { ...props };

  useEffect(() => {
    setQuestions(questionsData);
  }, []);

  const save = async () => {
    setLoading(true);

    try {
      await questionClient.sortQuestions(questions);

      setLoading(false);
      onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  const onSort = (dragResult: DropResult) => {
    const copy = questions.splice(0, questions.length);

    const [moved] = copy.splice(dragResult.source.index, 1);

    if (dragResult.destination) {
      copy.splice(dragResult.destination.index, 0, moved);
    }

    for (let i = 0; i < copy.length; i++) {
      copy[i].sort = i + 1;
    }

    setQuestions(copy);
  };

  return (
    <React.Fragment>
      <Menu icon="labeled">
        <Menu.Item onClick={onCancel}>
          <Icon name="arrow left" />
          {localize.cancelLowerCase}
        </Menu.Item>
        <Menu.Item onClick={() => save()} disabled={loading}>
          <Icon name="save" />
          {localize.saveLowerCase}
        </Menu.Item>
      </Menu>
      <Divider horizontal hidden />
      <DragDropContext onDragEnd={onSort}>
        <Droppable direction="vertical" droppableId="questions">
          {(droppableProvided: DroppableProvided) => (
            <div ref={droppableProvided.innerRef}>
              {questions.map((x, i) => (
                <Draggable key={x.id} draggableId={x.id!.toString()} index={i}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Segment key={x.id} loading={loading}>
                        <div className="dd-question-sort-title">
                          {x.sort || 0}. {x.content || ""}
                        </div>
                        <ItemsList
                          items={[
                            {
                              title: x.questionCategory
                                ? x.questionCategory.title || ""
                                : "",
                            },
                          ]}
                        />
                      </Segment>
                      <Divider horizontal hidden />
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Message
        error
        visible={hasError}
        hidden={!hasError}
        content={localize.generalErrorMessage}
        header={localize.generalErrorHeader}
      />
    </React.Fragment>
  );
};
