import {
  Button,
  Form,
  FormGroup,
  Header,
  Icon,
  Message,
  Modal,
} from "semantic-ui-react";
import { CompanyType, SubCompany } from "../../../Swagger/api";
import React, { useEffect, useState } from "react";

import { countriesListDe } from "../../../constants";
import { localize } from "../../../Localization/localize";
import { getApis } from "../../../Services/webservice";

interface ISubCompanyProps {
  subCompany: SubCompany;
  onChanged: () => void;
  availableCompanyTypes: CompanyType[];
}

export const SubCompanyView = (props: ISubCompanyProps) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [webSite, setWebSite] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [hasError, setHasError] = useState(false);
  const [companyTypeIds, setCompanyTypeIds] = useState<number[]>([]);
  const [companyTitle, setCompanyTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { subCompaniesClient } = { ...getApis() };

  useEffect(() => {
    setHasError(false);
    setEmailAddress(subCompany.emailAddress || "");
    setFirstName(subCompany.firstName || "");
    setLastName(subCompany.lastName || "");
    setStreet(subCompany.street || "");
    setPostcode(subCompany.postcode || "");
    setCity(subCompany.city || "");
    setState(subCompany.state || "");
    setTelephoneNumber(subCompany.telephoneNumber || "");
    setCompanyTitle(subCompany.companyTitle || "");
    setCompanyTypeIds((subCompany.companyTypes || []).map((x) => x.id!));
    setWebSite(subCompany.webSite || "");
  }, []);

  const onSave = async () => {
    setLoading(true);
    const data: SubCompany = {
      city,
      companyTitle,
      emailAddress,
      firstName,
      lastName,
      parentCompanyId: subCompany.parentCompanyId,
      postcode,
      state,
      street,
      telephoneNumber,
      webSite,
      id: subCompany.id,
    };

    try {
      setHasError(false);
      if (!data.id || data.id < 0) {
        data.id = 0;
        data.id = await subCompaniesClient.addSubCompany(data);
      } else {
        await subCompaniesClient.updateSubCompany(data);
      }
      await subCompaniesClient.assignSubCompanyTypes({
        subCompanyId: data.id,
        companyTypeIds,
      });
    } catch {
      setHasError(true);
    }

    setLoading(false);
    onChanged();
  };

  const onDelete = async () => {
    setLoading(true);

    if (subCompany.id && subCompany.id !== -1) {
      await subCompaniesClient.deleteSubCompany(subCompany.id);
    }

    setLoading(false);
    onChanged();
  };

  const { subCompany, onChanged, availableCompanyTypes } = { ...props };

  return (
    <section className="dd-subcompany-view">
      <Button
        primary
        icon
        labelPosition="right"
        floated="right"
        loading={loading}
        onClick={onSave}
      >
        <Icon name="save" />
        {localize.saveSubCompany}
      </Button>
      <Button
        icon
        labelPosition="left"
        floated="right"
        loading={loading}
        onClick={() => setShowDeleteModal(true)}
      >
        <Icon name="x" />
        {localize.deleteLowerCase}
      </Button>
      <Modal dimmer="blurring" open={showDeleteModal}>
        <Modal.Header>{localize.deleteSubCompanyHeader}</Modal.Header>
        <Modal.Content>
          {localize.formatString(
            localize.deleteSubCompanyMessage,
            companyTitle
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowDeleteModal(false)} floated="left">
            {localize.cancelLowerCase}
          </Button>
          <Button primary icon labelPosition="right" onClick={onDelete}>
            <Icon name="x" />
            {localize.deleteLowerCase}
          </Button>
        </Modal.Actions>
      </Modal>
      <Header size="huge">
        {companyTitle
          ? `${companyTitle} (${localize.subCompanyUpperCase})`
          : localize.newSubCompany}
      </Header>
      <Form>
        <Form.Input
          label={localize.emailAddress}
          type="email"
          value={emailAddress}
          onChange={(_e, { value }) => setEmailAddress(value)}
        />
        <Header>{localize.contactPerson}</Header>
        <Message
          error
          visible={hasError}
          content={localize.generalErrorMessage}
          header={localize.generalErrorHeader}
        />
        <Form.Group widths="equal">
          <Form.Input
            label={localize.firstName}
            type="text"
            value={firstName}
            onChange={(_e, { value }) => setFirstName(value)}
          />
          <Form.Input
            label={localize.lastName}
            type="text"
            value={lastName}
            onChange={(_e, { value }) => setLastName(value)}
          />
        </Form.Group>
        <FormGroup>
          <Form.Input
            width="11"
            label={localize.myCompanyWithLegalForm}
            type="text"
            value={companyTitle}
            onChange={(_e, { value }) => setCompanyTitle(value)}
          />
          <Form.Select
            width="5"
            label={localize.companyType}
            value={companyTypeIds}
            options={
              availableCompanyTypes
                ? availableCompanyTypes.map((c) => {
                    return {
                      text: c.title,
                      value: c.id,
                      disabled: c.deactivated,
                    };
                  })
                : []
            }
            multiple
            loading={!availableCompanyTypes}
            onChange={(_e, { value }) => setCompanyTypeIds(value as number[])}
          />
        </FormGroup>

        <Header>{localize.contactInfo}</Header>
        <Form.Input
          label={localize.streetAndHouseNumber}
          type="text"
          value={street}
          onChange={(_e, { value }) => setStreet(value)}
        />
        <Form.Group>
          <Form.Input
            width="4"
            label={localize.postCode}
            type="text"
            value={postcode}
            onChange={(_e, { value }) => setPostcode(value)}
          />
          <Form.Input
            width="6"
            label={localize.city}
            type="text"
            value={city}
            onChange={(_e, { value }) => setCity(value)}
          />
          <Form.Select
            width="6"
            label={localize.state}
            type="text"
            value={state}
            options={countriesListDe.map((x) => ({
              value: x.name,
              text: x.name,
              flag: x.alpha2,
            }))}
            onChange={(_e, { value }) => setState(value as string)}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label={localize.telephoneNumber}
            type="text"
            value={telephoneNumber}
            onChange={(_e, { value }) => setTelephoneNumber(value)}
          />
          <Form.Input
            label={localize.website}
            type="text"
            value={webSite}
            onChange={(_e, { value }) => setWebSite(value)}
          />
        </Form.Group>
      </Form>
    </section>
  );
};
