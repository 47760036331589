import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import Textarea from 'react-textarea-autosize';
import { Button, Divider, Form, Grid, Header, Icon, Label, Loader, Popup, Radio } from 'semantic-ui-react';
import { localize } from '../../../Localization/localize';
import {
  AuditResult,
  Question,
  StarAuditorOverViewCompanyAnswer,
  StarAuditorOverviewQuestion,
  StarAuditorOverviewStarAuditorAnswer,
} from '../../../Swagger/api';
import { CollapseText } from '../../common/collapseText';
import { getAuditMethod, getAuditResult } from '../starRun/questionContent';
import { QuestionMeta } from '../starRun/questionMeta';
import { AuditorAnswerState } from './audiorAnswerState';

interface IQuestionAuditContent {
  question: StarAuditorOverviewQuestion;
  companyAnswer?: StarAuditorOverViewCompanyAnswer;
  answer: StarAuditorOverviewStarAuditorAnswer;
  onBack: () => void;
  onSearch: () => void;
  onClose: () => void;
  onNext: () => void;
  // eslint-disable-next-line no-unused-vars
  onAnswerChange: (answer: StarAuditorOverviewStarAuditorAnswer) => void;
  readOnlyMode: boolean;
  firstQuestion: boolean;
  lastQuestion: boolean;
  allCompanyAnswers: StarAuditorOverViewCompanyAnswer[];
  starId: number;
  saving: boolean;
  previousAnswer?: StarAuditorOverViewCompanyAnswer;
}

export const QuestionAuditContent = (props: IQuestionAuditContent) => {
  const [block, setBlock] = useState(!props.readOnlyMode);

  const getCompanyAnswerContent = (questionId: number) => {
    var item = props.allCompanyAnswers.find((x) => x.questionId === questionId);

    return item ? item.content || localize.noData : localize.noData;
  };

  const getAuditorAnswerContent = (questionId: number) => {
    var item = props.allCompanyAnswers.find((x) => x.questionId === questionId);

    return item?.starAuditorAnswer?.auditReportText || localize.noData;
  };

  const childQuestions = props.allCompanyAnswers
    .map((x) => x.question)
    .filter((x) =>
      props.question.connectedQuestionMaps?.some((y) => y.childQuestionId === x?.id)
    ) as StarAuditorOverviewQuestion[];

  return (
    <Grid className="dd-question-content">
      <Grid.Row>
        <Grid.Column width="16">
          <Header size="huge">
            {props.question.sort}. {props.question.content}
          </Header>
          <Popup
            content={localize.questionIdHint}
            trigger={
              <Label basic>
                {localize.questionId}: {props.question.id}
              </Label>
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="10">
          <Form>
            <div className="dd-with-preline">
              <CollapseText>
                {props.companyAnswer ? props.companyAnswer.content || localize.noData : localize.noData}
              </CollapseText>
            </div>
            {childQuestions.length ? (
              <>
                <Divider horizontal hidden></Divider>
                <Header>{localize.connectedQuestionsInfo}</Header>
                {childQuestions?.map((x, i) => (
                  <Form.Field key={i} className="dd-connected-question">
                    <label>
                      {x.sort}. {x.content}
                    </label>
                    <CollapseText>
                      {getCompanyAnswerContent(x.id)}
                      <Form.Field>
                        <label className="dd-spaced-label">{localize.auditorComment}</label>
                        {getAuditorAnswerContent(x.id)}
                      </Form.Field>
                    </CollapseText>
                  </Form.Field>
                ))}
              </>
            ) : null}

            {props.previousAnswer && (
              <>
                <Header>{localize.previousAnswerAuditor}</Header>
                <CollapseText>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Field>
                          <label>{localize.auditMethod}</label>
                          {getAuditMethod(props.previousAnswer.starAuditorAnswer)}
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <label>{localize.result}</label>
                          {getAuditResult(props.previousAnswer.starAuditorAnswer?.auditResult)}
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Form.Field className="dd-previous-answer-content-container">
                    <label>{localize.auditorComment}</label>
                    {props.previousAnswer.starAuditorAnswer?.auditReportText || localize.noData}
                    {props.previousAnswer.starAuditorAnswer?.improvements && (
                      <>
                        <label>{localize.improvements}</label>
                        {props.previousAnswer.starAuditorAnswer?.improvements}
                      </>
                    )}
                    {props.previousAnswer.starAuditorAnswer?.problems && (
                      <>
                        <label>{localize.problems}</label>
                        {props.previousAnswer.starAuditorAnswer?.problems}
                      </>
                    )}
                  </Form.Field>
                </CollapseText>
              </>
            )}
            <Divider horizontal hidden></Divider>
            <Form.Field>
              <label>{localize.auditorComment}</label>
              {props.readOnlyMode ? (
                <>{props.answer.auditReportText || localize.noData}</>
              ) : (
                <Textarea
                  maxRows={10}
                  value={props.answer.auditReportText ?? ''}
                  onChange={(event) => props.onAnswerChange({ ...props.answer, auditReportText: event.currentTarget?.value })}
                  disabled={props.readOnlyMode}
                />
              )}
            </Form.Field>
            <AuditorAnswerState currentState={props.answer.auditResult} />
            <Form.Field>
              <label>{localize.auditMethod}</label>
            </Form.Field>
            <Form.Group widths="4">
              <Form.Checkbox
                label={localize.auditMethodObservation}
                checked={props.answer.observation}
                readOnly={props.readOnlyMode}
                onChange={(_e, { checked }) => props.onAnswerChange({ ...props.answer, observation: !!checked })}
              />
              <Form.Checkbox
                label={localize.auditMethodCommunication}
                checked={props.answer.comunication}
                readOnly={props.readOnlyMode}
                onChange={(_e, { checked }) => props.onAnswerChange({ ...props.answer, comunication: !!checked })}
              />
              <Form.Checkbox
                label={localize.auditMethodDocument}
                checked={props.answer.documentation}
                readOnly={props.readOnlyMode}
                onChange={(_e, { checked }) => props.onAnswerChange({ ...props.answer, documentation: !!checked })}
              />
            </Form.Group>
            <Form.Field>
              <label>{localize.result}</label>
            </Form.Field>
            <Form.Group widths="4">
              <Form.Field>
                <Radio
                  name="auditResult"
                  label={localize.completed}
                  value={1}
                  checked={props.answer?.auditResult === 1}
                  onChange={(_e, { value }) =>
                    props.onAnswerChange({ ...props.answer, auditResult: value as AuditResult, notRelevant: false })
                  }
                  readOnly={props.readOnlyMode}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  name="auditResult"
                  label={localize.partiallyCompleted}
                  value={2}
                  checked={props.answer?.auditResult === 2}
                  onChange={(_e, { value }) =>
                    props.onAnswerChange({ ...props.answer, auditResult: value as AuditResult, notRelevant: false })
                  }
                  readOnly={props.readOnlyMode}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  name="auditResult"
                  label={localize.notCompleted}
                  value={4}
                  checked={props.answer?.auditResult === 4}
                  onChange={(_e, { value }) =>
                    props.onAnswerChange({ ...props.answer, auditResult: value as AuditResult, notRelevant: false })
                  }
                  readOnly={props.readOnlyMode}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  name="auditResult"
                  label={localize.notAudited}
                  value={AuditResult.NotAudited}
                  checked={props.answer?.auditResult === AuditResult.NotAudited}
                  onChange={(_e, { value }) =>
                    props.onAnswerChange({ ...props.answer, auditResult: value as AuditResult, notRelevant: false })
                  }
                  readOnly={props.readOnlyMode}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  name="auditResult"
                  label={localize.notRelevant}
                  checked={props.answer?.notRelevant}
                  onChange={(_e, { checked }) => props.onAnswerChange({ ...props.answer, notRelevant: !!checked })}
                  readOnly={props.readOnlyMode}
                />
                <div>({localize.notRelevantHint})</div>
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Popup content={localize.importantQuestionHint} trigger={<label>{localize.importantQuestion}</label>}></Popup>
              <Form.Checkbox
                toggle
                readOnly={props.readOnlyMode}
                checked={!!props.answer?.important}
                onChange={(_, { checked }) => props.onAnswerChange({ ...props.answer, important: !!checked })}
                label={props.answer.important ? localize.yes : localize.no}
              />
            </Form.Field>
            <Form.Field>
              <label>{localize.improvements}</label>
              {props.readOnlyMode ? (
                props.answer.improvements || localize.noData
              ) : (
                <Textarea
                  className="dd-disabled-light"
                  maxRows={10}
                  value={props.answer.improvements ?? ''}
                  onChange={(event) => props.onAnswerChange({ ...props.answer, improvements: event.currentTarget!.value })}
                  disabled={props.readOnlyMode || props.answer.auditResult !== 2}
                />
              )}
            </Form.Field>
            <Form.Field>
              <label>{localize.problems}</label>
              {props.readOnlyMode ? (
                props.answer.problems || localize.noData
              ) : (
                <Textarea
                  className="dd-disabled-light"
                  maxRows={10}
                  value={props.answer.problems ?? ''}
                  onChange={(event) => props.onAnswerChange({ ...props.answer, problems: event.currentTarget!.value })}
                  disabled={props.readOnlyMode || props.answer.auditResult !== 4}
                />
              )}
            </Form.Field>
          </Form>
          <Divider horizontal hidden />
          {props.firstQuestion ? (
            <Button basic>{localize.firstQuestion}</Button>
          ) : (
            <Button icon labelPosition="left" onClick={props.onBack} disabled={props.saving}>
              <Icon name="arrow left" /> {localize.backLowerCase}
            </Button>
          )}

          <Button icon labelPosition="left" onClick={props.onSearch} disabled={props.saving}>
            <Icon name="search" /> {localize.toOtherQuestionLowerCase}
          </Button>

          {props.lastQuestion ? (
            <Button basic floated="right">
              {localize.lastQuestion}
            </Button>
          ) : (
            <Button icon labelPosition="right" primary floated="right" onClick={props.onNext} disabled={props.saving}>
              <Icon name="arrow right" /> {localize.nextQuestion}
            </Button>
          )}

          <Divider horizontal hidden></Divider>
          {!props.readOnlyMode ? (
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setBlock(false);
                props.onClose();
              }}
              disabled={props.saving}
            >
              <Icon name="save" /> {localize.saveAndCloseLowerCase}
            </Button>
          ) : (
            <Button
              onClick={() => {
                setBlock(false);
                props.onClose();
              }}
            >
              {localize.closeLowerCase}
            </Button>
          )}

          {props.saving && (
            <div className="dd-question-saving">
              {localize.savingAnswer}
              <Loader active size="small" inline />
            </div>
          )}
        </Grid.Column>
        <Grid.Column width="6">
          <QuestionMeta
            question={props.question as Question}
            starId={props.starId}
            importantQuestion={props.answer.important}
          />
        </Grid.Column>
      </Grid.Row>
      <Prompt when={block} message={localize.saveDataPrompt} />
    </Grid>
  );
};
