import { Button, Modal, Segment } from 'semantic-ui-react';

import React from 'react';
import { StarAuditorOverviewPreviousItem } from '../../../Swagger/api';
import { localize } from '../../../Localization/localize';

interface IPreviousCorrections {
  star: StarAuditorOverviewPreviousItem;
  onClose: () => void;
}

export const PreviousCorrections = (props: IPreviousCorrections) => {
  const { star, onClose } = { ...props };

  let items: string[] = [];
  if (star.answers) {
    items = star.answers.filter((x) => x.starAuditorAnswer?.problems).map((x) => x.starAuditorAnswer?.problems!);
  }

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>{localize.seePreviousCorrections}</Modal.Header>
      <Modal.Content>
        {items.map((x, i) => (
          <Segment key={i}>{x}</Segment>
        ))}
        {items.length === 0 ? localize.noData : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{localize.closeLowerCase}</Button>
      </Modal.Actions>
    </Modal>
  );
};
