import { Button, Divider, Icon, Message, Modal } from "semantic-ui-react";
import React, { useState } from "react";

import { StarVersion } from "../../../../Swagger/api";
import { localize } from "../../../../Localization/localize";
import { getApis } from "../../../../Services/webservice";

export interface IStarVersionDelete {
  version: StarVersion;
  onDone: () => void;
  onCancel: () => void;
}

export const DeleteStarVersion = (props: IStarVersionDelete) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { starVersionClient } = { ...getApis() };

  const deleteStarVersion = async () => {
    setLoading(true);

    try {
      await starVersionClient.deleteStarVersion(props.version.id);

      setLoading(false);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>{localize.deleteStarVersionHeader}</Modal.Header>
      <Modal.Content>
        <div
          dangerouslySetInnerHTML={{
            __html: localize.formatString(
              localize.deleleteStarVersionContent,
              props.version.title || "-"
            ) as string,
          }}
        ></div>
        <Divider horizontal hidden />
        {hasError ? (
          <Message
            error
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onCancel} floated="left">
          {localize.cancelLowerCase}
        </Button>
        <Button
          primary
          icon
          labelPosition="right"
          onClick={deleteStarVersion}
          loading={loading}
        >
          <Icon name="trash alternate outline" />
          {localize.deleteLowerCase}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
