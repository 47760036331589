import { Button, Divider, Icon, Message, Modal } from "semantic-ui-react";
import React, { useState } from "react";

import { Question } from "../../../Swagger/api";
import { localize } from "../../../Localization/localize";
import { getApis } from "../../../Services/webservice";

export interface IQuestionDelete {
  question: Question;
  onDone: () => void;
  onCancel: () => void;
}

export const QuestionDelete = (props: IQuestionDelete) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { questionClient } = { ...getApis() };

  const { question, onDone, onCancel } = { ...props };

  const deleteQuestion = async () => {
    setLoading(true);

    try {
      await questionClient.deleteQuestion(question.id);

      setLoading(false);
      onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>{localize.deleteQuestion}</Modal.Header>
      <Modal.Content>
        {localize.formatString(
          localize.deleteQuestionMessage,
          question.content || ""
        )}
        <Divider horizontal hidden />
        {hasError ? (
          <Message
            error
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} floated="left">
          {localize.cancelLowerCase}
        </Button>
        <Button
          primary
          icon
          labelPosition="right"
          onClick={deleteQuestion}
          loading={loading}
        >
          <Icon name="trash alternate outline" />
          {localize.deleteLowerCase}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
