import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Message } from 'semantic-ui-react';
import { LoginContext } from '../../../Contexts/loginContext';
import { localize } from '../../../Localization/localize';
import { getApis } from '../../../Services/webservice';
import { AnswerState, ProductAccess, Question, StarCompanyAnswer } from '../../../Swagger/api';
import { history, paths } from '../../layout/layout';
import { Loading } from '../../layout/loading';
import { scrollUp } from '../starAdmin/starAdmin';
import { QuestionContent } from './questionContent';
import { QuestionNavigator } from './questionNavigator';

interface IStarRun {
  match: {
    params: {
      starId: number;
    };
  };
}

export const StarRun = (props: IStarRun) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<StarCompanyAnswer[]>([]);
  const [previousAnswers, setPreviousAnswers] = useState<StarCompanyAnswer[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [showNavigator, setShowNavigator] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [answerContent, setAnswerContent] = useState('');
  const [answerState, setAnswerState] = useState<AnswerState>(0);
  const [reportText, setReportText] = useState('');
  const [readOnlyMode, setReadOnlyMode] = useState(false);

  const loginContext = useContext(LoginContext);

  const { starClient } = { ...getApis() };

  useEffect(() => {
    (async () => {
      const [previousAnswersData, isStarAuditAvailabe, questionsData] = await Promise.all([
        starClient.getStarCompanyPreviousAnswers(props.match.params.starId),
        starClient.isStarAuditAvailabe(props.match.params.starId),
        starClient.getQuestions(props.match.params.starId),
        loadAnswers(),
      ]);

      if (loginContext.login?.productAccess !== ProductAccess.CompanyReportOnly) {
        setReadOnlyMode(isStarAuditAvailabe);
      }

      setPreviousAnswers(previousAnswersData);

      let currentQuestions: Question[] = questionsData;
      currentQuestions = currentQuestions.sort((a, b) => (a.sort > b.sort ? 1 : -1));
      setQuestions(currentQuestions);

      if (currentQuestions.length) {
        setCurrentQuestion(currentQuestions[0]);
      }

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const currentAnswer = answers.find((x) => x.questionId === currentQuestion?.id);
    if (currentAnswer && !readOnlyMode) {
      starClient.setStarCompanyAnswerState({
        answerId: currentAnswer?.id,
        answerState,
      });
      loadAnswers();
    }
  }, [answerState]);

  const loadAnswers = async () => {
    const data = await starClient.getStarCompanyAnswers(props.match.params.starId);
    setAnswers(data);
    return data;
  };

  const goBack = async () => {
    await saveAnswer();
    scrollUp();

    if (!currentQuestion) {
      setCurrentQuestion(questions[0]);
      return;
    }

    const currentPosition = currentQuestion.sort!;

    if (currentPosition === getSmallestSort()) {
      return;
    } else {
      const backQuestion = questions.filter((x) => x.sort! < currentPosition);
      if (backQuestion && backQuestion.length) {
        setCurrentQuestion(backQuestion[backQuestion.length - 1]);
      }
    }
  };

  const goNext = async () => {
    await saveAnswer();
    scrollUp();

    if (!currentQuestion) {
      setCurrentQuestion(questions[0]);
      return;
    }

    const currentPosition = currentQuestion.sort!;

    if (currentPosition === getBiggestSort()) {
      return;
    } else {
      const nextQuestion = questions.filter((x) => x.sort! > currentPosition);
      if (nextQuestion && nextQuestion.length) {
        setCurrentQuestion(nextQuestion[0]);
      }
    }
  };

  const onClose = async () => {
    await saveAnswer();
    history.push(paths.company.overview);
  };

  const navigate = async (position: number) => {
    setShowNavigator(false);
    await saveAnswer();
    const question = questions.find((x) => x.sort === position);

    if (question) {
      setCurrentQuestion(question);
    }
  };

  useEffect(() => selectAnswer(), [currentQuestion]);
  const selectAnswer = () => {
    if (currentQuestion) {
      const currentAnswer = answers.find((x) => x.questionId === currentQuestion.id);

      if (currentAnswer) {
        setAnswerContent(currentAnswer.content || '');
        setReportText(currentAnswer.reportText || '');
        setAnswerState(currentAnswer.answerState || 0);
      } else {
        setAnswerContent('');
        setReportText('');
        setAnswerState(0);
      }
    }
  };

  const saveAnswer = async () => {
    if (readOnlyMode) {
      await saveJustCompanyReportText();
      return;
    }

    setHasError(false);
    setSaving(true);
    const answer: StarCompanyAnswer = {
      content: answerContent,
      reportText: reportText,
      questionId: currentQuestion!.id,
      starId: props.match.params.starId,
      answerState,
      id: 0,
    };
    try {
      if (loginContext.login?.productAccess === ProductAccess.CompanyReportOnly) {
        await starClient.setStarCompanyAnswerForCompanyReport(answer);
      } else {
        await starClient.setStarCompanyAnswer(answer);
      }

      await loadAnswers();
    } catch (ex) {
      console.error(ex);
      setHasError(true);
    }
    setSaving(false);
  };

  const saveJustCompanyReportText = async () => {
    setHasError(false);
    setSaving(true);
    const answer: StarCompanyAnswer = {
      reportText: reportText,
      questionId: currentQuestion!.id,
      starId: props.match.params.starId,
      answerState,
      id: 0, // not used
    };
    try {
      await starClient.setStarCompanyAnswerForCompanyReport(answer);

      await loadAnswers();
    } catch {
      setHasError(true);
    }
    setSaving(false);
  };

  const getSmallestSort = () => {
    return Math.min(...questions.map((x) => x.sort!));
  };

  const getBiggestSort = () => {
    return Math.max(...questions.map((x) => x.sort!));
  };

  const getIsFirstQuestion = () => {
    if (currentQuestion && currentQuestion.sort && questions.length && currentQuestion.sort === getSmallestSort()) {
      return true;
    }

    return false;
  };

  const getIsLastQuestion = () => {
    if (currentQuestion && currentQuestion.sort && questions.length && currentQuestion.sort === getBiggestSort()) {
      return true;
    }

    return false;
  };

  const setAnswerContentInternal = (value: string) => {
    setAnswerContent(value);
    if (value && answerState === 0) {
      setAnswerState(1);
    }

    if (!value && answerState !== 0) {
      setAnswerState(0);
    }
  };

  const setReportTextInternal = (value: string) => {
    setReportText(value);

    if (loginContext.login?.productAccess !== ProductAccess.CompanyReportOnly) {
      return;
    }

    if (value && answerState === 0) {
      setAnswerState(1);
    }

    if (!value && answerState !== 0) {
      setAnswerState(0);
    }
  };

  const previousAnswer = useMemo(() => {
    return previousAnswers.find((x) => x.questionId === currentQuestion?.id);
  }, [previousAnswers, currentQuestion]);

  const currentAnswer = useMemo(() => {
    return answers.find((x) => x.questionId === currentQuestion?.id);
  }, [answers, currentQuestion]);

  if (currentQuestion) {
    return (
      <React.Fragment>
        <QuestionContent
          question={currentQuestion}
          allAnswers={answers}
          onBack={goBack}
          onClose={onClose}
          onNext={goNext}
          onSearch={() => setShowNavigator(true)}
          content={answerContent}
          reportText={reportText}
          onContentChange={(value) => setAnswerContentInternal(value)}
          onReportTextChange={(value) => setReportTextInternal(value)}
          readOnlyMode={readOnlyMode}
          firstQuestion={getIsFirstQuestion()}
          lastQuestion={getIsLastQuestion()}
          answerState={answerState}
          onStateAnswered={() => !readOnlyMode && setAnswerState(2)}
          onStateInProgress={() => !readOnlyMode && setAnswerState(1)}
          onStateNotAnswered={() => !readOnlyMode && setAnswerState(0)}
          starId={props.match.params.starId}
          saving={saving}
          previousAnswer={previousAnswer}
          relevantQuestionIds={questions.map((x) => x.id)}
          important={currentAnswer?.starAuditorAnswer?.important || false}
        />
        {showNavigator && (
          <QuestionNavigator
            questions={questions}
            onClose={() => setShowNavigator(false)}
            onNavigate={navigate}
            allAnswers={answers}
            allAnswersForAuditor={answers}
            justCompanyReport={loginContext.login?.productAccess === ProductAccess.CompanyReportOnly}
          />
        )}
        <Message error hidden={!hasError} content={localize.generalErrorMessage} header={localize.generalErrorHeader} />
      </React.Fragment>
    );
  } else if (questions.length === 0 && !loading) {
    return <Message info header={localize.noQuestionsAvailableHeader} content={localize.noQuestionsAvailableContent} />;
  } else if (loading) {
    return <Loading />;
  }

  return <div />;
};
