import React, { useEffect, useState } from 'react';
import { getApis } from '../../../Services/webservice';
import { Question, StarOverviewItem } from '../../../Swagger/api';
import { Loading } from '../../layout/loading';
import { QuestionNavigator } from '../starRun/questionNavigator';
import { QuestionAdminContent } from './questionAdminContent';

interface IStarRun {
  location: {
    state: {
      starId: number;
    };
  };
}

export function scrollUp() {
  window.scrollTo({ behavior: 'smooth', top: 0 });
}

export const StarAdmin = (props: IStarRun) => {
  const [item, setItem] = useState<StarOverviewItem>();
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [showNavigator, setShowNavigator] = useState(false);

  const { starClient } = { ...getApis() };

  useEffect(() => {
    (async () => {
      const data = await starClient.getStarAdminOverviewItem(props.location.state.starId);
      setItem(data);

      const questions = getQuestions(data);

      if (questions.length) {
        setCurrentQuestion(questions[0]);
      }
    })();
  }, []);

  const getQuestions = (data: StarOverviewItem) => {
    const notSorted = data.starCompanyAnswers?.filter((x) => x.question).map((x) => x.question!) || [];
    const sorted = notSorted.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    return sorted;
  };

  const questions = item ? getQuestions(item) : [];

  const goBack = async () => {
    scrollUp();
    if (!currentQuestion) {
      setCurrentQuestion(questions[0]);
      return;
    }

    const currentPosition = currentQuestion.sort!;

    if (currentPosition === getSmallestSort()) {
      return;
    } else {
      const backQuestion = questions.filter((x) => x.sort! < currentPosition);
      if (backQuestion && backQuestion.length) {
        setCurrentQuestion(backQuestion[backQuestion.length - 1]);
      }
    }
  };

  const goNext = async () => {
    scrollUp();
    if (!currentQuestion) {
      setCurrentQuestion(questions[0]);
      return;
    }

    const currentPosition = currentQuestion.sort!;

    if (currentPosition === getBiggestSort()) {
      return;
    } else {
      const nextQuestion = questions.filter((x) => x.sort! > currentPosition);
      if (nextQuestion && nextQuestion.length) {
        setCurrentQuestion(nextQuestion[0]);
      }
    }
  };

  const navigate = async (position: number) => {
    const question = questions.find((x) => x.sort === position);

    if (question) {
      setCurrentQuestion(question);
    }

    setShowNavigator(false);
  };

  const getSmallestSort = () => {
    return Math.min(...questions.map((x) => x.sort!));
  };

  const getBiggestSort = () => {
    return Math.max(...questions.map((x) => x.sort!));
  };

  const getIsFirstQuestion = () => {
    if (currentQuestion && currentQuestion.sort && questions.length && currentQuestion.sort === getSmallestSort()) {
      return true;
    }

    return false;
  };

  const getIsLastQuestion = () => {
    if (currentQuestion && currentQuestion.sort && questions.length && currentQuestion.sort === getBiggestSort()) {
      return true;
    }

    return false;
  };

  if (!item || !currentQuestion) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <QuestionAdminContent
        question={currentQuestion}
        answer={item!.starCompanyAnswers!.find((x) => x.questionId == currentQuestion.id)}
        onBack={goBack}
        onNext={goNext}
        onSearch={() => setShowNavigator(true)}
        firstQuestion={getIsFirstQuestion()}
        lastQuestion={getIsLastQuestion()}
        starId={props.location.state.starId}
        justCompanyReport={item.star?.justCompanyReport || false}
      />
      {showNavigator && (
        <QuestionNavigator
          questions={questions}
          onClose={() => setShowNavigator(false)}
          onNavigate={navigate}
          justCompanyReport={item.star?.justCompanyReport || false}
          allAnswersForAuditor={item.starCompanyAnswers}
        />
      )}
    </React.Fragment>
  );
};
