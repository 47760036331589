import React, { useState, useRef, useEffect } from "react";
import {
  Header,
  Form,
  Button,
  Divider,
  Grid,
  Message,
  Icon,
  Progress,
} from "semantic-ui-react";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";
import queryString from "query-string";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKey } from "../../constants";
import { history, paths } from "../layout/layout";

export const PasswordSet = () => {
  const [original, setOriginal] = useState("");
  const [copy, setCopy] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [lettersOk, setLettersOk] = useState(false);
  const [simbolsOk, setSimbolsOk] = useState(false);
  const [lengthOk, setLengthOk] = useState(false);
  const [numbersOk, setNumbersOk] = useState(false);
  const [weak, setWeak] = useState(false);
  const [notRobot, setNotRobot] = useState<boolean | undefined>(undefined);
  const [forwarding, setForwarding] = useState(false);
  const [timeUntilForwarad, setTimeUntilForward] = useState(0);

  const { signinClient } = { ...getApis() };

  let recaptchaRef = useRef<ReCAPTCHA>();

  const onButtonClick = async () => {
    setIsDone(false);
    setHasError(false);
    setWeak(false);

    if (!notRobot || !recaptchaRef.current) {
      setNotRobot(false);
      return;
    }

    if (copy && copy !== original) {
      setNoMatch(true);
      recaptchaRef.current.reset();

      return;
    } else {
      setNoMatch(false);
    }

    if (+lettersOk + +simbolsOk + +lengthOk + +numbersOk < 3) {
      setWeak(true);
      recaptchaRef.current.reset();

      return;
    }

    const parsedUrl = queryString.parse(window.location.search);
    const token = parsedUrl.token as string;

    if (!token) {
      setHasError(true);
      recaptchaRef.current.reset();

      return;
    }

    setLoading(true);

    try {
      await signinClient.setUserLoginCredentials({
        newPassword: original,
        token,
        recaptchaToken: recaptchaRef.current.getValue() || "",
      });
      setIsDone(true);
      setHasError(false);
      setForwarding(true);
    } catch {
      setIsDone(false);
      setHasError(true);
      recaptchaRef.current.reset();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (forwarding) {
      setTimeout(() => {
        if (timeUntilForwarad < 100) {
          setTimeUntilForward((timeUntilForwarad) => timeUntilForwarad + 1);
        } else {
          history.push(paths.signin.root);
        }
      }, 100);
    }
  }, [forwarding, timeUntilForwarad]);

  const checkPassword = (original: string) => {
    setOriginal(original);

    const lettersRegex = new RegExp("(?=.*[a-zA-Z])");
    const numberRegEx = new RegExp("(?=.*[0-9])");
    const simbolsRegEx = new RegExp("(?=.[!@#$%^&])");
    const lengthRegEx = new RegExp("(?=.{6,})");

    setLettersOk(lettersRegex.test(original));
    setNumbersOk(numberRegEx.test(original));
    setSimbolsOk(simbolsRegEx.test(original));
    setLengthOk(lengthRegEx.test(original));
  };

  return (
    <div>
      <Header size="huge">{localize.passwordSetHeader}</Header>
      <Form className="dd-signin-container">
        <Form.Input
          type="password"
          label={localize.password}
          value={original}
          onChange={(_event, { value }) => checkPassword(value)}
        />
        <Form.Input
          type="password"
          label={localize.passwordAgain}
          value={copy}
          onChange={(_event, { value }) => setCopy(value)}
        />
        <Form.Field>
          <label>{localize.passwordStrength}</label>
          <div>
            <Icon
              disabled={!lengthOk}
              name={lengthOk ? "check circle outline" : "circle outline"}
            />
            {localize.passwordLength}
          </div>
          <div>
            <Icon
              disabled={!lettersOk}
              name={lettersOk ? "check circle outline" : "circle outline"}
            />
            {localize.passwordLetters}
          </div>
          <div>
            <Icon
              disabled={!numbersOk}
              name={numbersOk ? "check circle outline" : "circle outline"}
            />
            {localize.passwordNumbers}
          </div>
          <div>
            <Icon
              disabled={!simbolsOk}
              name={simbolsOk ? "check circle outline" : "circle outline"}
            />
            {localize.passwordSimbols}
          </div>
        </Form.Field>
        <Divider horizontal hidden />
        <ReCAPTCHA
          sitekey={captchaSiteKey}
          ref={(x: any) => (recaptchaRef.current = x)}
          onChange={() => setNotRobot(true)}
        />
        <Divider horizontal hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={9} verticalAlign="middle" />
            <Grid.Column width={7}>
              <Button primary fluid disabled={loading} onClick={onButtonClick}>
                {localize.saveUpperCase}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Message
          success
          header={localize.passwordSetSuccessHeader}
          content={localize.passwordSetSuccessMessage}
          visible={isDone}
        />
        <Message
          error
          header={localize.generalErrorHeader}
          content={localize.generalErrorMessage}
          visible={hasError}
        />
        <Message
          warning
          header={localize.passwordNoMatchHeader}
          content={localize.passwordNoMatchMessage}
          visible={noMatch}
        />
        <Message
          warning
          header={localize.passwordSetWeakHeader}
          content={localize.passwordSetWeakMessage}
          visible={weak}
        />
        <Message
          warning
          header={localize.passwordRobotHeader}
          content={localize.passwordRobotMessage}
          visible={notRobot === false}
        />
        {forwarding ? (
          <Progress success size="tiny" percent={timeUntilForwarad} />
        ) : null}
      </Form>
    </div>
  );
};
