import { Button, Divider, Form, Icon, Message, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { StarVersion } from "../../../../Swagger/api";
import { localize } from "../../../../Localization/localize";
import { getApis } from "../../../../Services/webservice";

export interface IAssignStarVersion {
  onCancel: () => void;
  onDone: () => void;
}

export const AssignStarVersion = (props: IAssignStarVersion) => {
  const [items, setItems] = useState<StarVersion[]>([]);
  const [hasError, setHasError] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>();

  const { starVersionClient } = { ...getApis() };

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const data = await starVersionClient.getAllStarVersions();
      setItems(data);
      setLoading(false);
    } catch (ex) {
      setHasError(true);
      setLoading(false);
    }
  };

  const save = async () => {
    if (!selectedItemId) {
      setHasWarning(true);
      return;
    } else {
      setHasWarning(false);
    }

    setLoading(true);
    setHasError(false);

    try {
      await starVersionClient.assignStarVersion(selectedItemId);
      setLoading(false);
      props.onDone();
    } catch (ex) {
      setLoading(false);
      setHasError(true);
    }
  };

  return (
    <Modal dimmer="blurring" open={true} closeOnDimmerClick>
      <Modal.Header>{localize.assignStarVersionHeader}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Select
            label={localize.starContentVersionName}
            value={selectedItemId}
            required
            onChange={(_event, { value }) => setSelectedItemId(value as number)}
            options={items.map((x) => ({
              value: x.id,
              text: x.title,
              title: x.title,
            }))}
          />
          {localize.assignStarVersionContent}
          <Divider horizontal hidden></Divider>
          <Message
            visible={hasError}
            error
            header={localize.generalErrorHeader}
            content={localize.signinErrorMessage}
          />
          <Message warning visible={hasWarning}>
            {localize.publishWarning}
          </Message>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={localize.cancelLowerCase}
          floated="left"
          onClick={props.onCancel}
        />
        <Button
          primary
          icon
          labelPosition="right"
          onClick={save}
          loading={loading}
        >
          <Icon name="save" />
          {localize.saveLowerCase}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
