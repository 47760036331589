import { Header, Icon, Modal } from 'semantic-ui-react';

import React from 'react';
import { localize } from '../../Localization/localize';

export const PleaseWaitReport = () => (
  <Modal open size="tiny" dimmer="blurring">
    <Modal.Content>
      <Header icon textAlign="center">
        <Icon name="clock outline" />
        <Header.Content>{localize.loadingReport}</Header.Content>
      </Header>
    </Modal.Content>
  </Modal>
);
