import { Divider, Grid, Menu, Placeholder, Segment } from 'semantic-ui-react';

import React from 'react';

export const OverviewItemPlaceholder = () => {
  return (
    <div className="dd-placeholder-segment-container">
      {new Array(10).fill(0).map((_, i) => (
        <Segment key={i} className="dd-placeholder-segment">
          <Grid divided>
            <Grid.Row>
              <Grid.Column width={12}>
                <div>
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line></Placeholder.Line>
                    </Placeholder.Header>
                  </Placeholder>
                </div>
                <Placeholder>
                  <Placeholder.Line length="short"></Placeholder.Line>
                </Placeholder>
                <Divider horizontal hidden />
                <div className="dd-compact-grid">
                  <div className="dd-first-column">
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </div>
                  <div className="dd-arrow-column"></div>
                  <div className="dd-second-column">
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </div>
                </div>
                <div className="dd-compact-grid">
                  <div className="dd-first-column">
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </div>
                  <div className="dd-arrow-column"></div>
                  <div className="dd-second-column">
                    <Placeholder>
                      <Placeholder.Line />
                    </Placeholder>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <Menu vertical secondary fluid>
                  <Placeholder>
                    <Menu.Item></Menu.Item>
                    <Placeholder.Line></Placeholder.Line>
                    <Placeholder.Line></Placeholder.Line>
                    <Placeholder.Line></Placeholder.Line>
                  </Placeholder>
                </Menu>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ))}
    </div>
  );
};
