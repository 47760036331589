import { SigninClient, UserLoginViewModel } from "../Swagger/api";

import { LoginContext } from "./loginContext";
import React from "react";
import { basePathWebService } from "../constants";
import { history } from "../Components/layout/layout";
import localForage from "localforage";

interface ILoginWrapper {
  // eslint-disable-next-line no-unused-vars
  setLogin: (login: UserLoginViewModel | null) => Promise<void>;
  login: UserLoginViewModel | null;
  loading?: boolean;
  connectedDate: Date;
}

export const cacheKey = "LoginCredentials";

class LoginWrapper extends React.Component<{}, ILoginWrapper> {
  constructor(props: any) {
    super(props);

    this.state = {
      setLogin: async (login) => {
        this.setState(
          {
            login,
          },
          this.setLoginInDb
        );
      },
      login: null,
      loading: true,
      connectedDate: new Date(),
    };
  }

  async componentDidMount() {
    const cachedLogin = await localForage.getItem<UserLoginViewModel>(cacheKey);

    if (cachedLogin) {
      this.setState(
        {
          login: cachedLogin,
        },
        this.refreshTokens
      );
    } else {
      this.setState({ loading: false });
    }

    this.queueTokensRefreshCycle();

    window.addEventListener("online", async () => {
      await this.refreshTokens();
      this.setState({ connectedDate: new Date() });
    });
  }

  async refreshTokens() {
    if (this.state.login && navigator.onLine) {
      const signinClient = new SigninClient(basePathWebService);
      try {
        const refreshedLogin = await signinClient.refreshUserLogin(
          this.state.login.jwtToken
        );
        this.setState({ login: refreshedLogin }, this.setLoginInDb);
      } catch (ex: any) {
        if (ex.status === 401) {
          this.setState({ login: null }, () => {
            history.push("/");
          });
        }
      }
    }

    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }

  async setLoginInDb() {
    if (this.state.login) {
      await localForage.setItem<UserLoginViewModel>(cacheKey, this.state.login);
    } else {
      // delete local data
      localForage.clear();
    }
  }

  queueTokensRefreshCycle() {
    setInterval(() => {
      this.refreshTokens();
    }, 1000 * 60 * 10);
  }

  render() {
    return (
      <LoginContext.Provider value={this.state}>
        {this.state.loading ? null : this.props.children}
      </LoginContext.Provider>
    );
  }
}

export const withLogin = (children: any) => {
  return <LoginWrapper>{children}</LoginWrapper>;
};
