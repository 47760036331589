import React from "react";
import { Placeholder, Divider, Container } from "semantic-ui-react";

export const Loading = () => (
  <Container textAlign="center">
    <Placeholder>
      <Divider horizontal hidden></Divider>
    </Placeholder>
    <Divider horizontal hidden></Divider>
    <Divider horizontal hidden></Divider>
    <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line length="very long"></Placeholder.Line>
        <Placeholder.Line length="full"></Placeholder.Line>
        <Placeholder.Line length="medium"></Placeholder.Line>
      </Placeholder.Paragraph>
    </Placeholder>
    <Divider horizontal hidden></Divider>
    <Divider horizontal hidden></Divider>
    <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line length="very long"></Placeholder.Line>
        <Placeholder.Line length="full"></Placeholder.Line>
        <Placeholder.Line length="medium"></Placeholder.Line>
      </Placeholder.Paragraph>
    </Placeholder>
    <Divider horizontal hidden></Divider>
    <Divider horizontal hidden></Divider>
    <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line length="very long"></Placeholder.Line>
        <Placeholder.Line length="full"></Placeholder.Line>
        <Placeholder.Line length="medium"></Placeholder.Line>
      </Placeholder.Paragraph>
    </Placeholder>
  </Container>
);
