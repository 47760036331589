import React from 'react';
import ReactDOM from 'react-dom';
import './styles.sass';
import { App } from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { withLogin } from './Contexts/loginWrapper';
import { NewVersionNotice } from './Components/common/newVersionNotice';
import 'semantic-ui-less/semantic.less';

ReactDOM.render(<React.Fragment>{withLogin(<App />)}</React.Fragment>, document.getElementById('root'));

const showUpdateNotification = (registration: ServiceWorkerRegistration) => {
  ReactDOM.render(withLogin(<NewVersionNotice registration={registration} />), document.getElementById('newVersionRoot'));
};

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ onUpdate: (registration) => showUpdateNotification(registration) });

// Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
