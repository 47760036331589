import {
  AuditNotesClient,
  AuditorProfileClient,
  CompanyProfileClient,
  GraphicClient,
  QuestionClient,
  ReportClient,
  SigninClient,
  StarClient,
  StarControllDataClient,
  StarVersionClient,
  SubCompaniesClient,
  UserAccountsClient,
} from '../Swagger/api';

import { basePathWebService } from '../constants';

export const getApis = () => {
  const clients = {
    signinClient: new SigninClient(basePathWebService),
    userAccountsClient: new UserAccountsClient(basePathWebService),
    auditorProfileClient: new AuditorProfileClient(basePathWebService),
    companyProfileClient: new CompanyProfileClient(basePathWebService),
    subCompaniesClient: new SubCompaniesClient(basePathWebService),
    questionClient: new QuestionClient(basePathWebService),
    starClient: new StarClient(basePathWebService),
    graphicClient: new GraphicClient(basePathWebService),
    reportClient: new ReportClient(basePathWebService),
    starVersionClient: new StarVersionClient(basePathWebService),
    starControlDataClient: new StarControllDataClient(basePathWebService),
    auditNotesClient: new AuditNotesClient(basePathWebService),
  };

  return clients;
};
