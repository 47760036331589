import localForage from 'localforage';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Header, Icon, Label, Menu, Message, Popup, Search, Segment } from 'semantic-ui-react';
import { LoginContext } from '../../Contexts/loginContext';
import { localize } from '../../Localization/localize';
import { getApis } from '../../Services/webservice';
import {
  AuditNotes,
  CompaniesStarAuditorOverview,
  Star,
  StarAuditorAnswer,
  StarAuditorOverviewEdited,
  StarAuditorOverviewItem,
} from '../../Swagger/api';
import { generateAuditReport } from '../../words/auditReport';
import { generateCorrectionsReport } from '../../words/correctionsReport';
import { PleaseWaitReport } from '../common/pleaseWaitReport';
import { formatDate, getAuditStatusColor, getAuditStatusText } from '../company/overview/companyOverview';
import { history, paths } from '../layout/layout';
import { getLocalKey, updatesKey } from '../star/starAudit/auditNotes';
import { FinishAuditPrompt } from '../star/starAudit/finishAuditPrompt';
import { ArchiveConfirm } from './archiveConfirm';

export interface StarsDb {
  stars: StarAuditorOverviewItem[];
  date: Date;
  updatedStarIds: number[];
}

export const starDbKey = 'Stars';
export const companiesDbKey = 'Companies';

export const getCurrentStarFromDb = async (starId: number) => {
  const starsInDb = await localForage.getItem<StarsDb>(starDbKey);
  if (!starsInDb) {
    return undefined;
  }
  const currentStar = starsInDb.stars.find((x) => x.id === Number(starId)) as StarAuditorOverviewItem; // convert to number
  return currentStar;
};

export const AuditorOverview = () => {
  const [starsDb, setStarsDb] = useState<StarsDb>();
  const [companiesDb, setCompaniesDb] = useState<CompaniesStarAuditorOverview>();
  const [hasError, setHasError] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [online, setOnline] = useState(navigator.onLine);
  const [selectedForDone, setSelectedForDone] = useState<number>();
  const [loadingFinishAudit, setLoadingFinishAudit] = useState(false);
  const [loadingAuditReport, setLoadingAuditReport] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedForArchive, setSelectedForArchive] = useState<number>();

  const loginContext = useContext(LoginContext);

  const { starClient, subCompaniesClient, starControlDataClient, auditNotesClient } = {
    ...getApis(),
  };

  useEffect(() => {
    runLoaders();
  }, []);

  useEffect(() => {
    if (navigator.onLine !== online) {
      setOnline(navigator.onLine);

      if (navigator.onLine) {
        runLoaders();
      } else {
        setIsSyncing(false);
      }
    }
  });

  const runLoaders = async () => {
    await loadCompanies();
    const starsFromDb = await loadStarsDb();

    if (navigator.onLine && loginContext.login) {
      setIsSyncing(true);
      await refreshCompanies(); // can run async in parallel

      // upload changes
      try {
        if (starsFromDb) {
          await uploadAnswers(starsFromDb);
          await uploadControlData(starsFromDb);
          await uploadFinishForStar(starsFromDb);
          starsFromDb.updatedStarIds = [];
          await localForage.setItem(starDbKey, starsFromDb);
        }

        await uploadAuditNotes();

        // download processed data
        await refreshStarDb(starsFromDb);
        await downloadAuditNotes();
      } catch (ex) {
        setHasError(true);
      }
    }

    setIsSyncing(false);
  };

  const loadStarsDb = async () => {
    const starsFromDb = await localForage.getItem<StarsDb>(starDbKey);
    if (!starsFromDb) {
      return;
    }
    setStarsDb(starsFromDb);
    return starsFromDb;
  };

  const saveToStarsDb = async (db: StarsDb) => {
    await localForage.setItem(starDbKey, db);
  };

  const refreshStarDb = async (currentDb?: StarsDb) => {
    try {
      const currentStars = currentDb?.stars || [];
      const edits: StarAuditorOverviewEdited[] = currentStars.map((x) => ({
        starId: x.id,
        edited: x.edited,
      }));
      const [updatedStarItems, archivedStarIds] = await Promise.all([
        starClient.getAuditorOverview(edits),
        starClient.getArchivedStarIds(),
      ]);

      // merge stars in db with updated stars from web service
      const existingIds: number[] = [];
      let starsForDb: StarAuditorOverviewItem[] = [];
      for (const item of currentStars) {
        const updated = updatedStarItems.find((x) => x.id === item.id);
        if (updated) {
          starsForDb.push(updated);
          existingIds.push(updated.id);
        } else if (archivedStarIds.indexOf(item.id) === -1) {
          starsForDb.push(item);
        }
      }

      const newItems = updatedStarItems.filter((x) => existingIds.indexOf(x.id) === -1);
      for (const item of newItems) {
        starsForDb.push(item);
      }

      starsForDb = starsForDb.sort((a, b) => (a.id < b.id ? 1 : -1));

      const newDb: StarsDb = {
        date: new Date(),
        stars: starsForDb,
        updatedStarIds: currentDb?.updatedStarIds || [],
      };

      setStarsDb(newDb);
      await localForage.setItem(starDbKey, newDb);
    } catch (ex) {
      setHasError(true);
    }
  };

  const loadCompanies = async () => {
    const data = await localForage.getItem<CompaniesStarAuditorOverview>(companiesDbKey);
    if (data) {
      setCompaniesDb(data);
    }
  };

  const refreshCompanies = async () => {
    const data = await subCompaniesClient.getCompaniesAuditorOverview();
    setCompaniesDb(data);
    await localForage.setItem(companiesDbKey, data);
  };

  const finishAudit = async () => {
    setLoadingFinishAudit(true);
    const copyUpdated = [...starsDb!.updatedStarIds];

    if (selectedForDone && starsDb?.updatedStarIds.indexOf(selectedForDone) === -1) {
      copyUpdated.push(selectedForDone);
    }

    const newDb = { ...starsDb, updatedStarIds: copyUpdated } as StarsDb;

    const currentStar = newDb.stars.find((x) => x.id === selectedForDone) as StarAuditorOverviewItem;
    currentStar.finished = true;
    currentStar.auditFinished = new Date();

    const answersWithProblems = currentStar.starCompanyAnswers
      ?.map((x) => x.starAuditorAnswer)
      .some((x) => x?.auditResult === 4);
    if (answersWithProblems && currentStar.starControlData) {
      currentStar.starControlData.immediateProcessing = true;
    }

    setStarsDb(newDb);
    await saveToStarsDb(newDb);
    setLoadingFinishAudit(false);
    setSelectedForDone(undefined);
    history.push(`/star/audit/control/${selectedForDone}`, {
      requireFinishDate: true,
    });
  };

  const uploadAnswers = async (starsFromDb: StarsDb) => {
    const promises: Promise<number[]>[] = [];

    starsFromDb.updatedStarIds.map((updatedStarId) => {
      const currentStar = starsFromDb.stars.find((x) => x.id === updatedStarId);
      const answers = (currentStar?.starCompanyAnswers?.filter((x) => x.starAuditorAnswer).map((x) => x.starAuditorAnswer) ||
        []) as StarAuditorAnswer[];
      if (answers.length && !currentStar?.finished) {
        promises.push(starClient.setStarAuditorAnswers(answers));
      }
    });

    const results = await Promise.all(promises);
    starsFromDb.updatedStarIds.map((updatedStarId, index) => {
      const currentStar = starsFromDb.stars.find((x) => x.id === updatedStarId);
      const answers = (currentStar?.starCompanyAnswers?.filter((x) => x.starAuditorAnswer).map((x) => x.starAuditorAnswer) ||
        []) as StarAuditorAnswer[];
      const currentResults = results[index];
      if (answers.length && !currentStar?.finished && currentResults.length != answers.length) {
        throw new Error('Not all answers were synced!');
      }
    });
  };

  const uploadControlData = async (starsFromDb: StarsDb) => {
    const promises: Promise<void>[] = [];

    starsFromDb.updatedStarIds.map((updatedStarId) => {
      const currentStar = starsFromDb.stars.find((x) => x.id === updatedStarId);

      if (currentStar) {
        const slim: Star = {
          id: currentStar.id,
          auditorId: loginContext.login?.id,
          auditStarted: currentStar.auditStarted,
          auditFinished: currentStar.auditFinished,
          starControlData: currentStar.starControlData,
          finished: currentStar.finished,
          edited: currentStar.edited, // not used
          justForIso: false, // not used
          started: currentStar.started, // not used
          justCompanyReport: false, // not used
        };
        promises.push(starControlDataClient.saveControlData(slim));
      }
    });

    await Promise.all(promises);
  };

  const uploadFinishForStar = async (starsFromDb: StarsDb) => {
    const promises: Promise<void>[] = [];

    starsFromDb.updatedStarIds.map((updatedStarId) => {
      const currentStar = starsFromDb.stars.find((x) => x.id === updatedStarId);

      if (currentStar?.auditFinished && currentStar?.finished) {
        promises.push(starClient.endStar(currentStar.id));
      }
    });

    await Promise.all(promises);
  };

  const uploadAuditNotes = async () => {
    const updatedAuditNotesIds = await localForage.getItem<number[]>(updatesKey);
    if (!updatedAuditNotesIds?.length) {
      return;
    }
    const uploaded: number[] = [];
    for (const id of updatedAuditNotesIds) {
      const data = await localForage.getItem<AuditNotes>(getLocalKey(id));
      try {
        if (data) {
          await auditNotesClient.setAuditNotes(data);
          uploaded.push(id);
        }
      } catch (ex) {
        console.error(ex);
        setHasError(true);
      }
    }
    await localForage.setItem(
      updatesKey,
      updatedAuditNotesIds.filter((x) => uploaded.indexOf(x) === -1)
    );
  };

  const downloadAuditNotes = async () => {
    const companies = await localForage.getItem<CompaniesStarAuditorOverview>(companiesDbKey);
    if (!companies?.companies?.length) {
      return;
    }
    const promises: Promise<AuditNotes>[] = [];
    for (const company of companies.companies) {
      promises.push(auditNotesClient.getAuditNotes(company.userLoginId));
    }
    try {
      const results = await Promise.all(promises);

      for (const result of results) {
        try {
          await localForage.setItem(getLocalKey(result.companyId), result);
        } catch (ex) {
          console.error(ex);
          setHasError(true);
        }
      }
    } catch (ex) {
      console.error(ex);
      setHasError(true);
    }
  };

  const getCompanyTitle = (companyId: number) => {
    const currentCompany = companiesDb?.companies?.find((x) => x.userLoginId === companyId);
    return currentCompany?.companyTitle || '-';
  };

  return (
    <section className="dd-overflowing-section">
      <Header size="huge">{localize.companies}</Header>

      <Search
        onSearchChange={(_event, { value }) => setSearchQuery(value || '')}
        fluid
        input={{ fluid: true, placeholder: localize.searchStars }}
        open={false}
      />

      <Message error header={localize.generalErrorHeader} content={localize.generalErrorMessage} hidden={!hasError} />
      {!navigator.onLine ? (
        <Message
          warning
          content={localize.formatString(
            localize.cachedData,
            `${starsDb?.date.toLocaleDateString()} ${starsDb?.date.toLocaleTimeString()}`
          )}
        />
      ) : null}

      {isSyncing && (
        <Message positive>
          <Icon name="sync" loading></Icon>
          {localize.syncMessage}
        </Message>
      )}

      {starsDb?.stars
        .filter((item) => getCompanyTitle(item.companyId).toLowerCase().indexOf(searchQuery.trim().toLowerCase()) !== -1)
        .map((item) => (
          <Segment key={item.id} className="dd-star-list-item">
            <Grid divided>
              <Grid.Row>
                <Grid.Column computer={12} tablet={10}>
                  <p title={getCompanyTitle(item.companyId)}>
                    <Icon name="building" />
                    <span className="dd-item-title">{getCompanyTitle(item.companyId)}</span>
                  </p>
                  <p title="Auditor*in">
                    <Icon name="user" />
                    {item.auditorName || '-'}
                  </p>
                  <p title={item.starVersion || '-'}>
                    <Icon name="star" />
                    {item.starVersion || '-'}
                  </p>

                  <div className="dd-compact-grid">
                    <div className="dd-first-column">
                      <strong>{localize.startedAtDate}</strong>
                    </div>
                    <div className="dd-arrow-column"></div>
                    <div className="dd-second-column">
                      <strong>{localize.availableForAuditDate}</strong>
                    </div>
                  </div>
                  <div className="dd-compact-grid">
                    <div className="dd-first-column">{item.started ? formatDate(new Date(item.started)) : '-'}</div>
                    <div className="dd-arrow-column">
                      <Icon name="arrow right" />
                    </div>
                    <div className="dd-second-column">
                      {item.auditAvailable ? formatDate(new Date(item.auditAvailable)) : '-'}
                    </div>
                  </div>
                  <div className="dd-compact-grid">
                    <div className="dd-grid-separator" />
                  </div>
                  <div className="dd-compact-grid">
                    <div className="dd-first-column">
                      <strong>{localize.auditStartedDate}</strong>
                    </div>
                    <div className="dd-arrow-column"></div>
                    <div className="dd-second-column">
                      <strong>{localize.auditDoneDate}</strong>
                    </div>
                  </div>
                  <div className="dd-compact-grid">
                    <div className="dd-first-column">{item.auditStarted ? formatDate(new Date(item.auditStarted)) : '-'}</div>
                    <div className="dd-arrow-column">
                      <Icon name="arrow right" />
                    </div>
                    <div className="dd-second-column">
                      {item.auditFinished ? formatDate(new Date(item.auditFinished)) : '-'}
                    </div>
                  </div>
                  <div className="dd-compact-grid">
                    <div className="dd-grid-separator" />
                  </div>
                  <hr />
                  <Menu secondary stackable>
                    <Menu.Item onClick={() => history.push(`/auditor/companyProfile/${item.companyId}`)}>
                      {localize.companyDetails}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!item.finished}
                      onClick={async () => {
                        setLoadingAuditReport(true);
                        try {
                          await generateAuditReport(item.id);
                        } catch {
                          setHasError(true);
                        }
                        setLoadingAuditReport(false);
                      }}
                    >
                      {localize.auditReport}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!item.finished}
                      onClick={async () => {
                        setLoadingAuditReport(true);
                        try {
                          await generateCorrectionsReport(item.id);
                        } catch {
                          setHasError(true);
                        }
                        setLoadingAuditReport(false);
                      }}
                    >
                      {localize.auditCorrectionsReport}
                    </Menu.Item>
                  </Menu>
                </Grid.Column>
                <Grid.Column tablet={6} computer={4}>
                  <Label className="dd-item-status-label" size="large" color={getAuditStatusColor(item as Star)}>
                    {getAuditStatusText(item as Star)}
                  </Label>
                  <Menu vertical secondary fluid>
                    {item.finished && (
                      <Menu.Item
                        onClick={() =>
                          history.push(paths.star.auditStar, {
                            starId: item.id,
                          })
                        }
                      >
                        {localize.seeAnswers}
                      </Menu.Item>
                    )}
                    {item.auditStarted && !item.finished && item.auditorName && (
                      <Menu.Item
                        onClick={() =>
                          history.push(paths.star.auditStar, {
                            starId: item.id,
                          })
                        }
                      >
                        {localize.continueAudit}
                      </Menu.Item>
                    )}
                    {!item.auditStarted && item.auditAvailable && (
                      <Menu.Item
                        onClick={() =>
                          history.push(paths.star.startAudit, {
                            starId: item.id,
                          })
                        }
                      >
                        {localize.startAudit}
                      </Menu.Item>
                    )}
                    <Menu.Item
                      disabled={!!item.finished || !item.auditAvailable || !item.auditStarted}
                      onClick={() => setSelectedForDone(item.id)}
                    >
                      {item.finished ? localize.approvedLowerCase : localize.approveLowerCase}
                    </Menu.Item>
                    <Menu.Item disabled={!item.auditStarted} onClick={() => history.push(`/star/audit/control/${item.id}`)}>
                      {localize.toControlData}
                    </Menu.Item>

                    <Menu.Item onClick={() => history.push(`/auditor/questionIndex/${item.companyId}/${item.id}`)}>
                      {localize.toQuestionsIndex}
                    </Menu.Item>

                    <Popup
                      disabled={navigator.onLine}
                      content={localize.archiveDisabledHint}
                      trigger={
                        <Menu.Item disabled={!navigator.onLine} onClick={() => setSelectedForArchive(item.id)}>
                          {localize.sendToArchive}
                        </Menu.Item>
                      }
                    />
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}
      {selectedForDone && (
        <FinishAuditPrompt
          onConfirm={finishAudit}
          loading={loadingFinishAudit}
          onCancel={() => setSelectedForDone(undefined)}
        ></FinishAuditPrompt>
      )}
      {selectedForArchive && (
        <ArchiveConfirm
          starId={selectedForArchive}
          archive={true}
          onDone={() => {
            setSelectedForArchive(undefined);
            runLoaders();
          }}
          onCancel={() => setSelectedForArchive(undefined)}
        />
      )}
      {loadingAuditReport && <PleaseWaitReport />}
    </section>
  );
};
