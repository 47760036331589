import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Menu,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";
import { StarVersion, StarVersionQuestion } from "../../../../Swagger/api";
import { history, paths } from "../../../layout/layout";

import { DeleteStarVersion } from "./deleteStarVersion";
import { localize } from "../../../../Localization/localize";
import { getApis } from "../../../../Services/webservice";
import { useParams } from "react-router-dom";

export const EditStarVersion = () => {
  const [questions, setQuestions] = useState<StarVersionQuestion[]>([]);
  const [availableQuestions, setAvailableQuestions] = useState<
    StarVersionQuestion[]
  >([]);
  const [selectedAddQuestion, setSelectedAddQuestion] =
    useState<StarVersionQuestion>();
  const [selectedForDelete, setSelectedForDelete] = useState<number>();
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [versionTitle, setVersionTitle] = useState("");
  const [newVersionTitle, setNewVersionTitle] = useState("");
  const [selectedForDeletion, setSelectedForDeletion] = useState<StarVersion>();

  const { id } = useParams<{ id: string }>();
  const { starVersionClient } = { ...getApis() };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!isRenameOpen) {
      setNewVersionTitle("");
    }
  }, [isRenameOpen]);

  const loadData = async () => {
    setHasError(false);

    try {
      const [data, dataAvailableQuestions, dataTitle] = await Promise.all([
        starVersionClient.getStarVersionQuestions(Number(id)),
        starVersionClient.getAvailableQuestions(),
        starVersionClient.getStarVersionTitle(Number(id)),
      ]);
      setQuestions(data);
      setAvailableQuestions(dataAvailableQuestions);
      setVersionTitle(dataTitle);
      setLoading(false);
    } catch {
      setHasError(true);
    }
  };

  const onSort = (dragResult: DropResult) => {
    const copy = questions.splice(0, questions.length);

    const [moved] = copy.splice(dragResult.source.index, 1);

    if (dragResult.destination) {
      copy.splice(dragResult.destination.index, 0, moved);
    }

    for (let i = 0; i < copy.length; i++) {
      copy[i].sort = i + 1;
    }

    setQuestions(copy);
  };

  const addSelectedQuestion = () => {
    if (!selectedAddQuestion) {
      return;
    }

    const existionQuestions = questions.map((x) => ({
      ...x,
      sort: x.sort + 1,
    }));
    const newQuestions = [
      { ...selectedAddQuestion, sort: 1 },
      ...existionQuestions,
    ];
    setQuestions(newQuestions);
    setSelectedAddQuestion(undefined);
    setIsAddOpen(false);
  };

  const removeSelectedQuestion = () => {
    const data = questions.filter((x) => x.id != selectedForDelete);
    data.forEach((element, i) => {
      element.sort = i + 1;
    });
    setQuestions(data);
    setSelectedForDelete(undefined);
  };

  const saveVersion = async () => {
    setHasError(false);
    setLoading(true);

    try {
      const questionIdsSorted = questions.map((x) => x.id.toString()).join(",");
      await starVersionClient.saveStarVersion({
        id: Number(id),
        questionIdsSorted,
        title: versionTitle,
      });
      history.push(paths.admin.contentVersions);
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  const availableQuestionsOptions = availableQuestions
    .filter((x) => !questions.find((y) => y.id === x.id))
    .map((x) => ({ value: x.id, text: x.content }));

  return (
    <>
      <Header size="huge">{versionTitle}</Header>
      <Menu icon="labeled">
        <Menu.Item onClick={() => history.push(paths.admin.contentVersions)}>
          <Icon name="arrow left" />
          {localize.backLowerCase}
        </Menu.Item>
        <Menu.Item disabled={loading} onClick={() => setIsAddOpen(true)}>
          <Icon name="plus" />
          {localize.addQuestion}
        </Menu.Item>
        <Menu.Item disabled={loading} onClick={() => setIsRenameOpen(true)}>
          <Icon name="pencil" />
          {localize.renameStarVersion}
        </Menu.Item>
        <Menu.Item
          disabled={loading}
          onClick={() =>
            setSelectedForDeletion({
              id: Number(id),
              title: versionTitle,
            } as StarVersion)
          }
        >
          <Icon name="trash alternate" />
          {localize.deleteUpperCase}
        </Menu.Item>
        <Menu.Item disabled={loading} onClick={saveVersion}>
          <Icon name="save" />
          {localize.saveLowerCase}
        </Menu.Item>
      </Menu>
      {hasError && (
        <Message
          error
          header={localize.generalErrorHeader}
          content={localize.generalErrorMessage}
        />
      )}
      <DragDropContext onDragEnd={onSort}>
        <Droppable direction="vertical" droppableId="questions">
          {(droppableProvided: DroppableProvided) => (
            <div ref={droppableProvided.innerRef}>
              {questions.map((x, i) => (
                <Draggable key={x.id} draggableId={x.id!.toString()} index={i}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Segment key={x.id}>
                        <Grid divided>
                          <Grid.Row>
                            <Grid.Column width={12}>
                              <div className="dd-question-sort-title">
                                {x.sort || 0}. {x.content || "-"}
                              </div>
                              <Label>{x.category || ""}</Label>
                              {x.deleted && (
                                <Label color="orange">
                                  {localize.hintQuestionDeleted}
                                </Label>
                              )}
                            </Grid.Column>
                            <Grid.Column width={4} verticalAlign="middle">
                              <Menu vertical secondary fluid>
                                <Menu.Item
                                  onClick={() => setSelectedForDelete(x.id)}
                                >
                                  {localize.deleteUpperCase}
                                </Menu.Item>
                              </Menu>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                      <Divider horizontal hidden />
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isAddOpen && (
        <Modal open dimmer="blurring">
          <Modal.Header>{localize.addQuestion}</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Select
                search
                options={availableQuestionsOptions}
                label={localize.pickQuestion}
                onChange={(_, { value }) =>
                  setSelectedAddQuestion(
                    availableQuestions.find((x) => x.id == value)
                  )
                }
              ></Form.Select>
            </Form>
            <Message info content={localize.hintPickQuestion} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              floated="left"
              content={localize.cancelLowerCase}
              onClick={() => setIsAddOpen(false)}
            />
            <Button
              primary
              content={localize.saveLowerCase}
              onClick={addSelectedQuestion}
            />
          </Modal.Actions>
        </Modal>
      )}
      {selectedForDelete && (
        <Modal open dimmer="blurring">
          <Modal.Header>{localize.deleteUpperCase}</Modal.Header>
          <Modal.Content>
            <p
              dangerouslySetInnerHTML={{
                __html: localize.confirmDeleteQuestionFromVersion,
              }}
            ></p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              floated="left"
              content={localize.cancelLowerCase}
              onClick={() => setSelectedForDelete(undefined)}
            />
            <Button
              primary
              content={localize.deleteLowerCase}
              onClick={removeSelectedQuestion}
            />
          </Modal.Actions>
        </Modal>
      )}
      {isRenameOpen && (
        <Modal open dimmer="blurring">
          <Modal.Header>{localize.renameStarVersion}</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Input
                label={localize.starContentVersionName}
                type="text"
                value={newVersionTitle}
                onChange={(_, { value }) => setNewVersionTitle(value as string)}
              ></Form.Input>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              floated="left"
              content={localize.cancelLowerCase}
              onClick={() => setIsRenameOpen(false)}
            />
            <Button
              primary
              content={localize.saveLowerCase}
              onClick={() => {
                setIsRenameOpen(false);
                setVersionTitle(newVersionTitle);
              }}
            />
          </Modal.Actions>
        </Modal>
      )}

      {selectedForDeletion && (
        <DeleteStarVersion
          version={selectedForDeletion}
          onCancel={() => setSelectedForDeletion(undefined)}
          onDone={() => {
            history.push(paths.admin.contentVersions);
          }}
        />
      )}
    </>
  );
};
