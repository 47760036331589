import { GraphicAssignment, GraphicalResultCategory } from '../../Swagger/api';

import React from 'react';

const specs = {
  tx: 500,
  ty: 0,
  w: 389,
  h: 349,
  r1: 36,
  r2: 108,
  r3: 180,
  r4: 252,
  r5: 324,
};

interface IGraphicalDisplayProps {
  assignments: GraphicAssignment[];
  results: GraphicalResultCategory[];
}

export const GraphicalDisplay = (props: IGraphicalDisplayProps) => {
  const { assignments, results } = { ...props };

  const getHeigth = (partId: number) => {
    const assignment = assignments.find((x) => x.partId == partId);
    if (assignment) {
      const category = results.find((x) => x.categoryId == assignment.categoryId);
      if (category) {
        return specs.h - (specs.h / 100) * (category.procent || 0);
      }
    }
    return specs.h;
  };

  const getScale = (partId: number) => {
    const assignment = assignments.find((x) => x.partId == partId);
    if (assignment) {
      const category = results.find((x) => x.categoryId == assignment.categoryId);

      if (category && category.procent) {
        return (category.procent / 100) * 1.2;
      }
    }

    return 0;
  };

  const getColor = (partId: number) => {
    const assignment = assignments.find((x) => x.partId == partId);
    if (assignment) {
      const category = results.find((x) => x.categoryId == assignment.categoryId);

      if (category) {
        return category.categoryColor;
      }
    }

    return 'white';
  };

  return (
    <>
      <svg className="dd-graphic-container" viewBox="0 0 1000 1000">
        {/* center */}
        <polygon
          style={{ fill: getColor(1), transform: `scale(${getScale(1)})` }}
          points="500,344 650,452 591.5,628 408,628 350,452"
          className="dd-center"
        ></polygon>
        {/* inner parts */}
        <polygon
          style={{ transform: `rotate(${specs.r1}deg)`, fill: getColor(2) }}
          points={`${specs.tx},${getHeigth(2)} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
          className="dd-part"
        ></polygon>
        <polygon
          style={{ transform: `rotate(${specs.r2}deg)`, fill: getColor(3) }}
          points={`${specs.tx},${getHeigth(3)} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
          className="dd-part"
        ></polygon>
        <polygon
          style={{ transform: `rotate(${specs.r3}deg)`, fill: getColor(4) }}
          points={`${specs.tx},${getHeigth(4)} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
          className="dd-part"
        ></polygon>
        <polygon
          style={{ transform: `rotate(${specs.r4}deg)`, fill: getColor(5) }}
          points={`${specs.tx},${getHeigth(5)} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
          className="dd-part"
        ></polygon>
        <polygon
          style={{ transform: `rotate(${specs.r5}deg)`, fill: getColor(6) }}
          points={`${specs.tx},${getHeigth(6)} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
          className="dd-part"
        ></polygon>
        {/* container parts */}
        {getHeigth(2) > 0 && (
          <polygon
            style={{ transform: `rotate(${specs.r1}deg)` }}
            points={`${specs.tx},${specs.ty} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
            className="dd-part-container"
          ></polygon>
        )}
        {getHeigth(3) > 0 && (
          <polygon
            style={{ transform: `rotate(${specs.r2}deg)` }}
            points={`${specs.tx},${specs.ty} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
            className="dd-part-container"
          ></polygon>
        )}
        {getHeigth(4) > 0 && (
          <polygon
            style={{ transform: `rotate(${specs.r3}deg)` }}
            points={`${specs.tx},${specs.ty} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
            className="dd-part-container"
          ></polygon>
        )}
        {getHeigth(5) > 0 && (
          <polygon
            style={{ transform: `rotate(${specs.r4}deg)` }}
            points={`${specs.tx},${specs.ty} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
            className="dd-part-container"
          ></polygon>
        )}
        {getHeigth(6) > 0 && (
          <polygon
            style={{ transform: `rotate(${specs.r5}deg)` }}
            points={`${specs.tx},${specs.ty} ${1000 - specs.w},${specs.h} ${specs.w},${specs.h}`}
            className="dd-part-container"
          ></polygon>
        )}
      </svg>
    </>
  );
};
