import { Dropdown, Grid, Header, Icon, Label, Menu, Message, Search, Segment, Select } from 'semantic-ui-react';
import React, { Fragment, useEffect, useState } from 'react';
import { formatDate, getAuditStatusColor, getAuditStatusText } from '../../company/overview/companyOverview';
import { history, paths } from '../../layout/layout';

import { AdminOverviewItem, Star } from '../../../Swagger/api';
import { ArchiveConfirm } from '../../auditor/archiveConfirm';
import { CompanyReport } from '../../companyReport/companyReport';
import { OverviewItemPlaceholder } from '../../company/overview/overviewItemPlaceholder';
import { PleaseWaitReport } from '../../common/pleaseWaitReport';
import { generateAuditReport } from '../../../words/auditReport';
import { generateCorrectionsReport } from '../../../words/correctionsReport';
import { localize } from '../../../Localization/localize';
import { getApis } from '../../../Services/webservice';
import { IFilterState, useFilterState } from '../../../hooks/filter-state-hook';

const initialFilters: IFilterState = {
  starStatus: { name: localize.starStatus, selectedValue: -1 },
  starYear: { name: localize.yearStartedFilter, selectedValue: -1 },
  starContentVersion: { name: localize.starContentVersion, selectedValue: -1 },
  searchQuery: { name: undefined, selectedValue: '' },
};

export const AdminOverview = () => {
  const [items, setItems] = useState<AdminOverviewItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<AdminOverviewItem[]>([]);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedStar, setSelectedItem] = useState<AdminOverviewItem>();
  const [loadingReport, setLoadingReport] = useState(false);
  const [selectedForArchive, setSelectedForArchive] = useState<number>();
  const [filters, setFilters] = useFilterState('admin-overview-filters', initialFilters);

  const { starClient, userAccountsClient } = { ...getApis() };

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    let data = [...items];
    if (filters.starStatus.selectedValue !== -1) {
      data = data.filter((x) => getAuditStatusText(x as Star) === filters.starStatus.selectedValue);
    }

    if (filters.starYear.selectedValue !== -1) {
      data = data.filter((x) => new Date(x.started).getFullYear() === Number(filters.starYear.selectedValue));
    }

    if (filters.starContentVersion.selectedValue !== -1) {
      data = data.filter((x) => x.starVersion === filters.starContentVersion.selectedValue);
    }

    if (filters.searchQuery.selectedValue !== -1) {
      const filterValue = (filters.searchQuery.selectedValue as string).toLowerCase();
      data = data.filter((x) => x.company?.companyTitle?.toLowerCase().indexOf(filterValue) !== -1);
    }

    setFilteredItems(data);
  }, [filters, items]);

  const loadItems = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const data = await starClient.getStarAdminOverviewItems();
      setItems(data);
      setFilteredItems(data);
    } catch {
      setHasError(true);
    }

    setLoading(false);
  };

  const getFilterOptions = (filterKey: string) => {
    if (filterKey === 'starStatus') {
      return [
        { text: localize.all, value: -1, key: 0 },
        {
          text: localize.starStillRunning,
          value: localize.starStillRunning,
          key: 1,
        },
        {
          text: localize.availableForAuditDateLowerCase,
          value: localize.availableForAuditDateLowerCase,
          key: 2,
        },
        {
          text: localize.auditInProgress,
          value: localize.auditInProgress,
          key: 3,
        },
        {
          text: localize.auditFinished,
          value: localize.auditFinished,
          key: 4,
        },
        {
          text: localize.companyReportDoneLong,
          value: localize.companyReportDone,
          key: 5,
        },
      ];
    }

    if (filterKey === 'starYear') {
      const years: number[] = [];
      const uniqueYears = items
        .filter((x) => x.started)
        .map((x) => new Date(x.started).getFullYear())
        .reduce((a, c) => {
          if (a.indexOf(c) === -1) {
            return [...a, c];
          } else {
            return a;
          }
        }, years);

      uniqueYears.sort((a, b) => (a > b ? 1 : -1));
      return [
        { text: localize.all, value: -1, key: 0 },
        ...uniqueYears.map((y) => ({
          text: y,
          value: y,
          key: y,
        })),
      ];
    }

    if (filterKey === 'starContentVersion') {
      const versions: string[] = [];
      const uniqueVersions = items
        .filter((x) => x.starVersion)
        .map((x) => x.starVersion)
        .reduce((a, c) => {
          if (!c) {
            return a;
          }
          if (a.indexOf(c) === -1) {
            return [...a, c];
          } else {
            return a;
          }
        }, versions);

      uniqueVersions.sort((a, b) => (a > b ? 1 : -1));
      return [
        { text: localize.all, value: -1, key: 0 },
        ...uniqueVersions.map((y) => ({
          text: y,
          value: y,
          key: y,
        })),
      ];
    }
  };

  return (
    <section className="dd-overflowing-section">
      <Header size="huge">{localize.starsOverview}</Header>

      <Message error header={localize.generalErrorHeader} content={localize.generalErrorMessage} hidden={!hasError} />
      {loading ? (
        <OverviewItemPlaceholder />
      ) : (
        <>
          <Search
            onSearchChange={(_event, { value }) =>
              setFilters((p) => ({ ...p, searchQuery: { ...p.searchQuery, selectedValue: value as string } }))
            }
            fluid
            defaultValue={filters.searchQuery.selectedValue as string}
            input={{ fluid: true, placeholder: localize.searchStars }}
            open={false}
          />
          <Segment>
            {Object.keys(filters)
              .filter((key) => filters[key].name)
              .map((key) => (
                <Fragment key={key}>
                  <strong>{filters[key].name}: </strong>
                  <Dropdown
                    inline
                    className="dd-filters"
                    options={getFilterOptions(key)}
                    value={filters[key].selectedValue}
                    onChange={(_, { value }) =>
                      setFilters((p) => ({ ...p, [key]: { name: p[key].name, selectedValue: value as any } }))
                    }
                  />
                </Fragment>
              ))}
          </Segment>
          {(filteredItems || []).map((item) => (
            <Segment key={item.id} className="dd-star-list-item">
              <Grid divided>
                <Grid.Row>
                  <Grid.Column computer={12} tablet={10}>
                    <p title={item.company?.companyTitle || '-'}>
                      <Icon name="building" />
                      <span className="dd-item-title">{item.company?.companyTitle || '-'}</span>
                    </p>
                    {item.justCompanyReport !== true && (
                      <p title="Auditor*in">
                        <Icon name="user" />
                        {item.auditorFullName || '-'}
                      </p>
                    )}
                    <p title={item.starVersion || '-'}>
                      <Icon name="star" />
                      {item.starVersion || '-'}
                    </p>
                    <div className="dd-compact-grid">
                      <div className="dd-first-column">
                        <strong>{localize.startedAtDate}</strong>
                      </div>
                      <div className="dd-arrow-column"></div>
                      <div className="dd-second-column">
                        <strong>{item.justCompanyReport ? localize.companyReportDoneOnDate : localize.auditStartedDate}</strong>
                      </div>
                    </div>
                    <div className="dd-compact-grid">
                      <div className="dd-first-column">{item.started ? formatDate(new Date(item.started)) : '-'}</div>
                      <div className="dd-arrow-column">
                        <Icon name="arrow right" />
                      </div>
                      <div className="dd-second-column">
                        {item.auditAvailable || item.companyReportDone
                          ? formatDate(new Date(item.auditAvailable || item.companyReportDone!))
                          : '-'}
                      </div>
                    </div>

                    {item.justCompanyReport !== true && (
                      <>
                        <div className="dd-compact-grid">
                          <div className="dd-grid-separator" />
                        </div>
                        <div className="dd-compact-grid">
                          <div className="dd-first-column">
                            <strong>{localize.auditStartedDate}</strong>
                          </div>
                          <div className="dd-arrow-column"></div>
                          <div className="dd-second-column">
                            <strong>{localize.auditDoneDate}</strong>
                          </div>
                        </div>
                        <div className="dd-compact-grid">
                          <div className="dd-first-column">
                            {item.auditStarted ? formatDate(new Date(item.auditStarted)) : '-'}
                          </div>
                          <div className="dd-arrow-column">
                            <Icon name="arrow right" />
                          </div>
                          <div className="dd-second-column">
                            {item.auditFinished ? formatDate(new Date(item.auditFinished)) : '-'}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="dd-compact-grid">
                      <div className="dd-grid-separator" />
                    </div>
                    <hr />
                    <Menu secondary stackable>
                      <Menu.Item onClick={() => setSelectedItem(item)}>{localize.companyReport}</Menu.Item>
                      {item.justCompanyReport !== true && (
                        <>
                          <Menu.Item
                            disabled={!item.finished}
                            onClick={async () => {
                              setLoadingReport(true);
                              try {
                                const [starData, company] = await Promise.all([
                                  starClient.getStarAuditorOverviewItemForAdmin(item.id),
                                  userAccountsClient.getCompanyById(item.company?.userLoginId),
                                ]);
                                await generateAuditReport(item.id, starData, company);
                              } catch {
                                setHasError(true);
                              }
                              setLoadingReport(false);
                            }}
                          >
                            {localize.auditReport}
                          </Menu.Item>
                          <Menu.Item
                            disabled={!item.finished}
                            onClick={async () => {
                              setLoadingReport(true);
                              try {
                                const [starData, company] = await Promise.all([
                                  starClient.getStarAuditorOverviewItemForAdmin(item.id),
                                  userAccountsClient.getCompanyById(item.company?.userLoginId),
                                ]);
                                await generateCorrectionsReport(item.id, starData, company);
                              } catch {
                                setHasError(true);
                              }
                              setLoadingReport(false);
                            }}
                          >
                            {localize.auditCorrectionsReport}
                          </Menu.Item>
                        </>
                      )}
                    </Menu>
                  </Grid.Column>
                  <Grid.Column tablet={6} computer={4}>
                    <Label className="dd-item-status-label" size="large" color={getAuditStatusColor(item as any)}>
                      {getAuditStatusText(item as any)}
                    </Label>
                    <Menu vertical secondary fluid>
                      <Menu.Item onClick={() => history.push(paths.admin.starAdmin, { starId: item.id })}>
                        {localize.seeAnswers}
                      </Menu.Item>
                      {item.justCompanyReport !== true && (
                        <Menu.Item onClick={() => history.push(`/admin/star/control/${item.id}`)}>
                          {localize.toControlData}
                        </Menu.Item>
                      )}
                      <Menu.Item onClick={() => setSelectedForArchive(item.id)}>{localize.sendToArchive}</Menu.Item>
                    </Menu>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          ))}
        </>
      )}
      {selectedStar && (
        <CompanyReport
          onClose={() => setSelectedItem(undefined)}
          starId={selectedStar.id!}
          companyId={selectedStar.company?.userLoginId}
        />
      )}
      {loadingReport && <PleaseWaitReport />}
      {selectedForArchive && (
        <ArchiveConfirm
          starId={selectedForArchive}
          archive={true}
          onDone={() => {
            setSelectedForArchive(undefined);
            loadItems();
          }}
          onCancel={() => setSelectedForArchive(undefined)}
        />
      )}
    </section>
  );
};
