import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { localize } from "../../../Localization/localize";

interface IFinishAuditPromptProps {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
}

export const FinishAuditPrompt = ({
  onCancel,
  onConfirm,
  loading,
}: IFinishAuditPromptProps) => (
  <Modal open>
    <Modal.Header>{localize.finishAudit}</Modal.Header>
    <Modal.Content>
      <div
        dangerouslySetInnerHTML={{ __html: localize.finishAuditMessage }}
      ></div>
    </Modal.Content>
    <Modal.Actions>
      <Button floated="left" onClick={onCancel}>
        {localize.cancelLowerCase}
      </Button>
      <Button
        primary
        icon
        labelPosition="right"
        onClick={onConfirm}
        loading={loading}
      >
        <Icon name="save"></Icon>
        {localize.saveLowerCase}
      </Button>
    </Modal.Actions>
  </Modal>
);
