import {
  Button,
  Divider,
  DropdownItemProps,
  Form,
  Header,
  Message,
} from "semantic-ui-react";
import { CompletedStar, ProductAccess } from "../../Swagger/api";
import React, { useContext, useEffect, useState } from "react";

import { LoginContext } from "../../Contexts/loginContext";
import { history } from "../layout/layout";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export const StarStart = () => {
  const [justIso, setJustIso] = useState(0);
  const [selectedStarId, setSelectedStarId] = useState(-1);
  const [stars, setStars] = useState<CompletedStar[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingStars, setLoadingStars] = useState(false);
  const [hasError, setHasError] = useState(false);

  const loginContext = useContext(LoginContext);

  const { starClient } = { ...getApis() };

  useEffect(() => {
    loadSourceStars();
  }, []);

  const loadSourceStars = async () => {
    setLoadingStars(true);
    const sourceStars = await starClient.getCompletedStars();
    setStars(sourceStars);
    setLoadingStars(false);
  };

  const startStar = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    setHasError(false);

    try {
      let newStarId: number;
      if (selectedStarId && selectedStarId !== -1) {
        newStarId = await starClient.startStar(!!justIso, selectedStarId);
      } else {
        newStarId = await starClient.startStar(!!justIso);
      }

      history.push(`/star/run/${newStarId}`);
    } catch {
      setLoading(false);
      setHasError(true);
    }
  };

  const getSourceStarOptions = () => {
    const emptyOption = {
      key: -1,
      text: localize.withOutSourceStar,
      value: -1,
    };

    const options: DropdownItemProps[] = [];
    options.push(emptyOption);

    return options.concat(
      stars.map((x) => {
        return {
          value: x.id,
          text: `${
            x.justForIso ? localize.justIso : localize.starWithIso
          } - ${new Date(x.started!).toLocaleDateString()}`,
        };
      })
    );
  };

  return (
    <React.Fragment>
      <Header size="large">{localize.starStarHeader}</Header>
      <Divider hidden horizontal />
      <Form>
        <Form.Select
          label={localize.selectStar}
          options={getSourceStarOptions()}
          value={selectedStarId}
          onChange={(_event, { value }) => setSelectedStarId(value as number)}
          loading={loadingStars}
        />
        {loginContext.login?.productAccess !==
          ProductAccess.CompanyReportOnly && (
          <Form.Select
            required
            options={[
              { text: localize.starWithIso, value: 0 },
              { text: localize.justIso, value: 1 },
            ]}
            value={justIso}
            onChange={(_event, { value }) => setJustIso(value as number)}
            label={localize.starType}
          />
        )}
        <Form.Button
          animated
          primary
          floated="right"
          loading={loading}
          onClick={startStar}
        >
          <Button.Content visible>{localize.startStarButton}</Button.Content>
          <Button.Content hidden>{localize.goCrazyLowerCase}</Button.Content>
        </Form.Button>
        <Divider horizontal hidden></Divider>
        <Divider horizontal hidden></Divider>
        <Divider horizontal hidden></Divider>
        <Divider horizontal hidden></Divider>
        <Message
          error
          visible={hasError}
          content={localize.generalErrorMessage}
          header={localize.generalErrorHeader}
        />
      </Form>
    </React.Fragment>
  );
};
