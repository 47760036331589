import { Divider, Icon, Menu } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { SubCompany, UserAccountCompany } from "../../Swagger/api";

import { CompanyAddEdit } from "./companyAddEdit";
import { CompanyTypes } from "./companyTypes";
import { DeactivateUserLogin } from "./deactivateUserLogin";
import { DeleteUserAccount } from "./deleteUserAccount";
import { ItemsList } from "../common/itemsList/itemsList";
import { ResetUserPassword } from "./resetPasswort";
import { SubCompanyModal } from "./subCompanyModal";
import { UserAccount } from "./userAccount";
import { UserTabs } from "./UserTabs";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export const AdminUserCompanies = () => {
  const [userAccounts, setUserAccounts] = useState<UserAccountCompany[]>([]);
  const [selectedForEdit, setSelectedForEdit] =
    useState<UserAccountCompany | null>();
  const [userAccountsUpdated, setUserAccountsUpdated] = useState(false);
  const [selectedForDeactivate, setSelectedForDeactivate] =
    useState<UserAccountCompany | null>();
  const [selectedForDelete, setSelectedForDelete] =
    useState<UserAccountCompany | null>();
  const [selectedForReset, setSelectedForReset] =
    useState<UserAccountCompany | null>();
  const [showCompanyTypesModal, setShowCompanyTypesModal] = useState(false);
  const [subCompanies, setSubCompanies] = useState<SubCompany[]>([]);
  const [selectedSubCompany, setSelectedSubCompany] = useState<SubCompany>();
  const { userAccountsClient } = { ...getApis() };

  useEffect(() => {
    reloadUsers();
  }, []);

  useEffect(() => {
    if (userAccountsUpdated) {
      reloadUsers();
      setUserAccountsUpdated(false);
    }
  }, [userAccountsUpdated]);

  const reloadUsers = async () => {
    const [userAccountsData, subCompaniesData] = await Promise.all([
      userAccountsClient.getAllCompanies(),
      userAccountsClient.getAllSubCompanies(),
    ]);
    setUserAccounts(userAccountsData);
    setSubCompanies(subCompaniesData);
  };

  return (
    <div>
      <UserTabs activeIndex={1} />
      <Divider horizontal hidden />
      <Menu icon="labeled">
        <Menu.Item onClick={() => setSelectedForEdit(null)}>
          <Icon name="building" />
          {localize.createCompany}
        </Menu.Item>
        <Menu.Item onClick={() => setShowCompanyTypesModal(true)}>
          <Icon name="home" />
          {localize.companyTypes}
        </Menu.Item>
      </Menu>
      {userAccounts.map((userAccount) => (
        <UserAccount
          key={userAccount.userLoginId}
          deactivated={userAccount.userLogin?.deactivated || false}
          onDeactivate={() => setSelectedForDeactivate(userAccount)}
          onDelete={() => setSelectedForDelete(userAccount)}
          onEdit={() => setSelectedForEdit(userAccount)}
          onPasswordReset={() => setSelectedForReset(userAccount)}
          title={`${userAccount.companyTitle} ${
            userAccount.companyTypes && userAccount.companyTypes.length
              ? `(${userAccount.companyTypes.map((x) => x.title).join(", ")})`
              : ""
          }`}
          titleIconName="building"
          productAccess={userAccount.productAccess}
        >
          <p title={userAccount.userLogin!.emailAddress}>
            <Icon name="mail" />
            {userAccount.userLogin!.emailAddress}
          </p>
          <p title={`${userAccount.firstName} ${userAccount.lastName}`}>
            <Icon name="user" />
            {userAccount.firstName} {userAccount.lastName}
          </p>
          <p
            title={[
              userAccount.street,
              userAccount.postcode,
              userAccount.city,
              userAccount.state,
            ]
              .filter((x) => x)
              .join(", ")}
          >
            <Icon name="map" />
            {[
              userAccount.street,
              userAccount.postcode,
              userAccount.city,
              userAccount.state,
            ]
              .filter((x) => x)
              .join(", ")}
          </p>
          <p
            title={`${localize.fulltimePositions}: ${userAccount.fullTimePositions}`}
          >
            <Icon name="users" />
            {localize.fulltimePositions}: {userAccount.fullTimePositions}
          </p>
          {subCompanies.find(
            (x) => x.parentCompanyId === userAccount.userLoginId
          ) && (
            <p className="dd-centered-meta">
              <Icon name="building" />
              <ItemsList
                items={subCompanies
                  .filter((x) => x.parentCompanyId === userAccount.userLoginId)
                  .map((x) => {
                    return {
                      title: x.companyTitle || localize.noSubCompanyName,
                      onClick: () => {
                        setSelectedSubCompany(x);
                      },
                    };
                  })}
              />
            </p>
          )}
          <p
            title={`${localize.starContentVersion}: ${
              userAccount.starVersion?.title || "-"
            }`}
          >
            <Icon name="bullhorn" />
            {userAccount.starVersion?.title || "-"}
          </p>
        </UserAccount>
      ))}
      {selectedForEdit !== undefined ? (
        <CompanyAddEdit
          userAccount={selectedForEdit}
          onCancel={() => setSelectedForEdit(undefined)}
          onDone={() => {
            setSelectedForEdit(undefined);
            setUserAccountsUpdated(true);
          }}
        />
      ) : null}
      {selectedForDeactivate?.userLogin ? (
        <DeactivateUserLogin
          onCancel={() => setSelectedForDeactivate(undefined)}
          onDone={() => {
            setSelectedForDeactivate(undefined);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForDeactivate.userLogin}
        />
      ) : null}
      {selectedForDelete?.userLogin ? (
        <DeleteUserAccount
          onCancel={() => setSelectedForDelete(undefined)}
          onDone={() => {
            setSelectedForDelete(undefined);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForDelete.userLogin}
        />
      ) : null}
      {selectedForReset?.userLogin ? (
        <ResetUserPassword
          onCancel={() => setSelectedForReset(undefined)}
          onDone={() => {
            setSelectedForReset(undefined);
            setUserAccountsUpdated(true);
          }}
          userLogin={selectedForReset.userLogin}
        />
      ) : null}
      {showCompanyTypesModal ? (
        <CompanyTypes
          onCancel={() => setShowCompanyTypesModal(false)}
          onDone={() => {
            setUserAccountsUpdated(true);
            setShowCompanyTypesModal(false);
          }}
        />
      ) : null}
      {selectedSubCompany ? (
        <SubCompanyModal
          onClose={() => setSelectedSubCompany(undefined)}
          subCompany={selectedSubCompany}
        />
      ) : null}
    </div>
  );
};
