import React from "react";
import { Icon, SemanticICONS } from "semantic-ui-react";

interface IActionLink {
  iconName: SemanticICONS;
  label: string;
  action: () => void;
  multiline?: boolean;
  disabled?: boolean;
}

export const ActionLink = (props: IActionLink) => {
  const { iconName, label, action } = { ...props };

  return (
    <div
      className={`dd-action-link dd-dotdotdot ${props.multiline ? "dd-multi" : ""} ${
        props.disabled ? "dd-disabled" : ""
      }`}
      onClick={!props.disabled ? action : () => {}}
      title={label}>
      <Icon name={iconName}></Icon>
      {label}
    </div>
  );
};
