import { Grid, Icon, Label, Menu, Segment, SemanticICONS } from 'semantic-ui-react';

import { ProductAccess } from '../../Swagger/api';
import React from 'react';
import { localize } from '../../Localization/localize';

interface IUserAccountProps {
  title: string;
  titleIconName: SemanticICONS;
  deactivated: boolean;
  onEdit: () => void;
  onPasswordReset: () => void;
  onDeactivate: () => void;
  onDelete: () => void;
  children: React.ReactNode;
  productAccess?: ProductAccess;
}

const getColorForProductAccess = (productAccess: ProductAccess): 'green' | 'teal' | undefined => {
  switch (productAccess) {
    case ProductAccess.CompanyReportOnly:
      return 'teal';
    case ProductAccess.CseStar:
      return 'green';
    default:
      return undefined;
  }
};

const getTextForProductAccess = (productAccess: ProductAccess) => {
  switch (productAccess) {
    case ProductAccess.CompanyReportOnly:
      return localize.productAccessCompanyReport;
    case ProductAccess.CseStar:
      return localize.productAccessCseStar;
    default:
      return '-';
  }
};

export const UserAccount = (props: IUserAccountProps) => (
  <Segment>
    <Grid divided>
      <Grid.Row>
        <Grid.Column width={12}>
          <h4 title={props.title}>
            <Icon name={props.titleIconName} />
            <strong>{props.title}</strong>
          </h4>
          {props.children}
        </Grid.Column>
        <Grid.Column width={4}>
          {props.productAccess !== undefined && (
            <Label className="dd-item-status-label" size="large" color={getColorForProductAccess(props.productAccess)}>
              {getTextForProductAccess(props.productAccess)}
            </Label>
          )}
          <Menu vertical secondary fluid>
            <Menu.Item name="resetPassword" onClick={props.onPasswordReset}>
              {localize.passwordReset}
            </Menu.Item>
            <Menu.Item name="edit" onClick={props.onEdit}>
              {localize.editUppercase}
            </Menu.Item>
            <Menu.Item onClick={props.onDeactivate}>
              {props.deactivated ? localize.activateUpperCase : localize.deactivateUpperCase}
            </Menu.Item>
            <Menu.Item onClick={props.onDelete}>{localize.deleteUpperCase}</Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);
