import { Button, Form, Icon, Message, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { StarVersion } from "../../../../Swagger/api";
import { localize } from "../../../../Localization/localize";
import { getApis } from "../../../../Services/webservice";

interface IPublishStarVersion {
  starVersion?: StarVersion;
  onDone: () => void;
  onCancel: () => void;
}

export const PublishStarVersion = (props: IPublishStarVersion) => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setHasError] = useState(false);
  const [warning, setHasWarning] = useState(false);

  const { starVersionClient } = { ...getApis() };

  const save = async () => {
    if (!title) {
      setHasWarning(true);
      return;
    } else {
      setHasWarning(false);
    }

    setLoading(true);
    setHasError(false);

    try {
      await starVersionClient.publishStarVersion(title);

      setLoading(false);
      props.onDone();
    } catch {
      setLoading(false);
      setHasError(true);
    }
  };

  useEffect(() => {
    setHasWarning(false);
  }, [title]);

  return (
    <Modal dimmer="blurring" open={true} closeOnDimmerClick>
      <Modal.Header>{localize.publishVersion}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label={localize.starContentVersionName}
            type="text"
            value={title}
            required
            onChange={(_event, { value }) => setTitle(value)}
          />
          <Message
            visible={error}
            error
            header={localize.generalErrorHeader}
            content={localize.signinErrorMessage}
          />
          <Message warning visible={warning}>
            {localize.publishWarning}
          </Message>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={localize.cancelLowerCase}
          floated="left"
          onClick={props.onCancel}
        />
        <Button
          primary
          icon
          labelPosition="right"
          onClick={save}
          loading={loading}
        >
          <Icon name="save" />
          {localize.saveLowerCase}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
