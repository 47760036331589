import localForage from 'localforage';
import React, { useEffect, useState } from 'react';
import { Divider, Message, Placeholder } from 'semantic-ui-react';
import { localize } from '../../../Localization/localize';
import { getApis } from '../../../Services/webservice';
import {
  Question,
  StarAuditorAnswer,
  StarAuditorOverViewCompanyAnswer,
  StarAuditorOverviewQuestion,
  StarAuditorOverviewStarAuditorAnswer,
  StarCompanyAnswer,
} from '../../../Swagger/api';
import { getCurrentStarFromDb, starDbKey, StarsDb } from '../../auditor/auditorOverview';
import { history, paths } from '../../layout/layout';
import { scrollUp } from '../starAdmin/starAdmin';
import { QuestionNavigator } from '../starRun/questionNavigator';
import { AuditNotes } from './auditNotes';
import { QuestionAuditContent } from './questionAuditContent';

interface IStarAudit {
  location: {
    state: {
      starId: number;
    };
  };
}

export const StarAudit = (props: IStarAudit) => {
  const [questions, setQuestions] = useState<StarAuditorOverviewQuestion[]>([]);
  const [answers, setAnswers] = useState<StarAuditorOverViewCompanyAnswer[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<StarAuditorOverviewQuestion>();
  const [currentAuditorAnswer, setCurrentAuditorAnswer] = useState<StarAuditorOverviewStarAuditorAnswer>();
  const [showNavigator, setShowNavigator] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [previousStarAnswers, setPreviousStarAnswers] = useState<StarAuditorOverViewCompanyAnswer[]>([]);
  const [companyId, setCompanyId] = useState<number>();
  const { starClient } = { ...getApis() };

  useEffect(() => {
    loadStarFromDb();
  }, []);

  const loadStarFromDb = async () => {
    const currentStar = await getCurrentStarFromDb(props.location.state.starId);
    if (!currentStar) {
      return;
    }

    setReadOnlyMode(currentStar.finished);
    setCompanyId(currentStar.companyId);

    const currentAnswers = currentStar.starCompanyAnswers || [];
    setAnswers(currentAnswers);

    const currentQuestions = currentAnswers
      .map((x) => x.question as StarAuditorOverviewQuestion)
      .sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setQuestions(currentQuestions);
    setCurrentQuestion(currentQuestions[0]);

    try {
      if (currentStar.previousStar?.id) {
        const previousStarData = await getCurrentStarFromDb(currentStar.previousStar?.id);
        setPreviousStarAnswers(previousStarData?.starCompanyAnswers || []);
      }
    } catch {
      console.error('Could not load previous star data');
    }
  };

  const goBack = async () => {
    await saveAnswer();
    scrollUp();

    if (!currentQuestion) {
      setCurrentQuestion(questions[0]);
      return;
    }

    const currentPosition = currentQuestion.sort;

    if (currentPosition === getSmallestSort()) {
      return;
    } else {
      const backQuestion = questions.filter((x) => x.sort < currentPosition);
      if (backQuestion && backQuestion.length) {
        setCurrentQuestion(backQuestion[backQuestion.length - 1]);
      }
    }
  };

  const goNext = async () => {
    await saveAnswer();
    scrollUp();

    if (!currentQuestion) {
      setCurrentQuestion(questions[0]);
      return;
    }

    const currentPosition = currentQuestion.sort;

    if (currentPosition === getBiggestSort()) {
      return;
    } else {
      const nextQuestion = questions.find((x) => x.sort > currentPosition);
      if (nextQuestion) {
        setCurrentQuestion(nextQuestion);
      }
    }
  };

  const navigate = async (position: number) => {
    setShowNavigator(false);
    await saveAnswer();
    const question = questions.find((x) => x.sort === position);

    if (question) {
      setCurrentQuestion(question);
    }
  };

  const onClose = async () => {
    await saveAnswer();
    history.push(paths.auditor.overview);
  };

  useEffect(() => selectAnswer(), [currentQuestion]);
  const selectAnswer = () => {
    if (!currentQuestion) {
      return;
    }

    const currentAnswer = answers.find((x) => x.questionId === currentQuestion.id);

    if (!currentAnswer) {
      return;
    }

    if (currentAnswer.starAuditorAnswer) {
      setCurrentAuditorAnswer(currentAnswer.starAuditorAnswer);
    } else {
      const emptyAnswer: StarAuditorOverviewStarAuditorAnswer = {
        auditReportText: '',
        companyAnswerId: currentAnswer.id,
        comunication: false,
        observation: false,
        documentation: false,
        improvements: '',
        problems: '',
        auditResult: 0,
        id: 0,
        important: false,
        notRelevant: false,
      };
      setCurrentAuditorAnswer(emptyAnswer);
    }
  };

  const saveAnswer = async () => {
    if (readOnlyMode) {
      return;
    }

    setHasError(false);
    setSaving(true);
    try {
      if (!currentAuditorAnswer) {
        console.log('tried to save nothing');
        return;
      }
      if (currentAuditorAnswer.auditResult === 1) {
        currentAuditorAnswer.problems = '';
        currentAuditorAnswer.improvements = '';
      }

      if (currentAuditorAnswer.auditResult === 2) {
        currentAuditorAnswer.problems = '';
      }

      if (currentAuditorAnswer.auditResult === 4) {
        currentAuditorAnswer.improvements = '';
      }

      if (navigator.onLine) {
        const savedAnswerId = await starClient.setStarAuditorAnswer(currentAuditorAnswer as StarAuditorAnswer);
        if (savedAnswerId !== currentAuditorAnswer.companyAnswerId) {
          throw new Error('Answer from server does not match answer from user!');
        }
      }

      const starsInDb = await localForage.getItem<StarsDb>(starDbKey);
      const currentStar = starsInDb?.stars.find((x) => x.id === props.location.state.starId);
      const companyAnswer = currentStar?.starCompanyAnswers?.find((x) => x.id === currentAuditorAnswer.companyAnswerId);
      if (companyAnswer) {
        companyAnswer.starAuditorAnswer = currentAuditorAnswer;

        if (starsInDb?.updatedStarIds.indexOf(props.location.state.starId) === -1) {
          starsInDb.updatedStarIds.push(props.location.state.starId);
        }

        await localForage.setItem(starDbKey, starsInDb);

        const currentAnswers = currentStar?.starCompanyAnswers || [];
        setAnswers(currentAnswers);
      } else {
        setHasError(true);
      }

      setSaving(false);
    } catch {
      setHasError(true);
      setSaving(false);
      throw new Error('Something went wrong while saving this answer');
    }
  };

  const getSmallestSort = () => {
    return Math.min(...questions.map((x) => x.sort!));
  };

  const getBiggestSort = () => {
    return Math.max(...questions.map((x) => x.sort!));
  };

  const getIsFirstQuestion = () => {
    if (currentQuestion && currentQuestion.sort && questions.length && currentQuestion.sort === getSmallestSort()) {
      return true;
    }

    return false;
  };

  const getIsLastQuestion = () => {
    if (currentQuestion && currentQuestion.sort && questions.length && currentQuestion.sort === getBiggestSort()) {
      return true;
    }

    return false;
  };

  const getCurrentAnswer = () => {
    if (currentAuditorAnswer) {
      const item = answers.find((x) => x.id === currentAuditorAnswer.companyAnswerId);

      return item;
    }
    return undefined;
  };

  const getPreviousStarAnswer = (questionId: number) => {
    return previousStarAnswers.find((x) => x.questionId === questionId);
  };

  if (currentQuestion && currentAuditorAnswer) {
    return (
      <React.Fragment>
        <QuestionAuditContent
          question={currentQuestion}
          answer={currentAuditorAnswer}
          allCompanyAnswers={answers}
          companyAnswer={getCurrentAnswer()}
          onAnswerChange={(answer) => setCurrentAuditorAnswer(answer)}
          onBack={goBack}
          onClose={onClose}
          onNext={goNext}
          onSearch={() => setShowNavigator(true)}
          readOnlyMode={readOnlyMode}
          firstQuestion={getIsFirstQuestion()}
          lastQuestion={getIsLastQuestion()}
          starId={props.location.state.starId}
          saving={saving}
          previousAnswer={getPreviousStarAnswer(currentQuestion.id)}
        />
        <AuditNotes companyId={companyId} />
        {showNavigator && (
          <QuestionNavigator
            questions={questions as Question[]}
            onClose={() => setShowNavigator(false)}
            onNavigate={navigate}
            allAnswersForAuditor={answers as StarCompanyAnswer[]}
            justCompanyReport={false}
          />
        )}
        {hasError && <Message error content={localize.generalErrorMessage} header={localize.generalErrorHeader} />}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Placeholder>
        <Divider horizontal hidden></Divider>
      </Placeholder>
      <Divider horizontal hidden></Divider>
      <Divider horizontal hidden></Divider>
      <Placeholder>
        <Placeholder.Paragraph>
          <Placeholder.Line length="very long"></Placeholder.Line>
          <Placeholder.Line length="full"></Placeholder.Line>
          <Placeholder.Line length="medium"></Placeholder.Line>
        </Placeholder.Paragraph>
      </Placeholder>
      <Divider horizontal hidden></Divider>
      <Divider horizontal hidden></Divider>
      <Placeholder>
        <Placeholder.Paragraph>
          <Placeholder.Line length="very long"></Placeholder.Line>
          <Placeholder.Line length="full"></Placeholder.Line>
          <Placeholder.Line length="medium"></Placeholder.Line>
        </Placeholder.Paragraph>
      </Placeholder>
      <Divider horizontal hidden></Divider>
      <Divider horizontal hidden></Divider>
      <Placeholder>
        <Placeholder.Paragraph>
          <Placeholder.Line length="very long"></Placeholder.Line>
          <Placeholder.Line length="full"></Placeholder.Line>
          <Placeholder.Line length="medium"></Placeholder.Line>
        </Placeholder.Paragraph>
      </Placeholder>
    </React.Fragment>
  );
};
