import React, { useState, useContext, useRef } from "react";
import {
  Header,
  Form,
  Button,
  Divider,
  Grid,
  Message,
} from "semantic-ui-react";
import { localize } from "../../Localization/localize";
import { Link } from "react-router-dom";
import { getApis } from "../../Services/webservice";
import { LoginContext } from "../../Contexts/loginContext";
import { paths, history } from "../layout/layout";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKey } from "../../constants";

export const SignIn = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const loginContext = useContext(LoginContext);
  const [notRobot, setNotRobot] = useState<boolean | undefined>(undefined);

  const { signinClient } = { ...getApis() };

  let recaptchaRef = useRef<ReCAPTCHA>();

  const onButtonClick = async () => {
    if (!notRobot || !recaptchaRef.current) {
      setNotRobot(false);
      return;
    }

    setLoading(true);

    try {
      const userLogin = await signinClient.signInUser({
        emailAddress,
        password,
        recaptchaToken: recaptchaRef.current.getValue() || "",
      });
      setHasError(false);
      await loginContext.setLogin(userLogin);
      history.push("/");
    } catch {
      setLoading(false);
      setHasError(true);
      recaptchaRef.current.reset();
    }
  };

  return (
    <div>
      <Header size="huge">
        Willkommen beim CSE STAR, dem Nachhaltigkeitstool für Bewertung und
        Berichtserstattung
      </Header>
      <Form className="dd-signin-container">
        <Form.Input
          label={localize.emailAddress}
          value={emailAddress}
          onChange={(_event, { value }) => setEmailAddress(value)}
        />
        <Form.Input
          label={localize.password}
          value={password}
          type="password"
          onChange={(_event, { value }) => setPassword(value)}
        />
        <Divider horizontal hidden />
        <ReCAPTCHA
          sitekey={captchaSiteKey}
          ref={(x: any) => (recaptchaRef.current = x)}
          onChange={() => setNotRobot(true)}
        />
        <Divider horizontal hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={9} verticalAlign="middle">
              <Link to={paths.signin.forgot}>{localize.passwordForgot}</Link>
            </Grid.Column>
            <Grid.Column width={7}>
              <Button primary fluid disabled={loading} onClick={onButtonClick}>
                {localize.siginin}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Message
          error
          header={localize.signinErrorHeader}
          content={localize.signinErrorMessage}
          visible={hasError}
        />
        <Message
          warning
          header={localize.passwordRobotHeader}
          content={localize.passwordRobotMessage}
          visible={notRobot === false}
        />
      </Form>
    </div>
  );
};
