import { useEffect, useState } from 'react';

export interface IFilterState {
  [key: string]: {
    name: string | undefined;
    selectedValue: string | number;
  };
}

export function useFilterState(key: string, initialFilters: IFilterState) {
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const savedFilters = sessionStorage.getItem(key);
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    } else {
      setFilters(initialFilters);
    }
  }, [initialFilters, key]);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(filters));
  }, [filters, key]);

  return [filters, setFilters] as const;
}
