import { Icon, Menu, Message, Placeholder, Table } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { history, paths } from "../../../layout/layout";

import { ActionLink } from "../../../common/actionLink/actionLink";
import { AssignStarVersion } from "./assignStarVersion";
import { PublishStarVersion } from "./publishStarVersion";
import { StarVersionOverItem } from "../../../../Swagger/api";
import { localize } from "../../../../Localization/localize";
import { getApis } from "../../../../Services/webservice";

export const ContentVersions = () => {
  const [publishOpen, setPublishOpen] = useState(false);
  const [items, setItems] = useState<StarVersionOverItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);

  useEffect(() => {
    loadItems();
  }, []);

  const { starVersionClient } = { ...getApis() };

  const loadItems = async () => {
    setLoading(true);
    setHasError(false);
    try {
      const data = await starVersionClient.getStarVersionsOverView();
      setItems(data);
      setLoading(false);
    } catch (ex) {
      setHasError(true);
    }
  };

  return (
    <React.Fragment>
      <Menu icon="labeled">
        <Menu.Item onClick={() => history.push(paths.admin.questions)}>
          <Icon name="arrow left" />
          {localize.backLowerCase}
        </Menu.Item>
        <Menu.Item onClick={() => setPublishOpen(true)}>
          <Icon name="bullhorn" />
          {localize.publishVersion}
        </Menu.Item>
        <Menu.Item onClick={() => setOpenAssignDialog(true)}>
          <Icon name="building" />
          {localize.assignStarVersionHeader}
        </Menu.Item>
      </Menu>

      <section className="dd-overflowing-section">
        <Message
          error
          header={localize.generalErrorHeader}
          content={localize.generalErrorMessage}
          hidden={!hasError}
        />
        <Table celled size="small" textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{localize.starContentVersion}</Table.HeaderCell>
              <Table.HeaderCell>
                {localize.starContentVersionPublishedDateUpperCase}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {localize.countOfCompaniesOnThisVersion}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {localize.countOfStarsUsingThisVersion}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading
              ? new Array(10).fill(0).map((_x, i) => (
                  <Table.Row key={i}>
                    {new Array(6).fill(0).map((_y, j) => (
                      <Table.Cell key={j}>
                        <Placeholder>
                          <Placeholder.Header>
                            <Placeholder.Line></Placeholder.Line>
                          </Placeholder.Header>
                        </Placeholder>
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
              : items.map((item) => (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {item.title || localize.noStarVersion}
                    </Table.Cell>
                    <Table.Cell>
                      {item.publishingDate &&
                      new Date(item.publishingDate).getFullYear() > 1971
                        ? new Date(item.publishingDate).toLocaleDateString()
                        : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {item.countOfCompaniesOnThisVersion}
                    </Table.Cell>
                    <Table.Cell>{item.countOfStarsUsingThisVersion}</Table.Cell>
                    <Table.Cell>
                      <ActionLink
                        disabled={!item.title}
                        label={localize.editLowercase}
                        iconName="pencil alternate"
                        action={() =>
                          history.push(`/admin/versions/${item.id}`)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
          </Table.Body>
        </Table>
      </section>

      {publishOpen && (
        <PublishStarVersion
          onCancel={() => setPublishOpen(false)}
          onDone={() => {
            setPublishOpen(false);
            loadItems();
          }}
        />
      )}

      {openAssignDialog && (
        <AssignStarVersion
          onCancel={() => setOpenAssignDialog(false)}
          onDone={() => {
            setOpenAssignDialog(false);
            loadItems();
          }}
        />
      )}
    </React.Fragment>
  );
};
