import { Button } from 'semantic-ui-react';
import React from 'react';
import { history } from '../layout/layout';
import { useLocation } from 'react-router-dom';

interface INavigationButtonProps {
  to: string;
  label: string;
  alternateMatches?: string[];
}

export const NavigationButton = (props: INavigationButtonProps) => {
  const location = useLocation();

  return (
    <div className="dd-navigation-button">
      <Button
        fluid
        onClick={() => history.push(props.to)}
        className={
          location.pathname === props.to || (props.alternateMatches && props.alternateMatches.indexOf(location.pathname) != -1)
            ? 'dd-selected'
            : ''
        }
      >
        {props.label}
      </Button>
    </div>
  );
};
