import { ILocalizationStrings } from './interface';

export const de: ILocalizationStrings = {
  starsOverview: 'STARs Übersicht',
  homeInfo: 'Anleitung',
  companies: 'Unternehmen',
  graphicalResult: 'Graphisches Ergebnis',
  myProfile: 'Mein Profil',
  myStars: 'Meine STARs',
  questions: 'Fragen',
  userManagement: 'Benutzerverwaltung',
  emailAddress: 'Email',
  emailAddressAgain: 'Email wiederholen',
  password: 'Passwort',
  passwordAgain: 'Passwort wiederholen',
  passwordForgot: 'Ich habe mein Passwort vergessen',
  passwordStrength: 'Passwortstärke',
  siginin: 'Einloggen',
  signinErrorHeader: 'Login fehlgeschlagen',
  signinErrorMessage: 'Bitte überprüfen Sie ihre Angabe',
  signout: 'Abmelden',
  notOnlineMessage: 'Sie sind offline',
  notSignedInMessage: 'Sie sind nicht eingeloggt',
  passwordForgotHeader: 'Passwort vergessen',
  passwordReset: 'Passwort zurücksetzen',
  resetUpperCase: 'Zurücksetzen',
  resetLowerCase: 'zurücksetzen',
  passwordForgotSuccessHeader: 'Ihre Anfrage ist eingegangen',
  passwordForgotSuccessMessage: 'Sie werden eine Email mit weiteren Instruktionen bekommen',
  generalErrorHeader: 'Etwas ist schief gelaufen',
  generalErrorMessage: 'Bitte versuchen Sie es später oder kontaktieren Sie uns',
  saveUpperCase: 'Speichern',
  saveLowerCase: 'speichern',
  passwordNoMatchHeader: 'Die Passwörter sind nicht gleich',
  passwordNoMatchMessage: 'Bitte überprüfen Sie ihre Angabe',
  passwordLength: 'Passwort länger als 6 Zeichen',
  passwordLetters: 'Passwort enthält Buchstabe',
  passwordNumbers: 'Passwort enthält Zahlen',
  passwordSimbols: 'Passwort enthält Symbole',
  passwordSetHeader: 'Passwort setzen',
  passwordSetSuccessHeader: 'Passwort erfolgreich gesetzt',
  passwordSetSuccessMessage: 'Sie werden in 10 Sekunden weitergeleitet',
  passwordSetWeakHeader: 'Das Passwort ist zu schwach',
  passwordSetWeakMessage: 'Das Passwort muss mindenstens 3 von 4 Anforderungen erfühlen',
  passwordRobotHeader: 'Roboterschutz wurde nicht bestätigt',
  passwordRobotMessage: 'Bitte bestätigen Sie, dass Sie kein Roboter sind',
  administrators: 'Administratoren',
  auditors: 'Auditoren',
  superAuditors: 'Super Auditoren',
  auditor: 'Auditor',
  auditorName: 'Name Auditor*in',
  createAdmin: 'Admin erstellen',
  createSuperAuditor: 'Super Auditor erstellen',
  createAuditor: 'Auditor erstellen',
  createCompany: 'Unternehmen erstellen',
  editUppercase: 'Bearbeiten',
  editLowercase: 'bearbeiten',
  deactivateUpperCase: 'Deaktivieren',
  activateUpperCase: 'Aktivieren',
  deleteUpperCase: 'Löschen',
  deleteLowerCase: 'löschen',
  certificationCenter: 'Partner',
  certificationCenters: 'Partner zur Auditierung',
  companyType: 'Unternehmensart',
  companyTypes: 'Unternehmensarten',
  fulltimePositions: 'Vollzeitstellen-Äquivalente',
  editAdmin: 'Admin bearbeiten',
  editSuperAuditor: 'Super Auditor bearbeiten',
  cancelUpperCase: 'Abbrechen',
  cancelLowerCase: 'abbrechen',
  city: 'Ort',
  firstName: 'Vorname',
  lastName: 'Nachname',
  postCode: 'Postleitzahl',
  state: 'Land',
  streetAndHouseNumber: 'Straße und Hausnummer',
  telephoneNumber: 'Telefonnummer',
  editAuditor: 'Auditor bearbeiten',
  editCompany: 'Unternehmen bearbeiten',
  contactInfo: 'Kontaktdaten',
  contactPerson: 'Ansprechperson',
  myCompanyWithLegalForm: 'Mein Unternehmen (mit Rechtsform)',
  companyWithLegalForm: 'Unternehmen (mit Rechtsform)',
  website: 'Webseite',
  shortForm: 'Kürzel',
  activateLowerCase: 'reaktivieren',
  deactivateLowerCase: 'deaktivieren',
  userLoginDeactivateConfirmMessage: 'Sind Sie sicher, dass Sie das Konto für {0} {1} wollen?',
  no: 'Nein',
  yes: 'Ja',
  userActivateModalTitle: 'Benutzer aktivieren',
  userDeactivateModalTitle: 'Benutzer deaktivieren',
  userDeleteModalContent: 'Sind Sie sicher, dass Sie das Konto für {0} löschen wollen?',
  userDeleteModalTitle: 'Benutzer löschen',
  userPasswordResetModalContent: 'Sind Sie sicher, dass Sie das Passwort für {0} zurücksetzen wollen?',
  companyItemsFooterMessage: 'Nach dem Speichern werden die Unternehmensarten alphabetisch sortiert *',
  insertLowerCase: 'hinzufügen',
  certificationCentersFooterMessage: 'Nach dem Speichern werden die Partner alphabetisch sortiert *',
  questionCategoriesFooterMessage: 'Nach dem Speichern werden die Rubriken alphabetisch sortiert *',
  searchQuestions: 'Fragen durchsuchen',
  questionAspectsFooterMessage: 'Nach dem Speichern werden die Nachhaltigkeitsaspekte alphabetisch sortiert *',
  questionCrossReferencesFooterMessage: 'Nach dem Speichern werden die Regelwerke alphabetisch sortiert *',
  saveSubCompany: 'Subunternehmen speichern',
  subCompanyUpperCase: 'Subunternehmen',
  newSubCompany: 'neues Subunternehmen',
  deleteSubCompanyHeader: 'Subunternehen löschen',
  deleteSubCompanyMessage: 'Sind Sie sicher, dass Sie das Subunternehmen {0} löschen wollen?',
  noSubCompanyName: 'Subunternehmen ohne Name',
  closeLowerCase: 'schließen',
  saveAndCloseLowerCase: 'speichern und schließen',
  aspects: 'Nachhaltigkeitsaspekte',
  categories: 'Rubriken',
  crossreferences: 'Regelwerke',
  corssReferencesDisplayName: 'Übereinstimmung mit anderen Regelwerken',
  questionCreate: 'Frage erstellen',
  sortUpperCase: 'Sortieren',
  connectedQuestions: 'Verbundene Fragen',
  connectedQuestionsInfo: 'Beachten Sie dazu folgende bereits beantwortete Fragen',
  documents: 'Dokumente',
  forms: 'Beispielformulare und weitere Informationen',
  hintsForCrossReference: 'Hinweise für {0}',
  questionAspect: 'Nachhaltigkeitsaspekt',
  questionCategory: 'Rubrik',
  questionContent: 'Inhalt (Alltagstext)',
  questionContentForCompanyReport: 'Inhalt für Nachhaltigkeitsbericht',
  questionCseStandard: 'CSE-Standard',
  cseStandardChapter: 'Kapitel',
  questionHintsForCompany: 'Hinweise',
  questionHintsForAuditor: 'Hinweise für die Prüfung',
  questionIsoNorm: 'ISO-Norm Text',
  isoNormReference: 'Normverweis zu ISO 14001',
  questionJustForIso: 'Nur für ISO',
  questionGoal: 'Ziel Frage',
  questionRelevance: 'Relevanz',
  relevantCompanyTypes: 'Relevante Unternehmensarten',
  questionEdit: 'Frage bearbeiten',
  helpTexts: 'Hintergrund Texte',
  settings: 'Einstellungen',
  deleteQuestion: 'Frage löschen',
  deleteQuestionMessage: 'Sind Sie sicher, dass Sie die Frage "{0}" löschen wollen?',
  editCrossReference: 'Regelwerk erfassen',
  titel: 'Titel',
  description: 'Beschreibung',
  icon: 'Icon',
  reportText: 'Text für Nachhaltigkeitsbericht',
  companyReport: 'Nachhaltigkeitsbericht',
  backLowerCase: 'zurück',
  nextQuestion: 'nächste Frage',
  toOtherQuestionLowerCase: 'zu anderer Frage',
  toOtherQuestionUpperCase: 'Zu anderer Frage',
  justIso: 'ISO STAR',
  selectStar: 'STAR auswählen',
  starStarHeader: 'STAR starten',
  starType: 'STAR-Typ auswählen',
  starWithIso: 'CSE STAR',
  startStarButton: 'STAR jetzt starten',
  goCrazyLowerCase: "los geht's!",
  withOutSourceStar: 'Blanko STAR',
  approvedLowerCase: 'schon freigegeben',
  approveLowerCase: 'freigeben',
  approveModalHeader: 'Für Prüfung freigeben',
  approveModalContent: `Damit Auditoren Ihre Angaben prüfen können, brauchen Sie einen definierten Status. Den legen Sie mit diesem Button fest. Wenn Sie auf „Speichern“ drücken, dann gilt die Abfrage als abgeschlossen und ist bereit für die Prüfung. Sie können dann an diesem STAR nichts mehr ändern. Ihre Eingaben sind dann die Grundlage für das Audit.`,
  startNewStarButton: 'neues STAR beginnen',
  availableForAuditDate: 'Für Audit freigegeben',
  availableForAuditDateLowerCase: 'für Audit freigegeben',
  continueStar: 'STAR fortsetzen',
  lastChangedDate: 'Zuletzt geändert',
  seeAnswers: 'Anworten sehen',
  startedAtDate: 'Gestartet am',
  firstQuestion: 'Das ist die erste Frage',
  lastQuestion: 'Das ist die letze Frage',
  cachedData: 'Weil Sie offline sind, werden gespeicherte Daten angezeigt. Letze Aktualisierung: {0}',
  auditStartedDate: 'Audit gestartet',
  auditChangedDate: 'Audit geändert',
  auditDoneDate: 'Audit beendet',
  continueAudit: 'Prüfung weiterführen',
  startAudit: 'Prüfung starten',
  auditorHints: 'Auditor Hinweise',
  completed: 'Konform',
  notRelevant: 'Nicht relevant',
  notRelevantHint: 'wird nicht im Bericht erscheinen',
  notAudited: 'Nicht geprüft',
  auditorComment: 'Kommentar Auditor*in',
  auditReport: 'Audit Bericht',
  auditCorrectionsReport: 'Korrekturmaßnahmen Ergänzung',
  improvements: 'Verbesserungsmaßnahme',
  notCompleted: 'Abweichung',
  partiallyCompleted: 'Teilweise erfüllt',
  problems: 'Abweichung',
  result: 'Ergebnis',
  syncMessage: 'Die Daten werden synchronisiert',
  finishAudit: 'Prüfung beenden',
  finishAuditMessage: `<p>Wenn Sie alle Fragen eingesehen und deren Antworten sowie Nachweise bewertet haben, können Sie mit diesem Button die Prüfung beenden. Es folgt dann eine letzte Seite in der Sie bitte noch Hinweise eingeben, wie das Audit lief und wie Ihre persönliche Einschätzung ist. Diese Anmerkungen werden nicht im Bericht erscheinen, sondern dienen lediglich der Information an den Standardgeber und als Gedächtnisstütze bzw. Einschätzung eines neuen Auditors für das nächste Audit. </p>
<p>Wurden diese Angaben ausgefüllt, und erhalten Sie dann Ihre Bewertungen als Auditbericht in Word-Format.</p>
<p>Bitte beachten, Sie, es können dann nachträglich keine Änderungen mehr vorgenommen werden.</p>
`,
  pageNotFound: 'Diese Seite existiert nicht',
  noData: 'Keine Angabe',
  yourAnswer: 'Ihre Antwort',
  yourReportEntry: 'Ihr Text für den Nachhaltigkeitsbericht',
  noDocsWhenOffline: 'offline nicht verfügbar',
  starStillRunning: 'wird noch ausgefüllt',
  answerForAuditor: 'Antwort für Auditor',
  startAuditHeader:
    'Herzlich Willkommen im Online Tool zur Überwachung und Berichterstattung von Nachhaltigkeitsleistungen STAR',
  startAuditContent: `<p>Um mit dem Dokumentieren der Auditergebnisse zu starten, wählen Sie bitte den Button „STAR Prüfung starten“. Sie werden nach einer ersten Seite für die Kontrolldaten zu den jeweiligen Kriterien weitergeführt. Hier sehen Sie die Fragen und Antworten der Unternehmen. Es befinden sich auf der rechten Seite Hinweise für Sie als Auditor. In diesen Hinweisen sind Informationen enthalten, worauf Sie bei dem jeweiligen Kriterium achten sollten und welche Nachweise ausreichend sind. </p>
<p>Das Ergebnis Ihrer Prüfung geben Sie bitte im Textfeld ein und klicken dann ein entsprechendes Kästchen (Erfüllt, teilweise erfüllt, nicht erfüllt) an. Ihre Eingaben werden gespeichert, wenn Sie zur nächsten Frage übergehen. Wird ein Kriterium nicht erfüllt ist es eine Abweichung. Bitte geben Sie dann im Feld „Abweichung“ ein, was genau nicht erfüllt wird.</p>
<p>Wird ein Kriterium nur teilweise erfüllt, müssen Sie entscheiden, ob das Unternehmen das Kriterium inhaltlich einhält, jedoch keine Dokumentation vorliegen hat, dann wären Verbesserungsmaßnahmen notwendig oder ob nur ein Teil des Kriteriums auch inhaltlich nicht erfüllt ist, dann wäre es eine Abweichung.</p>
<p>Einige Fragen bauen aufeinander auf. Wenn dies der Fall ist, dann erhalten Sie die schon eingegebene Bewertung der Textform. Hier können Sie dann ergänzen oder etwas verändern.</p>
<p>Wurden alle Kriterien überprüft, können Sie mit dem Button „STAR Prüfung beenden und Bericht generieren“ das Audit beenden. Es folgt dann eine letzte Seite in der Sie bitte noch Hinweise eingeben, wie das Audit lief und wie Ihre persönliche Einschätzung ist. Diese Anmerkungen werden nicht im Bericht erscheinen, sondern dienen lediglich der Information an den Standardgeber und als Gedächtnisstütze bzw. Einschätzung eines neuen Auditors für das nächste Audit. </p>
<p>Wurden diese Angaben ausgefüllt, erhalten Sie dann Ihre Bewertungen als Auditbericht in Word-Format.</p>
`,
  toControlData: 'zu Kontrolldaten',
  auditControlDataHeader: 'Kontrolldaten',
  ceoName: 'Name Geschäftsführer*in',
  contactPersonName: 'Verantwortliche Person für das Audit',
  address: 'Anschrift',
  enviromentResponsibleName: 'Verantwortliche Person für den Bereich Nachhaltigkeit',
  companyData: 'Betriebsdaten',
  auditData: 'Auditdaten',
  auditDate: 'Datum des Audits',
  accompanyingPersonName: 'Name verantwortliche Begleitperson',
  auditType: 'Art des Audits',
  lastAuditDate: 'Datum letztes Audit',
  otherAuditTypeName: 'Sonstige Art des Audits',
  previousCorrectionsDone: 'Sind alle Korrekturmaßnahmen gemäß letztem Bewertungsbericht umgesetzt?',
  seePreviousCorrections: 'Korrekturmaßnahmen offen',
  previousCorrectionsNotDone: 'Wenn Nein, welche Abweichungen liegen vor?',
  previousImprovementsDone: 'Sind alle Verbesserungsmaßnahmen gemäß letztem Bewertungsbericht umgesetzt?',
  seePreviousImprovements: 'Verbesserungsmaßnahmen offen',
  previousImprovementsNotDone: 'Wenn Nein, welche Verbesserungsmaßnahmen liegen vor?',
  previousCorrectionsNotRequested: 'Keine Korrekturmaßnahmen gefordert',
  previousImprovementsNotRequested: 'Keine Verbesserungsmaßnahmen gefordert',
  fromLowerCase: 'von',
  auditTypeFirstTime: 'Erstaudit',
  auditTypeInspection: 'Überwachungsaudit',
  auditTypeRecertification: 'Rezertifizierungsaudit',
  auditTypeOther: 'Sonstiges',
  untilLowerCase: 'um',
  notesForNextAudit: 'Anmerkungen und Hinweise für das nächste Audit',
  immediateProcessing: 'Sofortige Bearbeitung?',
  immediateProcessingReason: 'Grund für die Sofortige Bearbeitung',
  notesForLaterCorrections: 'Umsetzung der Korrekturmaßnahmen',
  legend: 'Legende',
  auditMethod: 'Prüfmethode',
  auditMethodCommunication: 'Aussage',
  auditMethodDocument: 'Dokument',
  auditMethodObservation: 'Begehung',
  generalFieldWarning: 'Nicht alle pflicht Felder wurden ausgefüllt',
  generalFieldWarningHeader: 'Bitte überprüfen Sie ihre Angabe',
  graphicCenter: 'Graphic Zentrum',
  graphicPoint: 'Graphic Spitze {0}',
  searchQuestionPlaceholder: 'nach einer Frage suchen',
  generateCompanyReport: 'Nachhaltigkeitsbericht generieren',
  generateCompanyReportStep1: '1. Vorlage auswählen',
  generateCompanyReportStep2: '2. Cover Bild auswählen',
  generateCompanyReportStep3: '3. Ihr Logo hochladen',
  generateCompanyReportStep4: '4. Ihr Unternehmensslogan',
  generateCompanyReportStep5: '5. Schriftfarbe auswählen',
  generateCompanyReportStep6: '6. Bericht herunterladen',
  selectedLowerCase: 'ausgewählt',
  uploadImage: 'Bild hochladen',
  downloadNowLowerCase: 'jetzt herunterladen',
  fileUploading: 'Datei wird hochgeladen',
  fileUploadMessage: 'Um ein neues Cover Bild hochzuladen, klicken Sie einfach noch einmal darauf',
  fileUploadContent:
    'Bitte achten Sie auf die korrekten Maße des Bildes.<br />Unsere Beispiel Bilder haben die Große: 1835 Pixels Breite, 1376 Pixels Höhe.<br/>Falls das Bild nicht passen soll, können Sie die "Crop" Funktion in Word benutzen.',
  companyReportFinalMessageContent:
    'Bevor Sie das Dokument herunterladen können muss ein Logo hochladen, ein Slogan eingeben und warten bis alle Bilder sich fertig hochladen haben.',
  companyReportFinalMessageHeader: 'Nicht alle benötigten Informationen wurden eingegeben',
  saveDataPrompt: 'Achtung, speichern nicht vergessen',
  answerStateNotAnswered: 'noch nicht beantwortet',
  answerStateAnswered: 'fertig beantwortet',
  answerStateInProgress: 'in Arbeit',
  emailDuplicate: 'Die Email Adresse wird schon verwendet',
  noQuestionsAvailableContent:
    'Bitte prüfen Sie, dass Sie zugewiesene Unternehmensarten haben. Das können sie in ihrem Profil bearbeiten. ',
  noQuestionsAvailableHeader: 'Keine Fragen verfügbar',
  noDataAvailableHeader: 'Keine Daten verfügbar',
  starContentVersion: 'STAR Inhalt Version',
  starContentVersionName: 'Name für STAR Inhalt Version',
  starContentVersionPublishedDateLowerCase: 'veröffentlicht am',
  starContentVersionPublishedDateUpperCase: 'Veröffentlicht am',
  publishVersion: 'Neue Version veröffentlichen',
  publishWarning: 'Bitte einen Version Titel eingeben.',
  openContentVersions: 'Inhalt Versionen',
  countOfCompaniesOnThisVersion: 'Unternehmen mit dieser Version',
  countOfStarsUsingThisVersion: 'Laufende STARs mit dieser Version',
  noStarVersion: 'Ohne Version',
  deleleteStarVersionContent:
    '<p>Sind Sie sicher, dass Sie die Version <strong>"{0}"</strong> Löschen wollen?</p><p>Bitte denken Sie daran, dem Unternehmen eine andere Version zuzuweisen.<br/>Laufende STARs werden diese Version weiterhin benutzen können.</p>',
  deleteStarVersionHeader: 'STAR Inhalt Version löschen',
  assignStarVersionHeader: 'Zu allen Unternehmen zuweisen',
  assignStarVersionContent:
    'Die ausgewählte Version wird allen Unternehmen zugewiesen. Laufende STARs sind von der Zuweisung nicht betroffen.',
  signoutAndDelete: 'Abmelden und alles löschen',
  staySignedin: 'Angemeldet bleiben',
  searchStars: 'STARs durchsuchen',
  statusUppercase: 'Status',
  reportsUpperCase: 'Berichte',
  loadingReport: 'Bitte warten, der Bericht wird generiert',
  savingAnswer: 'wird gespeichert',
  previousAnswerAuditor: 'Bitte beachten Sie folgenden Kommentar aus vorherigem Audit',
  pleaseChoose: 'Bitte auswählen',
  auditFinished: 'Audit fertig',
  auditInProgress: 'wird noch geprüft',
  companyDetails: 'Unternehmensdaten',
  archiveConfirmHeader: 'STAR Archiv',
  retrieveFromArchive: 'von Archiv zurückholen',
  sendToArchive: 'zu Archiv senden',
  archiveConfirmContentSend:
    '<p>Sind Sie sicher, dass Sie dieses STAR ins Archiv senden wollen?</p><p>Archivierte STARs sind nur online verfügbar.</p><p>Die Archivierung erfolgt für Sie, für alle Auditor*innen des Unternehmens und für Admins.</p>',
  archiveConfirmContentRetrieve: 'Sind Sie sicher, dass Sie dieses STAR aus der Archiv zurückholen wollen?',
  archiveDisabledHint: 'Das Archiv ist nur online verfügbar',
  archive: 'Archiv',
  noGraphicalDisplayAvailable: 'Noch kein fertiges STAR',
  confirmCrossReferenceDelete: 'Sind Sie sicher, dass Sie das Regelwerk löschen wollen?',
  confirmAspectDelete: 'Sind Sie sicher, dass Sie den Aspekt löschen wollen?',
  confirmCategoryDelete: 'Sind Sie sicher, dass Sie die Rubrik löschen wollen?',
  addQuestion: 'Frage hinzufügen',
  pickQuestion: 'Frage auswählen',
  hintPickQuestion: 'Die Frage wird ganz oben hinzugefügt',
  hintQuestionDeleted: 'Diese Frage wurde mittlerweile aus dem gesamten Fragenkatalog gelöscht',
  confirmDeleteQuestionFromVersion:
    'Sie Sie sicher, dass Sie diese Frage aus der Version löschen wollen?<br /><strong>Fragen gelöscht aus dem gesamten Fragenkatalog können nicht wieder hinzugefügt werden.</strong>',
  renameStarVersion: 'Version umbenennen',
  resolvedProblemState: 'Umsetzungsstand',
  productAccess: 'Produkt Zugriff',
  productAccessCompanyReport: 'Nachhaltigkeitsbericht nach STAR',
  productAccessCseStar: 'CSE STAR',
  companyReportDone: 'fertig gestellt',
  companyReportDoneLong: 'Nachhaltigkeitsbericht fertig gestellt',
  companyReportDoneOnDate: 'Fertig gestellt am',
  continueCompanyReport: 'Nachhaltigkeitsbericht nach STAR fortsetzen',
  setCompanyReportDone: 'fertig stellen',
  startCompanyReport: 'Nachhaltigkeitsbericht nach STAR starten',
  setCompanyReportDonePromptContent:
    '<p>Sind Sie sicher, dass Sie den Bericht als fertig stellen wollen?</p><p>Sie können danach ein neuen Bericht starten. Ihre Antworten können Sie weiterhin zu jeder Zeit ändern.</p>',
  setCompanyReportDonePromptHeader: 'Bericht fertig stellen',
  reportTextHint:
    'Nachdem Sie das STAR zur Audit freigegeben haben, können Sie nur den Text für den Nachhaltigkeitsbericht ändern.',
  fileMissing: 'Die Datei konnte nicht gefunden werden',
  generalOk: 'Ok',
  fileTooBig: 'Die ausgewählte Datei ist zu groß. Bitte wählen Sie eine Datei kleiner als 2MB.',
  newVersionNoticeHeader: 'Eine neue Version ist verfügbar!',
  newVersionNoticeContent: 'Die neue Version steht nach dem Neuladen der Seite automatisch für Sie bereit.',
  newVersionNoticeButton: 'jetzt neu laden',
  generalSettings: 'Allgemein',
  starStatus: 'STAR Status',
  yearStartedFilter: 'Gestartet im Jahr',
  filters: 'Verfeinerungen',
  all: 'Alle',
  auditNotesHeader: 'Notizen für Audit',
  savingAuditNotes: 'Notizen werden automatisch gespeichert',
  importantQuestion: 'Schwerpunkt Frage',
  importantQuestionHint: 'Setzen Sie die Frage als Schwerpunkt, wenn zukünftige Audits besonders darauf achten sollen',
  importantQuestionInfo: 'Diese Frage wurde als Schwerpunkt gesetzt, diese Frage wird ausführlicher behandelt',
  questionId: 'Frage-ID',
  questionIdHint:
    'Die Frage-ID ist die eindeutige Identifikationsnummer der Frage, unabhängig von der Art des Unternehmens oder der CSE-Version',
  questionIndexHeader: 'Frageverzeichnis',
  toQuestionsIndex: 'zu Frageverzeichnis',
  questionSortHint: 'Position der Frage in dem Audit, abhängig von Art des Unternehmens und der CSE-Version',
  confirmQuestionsIndexBackContent:
    'Sie haben Änderungen, die noch nicht gespeichert wurden. Sind Sie sicher, dass Sie zurück gehen wollen?',
  confirmQuestionsIndexBackHeader: 'Änderungen noch nicht gespeichert',
  deleteQuestionIndexChanges: 'Änderungen verwerfen und zurück gehen',
  confirmQuestionsCancelContent: 'Sind Sie sicher, dass Sie abbrechen wollen? Alle Änderungen werden verworfen.',
  confirmQuestionsCancelHeader: 'Änderungen verwerfen',
  cancelQuestionsIndexChanges: 'Änderungen verwerfen',
  questionNavigationNotRelevant: 'Diese Frage ist für dieses Audit nicht relevant',
  questionNavigatorShowAll: 'Nicht relevante Fragen zusätzlich anzeigen',
  collapseText: 'Text einklappen',
  unCollapseText: 'Text ausklappen',
  previousQuestion: 'vorherige Frage',
  backToOverview: 'zurück zur Übersicht',
};
