import {
  Button,
  Form,
  FormGroup,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import {
  CompanyType,
  ProductAccess,
  SubCompany,
  UserAccountCompany,
} from "../../../Swagger/api";
import React, { useEffect, useState } from "react";

import { CompanyTypeLegend } from "./companyTypeLegend";
import { SubCompanyView } from "../subCompanyView/subCompanyView";
import { countriesListDe } from "../../../constants";
import { localize } from "../../../Localization/localize";
import { getApis } from "../../../Services/webservice";

export const CompanyProfile = () => {
  const [account, setAccount] = useState<UserAccountCompany>({
    fullTimePositions: 0,
    userLoginId: -1,
    productAccess: ProductAccess.CseStar,
  });
  const [availableCompanyTypes, setAvailableCompanyTypes] =
    useState<CompanyType[]>();
  const [companyTypes, setCompanyTypes] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [subCompanies, setSubCompanies] = useState<SubCompany[]>([]);
  const [showLegend, setShowLegend] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { companyProfileClient, subCompaniesClient } = { ...getApis() };

  useEffect(() => {
    setHasError(false);

    (async () => {
      const myProfile = await companyProfileClient.getMyProfile();

      setAccount(myProfile);

      setCompanyTypes((myProfile.companyTypes || []).map((x) => x.id!));

      setLoading(false);
    })();

    (async () => {
      const data = await companyProfileClient.getAllCompanyTypes();
      setAvailableCompanyTypes(data);
    })();

    loadSubCompanies();
  }, []);

  const loadSubCompanies = async () => {
    const data = await subCompaniesClient.getCompanySubCompanies();
    setSubCompanies(data);
  };

  const onSave = async () => {
    setLoading(true);

    try {
      await companyProfileClient.setUserAccount(account);

      await companyProfileClient.assignCompanyTypes(companyTypes);
    } catch (ex) {
      setHasError(true);
    }

    setLoading(false);
  };

  const addEmptySubCompany = () => {
    const copy = [...subCompanies];
    copy.push({
      id: -1,
      parentCompanyId: account.userLogin?.id as number,
    });
    setSubCompanies(copy);
  };

  return (
    <section>
      <Button
        primary
        icon
        labelPosition="right"
        floated="right"
        loading={loading}
        onClick={onSave}
      >
        <Icon name="save" />
        {localize.saveLowerCase}
      </Button>
      <Header size="huge">{localize.myProfile}</Header>
      <Form>
        <Form.Input
          label={localize.emailAddress}
          type="email"
          value={account.userLogin?.emailAddress || ""}
          disabled
        />

        <Header>{localize.contactPerson}</Header>
        <Form.Group widths="equal">
          <Form.Input
            label={localize.firstName}
            type="text"
            value={account.firstName || ""}
            onChange={(_e, { value }) =>
              setAccount({ ...account, firstName: value })
            }
          />
          <Form.Input
            label={localize.lastName}
            type="text"
            value={account.lastName || ""}
            onChange={(_e, { value }) =>
              setAccount({ ...account, lastName: value })
            }
          />
        </Form.Group>
        <Form.Input
          label={localize.myCompanyWithLegalForm}
          type="text"
          value={account.companyTitle || ""}
          onChange={(_e, { value }) =>
            setAccount({ ...account, companyTitle: value })
          }
        />
        <FormGroup>
          <Form.Select
            width="14"
            label={localize.companyType}
            value={companyTypes || []}
            options={
              availableCompanyTypes
                ? availableCompanyTypes.map((c) => {
                    return {
                      text: c.title,
                      value: c.id,
                      disabled: c.deactivated,
                    };
                  })
                : []
            }
            multiple
            loading={!availableCompanyTypes}
            onChange={(_e, { value }) => setCompanyTypes(value as number[])}
          />
          <Form.Field width="2">
            <label>{localize.legend}</label>
            <Button icon fluid onClick={() => setShowLegend(true)}>
              <Icon name="info"></Icon>
            </Button>
          </Form.Field>
        </FormGroup>

        <Header>{localize.contactInfo}</Header>
        <Form.Input
          label={localize.streetAndHouseNumber}
          type="text"
          value={account.street || ""}
          onChange={(_e, { value }) =>
            setAccount({ ...account, street: value })
          }
        />
        <Form.Group>
          <Form.Input
            width="4"
            label={localize.postCode}
            type="text"
            value={account.postcode || ""}
            onChange={(_e, { value }) =>
              setAccount({ ...account, postcode: value })
            }
          />
          <Form.Input
            width="6"
            label={localize.city}
            type="text"
            value={account.city || ""}
            onChange={(_e, { value }) =>
              setAccount({ ...account, city: value })
            }
          />
          <Form.Select
            width="6"
            label={localize.state}
            type="text"
            value={account.state || ""}
            options={countriesListDe.map((x) => ({
              value: x.name,
              text: x.name,
              flag: x.alpha2,
            }))}
            onChange={(_e, { value }) =>
              setAccount({ ...account, state: value as string })
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label={localize.telephoneNumber}
            type="text"
            value={account.telephoneNumber || ""}
            onChange={(_e, { value }) =>
              setAccount({ ...account, telephoneNumber: value })
            }
          />
          <Form.Input
            label={localize.website}
            type="text"
            value={account.webSite || ""}
            onChange={(_e, { value }) =>
              setAccount({ ...account, webSite: value })
            }
          />
        </Form.Group>

        <Header>{localize.fulltimePositions}</Header>
        <Form.Input
          value={account.fullTimePositions || 0}
          type="number"
          onChange={(_e, { value }) =>
            setAccount({ ...account, fullTimePositions: Number(value) })
          }
        />
        <Message
          error
          visible={hasError}
          content={localize.generalErrorMessage}
          header={localize.generalErrorHeader}
        />
      </Form>
      {subCompanies.map((subCompany) => (
        <SubCompanyView
          subCompany={subCompany}
          availableCompanyTypes={availableCompanyTypes || []}
          key={subCompany.id}
          onChanged={loadSubCompanies}
        />
      ))}
      {subCompanies.filter((x) => x.id && x.id < 0).length === 0 ||
      subCompanies.length === 0 ? (
        <Button
          floated="right"
          icon
          labelPosition="right"
          primary
          onClick={addEmptySubCompany}
        >
          <Icon name="home" />
          {localize.newSubCompany}
        </Button>
      ) : null}
      {showLegend ? (
        <CompanyTypeLegend onClose={() => setShowLegend(false)} />
      ) : null}
    </section>
  );
};
