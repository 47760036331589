import { Button, Divider, Icon, Message, Modal } from "semantic-ui-react";
import React, { useState } from "react";

import { UserLogin } from "../../Swagger/api";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export interface IResetPasswordUser {
  userLogin: UserLogin;
  onDone: () => void;
  onCancel: () => void;
}

export const ResetUserPassword = (props: IResetPasswordUser) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { userAccountsClient } = getApis();

  const { userLogin, onDone, onCancel } = { ...props };

  const resetPassword = async () => {
    setLoading(true);

    try {
      await userAccountsClient.requestResetToken({
        emailAddress: userLogin.emailAddress,
      });

      setLoading(false);
      onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Modal dimmer="blurring" open>
      <Modal.Header>{localize.passwordReset}</Modal.Header>
      <Modal.Content>
        {localize.formatString(
          localize.userPasswordResetModalContent,
          userLogin.emailAddress || ""
        )}
        <Divider horizontal hidden />
        {hasError ? (
          <Message
            error
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} floated="left">
          {localize.cancelLowerCase}
        </Button>
        <Button
          primary
          icon
          labelPosition="right"
          onClick={resetPassword}
          loading={loading}
        >
          <Icon name="undo" />
          {localize.resetLowerCase}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
