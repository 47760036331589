import { GraphicAssignment, GraphicalResultCategory } from "../../Swagger/api";
import { Header, Icon, Segment } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { GraphicalDisplay } from "./graphicalDisplay";
import { Loading } from "../layout/loading";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export const GraphicalDisplayCompany = () => {
  const [assignments, setAssignments] = useState<GraphicAssignment[]>([]);
  const [results, setResults] = useState<GraphicalResultCategory[]>([]);
  const [loading, setLoading] = useState(true);

  const { graphicClient } = { ...getApis() };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const [resultData, assignmentsData] = await Promise.all([
      graphicClient.getGraphicResultData(),
      graphicClient.getGraphicAssignments(),
    ]);
    setResults(resultData);
    setAssignments(assignmentsData);
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  if (assignments.length === 0 || results.length === 0) {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="star outline"></Icon>
          {localize.noGraphicalDisplayAvailable}
        </Header>
      </Segment>
    );
  }

  return (
    <section className="dd-graphic-section">
      <article className="dd-graphic">
        <GraphicalDisplay assignments={assignments} results={results} />
      </article>
      <article className="dd-legend">
        {assignments.map((x) => (
          <div className="dd-item" key={x.partId}>
            {x.partId === 1 ? (
              <svg className="dd-legend-image" viewBox="0, 0, 100, 100">
                <polygon
                  style={{ fill: x.category?.color }}
                  points="50,3 97,45 75,97 25,97 3,45"
                  className="dd-center"
                ></polygon>
              </svg>
            ) : (
              <svg className="dd-legend-image" viewBox="0, 0, 100, 65">
                <polygon
                  style={{ fill: x.category?.color }}
                  points="3,3 97,32.5 3,65"
                  className="dd-part"
                ></polygon>
              </svg>
            )}
            <Header size="large">{x.category?.title}</Header>
          </div>
        ))}
      </article>
    </section>
  );
};
