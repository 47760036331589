import localForage from 'localforage';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Textarea from 'react-textarea-autosize';
import { Button, Container, Divider, Form, Header, Icon, Message } from 'semantic-ui-react';
import { LoginContext } from '../../../Contexts/loginContext';
import { localize } from '../../../Localization/localize';
import { getApis } from '../../../Services/webservice';
import {
  AuditResult,
  AuditType,
  CompaniesStarAuditorOverview,
  ResolvedProblem,
  Star,
  StarAuditorOverViewCompanyAnswer,
  StarAuditorOverviewItem,
  StarAuditorOverviewPreviousItem,
  StarControlData,
  UserAccountCompany,
} from '../../../Swagger/api';
import { companiesDbKey, getCurrentStarFromDb, starDbKey, StarsDb } from '../../auditor/auditorOverview';
import { history, paths } from '../../layout/layout';
import { PreviousCorrections } from './previousCorrections';
import { PreviousImprovements } from './previousImprovements';
import { getTimeForInput } from './startAudit';

interface IControlData {
  location: {
    state: {
      requireFinishDate: boolean | undefined;
    };
  };
}

export const ControlData = (props: IControlData) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [starControlData, setStarControlData] = useState<StarControlData>();
  const [auditStartedDate, setAuditStartDate] = useState<string>('');
  const [auditStartedTime, setAuditStartTime] = useState<string>('');
  const [auditDoneDate, setAuditDoneDate] = useState<string>('');
  const [auditDoneTime, setAuditDoneTime] = useState<string>('');
  const [company, setCompany] = useState<UserAccountCompany>();
  const [previousStar, setPreviousStar] = useState<StarAuditorOverviewPreviousItem>();
  const [showPreviousCorrections, setShowPreviousCorrections] = useState(false);
  const [showPreviousImprovements, setShowPreviousImprovements] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [requireFinishDate, setRequireFinishDate] = useState(props.location?.state?.requireFinishDate);
  const [resolvedProblems, setResolvedProblems] = useState<ResolvedProblem[]>([]);
  const [answers, setAnswers] = useState<StarAuditorOverViewCompanyAnswer[]>([]);

  const loginContext = useContext(LoginContext);
  const params = useParams<{ starId: string }>();
  const starId = Number(params.starId);

  const { starControlDataClient } = { ...getApis() };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (hasError || hasWarning) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [hasError, hasError]);

  const loadData = async () => {
    const currentStar = await getCurrentStarFromDb(starId);
    const companiesDb = await localForage.getItem<CompaniesStarAuditorOverview>(companiesDbKey);

    if (!currentStar) {
      setHasError(true);
      return;
    }

    if (currentStar.finished) {
      setRequireFinishDate(true);
    }

    const currentCompany = companiesDb?.companies?.find((x) => x.userLoginId == currentStar.companyId);

    setCompany(currentCompany);

    let currentControlData = currentStar.starControlData;
    if (!currentControlData) {
      currentControlData = {
        auditType: 0,
        id: 0,
        immediateProcessing: false,
        previousCorrectionsNotRequested: true,
        previousImprovementsNotRequested: true,
      };
    }

    setStarControlData(currentControlData);

    setPreviousStar(currentStar.previousStar);

    if (currentStar.auditStarted) {
      const date = new Date(currentStar.auditStarted);
      setAuditStartDate(date.toISOString().split('T')[0]);
      setAuditStartTime(getTimeForInput(date));
    }

    if (currentStar.auditFinished) {
      const date = new Date(currentStar.auditFinished);
      setAuditDoneDate(date.toISOString().split('T')[0]);
      setAuditDoneTime(getTimeForInput(date));
    }

    const resolvedProblemsData: ResolvedProblem[] = [];
    const existingResolvedProblems = currentStar.starControlData?.resolvedProblems || [];

    if (!currentStar.starControlData) {
      return;
    }

    const answersData =
      currentStar.starCompanyAnswers?.filter((x) => x?.starAuditorAnswer?.auditResult == AuditResult.NotCompleted) || [];
    setAnswers(answersData);
    for (let item of answersData) {
      const existing = existingResolvedProblems.find((x) => x.questionId == item.questionId);
      if (existing) {
        resolvedProblemsData.push(existing);
      } else {
        resolvedProblemsData.push({
          questionId: item.questionId,
          starControlDataId: currentStar.starControlData.id,
          comunication: false,
          documentation: false,
          observation: false,
          id: 0,
          content: '',
        });
      }
    }
    setResolvedProblems(resolvedProblemsData);
  };

  const saveControlData = async () => {
    setHasError(false);
    setHasWarning(false);
    if (
      starControlData &&
      (!starControlData.contactPersonName ||
        !starControlData.enviromentResponsibleName ||
        !(!requireFinishDate || (auditDoneDate && auditDoneTime)))
    ) {
      setHasWarning(true);
      return;
    } else {
      setHasWarning(false);
    }

    try {
      setLoading(true);
      if (starControlData) {
        starControlData.resolvedProblems = resolvedProblems;
      }

      const data: Star = {
        auditStarted:
          auditStartedDate && auditStartedTime ? (`${auditStartedDate}T${auditStartedTime}` as unknown as Date) : undefined,
        auditFinished: auditDoneDate && auditDoneTime ? (`${auditDoneDate}T${auditDoneTime}` as unknown as Date) : undefined,
        starControlData,
        id: starId,
        edited: new Date(), // value not used
        justForIso: false, // value not used
        started: new Date(), // value not used
        finished: false, // value not used
        justCompanyReport: false, // value not used
      };

      if (starControlData) {
        if (starControlData.auditType !== 3) {
          starControlData.otherAuditTypeName = '';
        }

        if (starControlData.previousCorrectionsDone || starControlData.previousCorrectionsNotRequested) {
          starControlData.previousCorrectionsNotDone = '';
        }

        if (starControlData.previousImprovementsDone || starControlData.previousImprovementsNotRequested) {
          starControlData.previousImprovementsNotDone = '';
        }
        if (!starControlData.immediateProcessing) {
          starControlData.immediateProcessingReason = '';
        }
      }

      const starsInDb = await localForage.getItem<StarsDb>(starDbKey);
      if (starsInDb?.updatedStarIds.indexOf(Number(starId)) === -1) {
        starsInDb.updatedStarIds.push(Number(starId));
      }
      const currentStar = starsInDb?.stars.find((x) => x.id === Number(starId)) as StarAuditorOverviewItem;
      currentStar.auditStarted = data.auditStarted;
      currentStar.auditFinished = data.auditFinished;
      currentStar.starControlData = {
        ...(currentStar.starControlData! ?? {}),
        ...starControlData,
      };
      await localForage.setItem(starDbKey, starsInDb);

      if (navigator.onLine) {
        await starControlDataClient.saveControlData(data);
      }

      history.push(paths.auditor.overview);
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  const getResolvedProblemQuestion = (questionId: number) => {
    return answers.find((x) => x.questionId == questionId)?.question?.content || '-';
  };

  const getResolvedProblemComment = (questionId: number) => {
    return answers.find((x) => x.questionId == questionId)?.starAuditorAnswer?.auditReportText || '-';
  };

  const setResolvedProblem = (questionId: number, data: ResolvedProblem) => {
    const existing = resolvedProblems.find((x) => x.questionId == questionId);
    if (!existing) {
      return;
    }
    const copy = [...resolvedProblems];
    copy.splice(resolvedProblems.indexOf(existing), 1, data);
    setResolvedProblems(copy);
  };

  return (
    <React.Fragment>
      <Header size="large">{localize.auditControlDataHeader}</Header>
      <Form>
        <Header>{localize.companyData}</Header>
        <Form.Field>
          <Form.Field>
            <label>{localize.companyWithLegalForm}</label>
            {company ? company.companyTitle || localize.noData : localize.noData}
          </Form.Field>
          <Form.Input
            label={localize.ceoName}
            value={starControlData ? starControlData.ceoName || '' : ''}
            onChange={(_event, { value }) => setStarControlData({ ...starControlData!, ceoName: value })}
          ></Form.Input>
          <Form.Group widths="equal">
            <Form.Input
              label={localize.contactPersonName}
              value={starControlData ? starControlData.contactPersonName || '' : ''}
              onChange={(_event, { value }) =>
                setStarControlData({
                  ...starControlData!,
                  contactPersonName: value,
                })
              }
              required
            ></Form.Input>
            <Form.Input
              label={localize.enviromentResponsibleName}
              value={starControlData ? starControlData.enviromentResponsibleName || '' : ''}
              onChange={(_event, { value }) =>
                setStarControlData({
                  ...starControlData!,
                  enviromentResponsibleName: value,
                })
              }
              required
            ></Form.Input>
          </Form.Group>

          <Form.Field>
            <label>{localize.address}</label>
            {company
              ? [company.street, company.postcode, company.city, company.state].filter((x) => x).join(', ')
              : localize.noData}
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{localize.telephoneNumber}</label>
              {company ? company.telephoneNumber || localize.noData : localize.noData}
            </Form.Field>
            <Form.Field>
              <label htmlFor="">{localize.emailAddress}</label>
              {company && company.userLogin ? company.userLogin.emailAddress || localize.noData : localize.noData}
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label htmlFor="">{localize.fulltimePositions}</label>
            {company && company.fullTimePositions !== undefined ? company.fullTimePositions : localize.noData}
          </Form.Field>
          <Header>{localize.auditData}</Header>
          <Form.Field>
            <label>{localize.auditorName}</label>
            {loginContext?.login?.displayName || localize.noData}
          </Form.Field>
          <Form.Input
            label={localize.accompanyingPersonName}
            value={starControlData ? starControlData.accompanyingPersonName || '' : ''}
            onChange={(_event, { value }) =>
              setStarControlData({
                ...starControlData!,
                accompanyingPersonName: value,
              })
            }
          ></Form.Input>
          <Form.Group>
            <Form.Input
              width="5"
              type="date"
              label={localize.auditStartedDate}
              value={auditStartedDate}
              onChange={(_event, { value }) => setAuditStartDate(value)}
            ></Form.Input>
            <Form.Input
              width="3"
              type="time"
              label={localize.fromLowerCase}
              value={auditStartedTime}
              onChange={(_event, { value }) => setAuditStartTime(value)}
            ></Form.Input>
            <Form.Input
              width="8"
              type="date"
              label={localize.lastAuditDate}
              value={starControlData?.lastAuditDate ? new Date(starControlData.lastAuditDate).toISOString().split('T')[0] : ''}
              onChange={(_event, { value }) =>
                setStarControlData({
                  ...starControlData!,
                  lastAuditDate: new Date(value),
                })
              }
            ></Form.Input>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Select
              options={[
                { value: 0, text: localize.auditTypeFirstTime },
                { value: 1, text: localize.auditTypeInspection },
                { value: 2, text: localize.auditTypeRecertification },
                { value: 3, text: localize.auditTypeOther },
              ]}
              label={localize.auditType}
              value={starControlData?.auditType}
              onChange={(_event, { value }) =>
                setStarControlData({
                  ...starControlData!,
                  auditType: value as AuditType,
                })
              }
            ></Form.Select>
            <Form.Input
              label={localize.otherAuditTypeName}
              disabled={starControlData?.auditType !== 3}
              value={starControlData?.otherAuditTypeName || ''}
              onChange={(_event, { value }) =>
                setStarControlData({
                  ...starControlData!,
                  otherAuditTypeName: value,
                })
              }
            ></Form.Input>
          </Form.Group>
          <Form.Field>
            <label>
              {localize.previousCorrectionsDone}
              <Button
                basic
                size="mini"
                compact
                className="dd-see-button"
                disabled={!previousStar}
                onClick={() => setShowPreviousCorrections(true)}
              >
                {localize.seePreviousCorrections}
              </Button>
            </label>
          </Form.Field>
          <Form.Checkbox
            label={localize.previousCorrectionsNotRequested}
            checked={starControlData?.previousCorrectionsNotRequested}
            onChange={(_, { checked }) =>
              setStarControlData({
                ...starControlData!,
                previousCorrectionsNotRequested: checked ?? false,
                previousCorrectionsDone: undefined,
              })
            }
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.yes}
            checked={starControlData?.previousCorrectionsDone === true}
            onChange={(_, { checked }) =>
              setStarControlData({
                ...starControlData!,
                previousCorrectionsDone: checked ?? true,
                previousCorrectionsNotRequested: false,
              })
            }
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.no}
            checked={starControlData?.previousCorrectionsDone === false}
            onChange={(_, { checked }) =>
              setStarControlData({
                ...starControlData!,
                previousCorrectionsDone: !checked ?? false,
                previousCorrectionsNotRequested: false,
              })
            }
          ></Form.Checkbox>
          <Form.Field disabled={starControlData?.previousCorrectionsDone !== false}>
            <label>{localize.previousCorrectionsNotDone}</label>
            <Textarea
              maxRows={10}
              disabled={starControlData?.previousCorrectionsDone !== false}
              value={starControlData?.previousCorrectionsNotDone || ''}
              onChange={(event) =>
                setStarControlData({
                  ...starControlData!,
                  previousCorrectionsNotDone: event.currentTarget!.value,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>
              {localize.previousImprovementsDone}
              <Button
                basic
                size="mini"
                compact
                disabled={!previousStar}
                className="dd-see-button"
                onClick={() => setShowPreviousImprovements(true)}
              >
                {localize.seePreviousImprovements}
              </Button>
            </label>
          </Form.Field>
          <Form.Checkbox
            label={localize.previousImprovementsNotRequested}
            checked={starControlData?.previousImprovementsNotRequested}
            onChange={(_, { checked }) =>
              setStarControlData({
                ...starControlData!,
                previousImprovementsNotRequested: checked ?? false,
                previousImprovementsDone: undefined,
              })
            }
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.yes}
            checked={starControlData?.previousImprovementsDone === true}
            onChange={(_, { checked }) =>
              setStarControlData({
                ...starControlData!,
                previousImprovementsDone: checked ?? true,
                previousImprovementsNotRequested: false,
              })
            }
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.no}
            checked={starControlData?.previousImprovementsDone === false}
            onChange={(_, { checked }) =>
              setStarControlData({
                ...starControlData!,
                previousImprovementsDone: !checked ?? false,
                previousImprovementsNotRequested: false,
              })
            }
          ></Form.Checkbox>
          <Form.Field disabled={starControlData?.previousImprovementsDone !== false}>
            <label>{localize.previousImprovementsNotDone}</label>
            <Textarea
              maxRows={10}
              disabled={starControlData?.previousImprovementsDone !== false}
              value={starControlData?.previousImprovementsNotDone || ''}
              onChange={(event) =>
                setStarControlData({
                  ...starControlData!,
                  previousImprovementsNotDone: event.currentTarget?.value || '',
                })
              }
            />
          </Form.Field>
          <Form.Group>
            <Form.Input
              width="5"
              type="date"
              label={localize.auditDoneDate}
              value={auditDoneDate}
              onChange={(_event, { value }) => setAuditDoneDate(value)}
              required={requireFinishDate}
            ></Form.Input>
            <Form.Input
              width="3"
              type="time"
              label={localize.untilLowerCase}
              value={auditDoneTime}
              onChange={(_event, { value }) => setAuditDoneTime(value)}
              required={requireFinishDate}
            ></Form.Input>
          </Form.Group>
          <Form.Field>
            <label>{localize.notesForNextAudit}</label>
            <Textarea
              maxRows={10}
              value={starControlData ? starControlData.notesForNextAudit || '' : ''}
              onChange={(event) =>
                setStarControlData({
                  ...starControlData!,
                  notesForNextAudit: event.currentTarget!.value,
                })
              }
            />
          </Form.Field>
          <Form.Field required>
            <label>{localize.immediateProcessing}</label>
          </Form.Field>
          <Form.Checkbox
            label={localize.yes}
            checked={
              starControlData && starControlData.immediateProcessing !== undefined ? starControlData.immediateProcessing : false
            }
            onChange={() =>
              setStarControlData({
                ...starControlData!,
                immediateProcessing: true,
              })
            }
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.no}
            checked={
              starControlData && starControlData.immediateProcessing !== undefined
                ? !starControlData.immediateProcessing
                : false
            }
            onChange={() =>
              setStarControlData({
                ...starControlData!,
                immediateProcessing: false,
              })
            }
          ></Form.Checkbox>
          <Form.Field
            disabled={
              starControlData && starControlData.immediateProcessing !== undefined
                ? !starControlData.immediateProcessing
                : false
            }
          >
            <label>{localize.immediateProcessingReason}</label>
            <Textarea
              disabled={
                starControlData && starControlData.immediateProcessing !== undefined
                  ? !starControlData.immediateProcessing
                  : false
              }
              maxRows={10}
              value={starControlData ? starControlData.immediateProcessingReason || '' : ''}
              onChange={(event) =>
                setStarControlData({
                  ...starControlData!,
                  immediateProcessingReason: event.currentTarget!.value,
                })
              }
            />
          </Form.Field>
          {answers.length != 0 && requireFinishDate && (
            <>
              <Divider horizontal hidden />
              <Header size="large">{localize.notesForLaterCorrections}</Header>
              {resolvedProblems.map((item) => (
                <React.Fragment key={item.questionId}>
                  <Form.Field>
                    <Header>{getResolvedProblemQuestion(item.questionId)}</Header>
                  </Form.Field>
                  <Form.Field>
                    <label>{localize.auditorComment}</label>
                    {getResolvedProblemComment(item.questionId)}
                  </Form.Field>
                  <Form.Field>
                    <label>{localize.auditMethod}</label>
                  </Form.Field>
                  <Form.Group widths="5">
                    <Form.Checkbox
                      label={localize.auditMethodObservation}
                      checked={item.observation}
                      onChange={(_e, { checked }) =>
                        setResolvedProblem(item.questionId, {
                          ...item,
                          observation: !!checked,
                        })
                      }
                    />
                    <Form.Checkbox
                      label={localize.auditMethodCommunication}
                      checked={item.comunication}
                      onChange={(_e, { checked }) =>
                        setResolvedProblem(item.questionId, {
                          ...item,
                          comunication: !!checked,
                        })
                      }
                    />
                    <Form.Checkbox
                      label={localize.auditMethodDocument}
                      checked={item.documentation}
                      onChange={(_e, { checked }) =>
                        setResolvedProblem(item.questionId, {
                          ...item,
                          documentation: !!checked,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Field>
                    <label>{localize.resolvedProblemState}</label>
                    <Textarea
                      maxRows={10}
                      value={item.content}
                      onChange={(event) =>
                        setResolvedProblem(item.questionId, {
                          ...item,
                          content: event.currentTarget?.value,
                        })
                      }
                    />
                  </Form.Field>
                </React.Fragment>
              ))}
            </>
          )}
          <Container textAlign="right" fluid>
            <Button primary loading={loading} icon labelPosition="right" onClick={() => saveControlData()}>
              <Icon name="save"></Icon>
              {localize.saveLowerCase}
            </Button>
          </Container>
        </Form.Field>

        <Message error visible={hasError} content={localize.generalErrorMessage} header={localize.generalErrorHeader} />
        <Message
          warning
          visible={hasWarning}
          content={localize.generalFieldWarning}
          header={localize.generalFieldWarningHeader}
        />
      </Form>
      {showPreviousCorrections && previousStar && (
        <PreviousCorrections star={previousStar} onClose={() => setShowPreviousCorrections(false)}></PreviousCorrections>
      )}
      {showPreviousImprovements && previousStar && (
        <PreviousImprovements star={previousStar} onClose={() => setShowPreviousImprovements(false)}></PreviousImprovements>
      )}
    </React.Fragment>
  );
};
