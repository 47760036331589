import { Dropdown, Icon, Menu, Search, Segment } from 'semantic-ui-react';
import React, { Fragment, useEffect, useState } from 'react';
import { history, paths } from '../../layout/layout';

import { Question } from '../../../Swagger/api';
import { QuestionAspects } from '../questionAspects';
import { QuestionCategories } from '../questionCategeories';
import { QuestionCrossReferences } from './questionCrossReferences/questionCrossReferences';
import { QuestionDelete } from './questionDelete';
import { QuestionItem } from './questionItem';
import { QuestionSort } from './questionSort/questionSort';
import { localize } from '../../../Localization/localize';
import { getApis } from '../../../Services/webservice';
import { IFilterState, useFilterState } from '../../../hooks/filter-state-hook';
import { OverviewItemPlaceholder } from '../../company/overview/overviewItemPlaceholder';

const initialFilters: IFilterState = {
  searchQuery: { name: undefined, selectedValue: '' },
  categories: { name: localize.categories, selectedValue: -1 },
  companyTypes: { name: localize.companyTypes, selectedValue: -1 },
};

export const AdminQuestions = () => {
  const [items, setItems] = useState<Question[]>([]);
  const [filteredItems, setFilteredItems] = useState<Question[]>([]);
  const [filters, setFilters] = useFilterState('admin-questions-overview', initialFilters);
  const [selectedQuestionForDelete, setSelectedQuestionForDelete] = useState<Question>();
  const [sortOpen, setSortOpen] = useState(false);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [aspectsOpen, setAspectsOpen] = useState(false);
  const [crossReferencesOpen, setCrossReferencesOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const { questionClient } = { ...getApis() };

  useEffect(() => {
    loadQuestions();
  }, []);

  useEffect(() => {
    let data = [...items];

    if (filters.categories.selectedValue !== -1) {
      data = data.filter((x) => x.questionCategory?.title === filters.categories.selectedValue);
    }

    if (filters.companyTypes.selectedValue !== -1) {
      data = data.filter((x) => x.companyTypeMaps?.some((x) => x.companyType?.title === filters.companyTypes.selectedValue));
    }

    if (filters.searchQuery.selectedValue !== -1) {
      const filterValue = (filters.searchQuery.selectedValue as string).toLowerCase();
      data = data.filter((x) => x.content?.toLowerCase().indexOf(filterValue) !== -1);
    }

    setFilteredItems(data);
  }, [items, filters]);

  const loadQuestions = async () => {
    const questionsData = await questionClient.getAllQuestionsForOverview();
    setItems(questionsData);
    setLoading(false);
  };

  const getFilterOptions = (filterKey: string) => {
    if (filterKey === 'categories') {
      const categories: string[] = [];
      const uniqueCategories = items
        .filter((x) => x.questionCategory)
        .map((x) => x.questionCategory?.title || '-')
        .reduce((a, c) => {
          if (a.indexOf(c) === -1) {
            return [...a, c];
          } else {
            return a;
          }
        }, categories);

      uniqueCategories.sort((a, b) => (a > b ? 1 : -1));
      return [
        { text: localize.all, value: -1, key: 0 },
        ...uniqueCategories.map((y) => ({
          text: y,
          value: y,
          key: y,
        })),
      ];
    }

    if (filterKey === 'companyTypes') {
      const companyTypes: string[] = [];
      const uniqueCompanyTypes = items
        .filter((x) => x.companyTypeMaps)
        .map((x) => x.companyTypeMaps)
        .reduce((a, c) => {
          if (!c) {
            return a;
          }

          c.filter((x) => x.companyType?.title).map((x) => {
            if (a.indexOf(x.companyType!.title!) === -1) {
              a.push(x.companyType!.title!);
            }
          });

          return a;
        }, companyTypes);

      uniqueCompanyTypes.sort((a, b) => (a > b ? 1 : -1));
      return [
        { text: localize.all, value: -1, key: 0 },
        ...uniqueCompanyTypes.map((y) => ({
          text: y,
          value: y,
          key: y,
        })),
      ];
    }
  };

  if (sortOpen) {
    return (
      <QuestionSort
        onCancel={() => setSortOpen(false)}
        onDone={() => {
          setSortOpen(false);
          loadQuestions();
        }}
        questionsData={items}
      />
    );
  }

  return (
    <React.Fragment>
      <Menu icon="labeled">
        <Menu.Item
          onClick={() => {
            history.push('/admin/question/');
          }}
        >
          <Icon name="question" />
          {localize.questionCreate}
        </Menu.Item>
        <Menu.Item onClick={() => setSortOpen(true)}>
          <Icon name="exchange" rotated="clockwise" />
          {localize.sortUpperCase}
        </Menu.Item>
        <Menu.Item onClick={() => setCrossReferencesOpen(true)}>
          <Icon name="shuffle" />
          {localize.crossreferences}
        </Menu.Item>
        <Menu.Item onClick={() => setCategoriesOpen(true)}>
          <Icon name="book" />
          {localize.categories}
        </Menu.Item>
        <Menu.Item onClick={() => setAspectsOpen(true)}>
          <Icon name="archive" />
          {localize.aspects}
        </Menu.Item>
        <Menu.Item onClick={() => history.push(paths.admin.contentVersions)}>
          <Icon name="list ol" />
          {localize.openContentVersions}
        </Menu.Item>
      </Menu>
      {loading ? (
        <OverviewItemPlaceholder />
      ) : (
        <>
          <Search
            onSearchChange={(_event, { value }) =>
              setFilters((p) => ({ ...p, searchQuery: { ...p.searchQuery, selectedValue: value as string } }))
            }
            fluid
            defaultValue={filters.searchQuery.selectedValue as string}
            input={{ fluid: true, placeholder: localize.searchQuestions }}
            open={false}
          />
          <Segment>
            {Object.keys(filters)
              .filter((key) => filters[key].name)
              .map((key) => (
                <Fragment key={key}>
                  <strong>{filters[key].name}: </strong>
                  <Dropdown
                    inline
                    className="dd-filters"
                    options={getFilterOptions(key)}
                    value={filters[key].selectedValue}
                    onChange={(_, { value }) =>
                      setFilters((p) => ({ ...p, [key]: { name: p[key].name, selectedValue: value as any } }))
                    }
                  />
                </Fragment>
              ))}
          </Segment>
          {filteredItems.map((question) => (
            <QuestionItem
              key={question.id}
              crossReferences={(question.questionCrossReferenceMaps || []).map((x) =>
                x.questionCrossReference ? x.questionCrossReference.title || '' : ''
              )}
              companyTypes={(question.companyTypeMaps || []).map((x) => (x.companyType ? x.companyType.title || '' : ''))}
              onDelete={() => setSelectedQuestionForDelete(question)}
              onEdit={() => history.push(`/admin/question/${question.id}`)}
              title={question.content || ''}
              id={question.id}
            />
          ))}
        </>
      )}
      {selectedQuestionForDelete ? (
        <QuestionDelete
          question={selectedQuestionForDelete}
          onDone={() => {
            loadQuestions();
            setSelectedQuestionForDelete(undefined);
          }}
          onCancel={() => {
            setSelectedQuestionForDelete(undefined);
          }}
        />
      ) : null}
      {categoriesOpen ? (
        <QuestionCategories
          onCancel={() => setCategoriesOpen(false)}
          onDone={() => {
            loadQuestions();
            setCategoriesOpen(false);
          }}
        />
      ) : null}
      {aspectsOpen ? (
        <QuestionAspects
          onCancel={() => setAspectsOpen(false)}
          onDone={() => {
            loadQuestions();
            setAspectsOpen(false);
          }}
        />
      ) : null}
      {crossReferencesOpen ? (
        <QuestionCrossReferences
          onCancel={() => setCrossReferencesOpen(false)}
          onDone={() => {
            loadQuestions();
            setCrossReferencesOpen(false);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};
