import "./styles.sass";

import React, { useEffect, useState } from "react";

import { Layout } from "./Components/layout/layout";
import localForage from "localforage";

localForage.config({
  name: "CSE STAR",
  version: 1.0,
  storeName: "CSE STAR",
});

export const App = () => {
  const [, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener("offline", () => setOnline(navigator.onLine));
    window.addEventListener("online", () => setOnline(navigator.onLine));
  }, []);

  return <Layout />;
};
