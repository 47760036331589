import React from 'react';
import { localize } from '../../Localization/localize';
import { Header, Container } from 'semantic-ui-react';

export const CompanyHome = () => (
  <Container textAlign="center">
    <Header size="large">{localize.starStarHeader}</Header>
    Bitte klicken Sie{' '}
    <a href="https://gfaw.eu/cse-star-tool-videos/" target="_blank">
      hier
    </a>
    , um eine Anleitung mittels Videos zu erhalten.
  </Container>
);
