import { Icon } from 'semantic-ui-react';
import React from 'react';
import { localize } from '../../../Localization/localize';

interface IAnswerStateProps {
  currentState: number;
  onNotAnswered?: () => void;
  onInProgress?: () => void;
  onAnswered?: () => void;
}

export const AnswerState = (props: IAnswerStateProps) => {
  return (
    <div className="dd-answer-state">
      {props.onNotAnswered && <strong>{localize.pleaseChoose}:</strong>}
      <Icon
        name="circle"
        className={`dd-state-notAnswered ${props.currentState === 0 ? 'dd-selected' : ''}`}
        onClick={props.onNotAnswered}
        title={localize.answerStateNotAnswered}
      />
      <Icon
        name="circle"
        className={`dd-state-inProgress ${props.currentState === 1 ? 'dd-selected' : ''}`}
        onClick={props.onInProgress}
        title={localize.answerStateInProgress}
      />
      <Icon
        name="circle"
        className={`dd-state-answered ${props.currentState === 2 ? 'dd-selected' : ''}`}
        onClick={props.onAnswered}
        title={localize.answerStateAnswered}
      />
    </div>
  );
};
