import localForage from 'localforage';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  Button,
  Checkbox,
  Confirm,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Message,
  Popup,
} from 'semantic-ui-react';
import { localize } from '../../Localization/localize';
import { getApis } from '../../Services/webservice';
import {
  AuditResult,
  CompaniesStarAuditorOverview,
  QuestionCategory,
  StarAuditorAnswer,
  StarAuditorOverViewCompanyAnswer,
  StarAuditorOverviewItem,
} from '../../Swagger/api';
import { history, paths } from '../layout/layout';
import { companiesDbKey, getCurrentStarFromDb, starDbKey, StarsDb } from './auditorOverview';

export const QuestionsIndex = () => {
  const [company, setCompany] = useState<string>();
  const [star, setStar] = useState<StarAuditorOverviewItem>();
  const [hasError, setHasError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [answers, setAnswers] = useState<StarAuditorOverViewCompanyAnswer[]>([]);
  const [activeCategories, setActiveCategories] = useState<number[]>([1]);
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [changedAnswers, setChangedAnswers] = useState<number[]>([]);

  const { companyId, starId } = useParams<{ companyId: string; starId: string }>();

  const { starClient } = getApis();

  useEffect(() => {
    loadCompany();
    loadStar();
  }, []);

  const loadCompany = async () => {
    const companies = await localForage.getItem<CompaniesStarAuditorOverview>(companiesDbKey);
    const company = companies?.companies?.find((c) => c.userLoginId === Number(companyId));
    setCompany(company?.companyTitle || '');
  };

  const loadStar = async () => {
    const star = await getCurrentStarFromDb(Number(starId));
    setStar(star);
    const companyAnswers = star?.starCompanyAnswers?.length ? [...star.starCompanyAnswers] : [];
    setAnswers(companyAnswers);
  };

  const toggleCategory = (id: number) => {
    if (activeCategories.indexOf(id) === -1) {
      setActiveCategories([...activeCategories, id]);
    } else {
      setActiveCategories([...activeCategories.filter((x) => x !== id)]);
    }
  };

  const handleAnswerChange = async (companyAnswerId: number, important?: boolean, notRelevant?: boolean) => {
    setChangedAnswers((p) => [...p, companyAnswerId]);

    const data = [...answers];
    const companyAnswer = data.find((x) => x.id == companyAnswerId);
    if (!companyAnswer) {
      return;
    }

    if (!companyAnswer?.starAuditorAnswer) {
      companyAnswer.starAuditorAnswer = {
        auditResult: AuditResult.None,
        companyAnswerId: companyAnswerId,
        comunication: false,
        documentation: false,
        important: false,
        observation: false,
        id: 0,
        notRelevant: false,
      };
    }
    if (important !== undefined) {
      companyAnswer.starAuditorAnswer.important = important;
    }
    if (notRelevant !== undefined) {
      companyAnswer.starAuditorAnswer.notRelevant = notRelevant;
    }
    setAnswers(data);
  };

  const goBackConfirm = () => {
    if (changedAnswers.length) {
      setShowConfirmBack(true);
    } else {
      goBack();
    }
  };

  const goBack = () => {
    history.push(paths.auditor.overview);
  };

  const saveAnswers = async () => {
    setHasError(false);
    if (!changedAnswers.length) {
      return;
    }
    setSaving(true);

    try {
      if (navigator.onLine) {
        for (const answer of answers.filter((x) => changedAnswers.indexOf(x.id) !== -1).map((x) => x.starAuditorAnswer)) {
          if (!answer) {
            continue;
          }
          try {
            const savedAnswerId = await starClient.setStarAuditorAnswer(answer as StarAuditorAnswer);
            if (savedAnswerId != answer.companyAnswerId) {
              throw new Error('Answer from server does not match answer from user!');
            }
          } catch (ex) {
            console.error(ex);
            setHasError(true);
          }
        }
      }

      const starsInDb = await localForage.getItem<StarsDb>(starDbKey);
      const currentStar = starsInDb?.stars.find((x) => x.id === Number(starId));
      for (const answer of answers.filter((x) => changedAnswers.indexOf(x.id) !== -1).map((x) => x.starAuditorAnswer)) {
        if (!answer) {
          continue;
        }
        const companyAnswer = currentStar?.starCompanyAnswers?.find((x) => x.id === answer.companyAnswerId);
        if (companyAnswer) {
          companyAnswer.starAuditorAnswer = answer;

          if (starsInDb?.updatedStarIds.indexOf(Number(starId)) === -1) {
            starsInDb.updatedStarIds.push(Number(starId));
          }

          await localForage.setItem(starDbKey, starsInDb);
          setChangedAnswers((p) => p.filter((x) => x !== answer.companyAnswerId));
        } else {
          setHasError(true);
        }
      }
    } catch (ex) {
      console.error(ex);
      setHasError(true);
    }
    setSaving(false);
  };

  const questionCategories = useMemo(() => {
    if (!star) {
      return [];
    }
    const all = star?.starCompanyAnswers
      ?.map((x) => x.question?.questionAspect?.questionCategory)
      .filter((x) => x) as QuestionCategory[];
    const distinct = all.filter((x, i) => i === all.findIndex((y) => y.id === x.id));
    return distinct;
  }, [star]);

  return (
    <section>
      <Header size="huge">
        {localize.questionIndexHeader}
        <Header.Subheader>
          {company} - Audit{' '}
          {star?.auditStarted
            ? new Date(star.auditStarted).toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })
            : ''}
        </Header.Subheader>
      </Header>
      <Accordion>
        {questionCategories.map((category) => [
          <Accordion.Title
            index={category.id}
            active={activeCategories.indexOf(category.id) !== -1}
            onClick={() => toggleCategory(category.id)}
            key={`title_${category.id}`}
          >
            <Label
              size="large"
              className={`dd-category-label dd-dotdotdot ${category.color ? '' : 'dd-no-color'} dd-navigator`}
              style={{
                backgroundColor: category.color,
              }}
            >
              {category.title || ''}
            </Label>
          </Accordion.Title>,
          <Accordion.Content key={`content_${category.id}`} active={activeCategories.indexOf(category.id) !== -1}>
            <Grid stackable verticalAlign="middle">
              {answers
                .filter((x) => x.question?.questionAspect?.questionCategoryId === category.id)
                .map((answer) => (
                  <Grid.Row key={answer.questionId}>
                    <Grid.Column width={2}>
                      <Popup
                        content={localize.questionIdHint}
                        trigger={
                          <Label basic style={{ width: '100%', textAlign: 'center' }}>
                            {localize.questionId}: {answer.questionId}
                          </Label>
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Popup content={localize.questionSortHint} trigger={<span>{answer.question?.sort}</span>} />
                      {'. '}
                      {answer.question?.content || '-'}
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Checkbox
                        toggle
                        label={
                          <Popup
                            mouseEnterDelay={700}
                            content={localize.importantQuestionHint}
                            trigger={<label>{localize.importantQuestion}</label>}
                          ></Popup>
                        }
                        onChange={(_, { checked }) => handleAnswerChange(answer.id, !!checked, undefined)}
                        checked={answer.starAuditorAnswer?.important}
                      ></Checkbox>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Checkbox
                        toggle
                        label={
                          <Popup
                            mouseEnterDelay={700}
                            content={localize.notRelevantHint}
                            trigger={<label>{localize.notRelevant}</label>}
                          ></Popup>
                        }
                        onChange={(_, { checked }) => handleAnswerChange(answer.id, undefined, !!checked)}
                        checked={answer.starAuditorAnswer?.notRelevant}
                      ></Checkbox>
                    </Grid.Column>
                  </Grid.Row>
                ))}
            </Grid>
          </Accordion.Content>,
        ])}
      </Accordion>
      {hasError && <Message error header={localize.generalErrorHeader} content={localize.generalErrorMessage} />}
      <Divider horizontal />
      <Container fluid textAlign="right">
        <Button floated="left" icon labelPosition="left" onClick={goBackConfirm} disabled={saving}>
          <Icon name="arrow left" />
          {localize.backLowerCase}
        </Button>
        <Button icon labelPosition="right" onClick={() => setShowConfirmCancel(true)} disabled={saving}>
          <Icon name="cancel" />
          {localize.cancelLowerCase}
        </Button>
        <Button primary icon labelPosition="right" onClick={saveAnswers} loading={saving} disabled={saving}>
          <Icon name="save" />
          {localize.saveLowerCase}
        </Button>
      </Container>
      {showConfirmBack && (
        <Confirm
          open
          header={localize.confirmQuestionsIndexBackHeader}
          content={localize.confirmQuestionsIndexBackContent}
          confirmButton={<Button>{localize.cancelLowerCase}</Button>}
          cancelButton={
            <Button icon labelPosition="left">
              <Icon name="trash" />
              {localize.deleteQuestionIndexChanges}
            </Button>
          }
          onConfirm={() => setShowConfirmBack(false)}
          onCancel={() => goBack()}
        />
      )}
      {showConfirmCancel && (
        <Confirm
          open
          header={localize.confirmQuestionsCancelHeader}
          content={localize.confirmQuestionsCancelContent}
          confirmButton={<Button>{localize.backLowerCase}</Button>}
          cancelButton={
            <Button icon labelPosition="left">
              <Icon name="trash" />
              {localize.cancelQuestionsIndexChanges}
            </Button>
          }
          onConfirm={() => setShowConfirmCancel(false)}
          onCancel={() => goBack()}
        />
      )}
    </section>
  );
};
