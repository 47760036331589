import {
  AlignmentType,
  Document,
  HeadingLevel,
  ImportDotx,
  Media,
  Packer,
  PageNumberFormat,
  Paragraph,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import { CompaniesStarAuditorOverview, StarAuditorAnswer, StarAuditorOverviewItem, UserAccountCompany } from '../Swagger/api';
import {
  ICircleImages,
  addCircleImages,
  dateFormatWithDate,
  dateFormatWithTime,
  getAuditType,
  getCircleImagesData,
  getCommonHeader,
  getFooter,
  getImagesForAuditMethod,
  getSignatures,
  margins,
  tableCellBordersNoBorder,
} from './auditReport';
import { companiesDbKey, getCurrentStarFromDb } from '../Components/auditor/auditorOverview';

import localForage from 'localforage';
import { saveAs } from 'file-saver';

export const generateCorrectionsReport = async (
  starId: number,
  starData?: StarAuditorOverviewItem,
  company?: UserAccountCompany
) => {
  let star: StarAuditorOverviewItem;

  if (!starData) {
    const data = await getCurrentStarFromDb(starId);
    if (!data) {
      return;
    }
    star = data;
  } else {
    star = starData;
  }

  if (!star) {
    throw new Error('STAR not found');
  }

  if (!company) {
    const companies = await localForage.getItem<CompaniesStarAuditorOverview>(companiesDbKey);
    if (!companies) {
      return;
    }
    company = companies.companies?.find((x) => x.userLoginId === star.companyId);
  }

  const documentTemplate = (await (await fetch('/assets/wordTemplates/auditReportTemplate.dotx')).arrayBuffer()) as any; // Buffer
  const importer = new ImportDotx();
  const importedTemplate = await importer.extract(documentTemplate);

  const doc = new Document(undefined, { template: importedTemplate });

  const circleImagesData = await getCircleImagesData();
  const footerCircleImages = addCircleImages(doc, circleImagesData, 10);
  const answersCircleImages = addCircleImages(doc, circleImagesData, 30);

  const commonHeader = await getCommonHeader(doc);
  const commonFooter = getFooter(footerCircleImages, star, company);
  const firstPage = await getFirstPage(doc, star, company);

  doc.addSection({
    ...commonHeader,
    ...commonFooter,
    margins,
    properties: {
      pageNumberFormatType: PageNumberFormat.DECIMAL,
    },
    children: [...firstPage, ...getAuditData(star), ...getCorrections(star, answersCircleImages), ...getSignatures()],
  });

  Packer.toBlob(doc).then((b) =>
    saveAs(
      b,
      `Korrekturmaßnahmen Ergänzung ${company?.companyTitle || ''} ${
        star.auditFinished ? new Date(star.auditFinished).toLocaleDateString('de-DE', dateFormatWithDate) : ''
      }.docx`
    )
  );
};

const getFirstPage = async (doc: Document, starData: StarAuditorOverviewItem, company?: UserAccountCompany) => {
  const cseLogoData = await (await fetch('/assets/images/auditReportPdf/cseLogo.jpg')).arrayBuffer();
  const cseLogoImage = Media.addImage(doc, cseLogoData, 157, 221);
  return [
    new Paragraph({
      children: [new TextRun('Erfüllen von Korrekturmaßnahmen\n')],
      style: 'Documenttitle',
      spacing: { after: 0, before: 1800 },
      heading: HeadingLevel.TITLE,
    }),
    new Paragraph({
      children: [
        new TextRun(
          `Ergänzung zum CSE-Auditbericht${
            starData.auditFinished
              ? ` vom ${new Date(starData.auditFinished).toLocaleDateString('de-DE', dateFormatWithDate)}`
              : ''
          }`
        ),
      ],
      style: 'Documenttitle',
    }),
    new Paragraph({
      children: [new TextRun(company?.companyTitle || '')],
      style: 'Documentsubtitle',
    }),
    new Paragraph({
      children: [cseLogoImage],
      alignment: AlignmentType.CENTER,
      spacing: { after: 1320 },
    }),

    new Paragraph({
      children: [new TextRun('Betriebsdaten')],
      style: 'Heading1',
      heading: HeadingLevel.HEADING_1,
    }),
    new Paragraph({
      children: [new TextRun('Geschäftsführer*in')],
      style: 'Subheading',
    }),
    new Paragraph(starData.starControlData?.ceoName || '-'),
    new Paragraph({
      children: [new TextRun('Verantwortliche Person für das Audit')],
      style: 'Subheading',
    }),
    new Paragraph(starData.starControlData?.contactPersonName || '-'),
    new Paragraph({
      children: [new TextRun('Anschrift')],
      style: 'Subheading',
    }),
    new Paragraph([company?.street, company?.postcode, company?.state].filter((x) => x).join(', ') || '-'),
    new Paragraph({ children: [new TextRun('Telefon')], style: 'Subheading' }),
    new Paragraph(company?.telephoneNumber || '-'),
    new Paragraph({ children: [new TextRun('Email')], style: 'Subheading' }),
    new Paragraph(company?.userLogin?.emailAddress || '-'),
  ];
};

const getAuditData = (starData: StarAuditorOverviewItem) => {
  let result = [
    new Paragraph({
      children: [new TextRun('Auditdaten')],
      style: 'Heading1',
      pageBreakBefore: true,
    }),
    new Paragraph({
      children: [new TextRun('Name Auditor*in')],
      style: 'Subheading',
    }),
    new Paragraph(starData.auditorName || '-'),
    new Paragraph({
      children: [new TextRun('Datum Audits')],
      style: 'Subheading',
    }),
    new Paragraph(
      `${starData?.auditStarted ? new Date(starData.auditStarted).toLocaleString('de-DE', dateFormatWithTime) : '-'} bis ${
        starData?.auditFinished ? new Date(starData.auditFinished).toLocaleString('de-DE', dateFormatWithTime) : '-'
      }`
    ),
    new Paragraph({
      children: [new TextRun('Name verantwortliche Begleitperson')],
      style: 'Subheading',
    }),
    new Paragraph(starData.starControlData?.accompanyingPersonName || '-'),
    new Paragraph({
      children: [new TextRun('Datum letztes Audit')],
      style: 'Subheading',
    }),
    new Paragraph(
      starData?.starControlData?.lastAuditDate
        ? new Date(starData.starControlData.lastAuditDate).toLocaleDateString('de-DE', dateFormatWithDate)
        : '-'
    ),
    new Paragraph({
      children: [new TextRun('Art des Audits')],
      style: 'Subheading',
    }),
    new Paragraph(getAuditType(starData.starControlData?.auditType, starData.starControlData?.otherAuditTypeName) || '-'),
  ];

  return result;
};

const getCorrections = (starData: StarAuditorOverviewItem, circleImages: ICircleImages) => {
  const result: (Paragraph | Table)[] = [
    new Paragraph({
      children: [
        new TextRun({
          children: ['Die folgenden Abweichungen wurden mittels Erfüllung der Korrekturmaßnahmen behoben:'],
          bold: true,
          color: 'C00000',
        }),
      ],
    }),
  ];

  const answers = starData.starControlData?.resolvedProblems || [];

  for (let answer of answers) {
    const companyAnswer = starData.starCompanyAnswers?.find((x) => x.questionId === answer.questionId);
    const question = companyAnswer?.question;

    result.push(
      new Paragraph({
        children: [new TextRun({ children: [question?.content || '-'] })],
        style: 'NormalAccent',
      })
    );
    result.push(
      new Paragraph({
        children: [
          new TextRun({
            children: [companyAnswer?.starAuditorAnswer?.problems || '-'],
          }),
        ],
        style: 'NormalAccent',
      })
    );
    result.push(
      new Paragraph({
        children: [
          new TextRun({
            children: [companyAnswer?.starAuditorAnswer?.auditReportText || '-'],
          }),
        ],
        style: 'NormalAccent',
      })
    );

    result.push(new Paragraph('Stand der Umsetzung'));

    const table = new Table({
      margins: {
        marginUnitType: WidthType.DXA,
        left: 113,
        right: 113,
      },
      columnWidths: [7683, 2119],
      layout: TableLayoutType.FIXED,
      alignment: AlignmentType.CENTER,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun(question?.content || '-')],
                  style: 'Subheading',
                  spacing: { after: 0 },
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ children: ['Prüfmethode'], color: '1F3864' })],
                  spacing: { after: 0 },
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                ...(answer.content
                  ? answer.content
                      .split('\n')
                      .filter((x) => x)
                      .map((x) => new Paragraph(x))
                  : [new Paragraph('-')]),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [...getImagesForAuditMethod(answer as unknown as StarAuditorAnswer, circleImages)],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              margins: { top: 113 },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ children: ['Normverweis'], color: '1F3864' })],
                  style: 'Supersmall',
                }),
              ],
              borders: tableCellBordersNoBorder,
              columnSpan: 2,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      children: ['CSE-Standard: ', question?.cseStandardChapter || '-'],
                    }),
                  ],
                  style: 'Supersmall',
                }),
              ],
              borders: tableCellBordersNoBorder,
              columnSpan: 2,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      children: ['Normverweis zu ISO 14001: ', question?.isoNormReference || '-'],
                    }),
                  ],
                  style: 'Supersmall',
                }),
              ],
              borders: tableCellBordersNoBorder,
              columnSpan: 2,
            }),
          ],
        }),
      ],
    });

    result.push(table);
    result.push(new Paragraph(''));
  }

  return result;
};
