import {
  AlignmentType,
  Document,
  Footer,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  ImportDotx,
  Media,
  Packer,
  PageBreak,
  PageNumber,
  PageNumberFormat,
  Paragraph,
  TextRun,
  TextWrappingType,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
} from 'docx';
import { CompanyReportLayout, FileResponse, QuestionAspect, QuestionCategory, StarCompanyAnswer } from '../Swagger/api';

import { GraphicalDisplay } from '../Components/graphicalDisplay/graphicalDisplay';
import { IPageMarginAttributes } from 'docx/build/file/document/body/section-properties/page-margin/page-margin-attributes';
import React from 'react';
import ReactDOM from 'react-dom';
import { saveAs } from 'file-saver';
import { svgAsPngUri } from 'save-svg-as-png';
import { getApis } from '../Services/webservice';

const margins: IPageMarginAttributes = {
  gutter: 0,
  footer: 708,
  header: 708,
  left: 1080,
  bottom: 1080,
  right: 1080,
  top: 1080,
};

export const generateCompanyReport = async (starId: number, admin?: boolean) => {
  const { starClient, reportClient } = { ...getApis() };

  const [data, companySlogan] = await Promise.all([
    starClient.getStarCompanyReportData(starId),
    admin ? reportClient.getCompanySloganAdmin(starId) : reportClient.getCompanySlogan(),
  ]);

  const documentTemplate = (await (await fetch('/assets/wordTemplates/auditReportTemplate.dotx')).arrayBuffer()) as any; // Buffer
  const importer = new ImportDotx();
  const importedTemplate = await importer.extract(documentTemplate);

  const doc = new Document(undefined, { template: importedTemplate });

  const companyName = data.star?.company?.companyTitle || '';
  const reportYear = data.star?.started ? new Date(data.star.started).getFullYear().toString() : '';

  const textColor = admin ? await reportClient.getTextColorAdmin(data.star?.companyId) : await reportClient.getTextColor();

  const firstPage = await getFirstPage(companyName, reportYear, doc, !!admin, data.star?.companyId, textColor.value);

  const secondPage = await getSecondPage(
    companySlogan.value || '',
    companyName,
    data.star?.starVersion?.title || '',
    doc,
    starId,
    textColor.value
  );

  const content = getContent(data.starCompanyAnswers || [], textColor.value);

  doc.addSection({
    footers: { default: getFooter() },
    margins,
    properties: {
      pageNumberFormatType: PageNumberFormat.DECIMAL,
    },
    children: [...firstPage, ...secondPage, ...content],
  });

  Packer.toBlob(doc).then((b) => saveAs(b, `Nachhaltigkeitsbericht ${companyName} ${reportYear}.docx`));
};

const getFooter = () => {
  return new Footer({
    children: [
      new Paragraph({
        children: [new TextRun({ children: [PageNumber.CURRENT] })],
        alignment: AlignmentType.RIGHT,
        spacing: {
          after: 0,
        },
      }),
    ],
  });
};

const calculateAspectRatioFit = (srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

const getImageSizes = (blob: Blob) =>
  new Promise<{ height: number; width: number }>((resolve) => {
    const tempUrl = URL.createObjectURL(blob);
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
      URL.revokeObjectURL(tempUrl);
    };
    img.src = tempUrl;
  });

const getFirstPage = async (
  companyName: string,
  year: string,
  doc: Document,
  admin: boolean,
  companyId?: number,
  textColor?: string
) => {
  const { reportClient } = { ...getApis() };

  const coverImageSetings = admin ? await reportClient.getCoverImageAdmin(companyId) : await reportClient.getCoverImage();
  const coverImageLayout = admin ? await reportClient.getLayoutAdmin(companyId) : await reportClient.getLayout();

  let coverImage;
  if (coverImageSetings.customImage) {
    const coverImageResult: FileResponse | null = admin
      ? await reportClient.getCustomCoverImageAdmin(companyId)
      : await reportClient.getCustomCoverImage();
    const originalCoverImageSizes = await getImageSizes(coverImageResult?.data!);
    const fittedCoverImageSizes = calculateAspectRatioFit(
      originalCoverImageSizes.width,
      originalCoverImageSizes.height,
      800,
      500
    );
    const coverImageData = await coverImageResult?.data!.arrayBuffer()!;
    coverImage = Media.addImage(doc, coverImageData, fittedCoverImageSizes.width, fittedCoverImageSizes.height, {
      floating: {
        horizontalPosition: {
          relative: HorizontalPositionRelativeFrom.PAGE,
          align: HorizontalPositionAlign.CENTER,
        },
        verticalPosition: {
          relative: VerticalPositionRelativeFrom.PAGE,
          align: coverImageLayout === CompanyReportLayout.Down ? VerticalPositionAlign.TOP : VerticalPositionAlign.BOTTOM,
        },
        wrap: {
          type: TextWrappingType.TOP_AND_BOTTOM,
        },
      },
    });
  } else {
    const coverImageResult = await (
      await fetch(`/assets/images/companyReport/${coverImageSetings.predefinedImageId}.jpg`)
    ).arrayBuffer();
    const fittedCoverImageSizes = calculateAspectRatioFit(1835, 1376, 800, 800);
    coverImage = Media.addImage(doc, coverImageResult, fittedCoverImageSizes.width, fittedCoverImageSizes.height, {
      floating: {
        horizontalPosition: {
          relative: HorizontalPositionRelativeFrom.PAGE,
          align: HorizontalPositionAlign.CENTER,
        },
        verticalPosition: {
          relative: VerticalPositionRelativeFrom.PAGE,
          align: coverImageLayout === CompanyReportLayout.Down ? VerticalPositionAlign.TOP : VerticalPositionAlign.BOTTOM,
        },
        wrap: {
          type: TextWrappingType.TOP_AND_BOTTOM,
        },
      },
    });
  }

  const logoResult = admin ? await reportClient.getLogoAdmin(companyId) : await reportClient.getLogo();
  const logoData = await logoResult?.data.arrayBuffer()!;
  const originalLogoImageSizes = await getImageSizes(logoResult?.data!);
  const fittedLogoSizes = calculateAspectRatioFit(originalLogoImageSizes.width, originalLogoImageSizes.height, 150, 284);
  const logoImage = Media.addImage(doc, logoData, fittedLogoSizes.width, fittedLogoSizes.height, {
    floating: {
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.MARGIN,
        align: HorizontalPositionAlign.RIGHT,
      },
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.MARGIN,
        align: coverImageLayout === CompanyReportLayout.Down ? VerticalPositionAlign.BOTTOM : VerticalPositionAlign.TOP,
      },
    },
  });

  const cseLogoData = await (await fetch('/assets/images/companyReport/star_logo.png')).arrayBuffer();
  const cseLogo = Media.addImage(doc, cseLogoData, 150, 284, {
    floating: {
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.MARGIN,
        align: HorizontalPositionAlign.LEFT,
      },
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.MARGIN,
        align: coverImageLayout === CompanyReportLayout.Down ? VerticalPositionAlign.BOTTOM : VerticalPositionAlign.TOP,
      },
    },
  });

  return [
    new Paragraph({
      children: [coverImage],
      alignment: AlignmentType.DISTRIBUTE,
    }),
    new Paragraph({
      children: [
        new TextRun({
          children: [`Nachhaltigkeitsbericht ${year}`],
          size: 48,
          color: textColor,
        }),
      ],
      style: 'Heading1',
      alignment: AlignmentType.CENTER,
      spacing: {
        after: 0,
        before: coverImageLayout === CompanyReportLayout.Down ? 0 : 4560,
      },
    }),
    new Paragraph({
      children: [
        new TextRun({
          children: [`der ${companyName} nach CSE-STAR`],
          size: 36,
          color: textColor,
        }),
      ],
      style: 'Subheading',
      alignment: AlignmentType.CENTER,
    }),
    new Paragraph({ children: [logoImage, cseLogo] }),
  ];
};

const getSecondPage = async (
  companySlogan: string,
  companyName: string,
  starContentVersion: string,
  doc: Document,
  starId: number,
  textColor?: string
) => {
  const { graphicClient } = { ...getApis() };

  const svgContainer = document.createElement('div') as HTMLElement;
  const [assignements, results] = await Promise.all([
    graphicClient.getGraphicAssignments(),
    graphicClient.getGraphicResultDataForStar(starId),
  ]);

  ReactDOM.render(<GraphicalDisplay assignments={assignements} results={results} />, svgContainer);
  const svg = svgContainer.querySelector('svg') as SVGSVGElement;
  const svgUri: string = await new Promise((resolve) => svgAsPngUri(svg, {}, resolve));
  const svgArrayBuffer = await (await fetch(svgUri)).arrayBuffer();
  const starImage = Media.addImage(doc, svgArrayBuffer, 400, 400);

  const legendImageData = await (await fetch('/assets/images/companyReport/legend.jpg')).arrayBuffer();
  const legendImage = Media.addImage(doc, legendImageData, 642, 51);

  return [
    new Paragraph({
      children: [
        new TextRun({
          children: [`Nachhaltigkeits-Leistung von ${companyName} graphisch dargestellt`],
          color: textColor,
        }),
      ],
      style: 'Heading1',
    }),
    new Paragraph({ children: [starImage], alignment: AlignmentType.CENTER }),
    new Paragraph({ children: [legendImage], alignment: AlignmentType.CENTER }),
    new Paragraph(companySlogan),
    new Paragraph({
      children: [
        new TextRun({
          children: [
            `Erläuterung zur graphischen Darstellung oder nach Bearbeitung löschen: Das STAR Sustainability Tool for Assessment and Reporting basiert auf den CSE-Kriterien des Certified Sustainable Economics Standards. Jedes Feld in der Grafik entspricht einer Nachhaltigkeitsrubrik. Sind alle Anforderungen der jeweiligen Rubrik erfüllt, erscheint das Feld farblich vollständig ausgefüllt - diese Rubrik entspricht somit zu 100% den CSE-Kriterien. Ist ein Feld nicht vollständig ausgefüllt, entspricht die Leistung in dem Bereich nicht in Gänze dem CSE-Standard. Die folgende Grafik bildet die Nachhaltigkeitsleistung gemessen am CSE - Standard von ${companyName} ab.`,
          ],
          italics: true,
        }),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          children: [
            'Dieser Bericht gibt lediglich wieder, was Sie im STAR eingetragen haben. Bitte formulieren Sie selbst eine Präambel, Einleitung, Vorwort, Grußwort o.ä. und formatieren den Bericht nach Ihren eigenen Vorstellungen.',
          ],
          italics: true,
        }),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          children: [
            'Ein Tool kann nur begrenzt individuelle Nuancen wiedergeben. Daher steckt in einer Gestaltung des Lay-Outs, eine Bereicherung mit Bildern aus Ihrem Unternehmen, den Grafiken aus dem Klimaberechnungstool und einem Story-Telling an passender Stelle viel Potential zur Ausgestaltung Ihres unternehmensindividuellen Nachhaltigkeitsbericht.',
          ],
          italics: true,
        }),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          children: ['Falls Sie Unterstützung brauchen, können wir gerne eine Agentur empfehlen. Bitte sprechen Sie uns an.'],
          italics: true,
        }),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          children: ['(Diesen Absatz bitte vor dem Veröffentlichen löschen)'],
          italics: true,
        }),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          children: [starContentVersion],
          italics: true,
        }),
      ],
    }),
  ];
};

const getContent = (companyAnswers: StarCompanyAnswer[], textColor?: string) => {
  const result: (Paragraph | PageBreak)[] = [];

  let grouppedAnswers: {
    category: QuestionCategory;
    answers: StarCompanyAnswer[];
  }[] = [];
  grouppedAnswers = companyAnswers.reduce((a, c) => {
    const category = c.question?.questionAspect?.questionCategory;
    if (!category) {
      return a;
    }
    const existing = a.find((x) => x.category.id === category.id);
    if (existing) {
      existing.answers.push(c);
    } else {
      a.push({
        answers: [c],
        category: category,
      });
    }

    return a;
  }, grouppedAnswers);

  for (const categoryGroup of grouppedAnswers) {
    result.push(
      new Paragraph({
        children: [
          new TextRun({
            children: [categoryGroup.category.title || '-'],
            color: categoryGroup.category.color,
          }),
        ],
        style: 'Heading1',
        pageBreakBefore: true,
      })
    );

    let grouppedByAspect: {
      aspect: QuestionAspect;
      answers: StarCompanyAnswer[];
    }[] = [];
    grouppedByAspect = categoryGroup.answers.reduce((a, c) => {
      const aspect = c.question?.questionAspect;
      if (!aspect) {
        return a;
      }
      const existing = a.find((x) => x.aspect.id === aspect.id);
      if (existing) {
        existing.answers.push(c);
      } else {
        a.push({
          answers: [c],
          aspect,
        });
      }

      return a;
    }, grouppedByAspect);

    for (const aspectGroup of grouppedByAspect) {
      result.push(
        new Paragraph({
          children: [
            new TextRun({
              children: [aspectGroup.aspect.title || '-'],
              color: textColor,
            }),
          ],
          style: 'Heading2',
        })
      );

      for (const answer of aspectGroup.answers) {
        result.push(
          new Paragraph({
            children: [
              new TextRun({
                children: [answer.question?.contentForCompanyReport || answer.question?.content || '-'],
              }),
            ],
            style: 'Subheading',
          })
        );

        const answerParts = answer.reportText ? answer.reportText.split('\n').filter((x) => x) : ['keine Eingabe'];
        for (const answerPart of answerParts) {
          result.push(
            new Paragraph({
              children: [new TextRun(answerPart)],
              spacing: {
                after: answerParts.indexOf(answerPart) === answerParts.length - 1 ? 0 : undefined,
              },
            })
          );
        }

        if (answer.question?.cseStandardChapter) {
          result.push(
            new Paragraph({
              children: [new TextRun(answer.question?.cseStandardChapter)],
              style: 'Supersmall',
            })
          );
        }
        result.push(new Paragraph(''));
      }
    }
  }

  return result;
};
