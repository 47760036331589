import React, { useLayoutEffect, useRef, useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { localize } from '../../Localization/localize';

export const CollapseText: React.FunctionComponent = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const innerContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (innerContainerRef.current) {
      if (innerContainerRef.current.clientHeight <= 40) {
        setShowAll(true);
      } else {
        setShowAll(false);
      }
    }
  });

  return (
    <div className="dd-collapse-text-container" style={{ maxHeight: !collapsed ? undefined : 'inherit' }}>
      <div ref={innerContainerRef}>{children}</div>
      {!showAll && (
        <>
          {!collapsed ? (
            <div className="dd-blurring" onClick={() => setCollapsed((p) => !p)}>
              <Popup
                position="bottom center"
                content={localize.unCollapseText}
                trigger={<Icon name="chevron down" size="large" />}
              />
            </div>
          ) : (
            <div className="dd-close" onClick={() => setCollapsed((p) => !p)}>
              <Popup
                position="bottom center"
                content={localize.collapseText}
                trigger={<Icon name="chevron up" size="large" />}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
