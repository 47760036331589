import { Button, Divider, Grid, Header, Icon, Label, Menu, Message, Segment } from 'semantic-ui-react';
import { ProductAccess, Star } from '../../../Swagger/api';
import React, { useContext, useEffect, useState } from 'react';
import { history, paths } from '../../layout/layout';

import { CompanyReport } from '../../companyReport/companyReport';
import { LoginContext } from '../../../Contexts/loginContext';
import { OverviewItemPlaceholder } from './overviewItemPlaceholder';
import { SetAvailablePrompt } from '../../star/starRun/setAvailablePrompt';
import { SetCompanyReportDonePrompt } from '../../star/starRun/setCompanyReportDonePrompt';
import { localize } from '../../../Localization/localize';
import { getApis } from '../../../Services/webservice';

export const formatDate = (value: Date) => {
  return value.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const getAuditStatusText = (star: Star) => {
  if (star.justCompanyReport) {
    if (star.companyReportDone || star.auditAvailable) {
      return localize.companyReportDone;
    }
  } else {
    if (star.finished) {
      return localize.auditFinished;
    }

    if (star.auditStarted) {
      return localize.auditInProgress;
    }

    if (star.auditAvailable) {
      return localize.availableForAuditDateLowerCase;
    }
  }

  if (star.started) {
    return localize.starStillRunning;
  }

  return '-';
};

export const getAuditStatusColor = (
  star: Star
):
  | 'green'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'olive'
  | 'teal'
  | 'blue'
  | 'violet'
  | 'purple'
  | 'pink'
  | 'brown'
  | 'grey'
  | 'black'
  | undefined => {
  if (star.justCompanyReport) {
    if (star.companyReportDone || star.auditAvailable) {
      return 'green';
    }
  } else {
    if (star.finished) {
      return 'green';
    }

    if (star.auditStarted) {
      return 'teal';
    }

    if (star.auditAvailable) {
      return 'blue';
    }
  }

  if (star.started) {
    return 'orange';
  }

  return undefined;
};

export const CompanyOverview = () => {
  const [items, setItems] = useState<Star[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [selectedStarForReport, setSelectedStarForReport] = useState<Star>();
  const [selectedStarIdForDone, setSelectedStarIdForDone] = useState<number>();
  const [doneLoading, setDoneLoading] = useState(false);

  const loginContext = useContext(LoginContext);

  const { starClient } = { ...getApis() };

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      const itemsData = await starClient.getCompanyOverview();
      setItems(itemsData);
      setLoading(false);
    } catch {
      setLoading(false);
      setHasError(true);
    }
  };

  const runningStar = () => {
    const existing = items.find((x) => !x.companyReportDone && !x.auditAvailable);

    if (existing) {
      return existing.id;
    } else {
      return false;
    }
  };

  const setAvailableForAudit = async () => {
    try {
      setDoneLoading(true);
      selectedStarIdForDone && (await starClient.setAvailableForAudit(selectedStarIdForDone));
      await loadItems();
    } catch {
      setHasError(true);
    }

    setDoneLoading(false);
    setSelectedStarIdForDone(undefined);
  };

  const setCompanyReportDone = async () => {
    try {
      setDoneLoading(true);
      selectedStarIdForDone && (await starClient.setCompanyReportDone(selectedStarIdForDone));
      await loadItems();
    } catch {
      setHasError(true);
    }

    setDoneLoading(false);
    setSelectedStarIdForDone(undefined);
  };

  const getItemTitle = (item: Star) => {
    return item.started
      ? new Date(item.started).toLocaleDateString('de-DE', {
          month: 'long',
          year: 'numeric',
        })
      : '-';
  };

  return (
    <React.Fragment>
      <Header size="huge">{localize.myStars}</Header>
      <Message error header={localize.generalErrorHeader} content={localize.generalErrorMessage} hidden={!hasError} />
      <Divider horizontal hidden></Divider>
      {runningStar() ? (
        <Button primary icon labelPosition="left" onClick={() => history.push(`/star/run/${runningStar()}`)}>
          <Icon name="star half full"></Icon>
          {loginContext.login?.productAccess === ProductAccess.CseStar ? localize.continueStar : localize.continueCompanyReport}
        </Button>
      ) : (
        <Button
          loading={loading}
          disabled={loading}
          primary
          icon
          labelPosition="left"
          onClick={() => history.push(paths.star.startStar)}
        >
          <Icon name="star outline"></Icon>
          {loginContext.login?.productAccess === ProductAccess.CseStar
            ? localize.startNewStarButton
            : localize.startCompanyReport}
        </Button>
      )}

      {loading ? (
        <OverviewItemPlaceholder />
      ) : (
        items.map((item) => (
          <Segment key={item.id} className="dd-star-list-item">
            <Grid divided>
              <Grid.Row>
                <Grid.Column computer={12} tablet={10}>
                  <p title={getItemTitle(item)}>
                    <Icon name={item.auditAvailable ? 'star' : 'star half full'} />
                    <span className="dd-item-title">{getItemTitle(item)}</span>
                  </p>
                  {!item.justCompanyReport && (
                    <p title="Auditor*in">
                      <Icon name="user" />
                      {item.auditor ? [item.auditor?.firstName || '', item.auditor?.lastName || ''].join(' ') : '-'}
                    </p>
                  )}
                  <div className="dd-compact-grid">
                    <div className="dd-first-column">
                      <strong>{localize.startedAtDate}</strong>
                    </div>
                    <div className="dd-arrow-column"></div>
                    <div className="dd-second-column">
                      <strong>
                        {!item.justCompanyReport ? localize.availableForAuditDate : localize.companyReportDoneOnDate}
                      </strong>
                    </div>
                  </div>

                  <div className="dd-compact-grid">
                    <div className="dd-first-column">{item.started ? formatDate(new Date(item.started)) : '-'}</div>
                    <div className="dd-arrow-column">
                      <Icon name="arrow right" />
                    </div>
                    <div className="dd-second-column">
                      {!item.justCompanyReport
                        ? item.auditAvailable
                          ? formatDate(new Date(item.auditAvailable))
                          : '-'
                        : item.companyReportDone || item.auditAvailable
                        ? formatDate(new Date(item.companyReportDone || item.auditAvailable!))
                        : '-'}
                    </div>
                  </div>
                  {!item.justCompanyReport && (
                    <>
                      <div className="dd-compact-grid">
                        <div className="dd-grid-separator" />
                      </div>
                      <div className="dd-compact-grid">
                        <div className="dd-first-column">
                          <strong>{localize.auditStartedDate}</strong>
                        </div>
                        <div className="dd-arrow-column"></div>
                        <div className="dd-second-column">
                          <strong>{localize.auditDoneDate}</strong>
                        </div>
                      </div>
                      <div className="dd-compact-grid">
                        <div className="dd-first-column">
                          {item.auditStarted ? formatDate(new Date(item.auditStarted)) : '-'}
                        </div>
                        <div className="dd-arrow-column">
                          <Icon name="arrow right" />
                        </div>
                        <div className="dd-second-column">
                          {item.auditFinished ? formatDate(new Date(item.auditFinished)) : '-'}
                        </div>
                      </div>
                    </>
                  )}
                </Grid.Column>
                <Grid.Column tablet={6} computer={4}>
                  <Label className="dd-item-status-label" size="large" color={getAuditStatusColor(item)}>
                    {getAuditStatusText(item)}
                  </Label>
                  <Menu vertical secondary fluid>
                    <Menu.Item onClick={() => history.push(`/star/run/${item.id}`)}>
                      {item.auditAvailable || item.companyReportDone ? localize.seeAnswers : localize.continueStar}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!!item.auditAvailable || !!item.companyReportDone}
                      onClick={() => setSelectedStarIdForDone(item.id)}
                    >
                      {!item.justCompanyReport
                        ? item.auditAvailable
                          ? localize.approvedLowerCase
                          : localize.approveLowerCase
                        : item.companyReportDone || item.auditAvailable
                        ? localize.companyReportDone
                        : localize.setCompanyReportDone}
                    </Menu.Item>
                    <Menu.Item onClick={() => setSelectedStarForReport(item)}>{localize.companyReport}</Menu.Item>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))
      )}

      {selectedStarForReport && (
        <CompanyReport onClose={() => setSelectedStarForReport(undefined)} starId={selectedStarForReport.id!} />
      )}
      {selectedStarIdForDone && loginContext.login?.productAccess === ProductAccess.CseStar && (
        <SetAvailablePrompt
          onConfirm={setAvailableForAudit}
          onCancel={() => setSelectedStarIdForDone(undefined)}
          loading={doneLoading}
        ></SetAvailablePrompt>
      )}
      {selectedStarIdForDone && loginContext.login?.productAccess === ProductAccess.CompanyReportOnly && (
        <SetCompanyReportDonePrompt
          onConfirm={setCompanyReportDone}
          onCancel={() => setSelectedStarIdForDone(undefined)}
          loading={doneLoading}
        ></SetCompanyReportDonePrompt>
      )}
    </React.Fragment>
  );
};
