import React from 'react';
import { UserLoginViewModel } from '../Swagger/api';

export interface ILoginContext {
  login: UserLoginViewModel | null;
  setLogin: (login: UserLoginViewModel | null) => Promise<void>;
  connectedDate: Date;
}

export const LoginContext = React.createContext<ILoginContext>({
  login: null,
  setLogin: async () => {},
  connectedDate: new Date(),
});
