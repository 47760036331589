import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { localize } from "../../../Localization/localize";

interface ICompanyTypeLegendProps {
  onClose: () => void;
}

export const CompanyTypeLegend = (props: ICompanyTypeLegendProps) => (
  <Modal open closeOnDimmerClick onClose={props.onClose} dimmer="blurring">
    <Modal.Header>{localize.legend}</Modal.Header>
    <Modal.Content>
      <p>DL mit A. = Dienstleister mit Angestellten</p>
      <p>DL ohne A. Dienstleister ohne Angestellte</p>
      <p>H. mit A. = Handelsunternehmen mit Angestellten</p>
      <p>H. ohne A. = Handelsunternehmen ohne Angestellte</p>
      <p>Handw. mit A. = Handwerksbetrieb mit Angestellten</p>
      <p>Handw. ohne A. = Handwerksbetrieb ohne Angestellte</p>
      <p>Prod. Food mit A. = Produktionsunternehmen Lebensmittel mit Angestellten</p>
      <p>Prod. Food ohne A. = Produktionsunternehmen Lebensmittel ohne Angestellte</p>
      <p>Prod. Non-Food mit A. = Produktionsunternehmen Non-Food mit Angestellten</p>
      <p>Prod. Non-Food ohne A. = Produktionsunternehmen Non-Food ohne Angestellte</p>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={props.onClose}>{localize.closeLowerCase}</Button>
    </Modal.Actions>
  </Modal>
);
