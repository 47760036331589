import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider, Form, Header } from 'semantic-ui-react';
import { localize } from '../../../Localization/localize';
import { getApis } from '../../../Services/webservice';
import { StarOverviewItem } from '../../../Swagger/api';
import { dateFormatWithTime } from '../../../words/auditReport';
import { history, paths } from '../../layout/layout';
import { Loading } from '../../layout/loading';

export const ControlDataReadOnly = () => {
  const [item, setItem] = useState<StarOverviewItem>();
  const [loading, setLoading] = useState(true);

  const { id } = useParams<{ id: string }>();
  const { starClient } = { ...getApis() };

  const { star } = { ...item };
  const { company, starControlData } = { ...star };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    if (!id) {
      return;
    }
    const data = await starClient.getStarAdminControlData(Number(id));
    setItem(data);
    setLoading(false);
  };

  const getResolvedProblemQuestion = (questionId: number) => {
    return item?.starCompanyAnswers?.find((x) => x.questionId == questionId)?.question?.content || '-';
  };

  const getResolvedProblemComment = (questionId: number) => {
    return item?.starCompanyAnswers?.find((x) => x.questionId == questionId)?.starAuditorAnswer?.auditReportText || '-';
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Header size="large">{localize.auditControlDataHeader}</Header>
      <Form>
        <Header>{localize.companyData}</Header>
        <Form.Field>
          <Form.Field>
            <label>{localize.companyWithLegalForm}</label>
            {company ? company.companyTitle || localize.noData : localize.noData}
          </Form.Field>
          <Form.Field>
            <label>{localize.ceoName}</label>
            {starControlData ? starControlData.ceoName || localize.noData : localize.noData}
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{localize.contactPersonName}</label>
              {starControlData ? starControlData.contactPersonName || localize.noData : localize.noData}
            </Form.Field>
            <Form.Field>
              <label>{localize.enviromentResponsibleName}</label>
              {starControlData ? starControlData.enviromentResponsibleName || localize.noData : localize.noData}
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label>{localize.address}</label>
            {company
              ? [company.street, company.postcode, company.city, company.state].filter((x) => x).join(', ')
              : localize.noData}
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{localize.telephoneNumber}</label>
              {company ? company.telephoneNumber || localize.noData : localize.noData}
            </Form.Field>
            <Form.Field>
              <label htmlFor="">{localize.emailAddress}</label>
              {company && company.userLogin ? company.userLogin.emailAddress || localize.noData : localize.noData}
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label htmlFor="">{localize.fulltimePositions}</label>
            {company && company.fullTimePositions !== undefined ? company.fullTimePositions : localize.noData}
          </Form.Field>
          <Header>{localize.auditData}</Header>
          <Form.Field>
            <label>{localize.auditorName}</label>
            {[star?.auditor?.firstName, star?.auditor?.lastName].join(' ') || localize.noData}
          </Form.Field>
          <Form.Field>
            <label>{localize.accompanyingPersonName}</label>
            {starControlData?.accompanyingPersonName || localize.noData}
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{localize.auditStartedDate}</label>
              {star?.auditStarted ? new Date(star.auditStarted).toLocaleString('de-DE', dateFormatWithTime) : localize.noData}
            </Form.Field>
            <Form.Field>
              <label>{localize.lastAuditDate}</label>
              {starControlData && starControlData.lastAuditDate
                ? new Date(starControlData.lastAuditDate).toLocaleString('de-DE', dateFormatWithTime)
                : localize.noData}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{localize.auditType}</label>
              {starControlData
                ? starControlData.auditType === 0
                  ? localize.auditTypeFirstTime
                  : starControlData.auditType === 1
                  ? localize.auditTypeInspection
                  : starControlData.auditType === 2
                  ? localize.auditTypeRecertification
                  : starControlData.auditType === 3
                  ? localize.auditTypeOther
                  : localize.noData
                : localize.noData}
            </Form.Field>
            <Form.Field disabled={(starControlData && starControlData.auditType !== 3) || starControlData === undefined}>
              <label>{localize.otherAuditTypeName}</label>
              {starControlData ? starControlData.otherAuditTypeName || localize.noData : localize.noData}
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>{localize.previousCorrectionsDone}</label>
          </Form.Field>
          <Form.Checkbox
            label={localize.previousCorrectionsNotRequested}
            checked={starControlData?.previousCorrectionsNotRequested}
            readOnly
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.yes}
            checked={starControlData?.previousCorrectionsDone === true}
            readOnly
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.no}
            checked={starControlData?.previousCorrectionsDone === false}
            readOnly
          ></Form.Checkbox>
          <Form.Field disabled={starControlData && starControlData.previousCorrectionsDone == false ? false : true}>
            <label>{localize.previousCorrectionsNotDone}</label>
            {starControlData ? starControlData.previousCorrectionsNotDone || localize.noData : localize.noData}
          </Form.Field>
          <Form.Field>
            <label>{localize.previousImprovementsDone}</label>
          </Form.Field>
          <Form.Checkbox
            label={localize.previousCorrectionsNotRequested}
            checked={starControlData?.previousImprovementsNotRequested}
            readOnly
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.yes}
            checked={starControlData?.previousImprovementsDone === true}
            readOnly
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.no}
            checked={starControlData?.previousImprovementsDone === false}
            readOnly
          ></Form.Checkbox>
          <Form.Field disabled={starControlData && starControlData.previousImprovementsDone == false ? false : true}>
            <label>{localize.previousImprovementsNotDone}</label>
            {starControlData ? starControlData.previousImprovementsNotDone || localize.noData : localize.noData}
          </Form.Field>
          <Form.Field>
            <label>{localize.auditDoneDate}</label>
            {star?.auditFinished ? new Date(star.auditFinished).toLocaleString('de-DE', dateFormatWithTime) : localize.noData}
          </Form.Field>
          <Form.Field>
            <label>{localize.notesForNextAudit}</label>
            {starControlData ? starControlData.notesForNextAudit || localize.noData : localize.noData}
          </Form.Field>
          <Form.Field>
            <label>{localize.immediateProcessing}</label>
          </Form.Field>
          <Form.Checkbox
            label={localize.yes}
            checked={
              starControlData && starControlData.immediateProcessing !== undefined ? starControlData.immediateProcessing : false
            }
            readOnly
          ></Form.Checkbox>
          <Form.Checkbox
            label={localize.no}
            checked={
              starControlData && starControlData.immediateProcessing !== undefined
                ? !starControlData.immediateProcessing
                : false
            }
            readOnly
          ></Form.Checkbox>
          <Form.Field
            disabled={
              starControlData && starControlData.immediateProcessing !== undefined
                ? !starControlData.immediateProcessing
                : false
            }
          >
            <label>{localize.immediateProcessingReason}</label>
            {starControlData ? starControlData.immediateProcessingReason || localize.noData : localize.noData}
          </Form.Field>
          {item?.starCompanyAnswers?.length !== 0 && item?.star?.starControlData?.resolvedProblems?.length !== 0 && (
            <>
              <Divider horizontal hidden />
              <Header size="large">{localize.notesForLaterCorrections}</Header>
              {starControlData?.resolvedProblems?.map((x) => (
                <React.Fragment key={x.questionId}>
                  <Form.Field>
                    <Header>{getResolvedProblemQuestion(x.questionId)}</Header>
                  </Form.Field>
                  <Form.Field>
                    <label>{localize.auditorComment}</label>
                    {getResolvedProblemComment(x.questionId)}
                  </Form.Field>
                  <Form.Field>
                    <label>{localize.auditMethod}</label>
                  </Form.Field>
                  <Form.Group widths="5">
                    <Form.Checkbox label={localize.auditMethodObservation} checked={x.observation} />
                    <Form.Checkbox label={localize.auditMethodCommunication} checked={x.comunication} />
                    <Form.Checkbox label={localize.auditMethodDocument} checked={x.documentation} />
                  </Form.Group>
                  <Form.Field>
                    <label>{localize.resolvedProblemState}</label>
                    {x.content || '-'}
                  </Form.Field>
                </React.Fragment>
              ))}
            </>
          )}
          <Button onClick={() => history.push(paths.admin.overview)}>{localize.backLowerCase}</Button>
        </Form.Field>
      </Form>
    </React.Fragment>
  );
};
