import { Button, Message, Modal } from "semantic-ui-react";
import React, { useState } from "react";

import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

interface IArchiveConfirm {
  starId: number;
  archive: boolean;
  onCancel: () => void;
  onDone: () => void;
}

export const ArchiveConfirm = (props: IArchiveConfirm) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { starClient } = { ...getApis() };

  const setArchive = async () => {
    setLoading(true);
    setHasError(false);

    try {
      await starClient.setStarArchived(props.starId, props.archive);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Modal open dimmer="blurring">
      <Modal.Header>{localize.archiveConfirmHeader}</Modal.Header>
      <Modal.Content>
        <div
          dangerouslySetInnerHTML={{
            __html: props.archive
              ? localize.archiveConfirmContentSend
              : localize.archiveConfirmContentRetrieve,
          }}
        ></div>
        {hasError && (
          <Message
            error
            visible
            header={localize.generalErrorHeader}
            content={localize.generalErrorMessage}
          ></Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button floated="left" onClick={props.onCancel} disabled={loading}>
          {localize.cancelLowerCase}
        </Button>
        <Button
          primary
          onClick={setArchive}
          loading={loading}
          disabled={loading}
        >
          {props.archive
            ? localize.sendToArchive
            : localize.retrieveFromArchive}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
