import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import React from 'react';
import { localize } from '../../Localization/localize';

export const FileMissing = ({ onDone }: { onDone: () => void }) => {
  return (
    <Modal basic open>
      <Header icon>
        <Icon name="question circle"></Icon>
        {localize.fileMissing}
      </Header>
      <Modal.Actions>
        <Button basic inverted onClick={onDone}>
          {localize.generalOk}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
