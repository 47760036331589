import { Button, Divider, Form, Icon, Input, Message, Modal } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';

import { QuestionCrossReference } from '../../../../Swagger/api';
import { localize } from '../../../../Localization/localize';
import { getApis } from '../../../../Services/webservice';

export interface IQuestionCrossReferences {
  onCancel: () => void;
  onDone: () => void;
}

export const QuestionCrossReferences = (props: IQuestionCrossReferences) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [items, setItems] = useState<QuestionCrossReference[]>([]);
  const [usedItemsIds, setUsedItemsIds] = useState<number[]>([]);
  const [availableIcons, setAvailableIcons] = useState<string[]>([]);
  const [editedCrossReference, setEditedCrossReference] = useState<QuestionCrossReference>();
  const [selectedForDelete, setSelectedForDelete] = useState<number>();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [hasDeleteError, setHasDeleteError] = useState(false);

  const { questionClient } = { ...getApis() };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setHasError(false);
      try {
        const [data, usedItemsData] = await Promise.all([
          questionClient.getQuestionCrossReferences(),
          questionClient.getUsedQuestionCrossReferenceIds(),
        ]);
        setItems(data);
        setUsedItemsIds(usedItemsData);

        const icons = await questionClient.getCrossReferenceIcons();
        setAvailableIcons(icons);
      } catch {
        setHasError(true);
      }
      setLoading(false);
    })();
  }, []);

  const { onCancel, onDone } = { ...props };

  const onActivateChange = (currentItem: QuestionCrossReference) => {
    const copy = [...items];
    for (const item of copy) {
      if (item.id == currentItem.id) {
        item.deactivated = !currentItem.deactivated;
      }
    }

    setItems(copy);
  };

  const addNewItem = () => {
    const item = {
      deactivated: false,
      id: (items.length + 1) * -1,
      title: '',
    };
    setEditedCrossReference(item);
  };

  const saveItem = () => {
    if (!editedCrossReference) return;

    const existingPosition = items.map((x) => x.id).indexOf(editedCrossReference.id);

    const copy: QuestionCrossReference[] = [...items];
    if (existingPosition !== -1) {
      copy.splice(existingPosition, 1, editedCrossReference);
    } else {
      copy.push(editedCrossReference);
    }
    setItems(copy);
    setEditedCrossReference(undefined);
  };

  const onSave = async () => {
    setLoading(true);
    setHasError(false);
    try {
      await questionClient.setQuestionCrossReferences(items);
      onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  const deleteItem = async () => {
    setHasDeleteError(false);
    setLoadingDelete(true);
    try {
      // if the item was already saved on the server it has a correct id
      if (selectedForDelete && selectedForDelete > 0) {
        await questionClient.deleteQuestionCrossReference(selectedForDelete);
        const data = await questionClient.getQuestionCrossReferences();
        setItems(data);
      } else {
        // otherwise just clear it from the state
        const data = [...items].filter((x) => x.id !== selectedForDelete);
        setItems(data);
      }

      setSelectedForDelete(undefined);
    } catch {
      setHasDeleteError(true);
    }
    setLoadingDelete(false);
  };

  const isItemUsed = (itemId: number) => !!usedItemsIds.find((x) => x === itemId);

  return (
    <React.Fragment>
      <Modal open dimmer="blurring">
        <Modal.Header>{localize.crossreferences}</Modal.Header>
        <Modal.Content>
          {items.map((item) => (
            <React.Fragment key={item.id}>
              <Input value={item.title} fluid spellCheck="false" action className="dd-cross-reference-line">
                <img
                  className="dd-cross-reference-icon"
                  src={`/assets/images/crossreferences/${encodeURIComponent(item.iconUrl || '')}`}
                />
                <input />
                <Button onClick={() => setEditedCrossReference(item)}>{localize.editLowercase}</Button>
                <Button
                  onClick={() => {
                    onActivateChange(item);
                  }}
                >
                  {item.deactivated ? localize.activateLowerCase : localize.deactivateLowerCase}
                </Button>
                <Button disabled={isItemUsed(item.id)} onClick={() => setSelectedForDelete(item.id)}>
                  {localize.deleteLowerCase}
                </Button>
              </Input>

              <Divider horizontal hidden />
            </React.Fragment>
          ))}
          <Divider horizontal hidden />
          {hasError ? <Message error content={localize.generalErrorMessage} header={localize.generalErrorHeader} /> : null}
          <div className="dd-certificationCenters-footerMessage">
            <Divider horizontal hidden />
            {localize.questionCrossReferencesFooterMessage}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel} floated="left">
            {localize.cancelLowerCase}
          </Button>
          <Button icon labelPosition="left" onClick={addNewItem}>
            <Icon name="plus" />
            {localize.insertLowerCase}
          </Button>

          <Button primary icon labelPosition="right" onClick={onSave} loading={loading}>
            <Icon name="save" />
            {localize.saveLowerCase}
          </Button>
        </Modal.Actions>
      </Modal>
      {editedCrossReference && (
        <Modal dimmer="blurring" open>
          <Modal.Header>{localize.editCrossReference}</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Input
                type="text"
                label={localize.titel || ''}
                value={editedCrossReference ? editedCrossReference.title : ''}
                onChange={(_event, data) => {
                  setEditedCrossReference({
                    ...editedCrossReference!,
                    title: data.value,
                  });
                }}
              />
              <Form.TextArea
                label={localize.description || ''}
                value={editedCrossReference ? editedCrossReference.description : ''}
                onChange={(_event, data) => {
                  setEditedCrossReference({
                    ...editedCrossReference!,
                    description: data.value as string,
                  });
                }}
              />
              <Form.Select
                options={availableIcons.map((x) => {
                  return { text: x, value: x };
                })}
                value={editedCrossReference ? editedCrossReference.iconUrl : ''}
                onChange={(_event, data) => {
                  setEditedCrossReference({
                    ...editedCrossReference!,
                    iconUrl: data.value as string,
                  });
                }}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button floated="left" onClick={() => setEditedCrossReference(undefined)}>
              {localize.cancelLowerCase}
            </Button>

            <Button primary icon labelPosition="right" onClick={saveItem}>
              <Icon name="save" />
              {localize.saveLowerCase}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {selectedForDelete && (
        <Modal open dimmer="blurring">
          <Modal.Header>{localize.deleteUpperCase}</Modal.Header>
          <Modal.Content>
            <p>{localize.confirmCrossReferenceDelete}</p>
            {hasDeleteError && <Message error content={localize.generalErrorMessage} header={localize.generalErrorHeader} />}
          </Modal.Content>
          <Modal.Actions>
            <Button floated="left" onClick={() => setSelectedForDelete(undefined)}>
              {localize.cancelLowerCase}
            </Button>

            <Button primary icon labelPosition="right" onClick={deleteItem} loading={loadingDelete}>
              <Icon name="delete" />
              {localize.deleteLowerCase}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
};
