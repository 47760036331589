import { Button, Form, Message, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { UserAccountSuperAuditor } from "../../Swagger/api";
import { countriesListDe } from "../../constants";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

interface ISuperAuditorAddEdit {
  userAccount: UserAccountSuperAuditor | null;
  onDone: () => void;
  onCancel: () => void;
}

export const SuperAuditorAddEdit = (props: ISuperAuditorAddEdit) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [hasError, setHasError] = useState(false);
  const [emailAddressEmpty, setEmailAddressEmpty] = useState(false);
  const [emailAddressDuplicate, setEmailAddressDuplicate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userAccount && userAccount.userLogin) {
      setEmailAddress(userAccount.userLogin.emailAddress || "");
      setFirstName(userAccount.firstName || "");
      setLastName(userAccount.lastName || "");
      setStreet(userAccount.street || "");
      setPostcode(userAccount.postcode || "");
      setCity(userAccount.city || "");
      setState(userAccount.state || "");
      setTelephoneNumber(userAccount.telephoneNumber || "");
    }
  }, []);

  const { userAccount, onDone, onCancel } = { ...props };
  const { userAccountsClient } = { ...getApis() };

  const onSave = async () => {
    if (!emailAddress) {
      setEmailAddressEmpty(true);
      return;
    } else {
      setEmailAddressEmpty(false);
    }

    setHasError(false);
    setLoading(true);

    const update = userAccount && userAccount.userLogin ? true : false;

    const data: UserAccountSuperAuditor = {
      city,
      firstName,
      lastName,
      postcode,
      state,
      street,
      telephoneNumber,
      userLoginId: 0,
      userLogin: {
        emailAddress,
        userType: 3,
        deactivated: false,
        id: 0,
      },
    };

    try {
      if (update) {
        data.userLoginId = userAccount!.userLoginId;
        await userAccountsClient.updateSuperAuditor(data);
      } else {
        await userAccountsClient.addSuperAuditor(data);
      }

      onDone();
    } catch {
      setHasError(true);
    }
    setLoading(false);
  };

  const checkIfDuplicate = async () => {
    const duplicate = await userAccountsClient.isDuplicate(emailAddress);
    setEmailAddressDuplicate(duplicate);
  };

  return (
    <Modal open={true} dimmer="blurring">
      <Modal.Header>
        {userAccount?.userLoginId
          ? localize.editSuperAuditor
          : localize.createSuperAuditor}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label={localize.emailAddress}
            type="email"
            required
            value={emailAddress}
            onChange={(_e, { value }) => setEmailAddress(value)}
            error={emailAddressEmpty}
            onBlur={checkIfDuplicate}
          />
          <Message
            warning
            content={localize.emailDuplicate}
            visible={emailAddressDuplicate}
          />
          <Form.Group widths="equal">
            <Form.Input
              label={localize.firstName}
              type="text"
              value={firstName}
              onChange={(_e, { value }) => setFirstName(value)}
            />
            <Form.Input
              label={localize.lastName}
              type="text"
              value={lastName}
              onChange={(_e, { value }) => setLastName(value)}
            />
          </Form.Group>
          <Form.Input
            label={localize.streetAndHouseNumber}
            type="text"
            value={street}
            onChange={(_e, { value }) => setStreet(value)}
          />
          <Form.Group>
            <Form.Input
              width="4"
              label={localize.postCode}
              type="text"
              value={postcode}
              onChange={(_e, { value }) => setPostcode(value)}
            />
            <Form.Input
              width="6"
              label={localize.city}
              type="text"
              value={city}
              onChange={(_e, { value }) => setCity(value)}
            />
            <Form.Select
              width="6"
              label={localize.state}
              type="text"
              value={state}
              search
              options={countriesListDe.map((x) => ({
                value: x.name,
                text: x.name,
                flag: x.alpha2,
              }))}
              onChange={(_e, { value }) => setState(value as string)}
            />
          </Form.Group>
          <Form.Input
            label={localize.telephoneNumber}
            type="text"
            value={telephoneNumber}
            onChange={(_e, { value }) => setTelephoneNumber(value)}
          />
          <Message
            error
            visible={hasError}
            content={localize.generalErrorMessage}
            header={localize.generalErrorHeader}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={localize.cancelLowerCase}
          floated="left"
          onClick={onCancel}
        />
        <Button
          disabled={emailAddressDuplicate}
          primary
          icon="save"
          content={localize.saveLowerCase}
          labelPosition="right"
          onClick={() => onSave()}
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};
