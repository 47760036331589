import React, { useState, useRef } from "react";
import {
  Header,
  Form,
  Button,
  Divider,
  Grid,
  Message,
} from "semantic-ui-react";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKey } from "../../constants";

export const PasswordForgot = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [notRobot, setNotRobot] = useState<boolean | undefined>(undefined);

  const { signinClient } = { ...getApis() };

  let recaptchaRef = useRef<ReCAPTCHA>();

  const onButtonClick = async () => {
    if (!notRobot || !recaptchaRef.current) {
      setNotRobot(false);
      return;
    }

    setLoading(true);
    setHasError(false);

    try {
      await signinClient.requestResetToken({
        emailAddress,
        recaptchaToken: recaptchaRef.current.getValue() || "",
      });
      setIsDone(true);
    } catch {
      setIsDone(false);
      setHasError(true);
      recaptchaRef.current.reset();
    }

    setLoading(false);
  };

  return (
    <div>
      <Header size="huge">{localize.passwordForgotHeader}</Header>
      <Form className="dd-signin-container">
        <Form.Input
          label={localize.emailAddress}
          value={emailAddress}
          onChange={(_event, { value }) => setEmailAddress(value)}
        />
        <Divider horizontal hidden />
        <ReCAPTCHA
          sitekey={captchaSiteKey}
          ref={(x: any) => (recaptchaRef.current = x)}
          onChange={() => setNotRobot(true)}
        />
        <Divider horizontal hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={9} verticalAlign="middle" />
            <Grid.Column width={7}>
              <Button primary fluid disabled={loading} onClick={onButtonClick}>
                {localize.resetUpperCase}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Message
          success
          header={localize.passwordForgotSuccessHeader}
          content={localize.passwordForgotSuccessMessage}
          visible={isDone}
        />
        <Message
          error
          header={localize.generalErrorHeader}
          content={localize.generalErrorMessage}
          visible={hasError}
        />
        <Message
          warning
          header={localize.passwordRobotHeader}
          content={localize.passwordRobotMessage}
          visible={notRobot === false}
        />
      </Form>
    </div>
  );
};
