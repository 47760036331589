import { AuditResult } from "../../../Swagger/api";
import { Icon } from "semantic-ui-react";
import React from "react";
import { localize } from "../../../Localization/localize";

interface IAnswerStateProps {
  currentState: AuditResult | undefined | null;
}

export const AuditorAnswerState = (props: IAnswerStateProps) => {
  return (
    <div className="dd-answer-state">
      <Icon
        name="circle"
        className={`dd-state-notAnswered ${props.currentState === 0 ? "dd-selected" : ""}`}
        title={localize.answerStateNotAnswered}
      />

      <Icon
        name="circle"
        className={`dd-state-answered ${props.currentState !== 0 ? "dd-selected" : ""}`}
        title={localize.answerStateAnswered}
      />
    </div>
  );
};
