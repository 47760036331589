import { Button, Header, Icon, Modal } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { StarsDb, starDbKey } from "../auditor/auditorOverview";

import { LoginContext } from "../../Contexts/loginContext";
import { history } from "../layout/layout";
import localForage from "localforage";
import { localize } from "../../Localization/localize";
import { getApis } from "../../Services/webservice";

export const UserMenu = () => {
  const [loading, setLoading] = useState(false);
  const [online, setOnline] = useState(navigator.onLine);
  const [showConfirm, setShowConfirm] = useState(false);
  const loginContext = useContext(LoginContext);
  const { signinClient } = getApis();

  useEffect(() => {
    if (navigator.onLine !== online) {
      setOnline(navigator.onLine);
    }
  });

  if (!online && loginContext.login) {
    return (
      <div className="dd-userMenu">
        <div className="dd-text">{loginContext.login.emailAddress}</div>
        <div className="dd-container">
          <Button basic>{localize.notOnlineMessage}</Button>
        </div>
      </div>
    );
  }

  const confirmSignout = async () => {
    const starsDb = await localForage.getItem<StarsDb>(starDbKey);
    if (starsDb && starsDb.updatedStarIds.length) {
      setShowConfirm(true);
    } else {
      await signout();
    }
  };

  const signout = async () => {
    setLoading(true);

    try {
      await loginContext.setLogin(null);
      history.push("/");
      await signinClient.clearRefreshToken();
    } catch (ex) {
      setLoading(false);
    }

    setShowConfirm(false);
    setLoading(false);
  };

  if (loginContext.login) {
    return (
      <div className="dd-userMenu">
        <div className="dd-text">{loginContext.login.emailAddress}</div>
        <div className="dd-container">
          <Button loading={loading} disabled={loading} onClick={confirmSignout}>
            {localize.signout}
          </Button>
        </div>
        {showConfirm && (
          <Modal open basic dimmer="blurring">
            <Header icon>
              <Icon name="warning"></Icon>
              Sie haben unsynchronisierte Daten
            </Header>
            <Modal.Content>
              <p>
                Wenn Sie sich jetzt abmelden, werden alle lokal gespeicherten
                Daten, Antworten, Kontrolldaten und Audit Daten unwiderruflich
                gelöscht!
              </p>
              <p>Sind Sie sicher, dass Sie sich abmelden wollen?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={loading}
                basic
                color="green"
                inverted
                onClick={() => {
                  setShowConfirm(false);
                  setLoading(false);
                }}
              >
                {localize.staySignedin}
              </Button>
              <Button
                basic
                color="red"
                inverted
                onClick={signout}
                loading={loading}
              >
                {localize.signoutAndDelete}
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  } else {
    return (
      <div className="dd-userMenu">
        <div className="dd-container">
          <Button basic>{localize.notSignedInMessage}</Button>
        </div>
      </div>
    );
  }
};
