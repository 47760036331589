import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  Header,
  HeadingLevel,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  ImportDotx,
  ISectionOptions,
  Media,
  Packer,
  PageNumber,
  PageNumberFormat,
  Paragraph,
  PictureRun,
  Table,
  TableCell,
  TableLayoutType,
  TableOfContents,
  TableRow,
  TextRun,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
  WidthType,
} from 'docx';
import { IPageMarginAttributes } from 'docx/build/file/document/body/section-properties/page-margin/page-margin-attributes';
import { saveAs } from 'file-saver';
import localForage from 'localforage';
import { companiesDbKey, getCurrentStarFromDb } from '../Components/auditor/auditorOverview';
import {
  AuditResult,
  CompaniesStarAuditorOverview,
  QuestionCategory,
  StarAuditorAnswer,
  StarAuditorOverViewCompanyAnswer,
  StarAuditorOverviewItem,
  UserAccountCompany,
} from '../Swagger/api';

export const generateAuditReport = async (starId: number, starData?: StarAuditorOverviewItem, company?: UserAccountCompany) => {
  let star: StarAuditorOverviewItem;

  if (!starData) {
    const data = await getCurrentStarFromDb(starId);
    if (!data) {
      return;
    }
    star = data;
  } else {
    star = starData;
  }

  if (!star) {
    throw new Error('STAR not found');
  }

  if (!company) {
    const companies = await localForage.getItem<CompaniesStarAuditorOverview>(companiesDbKey);
    company = companies?.companies?.find((x) => x.userLoginId === star.companyId);
  }

  const documentTemplate = (await (await fetch('/assets/wordTemplates/auditReportTemplate.dotx')).arrayBuffer()) as any; // Buffer
  const importer = new ImportDotx();
  const importedTemplate = await importer.extract(documentTemplate);

  const doc = new Document(undefined, { template: importedTemplate });

  const circleImagesData = await getCircleImagesData();
  const footerCircleImages = addCircleImages(doc, circleImagesData, 10);
  const answersCircleImages = addCircleImages(doc, circleImagesData, 30);

  const commonHeader = await getCommonHeader(doc);
  const commonFooter = getFooter(footerCircleImages, star, company);
  const firstPage = await getFirstPage(doc, star, company);

  doc.addSection({
    ...commonHeader,
    ...commonFooter,
    margins,
    properties: {
      pageNumberFormatType: PageNumberFormat.DECIMAL,
    },
    children: [...firstPage, ...getTableOfContents(), ...getAuditData(star)],
  });

  const answerSections = await getCategories(doc, footerCircleImages, company, star, answersCircleImages);
  for (let item of answerSections) {
    doc.addSection(item);
  }

  doc.addSection({
    ...commonHeader,
    ...commonFooter,
    margins,
    properties: {
      pageNumberFormatType: PageNumberFormat.DECIMAL,
    },
    children: [...getCompanyGoals(star), ...getAuditResult(star), ...getSignatures()],
  });

  Packer.toBlob(doc).then((b) =>
    saveAs(
      b,
      `Auditbericht ${company?.companyTitle || ''} ${star.auditFinished ? new Date(star.auditFinished).getFullYear() : ''}.docx`
    )
  );
};

export const margins: IPageMarginAttributes = {
  gutter: 0,
  footer: 708,
  header: 708,
  left: 1080,
  bottom: 1440,
  right: 1080,
  top: 1440,
};

export const getCommonHeader = async (doc: Document) => {
  const ecoControlImage = await (await fetch('/assets/images/auditReportPdf/ecocontrol.jpeg')).arrayBuffer();
  const starImage = await (await fetch('/assets/images/auditReportPdf/star.png')).arrayBuffer();
  const gfawImage = await (await fetch('/assets/images/auditReportPdf/gfaw.png')).arrayBuffer();

  const imageHeaderEcoControl = Media.addImage(doc, ecoControlImage, 45, 45, {
    floating: {
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.LINE,
        align: VerticalPositionAlign.CENTER,
      },
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.COLUMN,
        align: HorizontalPositionAlign.LEFT,
      },
    },
  });
  const imageHeaderStar = Media.addImage(doc, starImage, 150, 48, {
    floating: {
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.LINE,
        align: VerticalPositionAlign.CENTER,
      },
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.COLUMN,
        align: HorizontalPositionAlign.CENTER,
      },
    },
  });
  const imageHeaderGfaw = Media.addImage(doc, gfawImage, 125, 44, {
    floating: {
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.LINE,
        align: VerticalPositionAlign.CENTER,
      },
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.COLUMN,
        align: HorizontalPositionAlign.RIGHT,
      },
    },
  });

  return {
    headers: {
      default: new Header({
        children: [
          new Paragraph({
            children: [imageHeaderEcoControl, imageHeaderStar, imageHeaderGfaw],
          }),
        ],
      }),
    },
    children: [],
  };
};

interface ICircleImagesData {
  badData: ArrayBuffer;
  okData: ArrayBuffer;
  partialData: ArrayBuffer;
  communicationData: ArrayBuffer;
  documentData: ArrayBuffer;
  observationData: ArrayBuffer;
  notAuditedData: ArrayBuffer;
}

export interface ICircleImages {
  badImage: PictureRun;
  okImage: PictureRun;
  partialImage: PictureRun;
  communicationImage: PictureRun;
  documentImage: PictureRun;
  observationImage: PictureRun;
  notAuditedImage: PictureRun;
}

export const getCircleImagesData = async (): Promise<ICircleImagesData> => {
  const badData = await (await fetch('/assets/images/auditReportPdf/bad.png')).arrayBuffer();
  const okData = await (await fetch('/assets/images/auditReportPdf/ok.png')).arrayBuffer();
  const partialData = await (await fetch('/assets/images/auditReportPdf/partial.png')).arrayBuffer();

  const communicationData = await (await fetch('/assets/images/auditReportPdf/communication.png')).arrayBuffer();
  const documentData = await (await fetch('/assets/images/auditReportPdf/document.png')).arrayBuffer();
  const observationData = await (await fetch('/assets/images/auditReportPdf/observation.png')).arrayBuffer();

  const notAuditedData = await (await fetch('/assets/images/auditReportPdf/not audited.png')).arrayBuffer();

  return {
    badData,
    okData,
    partialData,
    communicationData,
    documentData,
    observationData,
    notAuditedData,
  };
};

export const addCircleImages = (doc: Document, imagesData: ICircleImagesData, size: number): ICircleImages => {
  const badImage = Media.addImage(doc, imagesData.badData, size, size);
  const okImage = Media.addImage(doc, imagesData.okData, size, size);
  const partialImage = Media.addImage(doc, imagesData.partialData, size, size);

  const communicationImage = Media.addImage(doc, imagesData.communicationData, size, size);
  const documentImage = Media.addImage(doc, imagesData.documentData, size, size);
  const observationImage = Media.addImage(doc, imagesData.observationData, size, size);

  const notAuditedImage = Media.addImage(doc, imagesData.notAuditedData, size, size);

  return {
    badImage,
    okImage,
    partialImage,
    communicationImage,
    documentImage,
    observationImage,
    notAuditedImage,
  };
};

export const getFooter = (
  images: ICircleImages,
  starData: StarAuditorOverviewItem,
  company?: UserAccountCompany,
  category?: QuestionCategory
) => {
  return {
    children: [],
    footers: {
      default: new Footer({
        children: [
          new Paragraph({
            children: [
              new TextRun('Legende Ergebnis: '),
              images.badImage,
              new TextRun(' = nicht erfüllt, '),
              images.partialImage,
              new TextRun(' = teilweise erfüllt, '),
              images.okImage,
              new TextRun(' = konform, '),
              images.notAuditedImage,
              new TextRun(' = nicht geprüft'),
            ],
            style: 'Smallfooter',
            spacing: {
              after: 0,
            },
          }),
          new Paragraph({
            children: [
              new TextRun('Legende Prüfmethode: '),
              images.communicationImage,
              new TextRun(' = Aussage, '),
              images.observationImage,
              new TextRun(' = Ergebnis nach Begehung, '),
              images.documentImage,
              new TextRun(' = eingesehenes Dokument/Aufzeichnung'),
            ],
            style: 'Smallfooter',
          }),
          new Paragraph({
            children: [
              new TextRun(
                `CSE-Audit-Bewertungsbericht ${company?.companyTitle || ''} ${
                  starData.auditFinished ? new Date(starData.auditFinished).toLocaleDateString('de-DE') : ''
                } `
              ),
              new TextRun({
                children: [category?.title || ''],
                color: category?.color,
                bold: true,
              }),
              new TextRun(` ${starData.starVersion || ''}`),
            ],
            style: 'Bigfooter',
          }),
          new Paragraph({
            children: [new TextRun({ children: [PageNumber.CURRENT] })],
            alignment: AlignmentType.RIGHT,
            spacing: {
              after: 0,
            },
          }),
        ],
      }),
    },
  };
};

const getFirstPage = async (doc: Document, starData: StarAuditorOverviewItem, company?: UserAccountCompany) => {
  const cseLogoData = await (await fetch('/assets/images/auditReportPdf/cseLogo.jpg')).arrayBuffer();
  const cseLogoImage = Media.addImage(doc, cseLogoData, 157, 221);
  return [
    new Paragraph({
      children: [
        new TextRun(
          `CSE-Audit-Bewertungsbericht ${starData.auditFinished ? new Date(starData.auditFinished).getFullYear() : ''}`
        ),
      ],
      style: 'Documenttitle',
      spacing: { after: 0, before: 2000 },
      heading: HeadingLevel.TITLE,
    }),
    new Paragraph({
      children: [new TextRun('Zur CSE-Zertifizierung')],
      style: 'Documenttitle',
    }),
    new Paragraph({
      children: [new TextRun(company?.companyTitle || '')],
      style: 'Documentsubtitle',
    }),
    new Paragraph({
      children: [cseLogoImage],
      alignment: AlignmentType.CENTER,
      spacing: { after: 1320 },
    }),

    new Paragraph({
      children: [new TextRun('Betriebsdaten')],
      style: 'Heading1',
      heading: HeadingLevel.HEADING_1,
    }),
    new Paragraph({
      children: [new TextRun('Geschäftsführer*in')],
      style: 'Subheading',
    }),
    new Paragraph(starData.starControlData?.ceoName || '-'),
    new Paragraph({
      children: [new TextRun('Verantwortliche Person für das Audit')],
      style: 'Subheading',
    }),
    new Paragraph(starData.starControlData?.contactPersonName || '-'),
    new Paragraph({
      children: [new TextRun('Anschrift')],
      style: 'Subheading',
    }),
    new Paragraph([company?.street, company?.postcode, company?.state].filter((x) => x).join(', ') || '-'),
    new Paragraph({ children: [new TextRun('Telefon')], style: 'Subheading' }),
    new Paragraph(company?.telephoneNumber || '-'),
    new Paragraph({ children: [new TextRun('Email')], style: 'Subheading' }),
    new Paragraph(company?.userLogin?.emailAddress || '-'),
  ];
};

const getTableOfContents = () => {
  return [
    new Paragraph({
      children: [new TextRun('Inhalt')],
      style: 'Heading1NoLevel',
    }),
    new TableOfContents('Inhalt', {
      headingStyleRange: '1-3',
      hyperlink: true,
    }),
  ];
};

export const dateFormatWithDate: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const dateFormatWithTime: Intl.DateTimeFormatOptions = {
  ...dateFormatWithDate,
  hour: '2-digit',
  minute: '2-digit',
};

export const getAuditType = (value?: number, other?: string) => {
  switch (value) {
    case 0:
      return 'Erstaudit';
    case 1:
      return 'Überwachungsaudit';
    case 2:
      return 'Rezertifizierungsaudit';
    case 4:
      return other;
    default:
      return '';
  }
};

const getAuditData = (starData: StarAuditorOverviewItem) => {
  let result = [
    new Paragraph({
      children: [new TextRun('Auditdaten')],
      style: 'Heading1',
      pageBreakBefore: true,
    }),
    new Paragraph({
      children: [new TextRun('Name Auditor*in')],
      style: 'Subheading',
    }),
    new Paragraph(starData.auditorName || '-'),
    new Paragraph({
      children: [new TextRun('Datum Audits')],
      style: 'Subheading',
    }),
    new Paragraph(
      `${starData?.auditStarted ? new Date(starData.auditStarted).toLocaleString('de-DE', dateFormatWithTime) : '-'} bis ${
        starData?.auditFinished ? new Date(starData.auditFinished).toLocaleString('de-DE', dateFormatWithTime) : '-'
      }`
    ),
    new Paragraph({
      children: [new TextRun('Name verantwortliche Begleitperson')],
      style: 'Subheading',
    }),
    new Paragraph(starData.starControlData?.accompanyingPersonName || '-'),
    new Paragraph({
      children: [new TextRun('Datum letztes Audit')],
      style: 'Subheading',
    }),
    new Paragraph(
      starData?.starControlData?.lastAuditDate
        ? new Date(starData.starControlData.lastAuditDate).toLocaleDateString('de-DE', dateFormatWithDate)
        : '-'
    ),
    new Paragraph({
      children: [new TextRun('Art des Audits')],
      style: 'Subheading',
    }),
    new Paragraph(getAuditType(starData.starControlData?.auditType, starData.starControlData?.otherAuditTypeName) || '-'),
    new Paragraph({
      children: [new TextRun('Sofortige Bearbeitung')],
      style: 'Subheading',
    }),
    new Paragraph({
      children: [
        starData.starControlData?.immediateProcessing ? new TextRun({ children: ['Ja'], bold: true }) : new TextRun('Nein'),
      ],
    }),
  ];

  if (starData.starControlData?.immediateProcessing) {
    result.push(
      new Paragraph({
        children: [new TextRun('Grund für Sofortige Bearbeitung')],
        style: 'Subheading',
      })
    );
    if (!starData.starControlData.immediateProcessingReason) {
      result.push(new Paragraph('-'));
    } else {
      const resonContent = starData.starControlData.immediateProcessingReason.split('\n').filter((x) => x);
      for (let part of resonContent) {
        result.push(new Paragraph(part));
      }
    }
  }

  result.push(
    new Paragraph({
      children: [new TextRun('Erfüllung vereinbarter Korrekturmaßnahmen')],
      style: 'Heading2',
    })
  );

  if (starData.starControlData?.previousCorrectionsDone) {
    result.push(
      new Paragraph({
        children: [new TextRun('Alle Korrekturmaßnahmen wurden gemäß letztem Bewertungsbericht umgesetzt.')],
      })
    );
  } else if (!starData.starControlData?.previousCorrectionsDone && !starData.starControlData?.previousCorrectionsNotRequested) {
    result.push(
      new Paragraph({
        children: [new TextRun('Folgende Abweichungen liegen vor:')],
      })
    );

    const correctionContent = starData.starControlData?.previousCorrectionsNotDone;

    if (!correctionContent) {
      result.push(new Paragraph('-'));
    } else {
      const parts = correctionContent?.split('\n').filter((x) => x);

      for (let part of parts) {
        result.push(new Paragraph(part));
      }
    }
  } else if (starData.starControlData.previousCorrectionsNotRequested) {
    result.push(new Paragraph('Keine Korrekturmaßnahmen gefordert'));
  }

  result.push(
    new Paragraph({
      children: [new TextRun('Erfüllung vorheriger Auflagen und Verbesserungsmaßnahmen')],
      style: 'Heading2',
    })
  );

  if (starData.starControlData?.previousImprovementsDone) {
    result.push(
      new Paragraph({
        children: [new TextRun('Alle Korrekturmaßnahmen wurden gemäß letztem Bewertungsbericht umgesetzt.')],
      })
    );
  } else if (
    !starData.starControlData?.previousImprovementsDone &&
    !starData.starControlData?.previousImprovementsNotRequested
  ) {
    result.push(
      new Paragraph({
        children: [new TextRun('Folgende Abweichungen liegen vor:')],
      })
    );

    const improvementsContent = starData.starControlData?.previousImprovementsNotDone;

    if (!improvementsContent) {
      result.push(new Paragraph('-'));
    } else {
      const parts = improvementsContent?.split('\n').filter((x) => x);

      for (let part of parts) {
        result.push(new Paragraph(part));
      }
    }
  } else if (starData.starControlData.previousImprovementsNotRequested) {
    result.push(new Paragraph('Keine Verbesserungsmaßnahmen gefordert'));
  }

  return result;
};

export interface IGrouppedAnswers {
  category: QuestionCategory;
  answers: StarAuditorOverViewCompanyAnswer[];
}

const getCategories = async (
  doc: Document,
  footerCircleImages: ICircleImages,
  company: UserAccountCompany | undefined,
  starData: StarAuditorOverviewItem,
  circleImages: ICircleImages
) => {
  let grouppedAnswers: IGrouppedAnswers[] = [];
  grouppedAnswers = (starData.starCompanyAnswers || []).reduce((a, c) => {
    const category = c.question?.questionAspect?.questionCategory;
    if (!category) {
      return a;
    }
    const existing = a.find((x) => x.category.id === category.id);
    if (existing) {
      existing.answers.push(c);
    } else {
      a.push({
        answers: [c],
        category: category,
      });
    }

    return a;
  }, grouppedAnswers);

  let result: ISectionOptions[] = [];
  const commonHeader = await getCommonHeader(doc);

  for (let i = 0; i < grouppedAnswers.length; i++) {
    let children: (Paragraph | Table)[] = [];

    if (i === 0) {
      children.push(
        new Paragraph({
          children: [new TextRun('Ergebnisse Bewertung')],
          style: 'Heading1',
          pageBreakBefore: true,
        })
      );
    }

    children = [...children, ...getCategory(grouppedAnswers[i], !!i, circleImages)];

    const footer = getFooter(footerCircleImages, starData, company, grouppedAnswers[i].category);

    const section: ISectionOptions = {
      ...commonHeader,
      ...footer,
      children,
      margins,
      properties: {
        pageNumberFormatType: PageNumberFormat.DECIMAL,
      },
    };

    result.push(section);
  }

  return result;
};

export const noBorderStyle = {
  size: 1,
  color: 'white',
  style: BorderStyle.SINGLE,
};
export const tableCellBordersNoBorder = {
  right: noBorderStyle,
  left: noBorderStyle,
  top: noBorderStyle,
  bottom: noBorderStyle,
};

const getCategory = (answersGroup: IGrouppedAnswers, breakParagraph: boolean, circleImages: ICircleImages) => {
  const result: (Paragraph | Table)[] = [
    new Paragraph({
      children: [
        new TextRun({
          children: [answersGroup.category.title || '-'],
          color: answersGroup.category.color,
        }),
      ],
      style: 'Heading2',
      pageBreakBefore: breakParagraph,
    }),
  ];

  const relevantAnswers = answersGroup.answers.filter((x) => x.starAuditorAnswer?.notRelevant !== true);

  for (let answer of relevantAnswers) {
    const table = new Table({
      margins: {
        marginUnitType: WidthType.DXA,
        left: 113,
        right: 113,
      },
      columnWidths: [7059, 1642, 1271],
      layout: TableLayoutType.FIXED,
      alignment: AlignmentType.CENTER,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun(`(Frage-ID: ${answer.questionId}) ${answer.question?.content || '-'}`)],
                  style: 'Subheading',
                  spacing: { after: 0 },
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ children: ['Prüfmethode'], color: '1F3864' })],
                  spacing: { after: 0 },
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ children: ['Bewertung'], color: '1F3864' })],
                  spacing: { after: 0 },
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                ...(answer.content
                  ? answer.content
                      .split('\n')
                      .filter((x) => x)
                      .map((x) => new Paragraph(x))
                  : [new Paragraph('-')]),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [...getImagesForAuditMethod(answer.starAuditorAnswer, circleImages)],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              margins: { top: 113 },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [getImageForResult(answer.starAuditorAnswer, circleImages)],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              margins: { top: 113 },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      children: ['Kommentar der auditierenden Person'],
                      color: '2F5496',
                    }),
                  ],
                  spacing: { after: 0 },
                }),
              ],
              columnSpan: 3,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                ...(answer.starAuditorAnswer?.auditReportText
                  ? answer.starAuditorAnswer?.auditReportText
                      .split('\n')
                      .filter((x) => x)
                      .map((x) => new Paragraph(x))
                  : [new Paragraph('-')]),
              ],
              columnSpan: 3,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ children: ['Normverweis'], color: '1F3864' })],
                  style: 'Supersmall',
                }),
              ],
              borders: tableCellBordersNoBorder,
              columnSpan: 3,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      children: ['CSE-Standard: ', answer.question?.cseStandardChapter || '-'],
                    }),
                  ],
                  style: 'Supersmall',
                }),
              ],
              borders: tableCellBordersNoBorder,
              columnSpan: 3,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      children: ['Normverweis zu ISO 14001: ', answer.question?.isoNormReference || '-'],
                    }),
                  ],
                  style: 'Supersmall',
                }),
              ],
              borders: tableCellBordersNoBorder,
              columnSpan: 3,
            }),
          ],
        }),
      ],
    });

    result.push(table);
    result.push(new Paragraph(''));
  }

  return result;
};

export const getImagesForAuditMethod = (answer: StarAuditorAnswer | undefined, circleImages: ICircleImages) => {
  const result: PictureRun[] = [];

  if (answer?.observation) {
    result.push(circleImages.observationImage);
  }

  if (answer?.comunication) {
    result.push(circleImages.communicationImage);
  }

  if (answer?.documentation) {
    result.push(circleImages.documentImage);
  }

  return result;
};

const getImageForResult = (answer: StarAuditorAnswer | undefined, circleImages: ICircleImages) => {
  switch (answer?.auditResult) {
    case AuditResult.Completed:
      return circleImages.okImage;
    case AuditResult.PartiallyCompleted:
      return circleImages.partialImage;
    case AuditResult.NotCompleted:
      return circleImages.badImage;
    case AuditResult.NotAudited:
      return circleImages.notAuditedImage;
    default:
      return new TextRun('');
  }
};

const getCompanyGoals = (starData: StarAuditorOverviewItem) => {
  const result = [
    new Paragraph({
      children: [new TextRun('Ziele des Unternehmens')],
      style: 'Heading1',
      pageBreakBefore: true,
    }),
  ];

  const goalAnswers =
    starData.starCompanyAnswers?.filter((x) => x.question?.goalQuestion && x.starAuditorAnswer?.notRelevant !== true) || [];

  for (let goal of goalAnswers) {
    result.push(
      new Paragraph({
        children: [new TextRun(goal.question?.content || '-')],
        style: 'Subheading',
      })
    );
    const content = goal.content?.split('\n').filter((x) => x) || [];
    for (let part of content) {
      result.push(new Paragraph({ children: [new TextRun(part)] }));
    }
  }

  return result;
};

const getAuditResult = (starData: StarAuditorOverviewItem) => {
  const result = [
    new Paragraph({
      children: [new TextRun('Ergebnis Audit')],
      style: 'Heading1',
    }),
    new Paragraph('Das Audit zur Konformität mit dem CSE Certified Sustainable Economics Standard ergab folgendes Ergebnis:'),
  ];

  const improvements = starData.starCompanyAnswers?.filter((x) => x.starAuditorAnswer?.auditResult === 2) || [];
  const problems = starData.starCompanyAnswers?.filter((x) => x.starAuditorAnswer?.auditResult === 4) || [];

  const answers =
    starData.starCompanyAnswers?.filter(
      (x) =>
        x.starAuditorAnswer?.auditResult &&
        (x.starAuditorAnswer?.auditResult === AuditResult.NotCompleted ||
          x.starAuditorAnswer?.auditResult === AuditResult.PartiallyCompleted)
    ) || [];

  if (problems.length === 0 && improvements.length === 0) {
    result.push(new Paragraph('Keine Abweichungen'));
  }

  if (problems.length === 0 && improvements.length !== 0) {
    result.push(
      new Paragraph({
        children: [
          new TextRun({
            children: ['Keine Abweichungen und folgende Verbesserungsmaßnahmen:'],
            bold: true,
            color: 'FF9933',
          }),
        ],
      })
    );
  } else if (problems.length !== 0 && improvements.length !== 0) {
    result.push(
      new Paragraph({
        children: [
          new TextRun({
            children: ['Folgende Verbesserungsmaßnahmen:'],
            bold: true,
            color: 'FF9933',
          }),
        ],
      })
    );
  }

  for (let answer of answers.filter((x) => x.starAuditorAnswer?.auditResult === AuditResult.PartiallyCompleted)) {
    result.push(
      new Paragraph({
        children: [new TextRun(answer.question?.content || '-')],
        style: 'Subheading',
      })
    );
    const partsCompanyAnswer = answer.content?.split('\n').filter((x) => x) || [];
    for (let part of partsCompanyAnswer) {
      result.push(new Paragraph(part));
    }
    if (partsCompanyAnswer.length === 0) {
      result.push(new Paragraph('-'));
    }

    result.push(
      new Paragraph({
        children: [
          new TextRun({
            children: ['Kommentar der auditierenden Person'],
            color: '2F5496',
          }),
        ],
        spacing: { after: 0 },
      })
    );
    const partsAuditorAnswer = answer.starAuditorAnswer?.auditReportText?.split('\n').filter((x) => x) || [];
    for (let part of partsAuditorAnswer) {
      result.push(new Paragraph(part));
    }
    if (partsAuditorAnswer.length === 0) {
      result.push(new Paragraph('-'));
    }

    result.push(
      new Paragraph({
        children: [new TextRun({ children: ['Verbesserungsmaßnahme'], color: '2F5496' })],
        spacing: { after: 0 },
      })
    );

    if (answer.starAuditorAnswer?.improvements) {
      const parts = answer.starAuditorAnswer?.improvements?.split('\n').filter((x) => x) || [];
      for (let part of parts) {
        result.push(new Paragraph(part));
      }
      if (parts.length === 0) {
        result.push(new Paragraph('-'));
      }
    } else {
      result.push(new Paragraph('-'));
    }
  }

  // --------------------

  if (problems.length !== 0) {
    result.push(
      new Paragraph({
        children: [
          new TextRun({
            children: ['Die folgenden Abweichungen und Auflagen:'],
            bold: true,
            color: 'C00000',
          }),
        ],
      })
    );
  }

  for (let answer of answers.filter((x) => x.starAuditorAnswer?.auditResult === AuditResult.NotCompleted)) {
    result.push(
      new Paragraph({
        children: [new TextRun(answer.question?.content || '-')],
        style: 'Subheading',
      })
    );
    const partsCompanyAnswer = answer.content?.split('\n').filter((x) => x) || [];
    for (let part of partsCompanyAnswer) {
      result.push(new Paragraph(part));
    }
    if (partsCompanyAnswer.length === 0) {
      result.push(new Paragraph('-'));
    }

    result.push(
      new Paragraph({
        children: [
          new TextRun({
            children: ['Kommentar der auditierenden Person'],
            color: '2F5496',
          }),
        ],
        spacing: { after: 0 },
      })
    );
    const partsAuditorAnswer = answer.starAuditorAnswer?.auditReportText?.split('\n').filter((x) => x) || [];
    for (let part of partsAuditorAnswer) {
      result.push(new Paragraph(part));
    }
    if (partsAuditorAnswer.length === 0) {
      result.push(new Paragraph('-'));
    }

    result.push(
      new Paragraph({
        children: [new TextRun({ children: ['Abweichungen'], color: '2F5496' })],
        spacing: { after: 0 },
      })
    );

    if (answer.starAuditorAnswer?.problems) {
      const parts = answer.starAuditorAnswer?.problems?.split('\n').filter((x) => x) || [];
      for (let part of parts) {
        result.push(new Paragraph(part));
      }
      if (parts.length === 0) {
        result.push(new Paragraph('-'));
      }
    } else {
      result.push(new Paragraph('-'));
    }
  }

  return result;
};

export const getSignatures = () => {
  const table = new Table({
    columnWidths: [2122, 1984, 6350],
    layout: TableLayoutType.FIXED,
    rows: [
      new TableRow({
        children: [
          new TableCell({ children: [new Paragraph(' ')] }),
          new TableCell({ children: [new Paragraph(' ')] }),
          new TableCell({ children: [new Paragraph(' ')] }),
        ],
      }),
    ],
  });

  return [
    new Paragraph(' '),
    table,
    new Paragraph({
      children: [new TextRun('Ort                              Datum                      Unterschrift Auditor')],
      style: 'Subheading',
    }),
    table,
    new Paragraph({
      children: [
        new TextRun(
          'Ort                              Datum                      Unterschrift Verantwortliche Person für das Audit'
        ),
      ],
      style: 'Subheading',
    }),
  ];
};
