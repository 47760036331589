import React from "react";
import { Header, Icon, Divider } from "semantic-ui-react";
import { localize } from "../../Localization/localize";

export const PageNotFound = () => (
  <section>
    <Divider horizontal hidden></Divider>
    <Divider horizontal hidden></Divider>
    <Header icon textAlign="center">
      <Icon name="exclamation triangle"></Icon>
      {localize.pageNotFound}
    </Header>
  </section>
);
