import React from "react";

interface IItemList {
  items: { title: string; onClick?: () => void }[];
}

export const ItemsList = (props: IItemList) => {
  const { items } = { ...props };

  return (
    <span className="dd-items-list ui multiple dropdown">
      {items.map((item, index) => (
        <span
          className="ui label dd-dotdotdot"
          title={item.title}
          key={index}
          onClick={item.onClick}>
          {item.title}
        </span>
      ))}
    </span>
  );
};
